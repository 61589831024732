{#if show}
  <div
    {id}
    {hidden}
    class="alert alert-{type}{className ? ` ${className}` : ''}"
    data-test={dataTest}
    transition:slideTransition|local={{ duration: transitionTime }}
    class:alert-dismissible={closable}
  >
    <div class={wrapperClass}>
      <div>
        {#if type === 'warning' && !noIcon}
          <Icon name="alert-triangle" class="text-warning" {title} />
        {:else if type === 'danger' && !noIcon}
          <Icon name="alert-triangle" class="text-danger" {title} />
        {:else if type === 'info' && !noIcon}
          <Icon name="info" class="text-info" {title} />
        {/if}
      </div>
      <div class="flex-grow">
        <slot />
      </div>
      {#if closable}<button on:click={() => (show = false)} type="button" class="close" data-dismiss="alert" use:tip={'Dismiss'}>&times;</button>{/if}
    </div>
  </div>
{/if}

<script>
  import tip from 'decorators/tip.js'
  import Icon from 'components/Icon.svelte'
  import { slide as slideTransition } from 'svelte/transition'

  export let show = true
  export let type = 'info'
  export let title = null
  export let closable = false
  export { className as class }
  export let wrapperClass = 'flex-row g1'
  export let timeToLive = null
  export let slide = false
  export let noIcon = false
  export let dataTest = null

  const id = ''
  const hidden = false
  let className = ''
  let timeout = null

  $: if (show) leaveWhenDead()
  $: transitionTime = slide ? 300 : 0

  function leaveWhenDead() {
    if (timeToLive == null) return
    clearTimeout(timeout)
    timeout = setTimeout(() => (show = false), timeToLive)
  }
</script>
