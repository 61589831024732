<div
  bind:this={dropdownElement}
  class="quick-dropdown{className ? ` ${className}` : ''}"
  data-test={dataTest}
  use:focusTrap={{ active: isOpen, createOptions: { clickOutsideDeactivates: true, allowOutsideClick: true } }}
>
  <a
    class:btn-default={!invalid && !hasWarnings && btnClass?.includes('btn-default')}
    class:btn-danger={invalid}
    class:btn-pulsate-danger={(invalid && !isOpen) || btnClass?.includes('btn-pulsate-danger')}
    class:btn-warning={hasWarnings}
    class:btn-pulsate-warning={(hasWarnings && !isOpen) || btnClass?.includes('btn-pulsate-warning')}
    class:disabled
    class="flex-row flex-align-center g05 text-left{btnClass ? ` ${btnClass}` : ''}"
    style={btnStyle}
    data-test={dataTest ? `${dataTest}-btn` : null}
    role={disabled ? 'none' : 'button'}
    tabindex={disabled ? null : '0'}
    {id}
    href={null}
    on:keydown={buttonElementKeydown}
    on:click={toggleIfLeftClick}
    use:tip={btnTitle}
    bind:this={buttonElement}
  >
    <div class="flex-grow {labelClass}">
      {#if showLoading}
        <Spinner />
      {:else if icon}
        <Icon name={icon} />
      {/if}
      <slot name="label">
        {#if label != null}
          <span>
            <SafeHtml value={label} />
          </span>
        {/if}
      </slot>
    </div>
    {#if !noCaret}
      <Icon name="caret-down" />
    {/if}
  </a>

  {#if isOpen}
    <div
      class="quick-dropdown-menu{dropdownClass ? ` ${dropdownClass}` : ''}"
      class:fixed-size={!autoSized}
      style={dropdownStyle}
      data-test="{dataTest ? `${dataTest}-` : ''}items"
      bind:this={dropdownMenuElement}
      on:click={closeIfAnyClickCloses}
      use:popper={!buttonElement
        ? null
        : {
            reference: buttonElement,
            strategy: positionMenu,
          }}
    >
      <slot />
    </div>
  {/if}
</div>

<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import focusTrap from 'decorators/focus-trap.js'
  import Icon from 'components/Icon.svelte'
  import Key from 'config/key.js'
  import popper from 'decorators/popper.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import Spinner from 'components/Spinner.svelte'
  import tip from 'decorators/tip.js'

  export let btnTitle = null
  export let isOpen = false
  export let canOpen = true
  export let dataTest = null
  let className = 'inline-block'
  export { className as class }
  export let btnClass = 'btn btn-default btn-sm'
  export let btnStyle = null
  export let dropdownClass = 'below left'
  export let dropdownStyle = null
  export let labelClass = ''
  export let anyClickCloses = false
  export let noCaret = false
  export let icon = null
  export let autofocus = false
  export let autofocusFirstItem = false
  export let autoSized = false
  export let disabled = false
  export let loading = false
  export let label = null
  export let id = null
  export let invalid = false
  export let hasWarnings = false
  export let stopPropagation = false
  export let positionMenu = 'absolute'

  const dispatch = createEventDispatcher()

  let dropdownElement = null
  let buttonElement = null
  let dropdownMenuElement = null
  let lastMouseDownTarget = null
  let ignoreChange = false
  let showLoading = loading
  let loadingTimeoutId = null

  onDestroy(removeListeners)
  onMount(() => {
    if (autofocus) focusButton()
  })

  $: _canOpen = canOpen && !disabled
  $: if (isOpen) addListeners()
  else removeListeners()

  // If the calling component toggles isOpen through data-binding
  // then we should focus the first element. However, since this block
  // will be rerun when isOpen changes (due to Svelte reactivity),
  // we need a mechanism to prevent double calls.
  $: {
    if (ignoreChange) {
      ignoreChange = false
    } else if (isOpen) {
      focusFirstAndDispatchOpen()
    } else {
      dispatchClose()
    }
  }

  $: icon, loading, updateShowLoading()

  function updateShowLoading() {
    if (!icon) {
      showLoading = false
      return
    }
    if (loading) {
      showLoading = true
      clearTimeout(loadingTimeoutId)
    } else if (loadingTimeoutId == null) {
      loadingTimeoutId = setTimeout(() => {
        showLoading = false
        loadingTimeoutId = null
      }, 200)
    }
  }

  function dispatchClose() {
    dispatch('close')
  }

  function addListeners() {
    document.addEventListener('mousedown', trackLastMouseDownTarget, { capture: true })
    document.addEventListener('click', clickListener, { capture: true })
  }

  function removeListeners() {
    document.removeEventListener('mousedown', trackLastMouseDownTarget, { capture: true })
    document.removeEventListener('click', clickListener, { capture: true })
  }

  export function focusButton() {
    buttonElement.focus()
  }

  function focusFirstItem() {
    setTimeout(() => dropdownMenuElement?.querySelector('input, label, a')?.focus?.())
  }

  function focusFirstAndDispatchOpen() {
    // Wait for next event loop (not just micro task as in tick()) so menu element is rendered
    setTimeout(() => {
      if (autofocusFirstItem) focusFirstItem()
      dispatch('open')
    })
  }

  function openIfNecessary() {
    if (isOpen) return
    if (!_canOpen) {
      dispatch('openBlocked')
      return
    }
    ignoreChange = true
    isOpen = true
    focusFirstAndDispatchOpen()
  }

  function closeIfNecessary() {
    if (!isOpen) return
    dispatchClose()
    ignoreChange = true
    isOpen = false
    lastMouseDownTarget = null
  }

  export function focusAndOpen() {
    focusButton()
    openIfNecessary()
  }

  function trackLastMouseDownTarget(e) {
    if (actuallyUnmounted()) return
    lastMouseDownTarget = e.target
  }

  function clickListener() {
    if (actuallyUnmounted()) return
    // If the element has since been removed from DOM, assume do nothing.
    // e.g. Open a date picker, select a date, the calendar goes away, but we should keep the QuickDropdown open.
    // This also handles if lastMouseDownTarget is null.
    if (!document.contains(lastMouseDownTarget)) return

    // For click events, e.target is the last element the mouse was on, so use the element they initially put their mouse down on instead.
    // wait til they finish the click to determine if we need to close it or not, so that click handlers can fire before we close
    // e.g. if they select all text in a box with mouse and end their "click" outside the menu, don't close
    if (clickedElement(dropdownElement)) return

    // modals are rendered outside the dropdown element,
    // so if they clicked a modal, don't close. Unless the modal is where the quickdropdown is rendered (i.e. when a quickdropdown is in a modal and we're clicking the modal to close the quickdropdown)
    const clickedModal = lastMouseDownTarget.closest('.modal')
    if (clickedModal && !clickedModal.contains(dropdownElement)) return

    closeIfNecessary()
  }

  function clickedElement(elem) {
    return elem === lastMouseDownTarget || elem.contains(lastMouseDownTarget)
  }

  function closeIfAnyClickCloses() {
    if (anyClickCloses) setTimeout(closeIfNecessary, 0) // Wait a bit so click registers prior to closing
  }

  function toggleIfLeftClick(e) {
    if (stopPropagation) e.stopPropagation()
    if (e.button !== 0) return
    toggle()
    e._cnWasHandled = true
  }

  function toggle() {
    if (isOpen) closeIfNecessary()
    else openIfNecessary()
  }

  function buttonElementKeydown(e) {
    switch (e.which || e.keyCode) {
      case Key.Down:
        openIfNecessary()
        return
      case Key.Enter:
        openIfNecessary()
        e.preventDefault()
        return
      case Key.Space:
        toggle()
        e.preventDefault()
        return
      case Key.Escape:
      case Key.Up:
        closeIfNecessary()
        return
      // Used to close on Shift+Tab; no longer do that as it's
      // more awkward when coupled with the focus trap behavior.
    }
  }

  // When running component tests, onDestroy() is never called. But we can detect
  // that we've been unmounted.
  function actuallyUnmounted() {
    return !document.contains(dropdownElement)
  }
</script>
