{#if $user.personas.length === 0}
  {#if $user.inactivePersonas.length === 0}
    <!--default route when they've signed up and are adding their first role -->
    <LazyRoute component="AfterJoining" />
    <LazyRoute path="add-role/*" component="AfterJoining" />
  {:else}
    <!--default route when they've signed up and added a role, but it has yet to be activated/approved-->
    <LazyRoute component="InactivePersonas" />
  {/if}
{:else if $persona.personaType != null}
  <LazyRoute component="Dashboard" />
  <LazyRoute path="/dashboard/temp/:slug/*splat" component="Dashboard" />
  <LazyRoute path="/dashboard/:slug/*splat" component="Dashboard" />

  <LazyRoute path="/step-submissions/*splat" component="MySteps" />
  <LazyRoute path="/step-submissions/temp/:slug/*splat" component="MySteps" />
  <LazyRoute path="/step-submissions/:slug/*splat" component="MySteps" />

  <LazyRoute path="/reports" component="Reports" />
  <LazyRoute path="/reports/temp/:slug/*splat" component="Reports" />
  <LazyRoute path="/reports/:slug/*splat" component="Reports" />

  <LazyRoute path="/courses/*" component="Courses" />
  {#if $persona.org != null}
    <LazyRoute path="/add-rotation/*" component="AddRotation" />
    <LazyRoute path="/schedule-students/*" component="ScheduleStudents" />
    <LazyRoute path="/org-wizard/:urlStepName/*urlSplat" component="OrgWizard" />
    <LazyRoute path="/org-wizard" component="OrgWizard" />
    <LazyRoute path="/opportunities/*" component="OpportunitiesView" />
    <MainContentRoute path="services/*" component={Services} />
    <LazyRoute path="/students/*" component="Students" />
    <LazyRoute path="/settings/*" component="Settings" />
  {:else if $user.isCN}
    <LazyRoute path="/cn-orgs/*" component="CNOrgs" />
    <LazyRoute path="/cn-subscriptions/*" component="CNSubscriptions" />
    <LazyRoute path="/cn-feature-packages/*" component="CNFeaturePackages" />
    <LazyRoute path="/cn-elective-request-settings/*" component="CNElectiveSettings" />
    <LazyRoute path="/cn-users/*" component="CNUsers" />
    <LazyRoute path="/cn-form-templates/*" component="CNFormTemplates" />
    <LazyRoute path="/cn-name-suggestions/*" component="CNNameSuggestions" />
    <LazyRoute path="/cn-dev-tools/*" component="CNDevTools" />
    <LazyRoute path="/cn-field-changer/*" component="CNFieldChanger" />
  {/if}
  <LazyRoute path="/documents/*" component="UserDocuments" />
  <LazyRoute path="/cn-documentation/*splat" component="CNDocumentation" />
{/if}

<!-- routes that work whether user has personas configured or not -->
<LazyRoute path="/search/*" component="Search" />
<LazyRoute path="/account/*" component="Account" />
<LazyRoute path="/connections/" component="Connections" />
<LazyRoute path="/notifications" component="Notifications" />
<LazyRoute path="/messages/:username/*" component="Messages" />
<LazyRoute path="/messages/*" component="Messages" />
<LazyRoute path="/user/:username/*" component="UserProfile" />
<!-- Legacy org route, when slugs were globally unique -->
<LazyRoute path="/orgs/:slug" component="OrgProfile" />
<!-- New org route, slugs are concatenated: /teams/3/grandparent/parent/child -->
<LazyRoute path="/orgs/:orgId/*relativePath" component="OrgProfile" />

<script>
  import LazyRoute from 'components/routing/LazyRoute.svelte'
  import persona from 'stores/persona.js'
  import user from 'stores/user.js'
  import MainContentRoute from './MainContentRoute.svelte'
  import Services from 'pages/authorized/org/Services.svelte'
</script>
