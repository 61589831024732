<Btn color="primary" on:click={startReopen} dataTest="reopen-match-btn" {disabled} {loading} icon="lock-open" animate>Reopen rotation</Btn>

{#if open}
  <Modal title="Reopen rotation" on:close={() => (open = false)} {focusTrapSettings}>
    <div class="modal-body">
      {#if rotationWasClosedLongAgo}
        <div class="alert alert-warning" data-test="rotation-closed-long-ago">
          Rotation was closed <FromNow date={match.dateClosed} showTooltip />.
        </div>
      {/if}
      <Form on:submit={reopen}>
        <FormGroup>
          <label for="new-match-dates">Optionally change start and end dates</label>
          <DatePicker name="new-match-dates" range bind:value={newStartDate} bind:endValue={newEndDate} />
        </FormGroup>

        <FormGroup>
          <label>Optionally extend onboarding deadline</label>
          <InputOptionalNumber
            bind:value={extendByDays}
            sm
            name="extend-onboarding-days"
            min={1}
            noValueIsNull
            noValueLabel={`Do not extend, leave onboarding deadline at ${originalOnboardingDeadline}`}
            valueLabelPostfix={pluralCount('day', extendByDays, 'omitNumber')}
            inputNumberClass="input-group-inline-grid"
            inputNumberStyle="width: 50px"
          />
          {#if extendByDays}
            <HelpBlock>Extend to {newOnboardingDeadline}. This will prevent auto-closing steps from closing this rotation in the future.</HelpBlock>
          {/if}
        </FormGroup>

        {#if $persona.personaType === PersonaType.ProviderStaff && match.pendingApplication && validator.dateBefore(newStartDate, today)}
          <Alert type="warning" dataTest="reopen-accept-warning">
            This application was not yet accepted. Reopening with a start date in the past will also accept it and extend the onboarding deadline.
          </Alert>
        {/if}

        <div>
          <Btn type="submit" icon="lock-open" {loading} class="btn btn-success reopen-submit-btn" dataTest="reopen-submit">Reopen</Btn>
          <Btn on:click={() => (open = false)}>Cancel</Btn>
        </div>
      </Form>
    </div>
  </Modal>
{/if}

<script>
  import { PersonaType } from 'config/enums.js'
  import Alert from './bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Btn from './bootstrap/Btn.svelte'
  import DatePicker from './fields/DatePicker.svelte'
  import Form from './Form.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import Modal from './Modal.svelte'
  import persona from 'stores/persona.js'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'
  import InputOptionalNumber from './InputOptionalNumber.svelte'
  import HelpBlock from './fields/HelpBlock.svelte'
  import FromNow from './FromNow.svelte'
  import { pluralCount } from 'services/string-utils'

  export let disabled
  export let match
  export let onMatchChanged

  const today = dayjs()

  const focusTrapSettings = { createOptions: { initialFocus: '.reopen-submit-btn' } }

  let open = false
  let newStartDate = null
  let newEndDate = null
  let extendByDays = null

  const rotationWasClosedLongAgo = dayjs(match.dateClosed).isBefore(today.add(-1, 'month'))
  const originalOnboardingDeadline = match.onboardingDeadline ? dayjs(match.onboardingDeadline).format('M/D/YYYY') : 'unset'

  // use current onboarding deadline if set, otherwise use the greater of start date or today
  const calculatedOnboardingDeadline = dayjs(match.onboardingDeadline ?? (dayjs(match.startDate).isAfter(today) ? match.startDate : today))
  $: newOnboardingDeadline = calculatedOnboardingDeadline.add(extendByDays, 'day').format('M/D/YYYY')

  let loading = false

  function startReopen() {
    newStartDate = match.startDate
    newEndDate = match.endDate
    open = true
  }

  async function reopen() {
    loading = true
    await getMatchController()
      .reopenMatch(
        {
          matchId: match.matchId,
        },
        {
          startDate: newStartDate,
          endDate: newEndDate,
          extendByDays,
        },
        api.noMonitor
      )
      .then(async () => {
        toaster.toast({ message: `Rotation reopened`, type: 'success', icon: 'edit' })
        open = false
        await onMatchChanged({ detailsHard: true, activity: true })
      })
      .finally(() => (loading = false))
  }
</script>
