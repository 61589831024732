{#if _show.shifts && shifts?.length}
  <div class="flex-column g05">
    {#each shifts as shift}
      <ShiftCard {capacity} {shift} show={_show} {isDetailsView} />
    {/each}
  </div>
{/if}

<script>
  import ShiftCard from 'components/ShiftCard.svelte'

  export let capacity // Need startDate, endDate, allowGroups
  export let shifts
  export let show
  export let isDetailsView = false

  $: _show = show || { shifts: true, shiftLimits: true, shiftStaff: true, shiftSchedule: true }
</script>
