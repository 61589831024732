<span class="tag label-{type}{className ? ` ${className}` : ''}" use:tip={title} data-test="tag-label">
  <slot />
</span>

<script>
  import tip from 'decorators/tip.js'
  export let type = 'default'
  export let title = null
  export { className as class }
  let className = ''
</script>
