<div class="flex-row flex-align-center g1{className ? ` ${className}` : ''}">
  <BtnGroupPicker {options} bind:value {name} btnClass={sm ? 'btn-sm' : ''} btnActiveClass="btn-primary" />
  <div class="flex-grow">
    <slot />
  </div>
</div>

<script>
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'

  export let value
  export let name = 'is-or-is-not'
  // These are usually bound to a variable called `exclude`...
  export let isValue = null
  export let isNotValue = true
  export let isLabel = null
  export let isNotLabel = null
  export let sm = false
  let className = null
  export { className as class }

  // we store null, so json in db is cleaner and doesn't store explicit false values
  // if switch to store false explicitly, migrate existing data to explicitly set all excludes to false
  // if migrate data, migrate "schoolIds" --> "guestOrgIds" too while at it
  $: if (value !== isValue && value !== isNotValue) value = isValue

  $: options = [
    { label: isLabel ?? 'is', value: isValue, dataTest: 'isorisnot-is' },
    { label: isNotLabel ?? 'is not', value: isNotValue, dataTest: 'isorisnot-isnot' },
  ]
</script>
