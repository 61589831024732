<div class="p3">
  <div class="flex-row flex-wrap">
    <div class="capacity-view-content flex-grow">
      {#if capacityGuest}
        <FormGroup>
          <label>Participant</label>
          <CapacityGuestProfilePictureAndName {capacityGuest} />
        </FormGroup>
      {/if}
      <!-- TODO(scheduling) show gantt chart of all rotations in the capacity, or at least link to dashboard pre-filtered to this capacity, something like that -->
      <FormGroup>
        <label>Rotations</label>
        <CapacityMatchesSummary {capacity} {capacityGuest} {onUpdate} />
      </FormGroup>
    </div>
    <CapacityActivity capacityId={capacity?.capacityId} serviceId={capacity?.serviceId} />
  </div>
</div>

<div class="modal-footer">
  <FormGroup>
    <ConfirmMatchesBtn {capacity} {capacityGuest} class="btn btn-primary" href={confirmMatchesHref} />
    <ReleaseMatchesBtn {capacity} {capacityGuest} class="btn btn-primary" href={releaseMatchesHref} />
    <Btn icon="close" on:click={onClose}>
      {#if canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest)}
        Cancel
      {:else}
        Close
      {/if}
    </Btn>
  </FormGroup>

  <Router>
    <Route path="confirm-matches">
      <ConfirmMatchesModal {capacity} {capacityGuest} onClose={onConfirmOrReleaseClose} onUpdate={onConfirmOrReleaseUpdate} />
    </Route>

    <Route path="release-matches">
      <ReleaseMatchesModal {capacity} {capacityGuest} onClose={onConfirmOrReleaseClose} onUpdate={onConfirmOrReleaseUpdate} />
    </Route>
  </Router>
</div>

<script>
  import { canConfirmAndReleaseCapacityGuestMatches } from 'services/capacity-usage.js'
  import { Route, Router } from 'svelte-routing'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityActivity from 'components/CapacityActivity.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import CapacityMatchesSummary from 'components/CapacityMatchesSummary.svelte'
  import ConfirmMatchesBtn from 'components/CapacityGuestView.ConfirmMatchesBtn.svelte'
  import ConfirmMatchesModal from 'components/CapacityGuestView.ConfirmMatchesModal.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import ReleaseMatchesBtn from 'components/CapacityGuestView.ReleaseMatchesBtn.svelte'
  import ReleaseMatchesModal from 'components/CapacityGuestView.ReleaseMatchesModal.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let capacity
  export let capacityGuest
  export let baseHref

  export let onClose = _.noop
  export let onUpdate = _.noop

  $: baseHrefOpportunities = `${baseHref.replace(/\/opportunities$/, '')}/opportunities`
  $: capacityHref = `${baseHrefOpportunities}/${capacity?.capacityId ?? ''}`
  $: confirmMatchesHref = `${baseHrefOpportunities}/confirm-matches/${capacity?.capacityId}`
  $: releaseMatchesHref = `${baseHrefOpportunities}/release-matches/${capacity?.capacityId}`

  function onConfirmOrReleaseClose() {
    unsavedForms.navigateSafe(capacityHref)
  }

  function onConfirmOrReleaseUpdate() {
    onConfirmOrReleaseClose()
    onUpdate()
  }
</script>

<style>
  .capacity-view-content {
    min-width: 400px;
  }
</style>
