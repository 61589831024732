<!--
  encode a value into a qrcode.
  example usage:
  <qrcode value="http://yay.com" />
-->
{#if value}
  <div data-test="qr-code" class={className}>
    {#if loading}
      <Spinner />
    {/if}
    <canvas bind:this={canvasElem} />
  </div>
{/if}

<script>
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'

  export let value = null
  let className = null
  export { className as class }

  let loading = true
  let canvasElem
  let qrCode

  $: if (canvasElem && qrCode && !validator.empty(value)) qrCode.toCanvas(canvasElem, value, { margin: 0 })

  import(/* webpackChunkName: "qrcode" */ 'qrcode').then(qr => {
    qrCode = qr
    loading = false
  })
</script>
