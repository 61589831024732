<!-- TODO: Would be nice to consolidate this with <ForgotPassword>, but that seems difficult to do *cleanly* -->
<Btn icon="email" loading={sending} {color} {autofocus} {confirm} on:click={sendPasswordReset} dataTest="send-password-reset-btn">
  {sending ? 'Sending…' : label}
</Btn>

<script>
  import { createEventDispatcher } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import api from 'services/api.js'
  import toaster from 'services/toaster.js'
  import { buildConfirm, buildUserDescription } from 'services/user-description.js'

  const dispatch = createEventDispatcher()

  export let user
  export let label = 'Send password reset instructions'
  export let color = 'default'
  export let autofocus = false

  const confirmOptions = {
    title: 'Send password reset instructions',
    confirmLabel: 'Send',
  }
  $: confirm = buildConfirm(confirmOptions, 'Are you sure you’d like to send password reset instructions to ', user)

  let sending = false
  async function sendPasswordReset() {
    if (sending) return
    // Capture state before API call in case `user` object changes before `await` returns
    const message = `Password reset instructions sent to ${buildUserDescription(user)}!`
    const event = { message, shouldToast: true }
    sending = true
    try {
      await api.account.forgotPasswordByUserId(user.userId)
      dispatch('sent', event)
      if (event.shouldToast) {
        toaster.toast({ message, html: true, type: 'success', icon: 'check' })
      }
    } finally {
      sending = false
    }
  }
</script>
