{#if inline}
  <ColorPickerChoices {select} {value} />
{:else}
  <QuickDropdown {dataTest} btnClass="specific-underline" noCaret bind:isOpen>
    <svelte:fragment slot="label">
      <SelectedColor dataTest={name} {value} {noCaret} />
    </svelte:fragment>

    <ColorPickerChoices {select} {value} />
  </QuickDropdown>
{/if}

<script>
  import ColorPickerChoices from 'components/fields/ColorPicker.Choices.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import SelectedColor from 'components/fields/SelectedColor.svelte'

  export let dataTest = null
  export let name = ''
  export let value = null
  export let inline = false
  export let noCaret = false

  let isOpen = false

  $: if (value == null) value = 'gray'

  function select(color) {
    value = color
    isOpen = false
  }
</script>
