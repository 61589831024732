<Form on:submit={submit}>
  <div class="modal-body">
    <div class="mb1">
      <Icon name="calendar-xmark" class="color-text-light-red" />
      <strong class="strongish">Date exceptions</strong>
      are dates outside of the normal availability of your opportunity (things like holidays, closures, or scheduled time off). These dates won’t be available
      to be scheduled by your <strong class="strongish">participants</strong>. If <strong class="strongish">Availability window</strong> dates overlap
      these dates, they will show up as exceptions within the window.
    </div>

    <FormGroup>
      <div class="flex-row g1">
        <DatePicker
          name="date-exception"
          range
          popCenter
          showDayCountTooltip
          autoSetOther={false}
          autoOpenEndDatePicker
          iconClass="color-text-dark-green"
          placeholder="Start date"
          endPlaceholder="End date"
          format={labelFormat}
          bind:value={datePickerStartDate}
          bind:endValue={datePickerEndDate}
        >
          <svelte:fragment slot="rangeEndLabel">
            <span>to</span>
            <Icon name="calendar" class="color-text-light-red" />
          </svelte:fragment>
        </DatePicker>
      </div>
    </FormGroup>
    <FormGroup>
      <div class="flex-row flex-wrap g1" style="min-height: 40px;">
        {#each input as dateException}
          <SelectedDate {dateException} clearable {onRemove} {labelFormat} {valueFormat} />
        {/each}
      </div>
    </FormGroup>
  </div>

  <div class="modal-footer">
    <SubmitBtn icon={inputInitial.length ? null : 'plus'} disabled={!hasChanges}>
      {#if inputInitial.length}
        Done
      {:else}
        Add exception{input.filter(i => i.isNew).length > 1 ? 's' : ''}
      {/if}
    </SubmitBtn>
    <Btn icon={inputInitial.length ? null : 'close'} class="btn btn-default" on:click={onCancel}>Cancel</Btn>
  </div>
</Form>

<script context="module">
  import dateService from 'services/date-service.js'

  const labelFormat = 'M/D/YYYY'
  const valueFormat = 'YYYY-MM-DD'

  function isOneDayAfterOrBefore(newStartDate, newEndDate, inputStartDate, inputEndDate) {
    return newStartDate.subtract(1, 'd').isSame(inputEndDate) || newEndDate.add(1, 'd').isSame(inputStartDate)
  }

  function isBetweenInclusive(date, start, end) {
    return date.isSame(start, 'd') || date.isSame(end, 'd') || date.isBetween(start, end) || date.isBetween(end, start)
  }

  export function addExceptionDate(start, end, flatInput) {
    let input = _.cloneDeep(flatInput)
    if (!start || !end) return input
    const newStartDate = dayjs(start, labelFormat)
    const newEndDate = dayjs(end, labelFormat)

    for (const dateInput of input) {
      dateInput.startDate = dayjs(dateInput.formattedStartDate, valueFormat)
      dateInput.endDate = dayjs(dateInput.formattedEndDate, valueFormat)
      if (!dateInput.startDate.isValid()) {
        dateInput.startDate = dayjs(dateInput.formattedStartDate, labelFormat)
        dateInput.endDate = dayjs(dateInput.formattedEndDate, labelFormat)
      }
      if (newStartDate.isSame(newEndDate)) {
        dateInput.pulsate =
          newStartDate.isSame(dateInput.startDate) ||
          newStartDate.isSame(dateInput.endDate) ||
          isOneDayAfterOrBefore(newStartDate, newEndDate, dateInput.startDate, dateInput.endDate) ||
          isBetweenInclusive(newStartDate, dateInput.startDate, dateInput.endDate)
      } else {
        dateInput.pulsate =
          newStartDate.isSame(dateInput.endDate) ||
          newEndDate.isSame(dateInput.startDate) ||
          isOneDayAfterOrBefore(newStartDate, newEndDate, dateInput.startDate, dateInput.endDate) ||
          dateService.rangesOverLap(newStartDate, newEndDate, dateInput.startDate, dateInput.endDate)
      }
    }
    const overlappingInputDates = input.filter(d => d.pulsate)
    if (!overlappingInputDates.length) {
      // if we are not animating, we are adding
      input = [
        ...input,
        {
          isNew: true,
          formattedStartDate: dateService.dateformat(start, valueFormat),
          formattedEndDate: dateService.dateformat(end, valueFormat),
          startDate: newStartDate,
          endDate: newEndDate,
        },
      ]
    } else {
      const minDate = dayjs.min([...overlappingInputDates.map(d => d.startDate), newStartDate])
      const maxDate = dayjs.max([...overlappingInputDates.map(d => d.endDate), newEndDate])
      const dateInput = {
        startDate: minDate,
        endDate: maxDate,
        formattedStartDate: minDate.format(valueFormat),
        formattedEndDate: maxDate.format(valueFormat),
        pulsate: true,
        isNew: true,
      }
      input = [...input.filter(d => !d.pulsate), dateInput]
    }
    return input.sort((a, b) => a.startDate.diff(b.startDate))
  }

  export function getInputDates(dateExceptions) {
    return dateService.mergeConsecutiveDates(
      dateExceptions.filter(d => !d.isNew),
      valueFormat
    )
  }

  export function getFormattedDates(input) {
    let dates = []
    for (const d of input) {
      dates = [...dates, ...dateService.getSingleDatesInRange(d.startDate, d.endDate, valueFormat)]
    }
    return [...new Set(dates)]
  }
</script>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import SelectedDate from 'components/SelectedDate.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let dateExceptions = []
  export let onSubmit = _.noop
  export let onCancel = _.noop

  const form = 'date-exceptions-form'
  let input = []
  let inputInitial = []
  let datePickerStartDate
  let datePickerEndDate

  $: dateExceptions, setInput()
  $: add(datePickerStartDate, datePickerEndDate)
  $: hasChanges = unsavedForms.formHasChanges(form, inputInitial, input)

  function setInput() {
    input = dateService.mergeConsecutiveDates(
      dateExceptions.filter(d => !d.isNew),
      valueFormat
    )
    inputInitial = _.cloneDeep(input)
  }

  function add(start, end) {
    if (!start || !end) return
    input = addExceptionDate(start, end, input)
    datePickerStartDate = null
    datePickerEndDate = null
  }

  function onRemove(exceptionStartDate, exceptionEndDate) {
    const i = input.findIndex(range => range.startDate == exceptionStartDate && range.endDate == exceptionEndDate)
    if (i > -1) {
      input.splice(i, 1)
      input = input
    }
  }

  function submit() {
    dateExceptions = getFormattedDates(input)
    onSubmit()
    inputInitial = _.cloneDeep(input)
    unsavedForms.del(form)
    onCancel()
  }
</script>
