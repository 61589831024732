{#if capacityGuest}
  <div data-test="matches-summary" class={className}>
    <!-- TODO(nursing): Confirm how we want to display this info (no Figma mockup of opportunity modal for schools, no slots icons on Rotation column) -->
    <div class="flex-row flex-align-center g05">
      <Icon lg autoColor name={iconName} class={iconClass} />

      {#if capacityGuest.guaranteedMatchCountGranted === 0 && capacityGuest.matchCount === 0}
        <span class="small">None available</span>
      {:else}
        <span class="small">{capacityGuest.matchCount}</span>
        <span>/</span>
        {#if capacity.maxMatches}
          <span class="small"
            >{capacityGuest.guaranteedMatchCountGranted} filled, {Math.max(0, capacityGuest.guaranteedMatchCountGranted - capacityGuest.matchCount)} available</span
          >
        {:else}
          <Icon lg name="infinity" class="text-info" />
          <span class="small">Unlimited</span>
        {/if}
      {/if}
    </div>

    <div class="small">
      {#if capacityGuest.desiredMatchCountConfirmedDateTime}
        <span class="text-success"><Icon name="check" /> Rotations were confirmed by school</span>
      {:else if capacityGuest.matchCountAvailable === 0}
        <span class="text-danger"><Icon name="slots-stop" /> Rotations limit was reached by school</span>
      {:else if capacityGuest.confirmDesiredMatchCountByDate}
        Rotations
        {#if confirmDesiredMatchCountByDateIsPast}
          should’ve been
        {:else}
          must be
        {/if}
        confirmed or released
        <FromNow
          showTooltip
          tooltipIconClass="color-text-blue"
          tooltipOptions={{ theme: 'light-gray-scrollable' }}
          date={capacityGuest.confirmDesiredMatchCountByDate}
          class={confirmDesiredMatchCountByDateIsPast ? 'text-danger' : confirmDesiredMatchCountByDateIsSoon ? 'text-warning' : null}
        />
        by school.
      {/if}
    </div>

    {#if showActions}
      <div class="flex-row flex-align-center g05 mt05">
        <ConfirmMatchesBtn {capacity} {capacityGuest} {onUpdate} class="btn btn-sm btn-default" href={confirmMatchesHref} />
        <ReleaseMatchesBtn {capacity} {capacityGuest} {onUpdate} class="btn btn-sm btn-default" href={releaseMatchesHref} />
      </div>
    {/if}
  </div>
{/if}

<script>
  import ConfirmMatchesBtn from 'components/CapacityGuestView.ConfirmMatchesBtn.svelte'
  import FromNow from 'components/FromNow.svelte'
  import Icon from 'components/Icon.svelte'
  import ReleaseMatchesBtn from 'components/CapacityGuestView.ReleaseMatchesBtn.svelte'

  export let capacity
  export let showActions = false
  export let confirmMatchesHref = null
  export let releaseMatchesHref = null
  export let capacityGuest = null
  export let onUpdate = _.noop
  let className = null
  export { className as class }

  $: confirmDesiredMatchCountByDateIsPast =
    capacityGuest && capacityGuest.confirmDesiredMatchCountByDate && dayjs(capacityGuest.confirmDesiredMatchCountByDate).isBefore(dayjs())
  $: confirmDesiredMatchCountByDateIsSoon =
    capacityGuest &&
    capacityGuest.confirmDesiredMatchCountByDate &&
    dayjs(capacityGuest.confirmDesiredMatchCountByDate).isBefore(dayjs().add(3, 'days'))

  $: iconClass =
    capacity.maxMatches == null ? 'text-info' : capacity.guaranteedMatchCountGranted > capacity.matchCount ? 'text-success' : 'text-danger'

  let iconName = null
  $: if (capacityGuest?.confirmDesiredMatchCountByDate) {
    iconName = capacityGuest.desiredMatchCountConfirmedDateTime == null ? 'slots-exclamation' : 'slots-check'
  } else {
    iconName = 'slots'
  }
</script>
