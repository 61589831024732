<div>
  {#if $isQuickFiltering && canQuickFilter}
    <QuickDropdown
      class={quickDropdownClass}
      bind:isOpen
      {canOpen}
      disabled={!canAct}
      on:close={() => filtersComponent?.resetQuickFilterSimulation()}
      on:openBlocked={onOpenBlocked}
      {btnTitle}
      dropdownClass="no-round btn-list"
      noCaret
      btnClass={$shouldHighlightQuickFilters ? 'btn btn-outline-info btn-pulsate-info' : 'btn btn-stealth'}
      btnStyle="padding: 2px 4px"
      {dataTest}
    >
      <div slot="label" class="flex-row flex-align-center g05">
        <slot>
          <Icon name={icon} class={className} {...iconProps} />
          <span>{value}</span>
        </slot>
      </div>

      {#if !noFilterActions}
        {#if hasFiltersVisibleToUser}
          <QuickFilterTagSimulationBtn action="add" text="Add this to my current filter set" disabled={addToOthersBtnDisabled} icon="filters" />
          <QuickFilterTagSimulationBtn action="set" text="Only filter by this" disabled={setBtnDisabled} />
        {:else}
          <QuickFilterTagSimulationBtn action="add" text="Filter by this" disabled={addOnlyBtnDisabled} />
        {/if}

        {#if configKey && hasFiltersVisibleToUser}
          {#if existingFiltersWrapped.length === 1}
            <QuickFilterTagSimulationBtn
              action="addExisting"
              text="Add to existing {type.toLowerCase()} filter"
              existingWrappedFilter={existingFiltersWrapped[0]}
            />
          {:else}
            {#each existingFiltersWrapped as w, i}
              <QuickFilterTagSimulationBtn
                action="addExisting"
                text="Add to {toOrdinalNumber(i + 1)} {type.toLowerCase()} filter"
                existingWrappedFilter={w}
              />
            {/each}
          {/if}
        {/if}
      {/if}

      {#if href}
        <Btn {href} class="btn-outline-default" icon="arrow-right">{viewThisBtnLabel}</Btn>
      {/if}

      {#if linkToCapacitiesPage}
        <Btn href={filteredCapacitiesPageHref} class="btn-outline-default" icon="arrow-right">
          View opportunities with this {type}
        </Btn>
      {/if}
    </QuickDropdown>

    <div class="hide">
      <div bind:this={tooltipElem} class="text-left">
        {#if canAct}
          {#if possibleActionTypes.length >= 2}
            <div class="flex-row">Click to…</div>
          {/if}

          {#if !noFilterActions}
            <!--
              Regarding the pseudo-disabled class, there's no need to check whether there are any enabled "Add to existing" filters,
              because the "Only filter by this" button will be enabled in that case.
            -->
            <div class="flex-row flex-align-center g05" class:pseudo-disabled={addToOthersBtnDisabled && setBtnDisabled && addOnlyBtnDisabled}>
              <Icon name="filter" class="text-gray" />
              <span>{tooltipFilterByPrefix ?? 'Filter by this'}</span>
              <Icon name={icon} class={className} {...iconProps} />
              <slot name="tooltipFilterByPostfix"><span>{tooltipFilterByPostfix ?? type}</span></slot>
            </div>
          {/if}

          {#if href}
            <div class="flex-row flex-align-center g05">
              <Icon name="arrow-right" class="text-gray" />
              <span>View this</span>
              <Icon name={icon} class={className} {...iconProps} />
              <span>{viewThisTooltipLabel}</span>
            </div>
          {/if}

          {#if linkToCapacitiesPage}
            <div class="flex-row flex-align-center g05">
              <Icon name="arrow-right" />
              <span>View</span>
              <Icon name="list" class="color-text-purple" />
              <span>Opportunities with this</span>
              <Icon name={icon} class={className} {...iconProps} />
              <span>{type}</span>
            </div>
          {/if}

          <slot name="tooltip" />
        {/if}
      </div>
    </div>
  {:else}
    <div
      class="g05 flex-align-center inline-flex-row{$shouldHighlightQuickFilters
        ? ' btn btn-outline-info btn-pulsate-info'
        : ' btn btn-stealth btn-no-hover btn-no-hover-bg'}"
      style="padding: 2px 4px;"
    >
      <slot>
        <Icon name={icon} class={className} {...iconProps} />
        <span data-test={value}>{value}</span>
      </slot>
    </div>
  {/if}
</div>

<script>
  import { buildFilterTypes, buildIgnoredFilterTypes } from 'components/CapacityFilters.svelte'
  import { FilterEncoder } from 'services/filters/index.js'
  import { setContext } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import shouldHighlightQuickFilters from 'stores/should-highlight-quick-filters.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import QuickFilterTagSimulationBtn from 'components/QuickFilterTag.SimulationBtn.svelte'
  import toOrdinalNumber from 'services/to-ordinal-number.js'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let icon
  export let iconProps = {}
  let className = null
  export { className as class }
  export let type
  export let filterType
  export let filterConfig = null
  export let configKey
  export let configValue
  export let simulationFilterOption = null
  export let value = null
  export let href = null
  export let viewThisTooltipLabel = type
  export let viewThisBtnLabel = `View this ${type.toLowerCase()}`
  export let hasFiltersVisibleToUser = false
  export let filtersComponent = null
  export let tooltipFilterByPrefix = null
  export let tooltipFilterByPostfix = null
  export let noFilterActions = buildIgnoredFilterTypes().includes(filterType)
  export let linkToCapacitiesPage = false
  export let dataTest = null
  export let quickDropdownClass = 'inline-block'
  export let canQuickFilter = true // Set this to false if you're not confident the data will be available as a filter option.

  let isOpen = false
  let tooltipElem = null

  $: meta = filtersComponent?.filterTypes[filterType]
  $: canHaveMultiple = meta?.canHaveMultiple ?? false
  $: filters = filtersComponent?.filters ?? []
  $: filterMetas = filters.map(f => filtersComponent?.filterTypes[f.type])
  // If a filter is added via an external source, it likely won't have meta specified.
  // Thus, the .map() above will result in, e.g., [undefined]
  $: hasFiltersVisibleToUser = filterMetas?.some(m => (m != null && m.canRemove == null) || m?.canRemove)
  $: existingFiltersWrapped = filters
    .filter(f => f.type === filterType)
    .map(f => {
      const values = f.config[configKey]
      const hasConfigValue = values?.includes(configValue) ?? false
      return {
        filter: f,
        values,
        hasConfigValue,
        onlyValueIsConfigValue: values?.length === 1 && hasConfigValue,
      }
    })

  $: alreadyHasFilterWithThisValue = existingFiltersWrapped.some(f => f.hasConfigValue)
  $: alreadyHasFilterWithOnlyThisValue = existingFiltersWrapped.some(f => f.onlyValueIsConfigValue)
  // TODO: Ideally we disable the "Only filter by this" for filters that pass filterConfig instead of configKey+configValue
  // However, the main use case Tim and I were trying to allow was for rotation start/end date filters, which are saved searches
  // and in order to make that work, we'd have to pull that filter out of the JSON in the DB on load.. turned out to be too much
  // work for the time-being.
  // || (filterConfig != null && existingFiltersWrapped.some(f => validator.equals(filterConfig, f.filter.config)))
  $: alreadyOnlyFilteringByThis = filters.length === 1 && alreadyHasFilterWithOnlyThisValue

  $: addToOthersBtnDisabled = alreadyHasFilterWithOnlyThisValue || (!canHaveMultiple && alreadyHasFilterWithThisValue)
  $: setBtnDisabled = alreadyOnlyFilteringByThis
  $: addOnlyBtnDisabled = alreadyHasFilterWithOnlyThisValue

  $: possibleActions = [
    ...(noFilterActions
      ? []
      : ['filter-add', hasFiltersVisibleToUser ? 'filter-set' : null, existingFiltersWrapped.length ? 'filter-addExisting' : null]),
    href ? 'view' : null,
    linkToCapacitiesPage ? 'viewOpportunities' : null,
  ].filter(_.identity)

  $: enabledActions = possibleActions.filter(action => {
    switch (action) {
      case 'filter-add':
        return !addToOthersBtnDisabled || (!hasFiltersVisibleToUser && !addOnlyBtnDisabled)
      case 'filter-set':
        return !setBtnDisabled
      case 'filter-addExisting':
        return existingFiltersWrapped.some(f => !f.hasConfigValue)
      case 'view':
        return true
      case 'viewOpportunities':
        return true
      default:
        return true
    }
  })

  $: possibleActionTypes = _.uniq(possibleActions.map(action => action.split('-')[0]))
  $: canOpen = possibleActions.length > 1
  $: canAct = enabledActions.length > 0

  // If we have a tooltip slot, there's a chance that the content could be scrollable so let's make sure they can get their mouse on top of it
  // in that case. Otherwise, it's totally in the way because of how many <QuickFilterTag>s (with their own tooltips) are next to each other.
  const hasTooltipSlot = !!$$props.$$slots?.tooltip
  $: btnTitle =
    tooltipElem && !isOpen && canAct ? { content: tooltipElem, options: { interactive: hasTooltipSlot, theme: 'light-gray-scrollable' } } : null

  function updateSimulation(action, event, existingFilter = null) {
    filtersComponent?.updateSimulation(action, event, filterType, filterConfig, configKey, configValue, simulationFilterOption, existingFilter)
  }

  function applyQuickFilter(action, existingFilter = null) {
    filtersComponent?.applyQuickFilter(filterType, filterConfig, configKey, configValue, action, existingFilter)
    isOpen = false
  }

  function onOpenBlocked() {
    if (possibleActions.length !== 1 || enabledActions.length !== 1) return
    const action = possibleActions[0]
    if (action !== enabledActions[0]) return
    if (action === 'filter-add') return applyQuickFilter('add')
    if (action === 'view') return unsavedForms.navigateSafe(href)
    if (action === 'viewOpportunities') return unsavedForms.navigateSafe(filteredCapacitiesPageHref)
  }

  let filteredCapacitiesPageHref = null
  $: if (linkToCapacitiesPage) filteredCapacitiesPageHref = buildFilteredCapacitiesPageHref(filterType, configKey, configValue)

  function buildFilteredCapacitiesPageHref(type, configKey, configValue) {
    const capacityFilterTypes = buildFilterTypes()
    const capacityIgnoredFilterTypes = buildIgnoredFilterTypes()
    const capacityFilterEncoder = new FilterEncoder(capacityFilterTypes, capacityIgnoredFilterTypes)
    const filters = [
      {
        type,
        config: {
          exclude: false,
          [configKey]: [configValue],
        },
      },
    ]
    const encoded = capacityFilterEncoder.encode(filters)
    return `/opportunities?filters=${encoded}`
  }

  setContext('qft', {
    updateSimulation,
    applyQuickFilter,
  })
</script>
