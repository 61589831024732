<span class="flex-row flex-align-center g05" data-test={dataTest}>
  {#if iconFirst}
    <Icon name={icon} class={iconClass} style={iconStyle} lg={iconLg} autoColor={iconAutoColor} title={iconTitle} />
    <Icon name={symbol} xs />
    <span>
      {#if qty === Infinity}
        <Icon lg name="infinity" class="text-info" />
      {:else if qty > 0}
        {readableNumber(qty)} {iconFirstLabel}
      {:else}
        {iconFirstZeroLabel}
      {/if}
    </span>
  {:else}
    <span>
      {#if qty === Infinity}
        <Icon lg name="infinity" class="text-info" />
      {:else}
        {readableNumber(qty)}
      {/if}
    </span>
    <Icon name={symbol} xs />
    <Icon name={icon} class={iconClass} style={iconStyle} lg={iconLg} autoColor={iconAutoColor} />
    {#if unit}
      <span>{pluralCount(unit, qty, 'omitNumber')}</span>
    {/if}
  {/if}
</span>

<script>
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'

  export let name = null
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  export let unit = null
  export let iconFirst = false
  export let iconFirstLabel = ''
  export let iconFirstZeroLabel = 'none'
  export let icon
  export let iconClass = null
  export let iconStyle = null
  export let iconAutoColor = false
  export let iconLg = false
  export let iconTitle = null
  export let nullMeansInfinity = false
  export let symbol = 'times'

  $: dataTest = name ? `${name}-count` : null
  $: qty = typeof count === 'number' ? count : nullMeansInfinity && count == null ? Infinity : _.size(count)
</script>
