<Card class="card-fill {statusHelper?.color ? 'card-' + statusHelper.color : ''}">
  <div class="mb1 flex-row">
    {#if multipleOthers}
      <div class="mr1">
        <InputCheckbox bind:checked name="other-step-submission-{stepSubmission.stepSubmissionId}" />
      </div>
    {/if}
    <div class="flex-grow">
      <div>
        {#if !stepSubmission.synced}
          <span class="text-warning" data-test="different-submission">Different {submissionType}</span>
        {:else if stepSubmission.syncedExact}
          <span class="text-success" data-test="same-submission-and-status">Same {submissionType} and status</span>
        {:else}<span class="text-success" data-test="same-submission">Same {submissionType}</span>{/if}
      </div>
      <div class="flex-grow">
        {#if stepSubmission.match}
          <h4 class="mb0">{stepSubmission.match.hostOrgName}</h4>
          <MatchCardDateInfo match={stepSubmission.match} />
          <div class="flex-row flex-align-center g05">
            <Icon name="shapes" class="text-purple" />
            <strong>{stepSubmission.match.serviceName}</strong>
          </div>
          <div class="flex-row flex-align-center g05">
            <Icon name="list" class="text-purple" />
            <strong>{stepSubmission.match.capacityName}</strong>
          </div>
        {/if}
      </div>
      <a href={null} tabindex="0" use:onInteract={() => (showDetails = !showDetails)}>
        {#if showDetails}Hide{:else}Show{/if}
        details
      </a>
    </div>
    {#if stepSubmission.match}
      <div>
        <strong class="text-{statusHelper.color}">
          <Icon name={statusHelper.icon} />
          {statusHelper.label}
        </strong>
      </div>
    {/if}
  </div>

  {#if showDetails}
    <div transition:slide|local>
      <StepSubmissionRow {stepSubmission} {matchModalMatchId} insideOtherStepSubmissionsModal {onActionCompleted} />
      {#if stepSubmission.matchId}
        <Btn class="btn-default btn-sm pull-right" to="matches/{stepSubmission.matchId}" icon="eye">View rotation</Btn>
      {/if}
    </div>
  {/if}
</Card>

<script>
  import matchStatusHelper from 'services/match-status-helper.js'
  import MatchCardDateInfo from 'components/MatchCard.DateInfo.svelte'

  import { slide } from 'svelte/transition'
  import Card from './Card.svelte'
  import InputCheckbox from './fields/InputCheckbox.svelte'
  import StepSubmissionRow from './StepSubmissionRow.svelte'
  import Icon from 'components/Icon.svelte'
  import { StepInputType } from 'config/enums.js'
  import Btn from './bootstrap/Btn.svelte'
  import onInteract from 'decorators/on-interact'

  export let checked
  export let multipleOthers
  export let stepSubmission
  export let matchModalMatchId
  export let onActionCompleted

  let showDetails = false

  $: statusHelper = stepSubmission.match ? matchStatusHelper.get(stepSubmission.match.status) : null
  $: submissionType = getSubmissionType(stepSubmission.step.stepInputType)

  function getSubmissionType(type) {
    switch (type) {
      case StepInputType.FileUpload:
        return 'files'
      case StepInputType.Form:
        return 'form submission'
      case StepInputType.ChecklistItem:
        return 'value'
      default:
        return 'submission'
    }
  }
</script>
