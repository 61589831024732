{#each orgs as o (o.orgId)}
  <div class="tree-item">
    <div class="tree-item-title">
      {#if !noToggle}
        <TreeToggle bind:expanded={expanded[o.orgId]} hide={!nested || !o.children.length} dataTest="orgs-picker-tree-toggle-{o.orgId}" />
      {/if}
      <div class:not-match={o.isNotMatch} class="tree-item-org">
        <slot name="org" org={o}>
          <DefaultOrgSlot org={o} {selected} {isNotMatch} {nested} {onSelectToggle} {small} {medium} {large} {xLarge} />
        </slot>
      </div>
    </div>
    {#if nested && o.children.length && expanded[o.orgId]}
      <div class="tree" transition:slide|local>
        <svelte:self orgs={o.children} {nested} {onSelectToggle} {selected} {expanded} {isNotMatch}>
          <div slot="org" let:org>
            <slot name="org" {org}>
              <DefaultOrgSlot {org} {selected} {isNotMatch} {nested} {onSelectToggle} {small} {medium} {large} {xLarge} />
            </slot>
          </div>
        </svelte:self>
      </div>
    {/if}
  </div>
{/each}

<script>
  import DefaultOrgSlot from 'components/filter-types/FilterTypeOrg.OrgsPicker.DefaultOrgSlot.svelte'
  import TreeToggle from 'components/TreeToggle.svelte'
  import { slide } from 'svelte/transition'

  export let orgs
  export let nested
  export let onSelectToggle
  export let selected
  export let expanded
  export let isNotMatch
  export let noToggle
  export let small = false
  export let medium = false
  export let large = false
  export let xLarge = false
</script>
