import { writable } from 'svelte/store'

const windowWidthStore = writable(window.innerWidth)
window.addEventListener('resize', () => {
  // note that most normal users will never fire this
  // event unless switching device orientation, so not
  // a big deal to leave listening even on pages where
  // it's not needed
  windowWidthStore.set(window.innerWidth)
})

export default windowWidthStore
