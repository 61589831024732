<InputCheckbox
  slot="after-filters"
  bind:checked={onlyAllowedStaff}
  name="only-allowed-staff"
  class="flex-row flex-align-center g05"
  labelClass="flex-row flex-align-center g05 m0 normal"
>
  Only show staff members who can access this opportunity’s rotations

  <svelte:fragment slot="after-label">
    <Help tipOptions={{ maxWidth: 400, theme: 'light-gray-scrollable', placement: 'bottom' }}>
      <div class="text-left">
        <h5>Staff members must have at least one role with access to:</h5>
        <ul class="m0 pl2">
          <li>
            <div class="flex-row flex-align-center g05">
              <span>The</span>
              <Icon name={$persona.orgIcon} class={$persona.orgIconClass} />
              <span>organization</span>
              <strong class="strongish"> {orgName}</strong>
            </div>
          </li>
          {#if $persona.personaType === PersonaType.ProviderStaff}
            {#if teamName}
              <li>
                <div class="flex-row flex-align-center g05">
                  <span>The</span>
                  <Icon name="users" class="color-text-orange" />
                  <span>team</span>
                  <strong class="strongish">{teamName}</strong>
                </div>
              </li>
            {/if}
            {#if serviceName}
              <li>
                <div class="flex-row flex-align-center g05">
                  <span>The</span>
                  <Icon name="users" class="color-text-purple" />
                  <span>service</span>
                  <strong class="strongish">{serviceName}</strong>
                </div>
              </li>
            {/if}
          {/if}
          {#if disciplines?.length}
            <li>
              <div class="flex-row flex-align-center g05">
                <span>At least one of the following</span>
                <Icon name="book" class="color-text-teal" />
                <span>disciplines:</span>
              </div>
              <ul class="m0 pl2">
                {#each disciplines as discipline}
                  <li><strong class="strongish">{discipline}</strong></li>
                {/each}
              </ul>
            </li>
          {/if}
        </ul>
      </div>
    </Help>
  </svelte:fragment>
</InputCheckbox>

<script>
  import { PersonaType } from 'config/enums.js'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'

  export let onlyAllowedStaff
  export let disciplines
  export let teamName
  export let serviceName
  export let orgName
</script>
