{#each users as u}
  {@const deleted = u.deletedDateTime != null}
  <div
    class="match-card-row flex-align-start {u.status != null && u.status != match.status
      ? `match-card-row-${matchStatusHelper.get(u.status).color}`
      : ''}"
  >
    <div>
      <ProfilePic
        src={u.profilePicture}
        initials={u.initials}
        name={u.name}
        id={u.userId}
        class="mr1"
        medium
        pseudoDisabled={deleted}
        title={deleted ? 'Inactive user' : null}
      />
    </div>
    <div class="row-text">
      <InactiveUserIcon show={deleted} />
      <span class="strong" data-test={u.name} class:text-gray={deleted}>{u.name}</span>
      <p class="small">
        {#if u.matchRole == MatchRole.Student}
          {u.studentYearDisciplineComputed ?? 'Student'}
        {:else}
          {formatEnumValue(MatchRole, u.matchRole)}
        {/if}
      </p>
      {#if showHide.school}
        <p class="small">{match.schoolName}</p>
      {/if}
    </div>
    <!-- only show status on each student if more than 1 student -->
    {#if u.status !== match.status}
      {@const userMatchStatus = matchStatusHelper.get(u.status)}
      <Icon name={userMatchStatus.icon} class="text-{userMatchStatus.color}" fw dataTest="match-user-status-icon" title={userMatchStatus.label} />
    {/if}
  </div>
{/each}

<script>
  import { formatEnumValue } from 'services/formatters'
  import { MatchRole } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import InactiveUserIcon from 'components/InactiveUserIcon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import ProfilePic from 'components/ProfilePic.svelte'

  export let users = []
  export let match = {}
  export let showHide = {}
</script>

<style lang="scss">
  .row-text p {
    white-space: break-spaces;
  }
</style>
