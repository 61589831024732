{#if barTotal > 0}
  <div class="progress lg mb1 clickable" use:tip={showDetails ? null : { content }} on:click|stopPropagation={toggleDetails}>
    {#if incompleteButScheduledPercent > 0}
      <div
        class="progress-bar bg-purple"
        role="progressbar"
        style="width: {incompleteButScheduledPercent}%"
        data-test="progress-bar-incomplete-but-scheduled"
      />
    {/if}
    {#if completedPercent > 0}
      <div class="progress-bar bg-success" role="progressbar" style="width: {completedPercent}%" data-test="progress-bar-completed" />
    {/if}
    {#if extraScheduledPercent > 0}
      <div class="progress-bar bg-warning" role="progressbar" style="width: {extraScheduledPercent}%" data-test="progress-bar-extra-scheduled" />
    {/if}
    {#if extraWorkedPercent > 0}
      <div class="progress-bar bg-danger" role="progressbar" style="width: {extraWorkedPercent}%" data-test="progress-bar-extra-worked" />
    {/if}
    {#if unscheduledPercent > 0}
      <div class="progress-bar bg-light-sky" role="progressbar" style="width: {unscheduledPercent}%" data-test="progress-bar-unscheduled" />
    {/if}
  </div>
{/if}

{#if showDetails}
  <div transition:slide|local>
    <CourseProgressDetails {incompleteButScheduled} {completed} {extraScheduled} {extraWorked} {unscheduled} />
  </div>
{:else}
  <div class="hide">
    <div bind:this={content}>
      <CourseProgressDetails {incompleteButScheduled} {completed} {extraScheduled} {extraWorked} {unscheduled} />
      <div class="smaller em mt05"><Icon name="sliders-simple" /> Click on the progress bar to toggle showing the hours breakdown.</div>
    </div>
  </div>
{/if}

<script>
  import tip from 'decorators/tip'
  import { tryPercent } from 'services/math-service'
  import { slide } from 'svelte/transition'
  import CourseProgressDetails from './CourseProgressDetails.svelte'
  import Icon from './Icon.svelte'

  export let scheduled
  export let completed
  export let extraScheduled
  export let extraWorked
  export let unscheduled
  export let showDetails = false

  let content = null

  $: incompleteButScheduled = Math.max(scheduled - completed, 0)
  $: barTotal = incompleteButScheduled + completed + extraScheduled + extraWorked + unscheduled
  $: extraScheduledPercent = tryPercent(extraScheduled, barTotal)
  $: unscheduledPercent = tryPercent(unscheduled, barTotal)
  $: extraWorkedPercent = tryPercent(extraWorked, barTotal)
  $: completedPercent = tryPercent(completed, barTotal)
  $: incompleteButScheduledPercent = tryPercent(incompleteButScheduled, barTotal)

  function toggleDetails() {
    showDetails = !showDetails
  }
</script>

<style>
  .progress {
    width: 180px;
  }
</style>
