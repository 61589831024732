{#if open && selectedDayCount > 0}
  <Modal on:close={cancel}>
    <div slot="title">
      <h4>{pluralCount('day', selectedDayCount)} selected</h4>
    </div>
    <div class="modal-body">
      {#if selectedDayCount === 1}
        <Tip>{dragSelectTip}</Tip>
      {/if}
      {#if availableShifts.length === 0}
        <HelpBlock>No shifts available for the {pluralCount('selected day', selectedDayCount)}</HelpBlock>
      {:else}
        <div class="shifts">
          {#each availableShifts as shift}
            <div class="shift">
              <div class="btn shift-name color-bg-{shift.color}" use:tip={'Add all people to this shift'} on:click={() => clickShift(shift)}>
                <div class="checkbox checkbox-primary" data-test="sp-checkbox">
                  <!-- must keep parent checkbox in sync manually: https://github.com/sveltejs/svelte/issues/4836 -->
                  <input
                    type="checkbox"
                    checked={meta[shift.shiftId].all}
                    indeterminate={!meta[shift.shiftId].all && meta[shift.shiftId].some}
                    on:change={e => {
                      e.target.checked = meta[shift.shiftId].all
                      e.target.indeterminate = !meta[shift.shiftId].all && meta[shift.shiftId].some
                    }}
                  />
                  <label>{shift.name}</label>
                </div>
                {#if shift.timeRange}
                  <div class="shift-time">
                    <Icon name="time" fw />
                    {shift.timeRange}
                  </div>
                {/if}
                {#if shift.location}
                  <div>
                    <!--TODO: match details doesn't bring down shift.location-->
                    <Icon name="map-marker" fw />
                    {shift.location.name}
                  </div>
                {/if}
              </div>
              <div class="people">
                {#each match.matchUsers as u}
                  {#if schedulableUsers[u.userId]}
                    <div class="checkbox checkbox-primary" on:click={() => clickPerson(shift, u)}>
                      <!-- must keep parent checkbox in sync manually: https://github.com/sveltejs/svelte/issues/4836 -->
                      <input
                        data-test="person-checkbox"
                        type="checkbox"
                        checked={meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all}
                        indeterminate={meta[shift.shiftId][u.userId].some && !(meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all)}
                        on:change={e => {
                          e.target.checked = meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all
                          e.target.indeterminate =
                            meta[shift.shiftId][u.userId].some && !(meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all)
                        }}
                      />
                      <label>
                        <ProfilePic src={u.profilePicture} initials={u.initials} name={u.name} id={u.userId} class="mr1" medium />
                        <div class="inline-block">
                          <strong>{u.name}</strong>
                          <p class="m0">{formatEnumKey(u.matchRoleName)}</p>
                        </div>
                      </label>
                    </div>
                  {/if}
                {/each}
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </div>
    <div class="modal-footer">
      <div class="text-center">
        <Btn on:click={apply} class="btn-primary" dataTest="apply">
          <Icon name="check" />
          Apply
        </Btn>
        <Btn on:click={() => (open = false)} class="btn-warning" dataTest="modify">
          <Icon name="expand" />
          Modify selection
        </Btn>
        <Btn on:click={cancel} dataTest="cancel">
          <Icon name="cancel" />
          Cancel
        </Btn>
      </div>
      <div class="mt1">
        {#if noExplicitDays}
          <Alert type="warning">{implicitMsg(match, matchPerStudent)}</Alert>
        {/if}
      </div>
    </div>
  </Modal>
{/if}

<script>
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Modal from 'components/Modal.svelte'
  import Icon from 'components/Icon.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import { formatEnumKey } from 'services/formatters.js'
  import tip from 'decorators/tip.js'
  import Tip from 'components/Tip.svelte'
  import { implicitMsg } from 'services/capacity-usage.js'
  import { dragSelectTip } from 'decorators/dragnselect.js'
  import { createEventDispatcher } from 'svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import { pluralCount } from 'services/string-utils.js'

  export let selectedDays
  export let match
  export let schedulableUsers
  export let matchPerStudent
  export let availableShifts

  const dispatch = createEventDispatcher()
  let matchDaysInitial
  let meta
  let open = true // open as soon as they've selected days, but give them ability to close and select more days

  $: matchDays = match.matchDays
  $: noExplicitDays = matchDays.length === 0
  $: selectedDays, openModal()
  $: selectedDayCount = selectedDays ? selectedDays.size : 0
  $: selectedMatchDays = selectedDays ? matchDays.filter(d => selectedDays.has(d.date)) : []
  $: selectedDates = selectedDays ? Array.from(selectedDays) : []
  $: selectedDays, matchDays, schedulableUsers, setMeta()

  function openModal() {
    matchDaysInitial = _.cloneDeep(match.matchDays)
    open = true
  }

  function clickShift(shift) {
    dispatch('toggleShift', { shift, deSelect: meta[shift.shiftId].some, days: selectedDays })
  }

  function clickPerson(shift, person) {
    dispatch('togglePerson', { shift, person, deSelect: meta[shift.shiftId][person.userId].some, days: selectedDays })
  }

  function cancel() {
    selectedDays = new Set()
    match.matchDays = matchDaysInitial
  }

  function apply() {
    selectedDays = new Set()
  }

  function setMeta() {
    meta = {}
    for (const s of match.shifts) {
      const selectedForShift = selectedMatchDays.filter(d => d.shiftId === s.shiftId)
      // every selected day has every person selected for this shift
      const allShift = selectedDates.every(date => selectedForShift.some(d => d.date === date && d.userId == null))

      // some selected days have this shift used
      const someShift = allShift ? true : selectedDates.some(date => selectedForShift.some(d => d.date === date))

      meta[s.shiftId] = {
        all: allShift,
        some: someShift,
      }

      // at people level too
      for (const userId in schedulableUsers) {
        const forDateAndUser = date => selectedForShift.some(d => d.date === date && (d.userId === userId || d.userId == null))
        // every selected day has this person selected for this shift
        const allPerson = allShift ? true : selectedDates.every(forDateAndUser)

        // some selected days have this person on this shift
        const somePerson = allPerson ? true : selectedDates.some(forDateAndUser)

        meta[s.shiftId][userId] = {
          all: allPerson,
          some: somePerson,
        }
      }
    }
  }
</script>

<style>
  .shifts {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
  }
  .shift {
    margin: 10px;
  }
  .shift-name {
    color: #eee;
    width: 100%;
  }
  .shift-time {
    font-style: italic;
  }
  .people {
    margin-top: 20px;
  }
</style>
