<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:item selectedItemsContainerClass={null} hideSearchBox>
  <OrgsPicker
    slot="picker"
    orgs={filterOptions}
    bind:value={filter[appliedMeta.filterProp]}
    hideMap
    multiple
    classContainer="scrollable-md"
    {placeholder}
    filterClass="mt1"
  />

  <OrgProfilePictureAndName
    slot="selectedItem"
    name={item.name}
    profilePicture={item.profilePicture}
    class="inline-flex-row flex-align-center nowrap"
    nameClass={item.orgId === filter.simulatedValue ? 'strong text-success' : 'strong'}
    maxNameLength={25}
  />
</FilterTypeListFilter>

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OrgsPicker from 'components/filter-types/FilterTypeOrg.OrgsPicker.svelte'

  export const meta = {
    ...baseMeta,
    idKey: 'orgId',
    excludable: true,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
  export let placeholder = 'Search locations'
</script>
