{#if canPause}
  <Btn on:click={() => (open = true)} class="btn-gray" dataTest="pause-match-btn" animate>
    <Icon name="pause" fw />
    Pause rotation
  </Btn>

  {#if open}
    <Modal title="Pause rotation" on:close={() => (open = false)}>
      <div class="modal-body">
        <Form on:submit={pause}>
          <FormGroup valid={validator.required(reason)} validationMessage="Please explain why you're pausing the rotation.">
            <label for="pause-reason">
              Comments / explanation
              <RequiredMarker />
            </label>
            <InputTextarea maxlength={256} autofocus bind:value={reason} name="pause-reason" />
          </FormGroup>
          <div>
            <Btn type="submit" {loading} class="btn btn-gray" dataTest="pause-submit" icon="pause">Pause rotation</Btn>
            <Btn on:click={() => (open = false)}>Cancel</Btn>
          </div>
        </Form>
      </div>
    </Modal>
  {/if}
{:else if canUnpause}
  <Btn on:click={startResume} class="btn-success" dataTest="resume-match-btn" icon="play" animate>Resume rotation</Btn>

  <Btn
    on:click={startComplete}
    class="btn-gray"
    title="If this rotation will never be unpaused, you can mark it as complete instead."
    dataTest="complete-match-btn"
    icon="check"
    animate
  >
    Complete rotation
  </Btn>

  {#if open}
    <Modal title="{completing ? 'Complete' : 'Resume'} rotation" on:close={() => (open = false)}>
      <div class="modal-body">
        <Form on:submit={unpause}>
          <FormGroup valid={validator.date(newStartDate) && validator.dateSameOrBefore(newStartDate, newEndDate)}>
            <label for="new-start-date">Start date</label>
            <DatePicker name="new-start-date" bind:value={newStartDate} max={newEndDate} />
          </FormGroup>

          {#if completing}
            <FormGroup valid={validator.date(newEndDate) && validator.dateSameOrBefore(newEndDate, yesterday)}>
              <label for="new-end-date">When did the rotation end?</label>
              <DatePicker name="new-end-date" bind:value={newEndDate} max={yesterday} />
            </FormGroup>
          {:else}
            <FormGroup valid={validator.date(newEndDate) && validator.dateAfter(newEndDate, today)}>
              <label for="new-end-date">When should the rotation end?</label>
              <DatePicker name="new-end-date" bind:value={newEndDate} min={tomorrow} />
            </FormGroup>
          {/if}

          <FormGroup>
            <label for="unpause-reason">Comments / explanation (optional)</label>
            <InputTextarea maxlength={256} autofocus bind:value={reason} name="unpause-reason" />
          </FormGroup>
          <div>
            <Btn type="submit" icon={completing ? 'check' : 'play'} {loading} class="btn btn-success" dataTest="unpause-submit">
              {#if completing}Complete rotation{:else}Resume rotation{/if}
            </Btn>
            <Btn on:click={() => (open = false)}>Cancel</Btn>
          </div>
        </Form>
      </div>
    </Modal>
  {/if}
{/if}

<script>
  import { MatchStatus } from 'config/enums.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import Icon from 'components/Icon.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import Modal from 'components/Modal.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'

  export let match
  export let onMatchChanged

  const yesterday = dayjs().add(-1, 'days')
  const today = dayjs()
  const tomorrow = dayjs().add(1, 'days')
  let open = false
  let reason = ''
  let loading = false
  let completing = false
  let newStartDate = null
  let newEndDate = null

  const pausableStatuses = [MatchStatus.Active, MatchStatus.Ready, MatchStatus.Onboarding]

  $: canPause = !match.paused && pausableStatuses.indexOf(match.status) > -1 && (match.isCoordinator || match.isSchoolCoordinator)
  $: canUnpause = match.paused && (match.isCoordinator || match.isSchoolCoordinator)

  function startResume() {
    newStartDate = match.startDate
    newEndDate = dayjs(match.endDate).isBefore(tomorrow) ? tomorrow.format('M/D/YYYY') : match.endDate
    open = true
    completing = false
  }

  function startComplete() {
    newStartDate = match.startDate
    newEndDate = dayjs(match.endDate).isAfter(yesterday) ? yesterday.format('M/D/YYYY') : match.endDate
    open = true
    completing = true
  }

  async function pause() {
    loading = true
    await getMatchController()
      .pause({ matchId: match.matchId }, { reason }, api.noMonitor)
      .then(async () => {
        toaster.toast({ message: 'Rotation paused', type: 'success', icon: 'edit' })
        open = false
        reason = ''
        await onMatchChanged({ details: true, activity: true })
      })
      .finally(() => (loading = false))
  }

  async function unpause() {
    loading = true
    await getMatchController()
      .unpause(
        {
          matchId: match.matchId,
        },
        {
          reason,
          completing,
          startDate: newStartDate,
          endDate: newEndDate,
        },
        api.noMonitor
      )
      .then(async () => {
        const action = completing ? 'completed' : 'resumed'
        toaster.toast({ message: `Rotation ${action}`, type: 'success', icon: 'edit' })
        open = false
        completing = false
        reason = ''
        await onMatchChanged({ details: true, activity: true })
      })
      .finally(() => (loading = false))
  }
</script>
