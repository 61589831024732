<TagCloudItems items={rotationTypes} type="Experience type" icon="briefcase-medical" theme="rotation-type" dataTest="rotation-type-tag" />
<TagCloudItems items={specialties} type="Specialty" icon="file-certificate" theme="specialty" dataTest="specialty-tag" />
<TagCloudItems items={disciplines} type="Discipline" icon="book" theme="discipline" dataTest="discipline-tag" />
<TagCloudItems items={orgTags} type="Organization type" icon="building" theme="primary" dataTest="org-tag" />

<script>
  import TagCloudItems from 'components/TagCloud.Items.svelte'

  export let rotationTypes
  export let specialties
  export let disciplines
  export let orgTags
</script>
