<Filters
  bind:this={filtersComponent}
  bind:filters
  interceptors={_interceptors}
  {includedFilterTypes}
  excludedFilterTypes={_excludedFilterTypes}
  {metaMapFuncs}
  {onChanged}
  class={null}
  filterOptionsController="matchFilterOptions"
  {label}
  {fullWidth}
  {showSearchBox}
  keywordSearchPlaceholder="Search rotations by name, organization, or group"
  keywordSearchId="match-filters-keyword-search"
>
  <slot />
</Filters>

<script context="module">
  import { buildFilterTypesArray, buildIgnoredFilterTypesArray } from 'components/Filters.svelte'

  const includedFilterTypes = [
    FilterType.Agreements,
    FilterType.MatchCapacity,
    FilterType.MatchShift,
    FilterType.MatchRequestType,
    FilterType.MatchHostOrgCoordinators,
    FilterType.MatchCloseReason,
    FilterType.Steps,
    FilterType.MatchHasProposedChanges,
    FilterType.MatchHasIncompleteSteps,
    FilterType.MatchHasStepsICanComplete,
    FilterType.MatchHasStepsDueSoon,
    FilterType.MatchHasStepsICanVerify,
    FilterType.KeywordSearch,
    FilterType.HostOrgs,
    FilterType.MatchHostOrgAddress,
    FilterType.Teams,
    FilterType.MatchPastStatuses,
    FilterType.MatchPreceptors,
    FilterType.GuestOrgCoordinators,
    FilterType.SchoolFaculty,
    FilterType.GuestOrgs,
    FilterType.Course,
    FilterType.MatchGuestOrgAddress,
    FilterType.Services,
    FilterType.MatchCreatedDate,
    FilterType.MatchSubmittedDate,
    FilterType.MatchStartDate,
    FilterType.MatchEndDate,
    FilterType.MatchClosedDate,
    FilterType.MatchStatusCategory,
    FilterType.MatchStatuses,
    FilterType.Disciplines,
    FilterType.Students,
    FilterType.StudentYears,
    FilterType.MatchWaitlistedBy,
    // When embedding <MatchFilters>, you likely want to add this to your excludedFilterTypes array.
    // making <MatchFilters> have a prop where you can pass in additional includedFilterTypes,
    // but that would screw up other things. Not worrying about it for now.
    FilterType.StepSubmissionTargetUser,
    FilterType.MatchCustomTags,
    FilterType.RotationTypes,
    // FilterType.CapacityLocations, // simply not implemented backend. and filtertypecapacitylocations should be set to use getorgs as options endpoint.
    // FilterType.Specialties, // simply not implemented backend
  ]

  const metaMapFuncs = {
    [FilterType.RotationTypes]: meta => {
      meta.toManyNoneCheckboxLabel = 'Include rotations without experience types'
      return meta
    },
    [FilterType.Disciplines]: meta => {
      meta.labelApplied = 'student discipline'
      meta.hasDoesntHave = true
      meta.toMany = true
      meta.toManySuffix = 'of the following disciplines'
      return meta
    },
    [FilterType.StudentYears]: meta => {
      meta.labelApplied = 'student year'
      meta.hasDoesntHave = true
      meta.toMany = true
      meta.toManySuffix = 'of the following student years'
      return meta
    },
  }

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }

  export function buildIgnoredFilterTypes(excludedFilterTypes = []) {
    return buildIgnoredFilterTypesArray(includedFilterTypes, excludedFilterTypes)
  }
</script>

<script>
  import { FilterType } from 'config/enums.js'
  import Filters from 'components/Filters.svelte'
  import persona from 'stores/persona'

  export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  export let excludedFilterTypes = []
  export let interceptors = {}
  export let label = null
  export let fullWidth = false
  export let showSearchBox = false

  $: _interceptors = {
    ...interceptors,
  }

  $: _excludedFilterTypes = excludedFilterTypes.concat(
    $persona.isStudent ? [FilterType.MatchHasStepsICanVerify, FilterType.MatchWaitlistedBy, FilterType.GuestOrgs] : []
  )
</script>
