<FilterTypeUserListFilter {filter} {editing} {filterOptions} {appliedMeta} />

<script context="module">
  import FilterTypeUserListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeUserListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.MatchPreceptors,
    label: 'Preceptor',
    labelApplied: 'preceptor',
    icon: 'hospital-user',
    iconClass: 'color-text-orange',
    optionsEndpoint: 'getPreceptorUsers',
    toManySuffix: 'of the following preceptors',
    hasDoesntHave: true,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
