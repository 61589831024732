<div class="modal-footer">
  <Btn icon="save" on:click={onSave} {disabled} class="btn-primary" dataTest="save-staff-btn">
    {staff.staffId == null ? 'Add new staff' : 'Save'}
  </Btn>
  <Btn icon="close" on:click={onClose}>Cancel</Btn>
  {#if showDelete}
    <StaffConfirmDeleteModal staff={deletingStaff} {isCapacityStaff} {onDelete} onClose={() => (deletingStaff = null)} />
    <Btn icon="delete" color="outline-danger pull-right" on:click={onDeleteClicked} dataTest="remove-staff-btn">
      {deleteButtonLabel}
    </Btn>
  {/if}
</div>

<script>
  import { Permission } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import StaffConfirmDeleteModal from 'components/Staff.ConfirmDeleteModal.svelte'
  import personaService from 'services/persona-service.js'

  export let orgStaff = {}
  export let disabled
  export let deleteButtonLabel = ' Remove from staff'
  export let isCapacityStaff
  export let routingConfig
  export let onDelete = _.noop
  export let onSave = _.noop
  import user from 'stores/user.js'

  const staff = {}
  let deletingStaff

  $: orgStaff, setStaff()
  $: canManageEntireStaffRecord = personaService.hasStaffPermission(Permission.ManageStaff, staff.orgId)
  $: showDelete = staff.userId && (isCapacityStaff || (!isCurrentUser(staff.userId) && canManageEntireStaffRecord))

  function isCurrentUser(userId) {
    return $user?.userId === userId
  }

  function setStaff() {
    staff.staffId = orgStaff.staffId
    staff.orgId = orgStaff.org?.orgId
    staff.userId = orgStaff.user?.userId
    staff.name = orgStaff.user?.name
  }

  function onDeleteClicked() {
    deletingStaff = staff
  }

  function onClose() {
    routingConfig.close()
  }
</script>
