export const Token = {
  ArrayStart: '(',
  ArrayEnd: ')',
  ArgSeparator: ',',
  FilterSeparatorOrEmptyArray: ';',
  Null: '_',
  Undefined: '@',
  True: '*',
  False: '!',
  Escape: '~',
}
export const tokens = Object.values(Token)
