<a
  {href}
  class="card match-card card-{matchStatusHelper.get(match.status).color}{className ? ` ${className}` : ''}"
  class:last-open={$lastOpenRotation === match.matchId}
  on:click={() => ($lastOpenRotation = match.matchId)}
  data-test="match-card"
>
  <div class="match-icons flex-row flex-align-center" style="gap: 1px">
    <div class="hide">
      <div bind:this={tooltipElem}>
        <!-- Prevent unnecessary DOM chewing up memory -->
        {#if match.hasCacheTaskQueueItem}
          <RotationSyncAlertMessage onReload={() => window.location.reload()} />
        {/if}
      </div>
    </div>
    {#if match.hasCacheTaskQueueItem}
      <SyncAlertIcon sm class="text-warning" tooltip={{ content: tooltipElem, options: { interactive: true, placement: 'top' } }} />
    {/if}

    {#if match.hasStepsICanComplete}
      <Icon name="circle" class="text-warning" dataTest="has-steps-i-can-complete-icon" title="Has steps you can complete" />
    {/if}

    {#if match.hasStepsICanVerify}
      <Icon name="circle" class="text-purple" dataTest="has-steps-i-can-verify-icon" title="Has steps you can verify" />
    {/if}

    {#if match.hasProposedChanges}
      <Icon name="has-changes" class="text-warning" dataTest="has-proposed-changes-icon" title="Has proposed changes" />
    {/if}

    {#if match.isDoubleBook}
      <Icon
        name="alert-triangle"
        class="text-warning"
        dataTest="is-double-book"
        title="This rotation has potential date conflicts with another rotation."
      />
    {/if}

    {#if match.hasStepsDueSoon}
      <Icon name="circle" class="text-danger" dataTest="danger-icon" title="This rotation has steps that are due soon." />
    {/if}

    <Icon name={matchStatus.icon} class="text-{matchStatus.color}" dataTest="match-status-icon" title={matchStatus.label} />
  </div>

  <div class="text">
    <div class="strong">
      <MatchCardDateInfo {match} />
    </div>

    {#if match.displayName}
      <div class="display-name-block">
        <h3 use:tip={match.displayName}>{match.displayName}</h3>
      </div>
    {/if}
    {#if showHide.location || showHide.service || showHide.opportunity}
      <div class="match-card-row match-card-row-org flex-align-start">
        {#if showHide.location}
          <div>
            <ProfilePic src={match.orgProfilePicture} name={match.orgName} class="mr1" medium />
          </div>
        {/if}
        <div class="row-text">
          {#if showHide.location}
            <h3>
              {match.orgName}
            </h3>
            {#if match.hostTeamName}
              <p class="strong">{match.hostTeamName}</p>{/if}
          {/if}
          {#if showHide.service && match.serviceName}
            <p class="mb0 strong">
              <Icon name="shapes" class="color-text-purple" />
              {match.serviceName}
            </p>
          {/if}
          {#if showHide.opportunity && match.capacityName}
            <p class="mb0">
              <Icon name="list" class="color-text-purple" />
              {match.capacityName}
            </p>
          {/if}
        </div>
      </div>
    {/if}

    {#if showHide.students}
      <MatchCardUsers users={match.students} {match} {showHide} />
    {/if}
    {#if showHide.faculty}
      <MatchCardUsers users={match.faculty} {match} {showHide} />
    {/if}
    {#if showHide.preceptors}
      <MatchCardUsers users={match.preceptors} {match} {showHide} />
    {/if}
  </div>
</a>

<script>
  import Icon from 'components/Icon.svelte'
  import lastOpenRotation from 'stores/last-open-rotation.js'
  import MatchCardDateInfo from 'components/MatchCard.DateInfo.svelte'
  import MatchCardUsers from 'components/MatchCard.Users.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RotationSyncAlertMessage from 'components/RotationSyncAlertMessage.svelte'
  import SyncAlertIcon from 'components/SyncAlertIcon.svelte'
  import tip from 'decorators/tip.js'

  // import UsersProfilePicture from './UsersProfilePicture.svelte'
  // export let filtersComponent = null

  export let match = null
  export let href = null
  export let showHide = {}

  let className = null
  export { className as class }

  let tooltipElem = null

  $: matchStatus = matchStatusHelper.get(match.status)
</script>

<style lang="scss">
  .display-name-block {
    overflow: hidden;
  }
</style>
