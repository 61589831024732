// This store will contain an object that looks like this, to be used for showing # of items that may need attention in navigation:
// {
//   pendingAgreementCount: 2,
//   pendingStaffCount: 1,
//   pendingStudentsCount: 3
//   pendingImportStaffCount: 0,
// }

import { Permission, PersonaType } from 'config/enums.js'
import { writable } from 'svelte/store'
import api from 'services/api.js'
import persona from 'stores/persona.js'
import personaService from 'services/persona-service.js'
import sockets from 'services/sockets.js'

function createStore() {
  const getApiData = () => {
    if (
      $persona.orgId == null ||
      $persona.orgId == 0 ||
      $persona.personaType === PersonaType.Student ||
      !personaService.hasAnyPermissionAnywhere(Permission.ManageStaff, Permission.ManageAgreements, Permission.ManageSchoolStudents)
    )
      return Promise.resolve()
    return api.org.getPendingConfigCounts({ orgId: $persona.orgId }, api.noMonitor)
  }

  const { subscribe, set } = writable(null)
  const load = () => getApiData().then(response => set(response))

  // sub to persona, and reload when it changes
  let $persona
  persona.subscribe(p => {
    $persona = p
    // console.log('refreshing counts for persona change')
    load()
  })

  // reload when staff, students, or agreements change
  // we can probably optimize this and send a different socket event, or include info in this event that tells us whether it changed to or from pending status
  sockets.on('UI_AgreementForm', () => {
    // console.log('refreshing counts for agreement change')
    load()
  })
  sockets.on('UI_PendingStaffChanged', () => {
    // console.log('refreshing counts for staff change')
    load()
  })
  sockets.on('UI_PendingStudentsChanged', () => {
    // console.log('refreshing counts for students change')
    load()
  })
  sockets.on('UI_PendingImportStaffChanged', () => {
    // console.log('refreshing counts for import staff change')
    load()
  })

  return {
    subscribe,
    set,
    load,
  }
}

export default createStore()
