{#if count > 0}
  <p data-test={dataTest} class="text-left {valid ? 'valid' : submitted ? 'invalid' : ''}">
    Minimum
    {pluralCount(unit, count)}
  </p>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'

  export let count
  export let dataTest
  export let valid
  export let submitted
  export let unit
</script>

<style lang="scss">
  @import '../../css/helpers';
  .valid {
    color: $success;
  }
  .invalid {
    color: $danger;
  }
  p {
    margin: 0;
    font-size: 12px;
    list-style: 14px;
    color: #475569;
  }
</style>
