<div>
  <PersonaFilterWarning />

  {#if $persona.personaType === PersonaType.ProviderStaff && personaService.hasPermissionAnywhere(Permission.ManageOpportunitiesAndServices)}
    <Btn icon="plus" class="btn-default btn-sm mb1" href="{_baseHrefOpportunities}/edit/+" dataTest="add-capacity-btn">Add opportunity</Btn>
  {/if}

  <div class="flex-row flex-align-start flex-wrap g05 mb1">
    <div class="flex-column g1">
      <!--By default we show just active capacities (those that haven't ended and are open for scheduling).
          Later on though, we'll implement saved searches and some default/template searches like "Ending soon", "Starting soon", "Started recently", "Ended recently" (though our "Automatic" sort kinda bubbles these to the top already)-->
      <CapacityFilters bind:filtersComponent {filters} onChanged={onFiltersChanged} {excludedFilterTypes}>
        <QuickFilterToggle />

        <ArchiveActivePicker
          bind:value={archiveActiveValue}
          on:change={onArchiveActiveFiltersChanged}
          archivedLabel="Ended"
          dataTest="opportunity-archive-active-filter"
        />
      </CapacityFilters>
    </div>

    <div class="flex-grow" />

    <!-- These should just wrap together. Looks weird when unbalanced. -->
    <div class="flex-row flex-align-start g05">
      <CapacityGridShowDropdown {showAgreement} {showService} />
      <CapacitySortDropdown bind:sortAscending bind:sortProperty onChange={() => (currentPage = 1)} />
      <ExportButtonExcel disabled={loading} exportFunc={exportExcel} dataTest="export" />

      <!--
        This technically works when this component is embedded on another page, but the query parameters
        really should be reserved for the page you're on. So, for now, we'll hide it.
        In the future, we could change the query parameters to be like:
        ?capacityFilters=...&capacitySort=...&capacityAsc=... etc.
      -->
      {#if showAgreement && showService}
        <ShareDropdown name="capacity" href={shareHref} />
      {/if}
    </div>
  </div>

  <CapacityGrid
    {capacities}
    viewOrEditHref="{_baseHrefOpportunities}/edit/[capacityId]"
    staffHref="{_baseHrefOpportunities}/[capacityId]/staff"
    copyHref="{_baseHrefOpportunities}/+[capacityId]"
    confirmMatchesHref="{_baseHrefOpportunities}/confirm-matches/[capacityId]"
    releaseMatchesHref="{_baseHrefOpportunities}/release-matches/[capacityId]"
    {onDeleteClicked}
    onUpdate={reload}
    bind:currentPage
    bind:pageSize
    {totalCount}
    {loading}
    {filtersComponent}
    {showAgreement}
    {showService}
  />
</div>

<Router>
  <Route path="{routeBaseHref}/:subpath/*restPath" let:params>
    <CapacityModal
      {agreementId}
      {allowedServiceIds}
      {ancestorOrgsById}
      {capacities}
      {listBodyIncludes}
      {newCapacityInput}
      {onCapacityChanged}
      {onClose}
      {saving}
      {serviceId}
      {services}
      baseHref={_baseHref}
      baseHrefOpportunities={_baseHrefOpportunities}
      onUpdate={reload}
      restPath={params.restPath}
      subpath={params.subpath}
    />
  </Route>
  <Route path="{routeBaseHref}/edit/:subpath/*guestOrgId/*" let:params>
    <CapacityModal
      {agreementId}
      {allowedServiceIds}
      {ancestorOrgsById}
      {capacities}
      {listBodyIncludes}
      {newCapacityInput}
      {onCapacityChanged}
      {onClose}
      {saving}
      {serviceId}
      {services}
      baseHref={_baseHref}
      baseHrefOpportunities={_baseHrefOpportunities}
      onUpdate={reload}
      subpath={params.subpath}
    />
  </Route>

  <Route path="{routeBaseHref}/:capacityId/staff/*" let:params>
    <!-- If the capacity isn't on the page, StaffRoleGridModal will load it itself -->
    <StaffRoleGridModal
      capacityId={+params.capacityId}
      capacity={capacities?.find(c => c.capacityId === +params.capacityId)}
      {onClose}
      {setWarningContext}
      baseHref={_baseHref}
      bind:orgs
      bind:teams
    />
  </Route>

  <!--
    The ConfirmMatchesModal and ReleaseMatchesModal are embedded on the page twice each.
    1. Embedded on the page itself, here, with /___-matches/:capacityId/:guestOrgId as the route.
    2. Embedded on the CapacityModal, via CapacityGuestView, with /:capacityId/___-matches as the route.
    This makes the UX more as expected:
      - If they click "Confirm rotations" straight from the grid, they'll hit 1. Closing the modal will go back to the grid.
      - If they click the capacity name, it'll open the modal where they can also click "Confirm rotations". That'll open a modal
        on top of the modal. Closing the modal go back to the modal behind it.
  -->
  <Route path="{routeBaseHref}/confirm-matches/:capacityId/:guestOrgId/*" let:params>
    <!-- If the capacity isn't on the page, ConfirmMatchesModal will load it itself -->
    {@const capacity = capacities?.find(c => c.capacityId === +params.capacityId)}
    {@const capacityGuest = capacity?.guests?.find(c => c.guestOrgId === +params.guestOrgId)}
    <ConfirmMatchesModal {capacity} {capacityGuest} capacityId={+params.capacityId} {onClose} {onUpdate} />
  </Route>

  <Route path="{routeBaseHref}/release-matches/:capacityId/:guestOrgId/*" let:params>
    <!-- If the capacity isn't on the page, ReleaseMatchesModal will load it itself -->
    {@const capacity = capacities?.find(c => c.capacityId === +params.capacityId)}
    {@const capacityGuest = capacity?.guests?.find(c => c.guestOrgId === +params.guestOrgId)}
    <ReleaseMatchesModal {capacity} {capacityGuest} capacityId={+params.capacityId} {onClose} {onUpdate} />
  </Route>
</Router>

<OpportunityConfirmDeleteModal capacity={deletingCapacity} onDelete={onCapacityDeleted} onCancel={() => (deletingCapacity = null)} />
<StaffFormWarningContextModal bind:warningContext />

<script>
  import { buildShareHref } from 'components/ShareHref.svelte'
  import { FilterDecoder, FilterEncoder } from 'services/filters/index.js'
  import { getLocation } from 'stores/req.js'
  import { Route, Router, navigate } from 'svelte-routing'
  import { PersonaType, Permission } from 'config/enums.js'
  import ArchiveActivePicker from 'components/fields/ArchiveActivePicker.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityFilters, { buildFilterTypes, buildIgnoredFilterTypes } from 'components/CapacityFilters.svelte'
  import CapacityGrid from 'components/CapacityGrid.svelte'
  import CapacityGridShowDropdown from 'components/CapacityGridShowDropdown.svelte'
  import CapacityModal from 'components/CapacityModal.svelte'
  import CapacitySortDropdown, { sortOptions } from 'components/CapacitySortDropdown.svelte'
  import ConfirmMatchesModal from 'components/CapacityGuestView.ConfirmMatchesModal.svelte'
  import ExportButtonExcel from 'components/ExportButtonExcel.svelte'
  import OpportunityConfirmDeleteModal from 'pages/authorized/org/Opportunities.ConfirmDeleteModal.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import PersonaFilterWarning from 'components/PersonaFilterWarning.svelte'
  import personaService from 'services/persona-service.js'
  import QuickFilterToggle from 'components/QuickFilterToggle.svelte'
  import ReleaseMatchesModal from 'components/CapacityGuestView.ReleaseMatchesModal.svelte'
  import ShareDropdown from 'components/ShareDropdown.svelte'
  import StaffFormWarningContextModal from 'components/StaffForm.WarningContextModal.svelte'
  import StaffRoleGridModal from 'components/StaffRoleGridModal.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let baseHref = null
  export let agreementId = null // used when showing on the agreement form
  export let newCapacityInput = null
  export let serviceId = null // used when showing on the service form
  export let allowedServiceIds = []
  export let saveCapacity
  export let saving

  export let archiveActiveValue
  export let defaultArchiveActiveValue
  export let sortProperty
  export let defaultSortProperty
  export let sortAscending
  export let defaultSortAscending
  export let currentPage
  export let pageSize
  export let loading
  export let capacities
  export let totalCount
  export let filters
  export let reload
  export let exportExcel

  export let excludedFilterTypes

  const location = getLocation()
  const opportunitiesHref = '/opportunities'
  const services = [] // const for now so no warning...
  let filtersComponent = null
  let deletingCapacity = null
  const ancestorOrgsById = {}

  // Set by <StaffRoleGridModal> the first time it's opened, then cached here via two-way binding
  // so if the user opens another staff modal, we won't have to make another request
  // TODO: discuss this--this is weird and we should just rely on our client-side caching instead. otherwise it likely makes sense to get fresh data in case it has changed.
  let orgs = null
  let teams = null

  $: showAgreement = agreementId == null
  $: showService = serviceId == null

  $: _baseHref = baseHref ?? opportunitiesHref
  $: _baseHrefOpportunities = baseHref == null ? opportunitiesHref : baseHref + opportunitiesHref
  $: routeBaseHref = baseHref == null ? '' : opportunitiesHref
  // reload when persona, sort, page, or filters change
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersTeamId = $personaFilters.teamId

  $: filterTypes = buildFilterTypes()
  $: ignoredFilterTypes = buildIgnoredFilterTypes()
  $: filterEncoder = new FilterEncoder(filterTypes, ignoredFilterTypes)
  $: filterDecoder = new FilterDecoder(filterTypes, ignoredFilterTypes)
  $: commonShareArgs = {
    filterDecoder,
    sortOptions,
    defaultSortProperty,
    defaultSortAscending,
    defaultArchiveActiveValue,
  }
  $: shareHref = buildShareHref({
    baseHref: _baseHrefOpportunities,
    filterSets: [{ filters, filterEncoder }],
    sortProperty,
    sortAscending,
    archiveActiveValue,
    ...commonShareArgs,
  })
  $: if (showService && showAgreement && !hadModalOpen) navigate(shareHref, { replace: true })

  $: personaFiltersOrgId, personaFiltersTeamId, resetOrgsAndTeams()

  function resetOrgsAndTeams() {
    orgs = null
    teams = null
  }

  const hadModalOpen = $location.pathname.includes(`${opportunitiesHref}/`)

  async function onFiltersChanged(_filters) {
    currentPage = 1
    filters = _filters
    reload()
  }

  function onArchiveActiveFiltersChanged() {
    onFiltersChanged(filters)
  }

  const listBodyIncludes = {
    includeOrgAndTeam: true,
    includeAddresses: true,
    includeLocations: true,
    includeServices: true,
    includeGuests: true,
    includeTags: true,
    includeShifts: true,
    includeStatusCounts: true,
    includeAgreements: true,
    includeStaff: true,
    includeDateExceptions: true,
    includeValidationData: true,
  }

  async function onCapacityChanged(e) {
    const { capacity } = e

    await saveCapacity(capacity, 'Opportunity saved')
    onClose()
    reload()
  }

  function onDeleteClicked(capacity) {
    deletingCapacity = capacity
  }

  function onCapacityDeleted() {
    deletingCapacity = null
    reload()
  }

  function onClose() {
    unsavedForms.navigateSafe(showAgreement && showService ? shareHref : _baseHref)
  }

  function onUpdate() {
    onClose()
    reload()
  }

  let warningContext = null
  function setWarningContext(context) {
    warningContext = context
  }
</script>
