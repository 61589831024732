<table>
  <!-- Prefer Microsoft Authenticator because Google Authenticator for iOS has a terrible rating -->
  <!-- Not including desktop suggestions, as there are Chrome plugins and Windows PC options -->
  <AuthenticatorAppLinksRow
    {downloadIconClass}
    iconSrc="microsoft-authenticator.png"
    name="Microsoft Authenticator"
    googlePlayHref="https://go.microsoft.com/fwlink/?Linkid=825072"
    appStoreHref="https://go.microsoft.com/fwlink/?Linkid=825073"
  />
  <AuthenticatorAppLinksRow
    {downloadIconClass}
    iconSrc="google-authenticator.svg"
    name="Google Authenticator"
    googlePlayHref="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
    appStoreHref="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
  />
  <AuthenticatorAppLinksRow
    {downloadIconClass}
    iconSrc="twilio-authy.svg"
    name="Twilio Authy"
    googlePlayHref="https://play.google.com/store/apps/details?id=com.authy.authy"
    appStoreHref="https://itunes.apple.com/us/app/authy/id494168017"
    desktopHref="https://authy.com/download/"
  />
</table>

<script>
  import AuthenticatorAppLinksRow from 'components/AuthenticatorAppLinks.Row.svelte'

  export let downloadIconClass = 'text-gray'
</script>
