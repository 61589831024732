<span class="input-group">
  {#if showCharacters}
    <input
      bind:this={inputElem}
      type="text"
      class="form-control"
      {autocomplete}
      on:blur={() => (blurred = true)}
      {name}
      id={name}
      data-test={name}
      bind:value
      {placeholder}
    />
  {:else}
    <input
      bind:this={inputElem}
      type="password"
      class="form-control"
      {autocomplete}
      on:blur={() => (blurred = true)}
      {name}
      id={name}
      data-test={name}
      bind:value
      {placeholder}
    />
  {/if}
  <a
    class="input-group-addon"
    data-test="show-characters-btn"
    on:click={() => (showCharacters = !showCharacters)}
    use:tip={showCharacters ? 'Hide password' : 'Show password'}
    tabindex="-1"
    href={null}
  >
    <Icon name={showCharacters ? 'hide' : 'eye'} />
  </a>
</span>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import { getContext } from 'svelte'

  export let name = null
  export let value = null
  export let autocomplete = 'off'
  export let autofocus = false
  export let placeholder = null

  const initialValue = value
  const markDirty = getContext('markDirty')
  let showCharacters = false
  let blurred = false
  let inputElem

  $: if (autofocus && inputElem) focus()
  $: if (markDirty != null && blurred && value != initialValue) markDirty()

  export function focus() {
    setTimeout(() => inputElem?.focus?.())
  }
</script>
