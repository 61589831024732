<Route {path} let:params>
  <MainContent>
    <svelte:component this={component} {...componentProps} {...withoutLocation(params)} />
  </MainContent>
</Route>

<script>
  import MainContent from 'components/MainContent.svelte'
  import { Route } from 'svelte-routing'
  export let path
  export let component

  let componentProps = {}
  $: $$props, (componentProps = getProps())

  function getProps() {
    const props = { ...$$props }
    delete props.path
    delete props.component
    return props
  }

  // svelte-routing wants to give us the param `location` but our components
  // don't expect to use it, so we get `<SomePage> was created with unknown prop 'location'`
  function withoutLocation(params) {
    const props = { ...params }
    delete props.location
    return props
  }
</script>
