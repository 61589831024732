<FilterTypeStatus {filter} {editing} {appliedMeta} options={matchStatusHelper.getOptions($persona)} />

<script context="module">
  import { FilterType, MatchStatus } from 'config/enums.js'
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.MatchStatuses,
    canHaveMultiple: true,
    label: 'Status',
    labelApplied: 'Status is',
    editLabel: 'Status is',
    icon: 'filter-status',
    iconClass: 'color-text-steel',
    filterProp: 'statuses',
    idKey: 'key',
    enumeration: MatchStatus,
    helper: matchStatusHelper,
  }
</script>

<script>
  import persona from 'stores/persona.js'

  export let filter
  export let editing = false
  export let appliedMeta
</script>
