{#if $user?.isImpersonating}
  <div class="impersonation flex-row flex-align-center g1">
    <ImpersonateQuickDropdown bind:changing disabled={stopping} extraBtnClass="btn-sm" />
    <Btn
      on:click={stopImpersonating}
      class="btn-sm btn-purple{disabled ? ' btn-disabled' : ''}"
      icon="close"
      dataTest="stop-impersonating"
      loading={stopping}
      {disabled}
      title={hasConvenientImpersonations ? 'Stop impersonating' : null}
    >
      Stop {#if !hasConvenientImpersonations}impersonating{/if}
    </Btn>
  </div>
{/if}

<script>
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import user from 'stores/user.js'
  import ImpersonateQuickDropdown from 'components/ImpersonateQuickDropdown.svelte'
  import { navigate } from 'svelte-routing'

  let stopping = false
  let changing = false

  $: disabled = stopping || changing
  $: convenientImpersonations = $user?.convenientImpersonations
  $: mostFrequentlyImpersonatedUsers = convenientImpersonations?.mostFrequentlyImpersonatedUsers ?? []
  $: recentlyImpersonatedUsers = convenientImpersonations?.recentlyImpersonatedUsers ?? []
  $: hasConvenientImpersonations = mostFrequentlyImpersonatedUsers.length || recentlyImpersonatedUsers.length

  async function stopImpersonating() {
    stopping = true
    try {
      await api.impersonation.stopImpersonating(api.noMonitor)
      await user.load()
      navigate('/cn-users')
    } finally {
      stopping = false
    }
  }
</script>

<style>
  .impersonation {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5000;
    text-align: center;
  }
</style>
