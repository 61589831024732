<div class="mb2">
  <Collapsible open={true}>
    <h3 slot="label" class="mb0">Participants detail</h3>
    <div>
      <div class="flex-row flex-justify-between">
        <h4>
          Participant rotation utilization {#if capacity?.maxRotations}({capacity.maxRotations} total rotations available){/if}
        </h4>
        <BtnGroupPicker {options} bind:value={view} class={className} {btnClass} on:change name={dataTest} />
      </div>
      {#if view === 'opportunity-participant'}
        <WaffleChart {capacity} bind:guests {filteredGuests} {capacityMatchStatusCounts} isParticipantView={true} />
      {/if}
      {#if view === 'opportunity-status'}
        <MatchStatusLegend {matches} />
        <WaffleChart {capacity} {guests} {capacityMatchStatusCounts} isStatusView={true} />
      {/if}
      {#if view === 'participant-status'}
        <MatchStatusLegend />
        <div class="grid-container" on:mousewheel={handleScroll}>
          <div class="grid">
            {#each guestsWithChartData as guest, i}
              <div class=" p1">
                <Chart id="chart{i}" type={'bar'} data={guest.chartData} options={chartOptions} />
                <div>
                  <hr class="m0 mb05" />
                  <CapacityGuestProfilePictureAndName capacityGuest={guest} size="medium" />
                </div>
                <div />
              </div>
            {/each}
          </div>
        </div>
      {/if}
      <div>
        <h4>Participant overview</h4>
        <CapacityGuestFilters {excludedFilterTypes} bind:filtersComponent bind:filters onChanged={filterGuests} />
        <ParticipantGrid guests={filteredGuests} {matches} />
      </div>
    </div>
  </Collapsible>
</div>

<script>
  import ParticipantGrid from 'components/ParticipantGrid.svelte'
  import { MatchStatus, FilterType, DateTimeComparison, DateTimeComparisonProviderType } from 'config/enums'
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import Chart from 'components/Chart.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import CapacityGuestFilters from 'components/CapacityGuestFilters.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import MatchStatusLegend from 'components/MatchStatusLegend.svelte'
  import WaffleChart from 'components/ParticipantDetail.WaffleChart.svelte'
  import enumToOptions from 'services/enum-to-options'
  import matchStatusHelper from 'services/match-status-helper.js'
  import dayjs from 'dayjs'

  export let btnClass = 'btn-sm'
  export let capacity
  export let matches
  export let className = ''
  export { className as class }
  export let dataTest = null
  export let view = 'opportunity-participant'
  export let filters = []
  export let excludedFilterTypes = []

  let guests = capacity?.guests ?? []
  let filtersComponent
  let filteredGuests = guests ?? []
  let chartMaximum = 0

  const buildOption = (value, label, dataTest) => ({ value, label, dataTest })
  $: options = [
    buildOption('opportunity-participant', 'Opportunity by participant view', 'opportunity-participant-view-tab'),
    buildOption('opportunity-status', 'Opportunity status view', 'opportunity-status-view-tab'),
    buildOption('participant-status', 'Participant status view', 'participant-status-view-tab'),
  ]

  $: guestStatusCounts = capacity?.statusCounts ?? []
  $: processGuests(guests)
  $: matchStatuses = enumToOptions(MatchStatus).filter(o => o.optionValue !== MatchStatus.Closed && o.optionValue !== MatchStatus.Unsubmitted)
  $: guestsWithChartData = buildGuestChartData(guests)
  $: capacityMatchStatusCounts = buildCapacityMatchStatusCounts()
  $: chartOptions = {
    backgroundColor: 'transparent',
    tooltips: {
      bodyFontSize: 16,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            precision: 0,
            max: chartMaximum,
          },
        },
      ],
    },
  }
  function handleScroll(event) {
    event.preventDefault()
    const container = event.target.closest('.grid-container')
    if (container) {
      const scrollAmount = event.deltaY
      container.scrollLeft += scrollAmount
    }
  }

  function buildGuestChartData(guests) {
    const guestsWithChartData = []
    for (const guest of guests) {
      const updatedGuest = _.cloneDeep(guest)
      updatedGuest.chartData = {
        labels: [''],
        datasets: [],
      }
      for (const status of matchStatuses) {
        const matchStatus = matchStatusHelper.get(status.optionValue)
        const newDataSet = {
          label: matchStatus.label,
          data: [guest.statusCounts.find(s => s.status === matchStatus.key)?.count || 0],
          backgroundColor: matchStatus.key === 25 ? '#555' : matchStatus.reportColor,
        }
        if (newDataSet.data[0] > chartMaximum) chartMaximum = newDataSet.data[0]
        updatedGuest.chartData.datasets.push(newDataSet)
      }
      guestsWithChartData.push(updatedGuest)
    }
    return guestsWithChartData
  }

  function buildCapacityMatchStatusCounts() {
    const capacityMatchStatusCounts = []
    const capacityStatusCounts = []
    for (const statusCounts of capacity.statusCounts) {
      for (const byStatus of statusCounts.byStatus) {
        capacityStatusCounts.push(byStatus)
      }
    }

    for (const status of matchStatuses) {
      const matchStatus = matchStatusHelper.get(status.optionValue)
      const statusCount = {
        key: matchStatus.key,
        label: matchStatus.label,
        count: capacityStatusCounts.reduce((acc, s) => (s.status === matchStatus.key ? acc + s.count : acc), 0),
        backgroundColor: matchStatus.key === 25 ? '#555' : matchStatus.reportColor,
      }
      capacityMatchStatusCounts.push(statusCount)
    }
    return capacityMatchStatusCounts
  }

  function processGuests(guests) {
    if (!guests) return
    for (const guest of guests) {
      const guestStatus = guestStatusCounts.find(s => s.guestOrgId === guest.guestOrgId)
      guest.statusCounts = guestStatus?.byStatus ?? []
    }

    guests.forEach(guest => {
      const filteredStatusCounts = guest.statusCounts.filter(s => s.status !== MatchStatus.Closed && s.status !== MatchStatus.Unsubmitted)
      guest.statusCounts = filteredStatusCounts
      guest.approvedRotations = guest.statusCounts
        .filter(s => s.status > MatchStatus.Waitlisted && s.status < MatchStatus.Closed)
        .reduce((acc, s) => acc + s.count, 0)
      guest.pendingRotations = guest.statusCounts
        .filter(s => s.status > MatchStatus.Unsubmitted && s.status < MatchStatus.Onboarding)
        .reduce((acc, s) => acc + s.count, 0)
      guest.approvedOverMax = guest.approvedRotations - guest.maximumRotations > 0 ? guest.approvedRotations - guest.maximumRotations : 0
      guest.pendingOverMax =
        guest.approvedOverMax > 0
          ? guest.pendingRotations
          : guest.approvedRotations + guest.pendingRotations > guest.maximumRotations
            ? guest.approvedRotations - guest.maximumRotations + guest.pendingRotations
            : 0
      guest.guaranteedLeft = guest.guaranteedMatches - guest.approvedRotations - guest.pendingRotations
      guest.textColor = getTextColorForUserColor(guest.color)
      return guest
    })
    return guests.sort((a, b) => {
      // If 'included' status is different, sort by it first, with 'true' values first
      if (a.included !== b.included) {
        return a.included ? -1 : 1
      }
      // If 'included' status is the same for both, then sort by 'guestOrgId'
      return a.guestOrgId - b.guestOrgId
    })
  }
  function getTextColorForUserColor(color) {
    const lightColors = ['yellow', 'gray', 'gold', 'light-purple', 'sky', 'lime', 'pink', 'orange']
    return lightColors.includes(color) ? 'black' : 'white'
  }

  function filterGuests() {
    filteredGuests = guests
    for (const filter of filters) {
      if (filter.type === FilterType.KeywordSearch) {
        if (filter.config.keyword) {
          filteredGuests = filteredGuests.filter(g => g.guestOrgName.toLowerCase().includes(filter.config.keyword.toLowerCase()))
        }
      }
      if (filter.type === FilterType.GuestOrgs) {
        if (filter.config.exclude) {
          if (filter.config.schoolOrgIds.length) {
            filteredGuests = filteredGuests.filter(g => !filter.config.schoolOrgIds.includes(g.guestOrgId))
          }
        } else {
          if (filter.config.schoolOrgIds.length) {
            filteredGuests = filteredGuests.filter(g => filter.config.schoolOrgIds.includes(g.guestOrgId))
          }
        }
      }
      if (filter.type === FilterType.CapacityGuestHasRotations) {
        if (filter.config.hasRotations) {
          filteredGuests = filteredGuests.filter(g => g.pendingRotations > 0 || g.approvedRotations > 0)
        } else {
          filteredGuests = filteredGuests.filter(g => g.pendingRotations === 0 && g.approvedRotations === 0)
        }
      }
      if (filter.type === FilterType.CapacityGuestRotationRequestRestrictionStartDate) {
        if (filter.config.comparisonProviderType === DateTimeComparisonProviderType.Parameterless) {
          if (filter.config.comparisonProvider.isNull) {
            filteredGuests = filteredGuests.filter(g => !g.schedulingStartDate)
          } else {
            filteredGuests = filteredGuests.filter(g => g.schedulingStartDate)
          }
        } else {
          const comparisonType = filter.config.comparisonProvider.comparison
          const comparisonDate = dayjs(filter.config.comparisonProvider.date, 'M/D/YYYY')

          if (comparisonType === DateTimeComparison.IsBefore) {
            filteredGuests = filteredGuests.filter(g => dayjs(g.schedulingStartDate, 'M/D/YYYY').isBefore(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsBeforeOrEqual) {
            filteredGuests = filteredGuests.filter(
              g =>
                dayjs(g.schedulingStartDate, 'M/D/YYYY').isBefore(comparisonDate) || dayjs(g.schedulingStartDate, 'M/D/YYYY').isSame(comparisonDate)
            )
          }
          if (comparisonType === DateTimeComparison.Is) {
            filteredGuests = filteredGuests.filter(g => dayjs(g.schedulingStartDate, 'M/D/YYYY').isSame(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsAfter) {
            filteredGuests = filteredGuests.filter(g => dayjs(g.schedulingStartDate, 'M/D/YYYY').isAfter(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsAfterOrEqual) {
            filteredGuests = filteredGuests.filter(
              g => dayjs(g.schedulingStartDate, 'M/D/YYYY').isAfter(comparisonDate) || dayjs(g.schedulingStartDate, 'M/D/YYYY').isSame(comparisonDate)
            )
          }
        }
      }
      if (filter.type === FilterType.CapacityGuestRotationRequestRestrictionEndDate) {
        if (filter.config.comparisonProviderType === DateTimeComparisonProviderType.Parameterless) {
          if (filter.config.comparisonProvider.isNull) {
            filteredGuests = filteredGuests.filter(g => !g.schedulingEndDate)
          } else {
            filteredGuests = filteredGuests.filter(g => g.schedulingEndDate)
          }
        } else {
          const comparisonType = filter.config.comparisonProvider.comparison
          const comparisonDate = dayjs(filter.config.comparisonProvider.date, 'M/D/YYYY')

          if (comparisonType === DateTimeComparison.IsBefore) {
            filteredGuests = filteredGuests.filter(g => !g.schedulingEndDate || dayjs(g.schedulingEndDate, 'M/D/YYYY').isBefore(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsBeforeOrEqual) {
            filteredGuests = filteredGuests.filter(
              g =>
                !g.schedulingEndDate ||
                dayjs(g.schedulingEndDate, 'M/D/YYYY').isBefore(comparisonDate) ||
                dayjs(g.schedulingEndDate, 'M/D/YYYY').isSame(comparisonDate)
            )
          }
          if (comparisonType === DateTimeComparison.Is) {
            filteredGuests = filteredGuests.filter(g => !g.schedulingEndDate || dayjs(g.schedulingEndDate, 'M/D/YYYY').isSame(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsAfter) {
            filteredGuests = filteredGuests.filter(g => !g.schedulingEndDate || dayjs(g.schedulingEndDate, 'M/D/YYYY').isAfter(comparisonDate))
          }
          if (comparisonType === DateTimeComparison.IsAfterOrEqual) {
            filteredGuests = filteredGuests.filter(
              g =>
                !g.schedulingEndDate ||
                dayjs(g.schedulingEndDate, 'M/D/YYYY').isAfter(comparisonDate) ||
                dayjs(g.schedulingEndDate, 'M/D/YYYY').isSame(comparisonDate)
            )
          }
        }
      }
    }
    return filteredGuests
  }
</script>

<style>
  .grid-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
  }
  .grid-container::-webkit-scrollbar {
    display: none;
  }
  .grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(10, 300px);
    grid-template-rows: 2;
  }
</style>
