<div class="toasts">
  <slot />
</div>

<style>
  .toasts {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 1060;
    padding: 0;
    margin: 0;
  }
</style>
