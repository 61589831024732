<FilterTypeOrg {filter} {editing} {appliedMeta} {filterOptions} />

<script context="module">
  import FilterTypeOrg, { meta as baseMeta } from 'components/filter-types/FilterTypeOrg.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.CapacityLocations,
    label: 'Opportunity location',
    labelApplied: 'opportunity location',
    editLabel: null,
    icon: 'hospital',
    iconClass: 'color-text-orange',
    hasDoesntHave: true,
    canHaveMultiple: true,
    filterProp: 'locationIds',
    optionsEndpoint: 'getCapacityLocations', // if hook up for matchdynamicquery, use getorgs probably, even for capacity filters. just get all.
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
