{#each Object.keys($$props) as key}
  <div class="debug" style={styleForKey(key)}>
    <h4>{'{'}{key}{'}'}</h4>
    <pre>{stringify($$props[key])}</pre>
  </div>
{/each}

<script>
  function computeStringHashCode(str) {
    if (str.length === 0) return 0
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0 // Convert to 32-bit integer
    }
    return hash
  }

  function styleForKey(key) {
    const hue = computeStringHashCode(key) % 361
    return `--debug-color: hsl(${hue}deg, 80%, 30%);`
  }

  function json(value) {
    return JSON.stringify(value, null, 2)
  }

  function stringify(value) {
    if (value == null || _.isString(value) || Array.isArray(value)) return json(value)
    if (typeof value === 'function') return value.toString()
    if (value instanceof Set) return json([...value])
    if (value instanceof Map) return json(value) // TODO...
    // Needs to go last-ish, most likely:
    // if (_.isObject(value)) return json(value)
    return json(value)
  }
</script>

<style lang="scss">
  @import '../../css/helpers';
  .debug {
    background: #eee;
    border: 2px solid var(--debug-color);
    margin-top: 10px;

    > * {
      margin: 0;
      font-family: monospace;
    }

    > h4 {
      padding: 5px 10px;
      background: var(--debug-color);
      color: $white;
      border-bottom: 2px solid var(--debug-color);
    }

    > pre {
      padding: 10px;
      font-size: 11px;
      max-height: 80vh;
      overflow-y: scroll;
    }
  }
</style>
