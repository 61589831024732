{#if name != null}
  {#if type == null}
    {#if value != null}{value}{/if}
  {:else if type === 'list'}
    <FriendlyList items={value} {...typeProps} allowShowFewer />
  {:else if type === 'bool'}
    {#if value}
      <Icon lg name="check" class="text-success" {...typeProps} />
    {:else}
      <Icon lg name="close" class="text-danger" {...typeProps} />
    {/if}
  {:else if type === 'yesNo'}
    {#if value}Yes{:else}No{/if}
  {:else if type === 'date'}
    {formatDate(value)}
  {:else if type === 'datetime'}
    {formatDateTime(value)}
  {:else if type === 'thumbnail'}<img src={value} class="thumb-sm rounded" alt={name} {...typeProps} />
  {:else if type === 'email'}<EmailAddressBtn class="btn btn-sm" email={value} />
  {:else if type === 'phone'}<TelephoneNumberBtn class="btn btn-sm" number={value} />
  {:else if type === 'money'}{formatMoney(value)}
  {:else if type === 'longdesc'}<LongDesc text={value} {...typeProps} />
  {/if}
{/if}

<script context="module">
  const formatDate = d => (d ? dayjs(d).format('M/D/YYYY') || '' : '')
  const formatDateTime = d => (d ? dayjs(d).format('M/D/YYYY h:mmA') || '' : '')
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import EmailAddressBtn from 'components/EmailAddressBtn.svelte'
  import TelephoneNumberBtn from 'components/TelephoneNumberBtn.svelte'
  import { formatMoney } from 'services/string-utils.js'
  import LongDesc from './LongDesc.svelte'

  export let row
  export let name
  export let type
  export let typeProps = {}

  $: value = row[name]
</script>
