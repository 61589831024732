{#if users.length}
  <div
    class={profilePicOnly ? 'flex-row flex-align-center flex-wrap g1' : 'flex-column g05'}
    style={profilePicSmall ? 'max-width: 200px' : 'max-width: 240px'}
  >
    <FriendlyList max={35} items={users} let:item={user} boldPunctuation={false} otherPrefix="Show " other="other person" otherPlural="other people">
      <UserDocumentAccessPeoplePerson {user} {profilePicOnly} {profilePicSmall} />
    </FriendlyList>
  </div>
{:else}
  <div class="em small">Only you</div>
{/if}

<script>
  import { MatchStatus } from 'config/enums.js'
  import FriendlyList from 'components/FriendlyList.svelte'
  import UserDocumentAccessPeoplePerson from 'components/UserDocumentAccessPeople.Person.svelte'

  export let userDocument
  export let userDocumentSharing

  $: users = buildUsers(userDocument, userDocumentSharing)
  $: profilePicOnly = users.length > 6
  $: profilePicSmall = users.length > 20

  const mapBy = (array, keySelector, copy = false) => {
    const map = new Map()
    for (const item of array) {
      map.set(keySelector(item), copy ? { ...item } : item)
    }
    return map
  }

  function buildUsers() {
    const matchById = mapBy(userDocumentSharing.matches, m => m.matchId)
    const usersById = mapBy(userDocumentSharing.users, u => u.userId, true)
    const users = new Set()
    for (const msd of userDocument.matchStepDownloaders) {
      const match = matchById.get(msd.matchId)
      if (match.status <= MatchStatus.Unsubmitted) continue
      const user = usersById.get(msd.userId)
      user.matchIds ??= new Set()
      user.matchIds.add(match.matchId)
      users.add(user)
    }
    return [...users]
  }
</script>
