<MainContent>
  {#if !capacity || loading}
    <h1>Loading...</h1>
  {:else}
    <div class="mb2">
      <div class="flex-row flex-justify-between">
        <div>
          <h1 class="mb1" style="font-weight: 300">{capacity.name}</h1>
          <p style="font-style: italic" class="pr2">{capacity.description ?? ''}</p>
        </div>

        <div class="flex-column g1" class:invisible={activityPanelOpen}>
          <div class="hide">
            <div bind:this={activityTooltipElem}>
              {#if unreadActivityCount}
                <div>{pluralCount('unread change', unreadActivityCount)}</div>
              {/if}
              {#if unreadCommentCount}
                <div>{pluralCount('unread comment', unreadCommentCount)}</div>
              {/if}
              {#if dateTimeLastViewed}
                <div>Since <FromNow date={dateTimeLastViewed} /></div>
              {/if}
            </div>
          </div>

          <Btn
            class="flex-row flex-align-center g05"
            on:click={() => (activityPanelOpen = true)}
            iconProps={{ lg: true }}
            icon="comment"
            title={activityTooltipElem && (unreadActivityCount || unreadCommentCount)
              ? { content: activityTooltipElem, options: { placement: 'left' } }
              : null}
          >
            <div class="flex-row flex-align-center g1">
              <span>Comments & activity</span>
              <Badge count={unreadActivityCount + unreadCommentCount} color="danger" class="m0 inset-0" />
            </div>
          </Btn>

          {#if isHealth}
            <Btn href="{baseHref}/{capacity.capacityId}/edit" class="btn btn-primary btn-low-contrast-white flex-row flex-align-center g05">
              <Icon name="edit" />
              Edit opportunity
            </Btn>
          {/if}
        </div>
      </div>
    </div>

    <div class="mb2 flex-row">
      <div>
        <CapacityStatusIcon {capacity} detailsView={true} />
      </div>
      <div class="rotation-status p2" style="border-left: 15px solid #f3aa41">
        <div class="flex-column flex-align-center flex-justify-center">
          <div class="strongish">
            {matches.filter(m => m.status >= MatchStatus.Onboarding && m.status < MatchStatus.Completed).length}
            /
            <Unlimited value={capacity.maxMatches} />
            <Icon name="users" class="" />
            Rotations filled
          </div>
          <div on:click={handleClickPendingRequests} class="text-warning-dark m0">
            <Icon name="alert-triangle" />
            {pendingMatchCount} Pending rotations
          </div>
        </div>
      </div>
    </div>
    <br />
    <DetailsInformation {capacity} {isHealth} />
    <div class="mb2">
      <Collapsible open={false}>
        <div slot="label" class="flex-row">
          <div class="mr2">
            <span class="small em">{capacity.staff.length} staff</span>
            {#if capacity.staff.length}
              <div class="flex-row g05">
                {#each capacity.staff as staff}
                  <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                {/each}
              </div>
            {/if}
          </div>
          <div>
            <span class="small em">{distinctMatchesStaff.length} faculty</span>
            {#if distinctMatchesStaff.length}
              <div class="flex-row g05">
                {#each distinctMatchesStaff as staff}
                  <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                {/each}
              </div>
            {/if}
          </div>
        </div>
        <div>
          <Collapsible open={true} noContent={capacity.staff.length > 0 ? false : true}>
            <div slot="label" class="flex-row g05 flex-align-center text-center">
              <Icon name="eye" />
              <h3 class="m0">Staff visible to applicants</h3>
              <Badge xs count={capacity.staff.length} />
            </div>
            <div class="flex-row flex-wrap g05">
              {#each capacity.staff as staff}
                <StaffCard {staff} bind:capacity />
              {/each}
            </div>
          </Collapsible>
          <Collapsible open={true}>
            <div slot="label" class="flex-row g05 flex-align-center text-center">
              <Icon name="school" />
              <h3 class="m0">School faculty (from rotations)</h3>
              <Badge xs count={distinctMatchesStaff.length} />
            </div>
            <div>
              {#each capacity.guests as guest}
                <Collapsible open={false} noContent={distinctMatchesStaff.filter(s => s.orgId === guest.guestOrgId).length > 0 ? false : true}>
                  <div slot="label" class="flex-row flex-align-center g05">
                    <OrgProfilePictureAndName
                      size="medium"
                      name={guest.guestOrgName}
                      relativeName={guest.guestOrgRelativeName}
                      profilePicture={guest.guestOrgProfilePicture}
                    />
                    <Badge xs count={distinctMatchesStaff.filter(s => s.orgId === guest.guestOrgId).length} />
                  </div>
                  <div class="flex-row flex-wrap">
                    {#each distinctMatchesStaff as staff}
                      {#if staff.orgId === guest.guestOrgId}
                        <StaffCard isFaculty={true} {staff} bind:capacity />
                      {/if}
                    {/each}
                  </div>
                </Collapsible>
              {/each}
            </div>
            <div class="flex-row flex-wrap g05" />
          </Collapsible>
        </div>
      </Collapsible>
    </div>
    {#if isHealth}
      <ParticipantsDetail {capacity} matches={matches.filter(m => m.status !== MatchStatus.Unsubmitted && m.status !== MatchStatus.Closed)} />
    {/if}

    <div class="mb2" id="matches">
      <Collapsible open={true}>
        <h3 slot="label" class="mb0">Rotations</h3>
        <DetailsRotationList
          {capacity}
          href={`/opportunities/${capacity.capacityId}`}
          {onFiltersChanged}
          bind:filters
          loading={loadingMatches}
          {totalCount}
          {matches}
        />
      </Collapsible>
    </div>

    <div class="mb2">
      <Collapsible open={true}>
        <h3 slot="label" class="mb0">Opportunity calendar</h3>
        {#if capacity}
          <div class="flex-grow"><CalendarControls bind:start bind:end /></div>
          <Calendar
            startDate={start}
            endDate={end}
            let:day
            min={capacity.startDate}
            max={capacity.endDate}
            fullWeekdayLabel={true}
            weekdayLabelStyle="font-size: 16px"
            dateStyle="min-height: 120px"
            dayLabelStyle="font-size: 14px"
          >
            <div slot="icons">
              {@const dayShifts = shiftsByDate[day.formatted] ?? []}
              {@const dayShiftCount = dayShifts.length}
              {@const anyShifts = dayShiftCount > 0}
              {@const allShifts = dayShiftCount === capacity.shifts.length}
              {@const anyInfiniteSlots = dayShifts.some(shift => shift.slots === 0)}
              {@const peopleCount = anyInfiniteSlots ? 0 : _.sumBy(dayShifts, shift => shift.slots)}
              <Badge label={allShifts ? 'All' : anyShifts ? dayShiftCount : 'None'} icon={anyShifts ? 'eye' : 'hide'} xs color="pureBlack" />
              <Badge label={anyInfiniteSlots ? '' : peopleCount} icon={anyInfiniteSlots ? 'infinity' : null} iconRight="users" xs color="pureBlack" />
            </div>
            {@const dayShifts = shiftsByDate[day.formatted] ?? []}
            {#if !day.disabled && dayShifts?.length > 0}
              {#each dayShifts as shift}
                {@const time = dayjs(shift.startTime, 'HH:mm').format('h:mm A')}
                <div data-test="shift" class="shift color-bg-{shift.color} mt1" style="border-radius: 5px;">
                  <div class="flex-row flex-align-center g05">
                    {#if shift.timeRange}
                      <span>{time}</span>
                    {/if}
                    <div class="flex-grow">{shift.name}</div>
                    <Badge
                      label={shift.slots === 0 ? '' : shift.slots}
                      icon={shift.slots === 0 ? 'infinity' : null}
                      iconRight="users"
                      xs
                      color="pureBlack"
                    />
                  </div>
                </div>
              {/each}
            {/if}
          </Calendar>
        {/if}
      </Collapsible>
    </div>

    <CapacityActivity
      {saving}
      capacityId={capacity.capacityId}
      headerStyle="drawer"
      bind:isOpen={activityPanelOpen}
      bind:dateTimeLastViewed
      bind:unreadActivityCount
      bind:unreadCommentCount
    />
  {/if}
</MainContent>

<Router>
  <Route path="/edit/*">
    <CapacityModal
      baseHrefOpportunities="{baseHref}/{capacity.capacityId}"
      isDetailsView={true}
      {capacity}
      {saving}
      onUpdate={load}
      {onCapacityChanged}
      onClose={closeEditModal}
    />
  </Route>
  <Route path="/matches/:matchId/*" let:params>
    <MatchModal matchId={params.matchId} on:close={e => onMatchModalClosed(e)} />
  </Route>
</Router>

<script>
  import { MatchListSortProperty, FilterType, MatchStatus, PersonaType } from 'config/enums'
  import { onDestroy } from 'svelte'
  import { pluralCount } from 'services/string-utils'
  import { Router, Route } from 'svelte-routing'
  import { shiftOccursOnDate } from 'services/calendar-service.js'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Calendar from 'components/Calendar.svelte'
  import CalendarControls from 'components/CalendarControls.svelte'
  import CapacityActivity from 'components/CapacityActivity.svelte'
  import CapacityModal from 'components/CapacityModal.svelte'
  import CapacityStatusIcon from 'components/CapacityStatusIcon.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import dayjs from 'dayjs'
  import DetailsInformation from './Opportunities.Details.Information.svelte'
  import DetailsRotationList from './Opportunities.Details.RotationList.svelte'
  import FromNow from 'components/FromNow.svelte'
  import getMatchController from 'services/match-controller.js'
  import Icon from 'components/Icon.svelte'
  import MainContent from 'components/MainContent.svelte'
  import MatchModal from 'components/MatchModal.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ParticipantsDetail from './Opportunities.ParticipantsDetail.svelte'
  import persona from 'stores/persona'
  import ProfilePic from 'components/ProfilePic.svelte'
  import sockets from 'services/sockets.js'
  import StaffCard from 'components/StaffCard.svelte'
  import Unlimited from 'components/Unlimited.svelte'
  import unsavedForms from 'stores/unsaved-forms'
  import validator from 'services/validator'

  export let pageSize
  export let currentPage = 1
  export let totalCount = 0
  export let load
  export let loading = false
  export let capacity
  export const defaultSortProperty = MatchListSortProperty.StartDate
  export const defaultSortAscending = false
  export let sortProperty = defaultSortProperty
  export let sortAscending = defaultSortAscending
  export let baseHref
  export let saveCapacity
  export let saving = false

  let currentXhr = null
  let currentXhrBody = null
  let loadingMatches = false
  let loadingStaff = false
  let matches = []
  let capacityMatchesStaffCount = 0
  let capacityMatchesStaff = []
  let filters = []

  // Activity-related:
  let unreadActivityCount
  let unreadCommentCount
  let dateTimeLastViewed
  let activityTooltipElem = null

  let start
  let startDate
  let end
  let endDate
  let shiftsByDate
  let capacityShifts = []

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  let activityPanelOpen = false

  $: distinctMatchesStaff = _.uniqBy(capacityMatchesStaff, cms => cms.userId).filter(cms => cms.orgId !== capacity.orgId)
  $: pendingMatchCount = matches.filter(
    m => m.status === MatchStatus.PendingApplicationVerification || m.status === MatchStatus.PendingClinicalSite
  ).length
  $: hiddenFilters = [{ type: FilterType.MatchCapacity, config: { capacityIds: [capacity?.capacityId] } }]

  const unsubscribe = sockets.on('UI_Capacity', ui => {
    if (ui?.capacityId != capacity?.capacityId) return
    if (saving) return
  })
  onDestroy(unsubscribe)

  async function onCapacityChanged(e) {
    const { capacity } = e
    await saveCapacity(capacity, 'Opportunity saved')
    closeEditModal()
    load()
  }

  function setCalendarData() {
    startDate = capacity?.startDate
    endDate = capacity?.endDate
    capacityShifts = capacity?.shifts
    capacityShifts, orderCapacityShiftsByStartTime(capacity.shifts)
    startDate, endDate, setStartEnd()
    capacityShifts, start, end, setShiftsByDate()
  }

  function setStartEnd() {
    if (capacity?.startDate) start = dayjs(capacity?.startDate)
    else if (start == null) start = dayjs()
    start = start.startOf('month')
    end = start.endOf('month')
  }

  function setShiftsByDate() {
    if (!capacity) return
    shiftsByDate = {}
    let currentDate = dayjs(start)
    while (currentDate.isSameOrBefore(end)) {
      const formattedDate = currentDate.format('M/D/YYYY')
      shiftsByDate[formattedDate] = capacity.shifts.filter(shift => shiftOccursOnDate(capacity, shift.shiftDays, currentDate))
      currentDate = currentDate.add(1, 'day')
    }
  }

  function orderCapacityShiftsByStartTime(shifts) {
    capacity.shifts = _.orderBy(shifts, [s => (s.startTime == null ? 0 : parseInt(s.startTime.replace(':', '')))])
  }

  function onMatchModalClosed() {
    unsavedForms.navigateSafe(baseHref + `/${capacity.capacityId}`)
  }

  function handleClickPendingRequests(event) {
    event.preventDefault()
    filters = [
      {
        type: FilterType.MatchStatuses,
        config: { statuses: [MatchStatus.PendingApplicationVerification, MatchStatus.PendingClinicalSite] },
      },
    ]

    loadMatches()
    const element = document.getElementById('matches')
    element.scrollIntoView({ behavior: 'smooth' })
  }

  async function loadMatches() {
    loadingMatches = true
    let thisXhr = null
    try {
      thisXhr = getGridData()
      const response = await thisXhr
      if (thisXhr === currentXhr) {
        totalCount = response.totalCount
        matches = response.matches
      }
    } finally {
      if (thisXhr === currentXhr) {
        loadingMatches = false
        currentXhr = null
        currentXhrBody = null
      }
    }
  }
  function buildGetDataBodyBase() {
    return {
      filters: [...filters, ...hiddenFilters],
      sortProperty,
      sortAscending,
    }
  }

  function getGridData() {
    const body = {
      ...buildGetDataBodyBase(),
      pageSize,
      offset: pageSize * (currentPage - 1),
      capacityId: capacity.capacityId,
      // includeAgreements: true,
    }

    if (currentXhr && validator.equals(currentXhrBody, body)) {
      return currentXhr
    }

    currentXhr = getMatchController().getListPage(body, api.noMonitor)
    currentXhrBody = body
    return currentXhr
  }

  function onFiltersChanged(_filters) {
    filters = _filters
    loadMatches()
  }

  async function loadStaff() {
    loadingStaff = true
    try {
      const response = await api.capacity.listCapacityStaff({ capacityId: capacity.capacityId })
      if (response) {
        capacityMatchesStaffCount = response.length
        capacityMatchesStaff = response.capacityStaff
      }
    } finally {
      loadingStaff = false
    }
  }

  function closeEditModal() {
    unsavedForms.navigateSafe(baseHref + `/${capacity.capacityId}`)
  }

  $: if (!capacity) load()
  $: if (capacity?.capacityId) {
    loadMatches()
    setCalendarData()
    loadStaff()
  }
</script>

<style>
  .rotation-status {
    background-color: #f9eac3;
    border-radius: 4px;
    align-self: stretch;
  }
</style>
