{#await srcPromise}
  <Loading />
{:then dataUrl}
  <img {width} {height} {alt} {title} src={dataUrl} data-test={dataTest} />
{:catch}
  <slot name="error" />
{/await}

<script>
  import Loading from 'components/Loading.svelte'
  import { getSrcPromise } from 'stores/lazy-image.js'
  export let src // string or a promise that returns string
  export let width
  export let height
  export let title
  export let alt
  export let dataTest
  let srcPromise
  $: srcPromise = getSrcPromise(src)
</script>
