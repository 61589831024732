{#if hasConfig && hasValues && canUseFeature}
  <DynamicForm {config} bind:values {viewOnly} {disabled} {compact} />
{/if}

<script>
  import DynamicForm from 'components/DynamicForm.svelte'
  import validator from 'services/validator.js'

  export let canUseFeature = false
  export let config = null
  export let disabled = false
  export let values = null
  export let viewOnly = false
  export let compact = false

  $: hasConfig = !validator.empty(config) && JSON.stringify(config) != '{}'
  $: hasValues = !validator.empty(values)
</script>
