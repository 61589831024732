<div
  class="collapsible"
  class:pseudo-disabled={disabled}
  class:light
  class:noContent
  class:mb0={noMarginBottom}
  class:expanded={open && !noContent}
  data-test={name}
>
  <div class="collapsible-title" use:onInteract={toggle} data-test="{name}-toggle">
    {#if !noContent}
      <div class="toggle-icon">
        <Icon name="chevron-right" title="{open ? 'Hide' : 'Show'} {label}" rotate90={open} class={iconClass} />
      </div>
    {/if}
    {#if icon != null}
      <Icon name={icon} fw class={iconClass} />
    {/if}
    <div class="toggle-label">
      <slot name="label">
        <h6 class={labelClass}>{label}</h6>
      </slot>
    </div>
  </div>
  {#if open && !noContent}
    <div class="collapsible-content" class:no-padding-content={noPaddingContent} class:scrollable={contentScrollable} transition:slide|local>
      <slot />
    </div>
  {/if}
</div>

<script>
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'
  import Icon from 'components/Icon.svelte'
  import LocalStorageStore from 'stores/local-storage-store.js'
  import onInteract from 'decorators/on-interact.js'

  const dispatch = createEventDispatcher()
  export let name = null
  export let label = ''
  export let icon = null
  export let remember = name != null
  export let open = false
  export let light = false
  export let noContent = false
  export let contentScrollable = false // a lot of places don't handle scrollable well since overflow-x seems to also make it vertically scroll. This can cause non-popper popups to get cut off (case in point, the "Schedule" collapsible on MatchModal when start/end is empty on an initially created rotation)
  export let noPaddingContent = false
  export let noMarginBottom = false
  export let iconClass = null
  export let labelClass = null
  export let disabled = false

  const openStore = LocalStorageStore(`collapsible-${name}`, open)

  // if remembering state, set to local storage value on load, and keep store in sync w/ local value
  if (remember) open = $openStore
  $: if (remember) $openStore = open

  function toggle() {
    if (noContent) return
    if (disabled) return
    open = !open
    if (open) dispatch('open')
    dispatch('toggle', open)
  }
</script>

<style lang="scss">
  @import '../../css/helpers';

  .collapsible {
    width: 100%;
    padding: 0 0 2px 0;
    border: 1px solid #eee;
    margin-bottom: 20px;
    border-radius: 4px;
    transition: 0.2s padding ease-in-out;
    background: #f7f7f7;
    position: relative;

    &::after {
      content: '';
      border: 1px solid transparent;
      height: 0;
      border-radius: 0 0 4px 4px;
      position: absolute;
      left: 0;
      right: 0;
      background: #f7f7f7;
      transition: height 0.1s ease-in-out;
    }

    &:hover:not(.expanded):not(.noContent) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        height: 8px;
        border-color: #eee;
      }
    }

    &:hover.pseudo-disabled {
      opacity: 0.5;
    }

    .collapsible-title {
      display: flex;
      flex-direction: row;
      padding: 10px 15px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;

      .toggle-label {
        flex-grow: 1;
        margin-bottom: 0;
        color: #444;
        font-size: 16px;
      }

      .toggle-icon {
        margin-right: 10px;
      }

      > * {
        margin-bottom: 0;
      }
    }

    .collapsible-title {
      cursor: pointer;
    }

    &.noContent .collapsible-title,
    &.noContent .toggle-label {
      /*InputToggle.scss is global, so need to target .toggle-label*/
      cursor: default;
    }

    .collapsible-content {
      padding: 10px;
      /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
      :global(> :last-child) {
        margin-bottom: 0 !important;
      }
      /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
      :global(.list-group-item:not(.active)) {
        background-color: transparent;
      }
      /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
      :global(.list-group-item[href]:hover:not(.active)) {
        background-color: #eee;
      }
    }

    .no-padding-content {
      padding: 0;
    }
  }

  .light {
    .collapsible-title,
    .collapsible-content {
      background-color: #fff;
    }
  }
</style>
