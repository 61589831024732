{#if !editing}Has steps that are due soon{/if}

<script context="module">
  import { FilterType } from 'config/enums.js'
  const label = 'Has steps that are due soon'
  export const meta = {
    type: FilterType.MatchHasStepsDueSoon,
    label,
    icon: 'clipboard-list',
    iconClass: 'text-danger',
  }
</script>

<script>
  export let editing = false
</script>
