{#if editing}
  <Tabs sm bind:active={filter.mode}>
    <Tab name={FilterMode.Simple}>Simple</Tab>
    <Tab name={FilterMode.Advanced}>Advanced</Tab>
  </Tabs>

  <div class="p2" style="min-width: 300px">
    {#if filter.mode === FilterMode.Simple}
      <FormGroup class="m0" validationMessageIsHtml valid={!validationMessages.simple.length} validationMessage={validationMessages.simple.join(' ')}>
        <InputRadioGroup
          name="capacity-rotations-simple"
          options={simpleOptions}
          bind:value={filter.simpleOption}
          firstOptionClass="mt0"
          lastOptionClass="mb0"
          class="flex-row flex-align-center g1"
          let:option
        >
          <Icon lg name="slots" class={iconClass[option.value]} />
          {option.name}

          <svelte:fragment slot="after-label">
            {#if option.value === SimpleMatchCountsOption.HasAvailableMatches}
              <Help tipOptions={{ maxWidth: Infinity, placement: 'right' }}>
                <div class="flex-row flex-align-center flex-wrap g05">
                  <span>Includes opportunities with</span>
                  <Icon lg name="infinity" />
                  <span><strong>Unlimited</strong> rotations</span>
                </div>
              </Help>
            {/if}
          </svelte:fragment>
        </InputRadioGroup>
      </FormGroup>
    {/if}

    {#if filter.mode === FilterMode.Advanced}
      <FormGroup validationMessageIsHtml valid={!validationMessages.matches.length} validationMessage={validationMessages.matches.join(' ')}>
        <label class="m0" for="filter-capacity-total-matches">Total rotations over time</label>
        <!-- In production, SELECT MAX(dbo.Capacities.Slots) == 24, so let's just default the range to 50 -->
        <InputRange
          name="filter-capacity-total-matches"
          bind:valueMin={filter.matchCountMin}
          bind:valueMax={filter.matchCountMax}
          {blankValue}
          {infinityValue}
          defaultFiniteMin={1}
          defaultFiniteMax={50}
          firstOptionClass="mt0"
          min={1}
        >
          <svelte:fragment slot="infinity-label">
            <Icon lg name="infinity" class="text-info" />
            <span>Unlimited</span>
          </svelte:fragment>
        </InputRange>
      </FormGroup>

      <FormGroup
        class="m0"
        validationMessageIsHtml
        valid={!validationMessages.matchesAvailable.length}
        validationMessage={validationMessages.matchesAvailable.join(' ')}
      >
        <label class="m0" for="filter-capacity-available-matches">Available rotations</label>
        <InputRange
          name="filter-capacity-available-matches"
          bind:valueMin={filter.matchCountAvailableMin}
          bind:valueMax={filter.matchCountAvailableMax}
          {blankValue}
          {infinityValue}
          defaultFiniteMin={0}
          defaultFiniteMax={50}
          firstOptionClass="mt0"
          lastOptionClass="mb0"
          excludeOptions={['infinity']}
          max={Number.isNaN(filter.matchCountMax) ? Infinity : filter.matchCountMax}
          disabled={filter.matchCountMin === Infinity || filter.matchCountMin == null || filter.matchCountMax === 0}
        >
          <svelte:fragment slot="infinity-label">
            <Icon lg name="infinity" class="text-info" />
            <span>Unlimited</span>
          </svelte:fragment>
        </InputRange>
      </FormGroup>
    {/if}
  </div>
{:else if filter.mode === FilterMode.Simple}
  <Icon lg name="slots" class={iconClass[filter.simpleOption]} />
  {simpleOptions.find(o => o.value === filter.simpleOption).name}
{:else if filter.matchCountMin == infinityValue}
  <Icon lg name="slots" class="text-info" />
  <span>Has</span>
  <Icon lg name="infinity" class="text-info" />
  <span>Unlimited rotations</span>
{:else}
  <Icon
    lg
    name="slots"
    class={filter.matchCountAvailableMax === 0 ? 'text-danger' : filter.matchCountMax === infinityValue ? 'text-info' : 'text-success'}
  />
  Has
  {#if filter.matchCountAvailableMin !== 0 || filter.matchCountAvailableMax != infinityValue}
    {#if filter.matchCountAvailableMin !== filter.matchCountAvailableMax}<strong>{filter.matchCountAvailableMin}</strong
      >–{:else if filter.matchCountMax != infinityValue && filter.matchCountAvailableMax != infinityValue && filter.matchCountAvailableMin === filter.matchCountAvailableMax && filter.matchCountAvailableMin === filter.matchCountMax}
      all
    {/if}{#if filter.matchCountAvailableMax == infinityValue}<Icon lg name="infinity" class="text-info" /> <strong>Unlimited</strong>{:else}<strong
        >{filter.matchCountAvailableMax}</strong
      >{/if}
    of
  {/if}
  {#if filter.matchCountMin !== filter.matchCountMax}<strong>{filter.matchCountMin}</strong>–{/if}{#if filter.matchCountMax == infinityValue}<Icon
      lg
      name="infinity"
      class="text-info"
    /> <strong>Unlimited</strong>{:else}<strong>{filter.matchCountMax}</strong>{/if}
  rotations
  {#if filter.matchCountAvailableMin !== 0 || filter.matchCountAvailableMax != infinityValue}
    available
  {/if}
{/if}

<script context="module">
  import { buildOption } from 'services/option-builder.js'
  import { FilterType, FilterMode, SimpleMatchCountsOption } from 'config/enums.js'
  import validator from 'services/validator.js'

  const infinityValue = null
  const blankValue = NaN

  function getValidationMessages(filter) {
    const messages = {
      simple: [],
      matches: [],
      matchesAvailable: [],
    }

    if (filter.mode === FilterMode.Simple) {
      // Should never happen.
      if (filter.simpleOption == null) messages.simple.push('Please select an option.')
      return messages
    }

    // Values will be NaN if they selected a radio with an <InputNumber> and either leave it blank
    // or paste invalid characters.
    const minGtMaxMsg = 'Minimum cannot be greater than maximum.'
    const matchesAvailableMaxMsg = `Please pick a maximum less than or equal to the <strong>${filter.matchCountMax}</strong> total rotations over time above.`

    if (Number.isNaN(filter.matchCountMin))
      messages.matches.push(
        Number.isNaN(filter.matchCountMax) ? 'Please select a minimum (at least 1) and maximum.' : 'Please select a minimum (at least 1).'
      )
    else if (Number.isNaN(filter.matchCountMax)) messages.matches.push('Please select a maximum.')
    if (Number.isNaN(filter.matchCountAvailableMin))
      messages.matchesAvailable.push(
        Number.isNaN(filter.matchCountAvailableMax) ? 'Please select a minimum and maximum.' : 'Please select a minimum.'
      )
    else if (Number.isNaN(filter.matchCountAvailableMax))
      messages.matchesAvailable.push(validator.int(filter.matchCountMax) ? matchesAvailableMaxMsg : 'Please select a maximum.')

    if (messages.matches.length || messages.matchesAvailable.length) return messages

    if (filter.matchCountMin != infinityValue && filter.matchCountMax != infinityValue && filter.matchCountMin > filter.matchCountMax)
      messages.matches.push(minGtMaxMsg)
    if (
      filter.matchCountAvailableMin != infinityValue &&
      filter.matchCountAvailableMax != infinityValue &&
      filter.matchCountAvailableMin > filter.matchCountAvailableMax
    )
      messages.matchesAvailable.push(minGtMaxMsg)
    if (messages.matches.length || messages.matchesAvailable.length) return messages

    if (
      filter.matchCountMax != infinityValue &&
      filter.matchCountAvailableMax != infinityValue &&
      filter.matchCountAvailableMax > filter.matchCountMax
    )
      messages.matchesAvailable.push(matchesAvailableMaxMsg)

    return messages
  }

  export const meta = {
    type: FilterType.CapacityMatchCounts,
    label: 'Rotations available',
    icon: 'slots',
    iconClass: 'color-text-steel',
    hideLabelIcon: true,
    btnBarClass: 'px2 pb2',
    dropdownClass: 'below',
    editLabel: null,

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.mode)
      writer.writeArg(config.simpleOption)
      writer.writeArg(config.matchCountMin)
      writer.writeArg(config.matchCountMax)
      writer.writeArg(config.matchCountAvailableMin)
      writer.writeArg(config.matchCountAvailableMax)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.mode = reader.readInt()
      config.simpleOption = reader.readInt()
      config.matchCountMin = reader.readInt(true)
      config.matchCountMax = reader.readInt(true)
      config.matchCountAvailableMin = reader.readInt(true)
      config.matchCountAvailableMax = reader.readInt(true)
      return config
    },

    create() {
      return {
        mode: FilterMode.Simple,
        simpleOption: SimpleMatchCountsOption.HasAvailableMatches,
        matchCountMin: 1,
        matchCountMax: null,
        matchCountAvailableMin: 0,
        matchCountAvailableMax: null,
      }
    },

    validate(filter) {
      const messages = getValidationMessages(filter)
      return !messages.simple.length && !messages.matches.length && !messages.matchesAvailable.length
    },
  }

  const simpleOptions = [
    buildOption('Has available rotations', SimpleMatchCountsOption.HasAvailableMatches),
    buildOption('Has limited available rotations', SimpleMatchCountsOption.HasLimitedAvailableMatches),
    buildOption('Doesn’t have available rotations', SimpleMatchCountsOption.HasNoAvailableMatches),
  ]

  const iconClass = {
    [SimpleMatchCountsOption.HasAvailableMatches]: 'text-info', // text-info because it includes Unlimited, which uses a text-info Infinity icon
    [SimpleMatchCountsOption.HasLimitedAvailableMatches]: 'text-success', // text-success because hurray, it's still open!
    [SimpleMatchCountsOption.HasNoAvailableMatches]: 'text-danger',
  }
</script>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'
  import InputRange from 'components/InputRange.svelte'

  import Tab from 'components/Tab.svelte'
  import Tabs from 'components/Tabs.svelte'

  export let filter
  // export let filterOptions
  export let editing = false
  // export let appliedMeta

  $: validationMessages = getValidationMessages(filter)
</script>
