<FormGroup>
  <div>
    {#each coordinatorsAndPreceptors as ns}
      <UserCard
        showStudentInfo={false}
        user={ns}
        showEmail
        showPhone
        roles={ns.roleNames}
        matchStatus={ns.status}
        showOnboardingWarning={ns.status < MatchStatus.Ready && match.status >= MatchStatus.Active}
        onDelete={canDeleteOrAdd(ns) ? deleteMatchUser(MatchRole.Preceptor) : null}
        deleteMessage="Remove {userNameFriendly(ns)} from the rotation as preceptor. {removeFacultyExtra(ns, MatchRole.Preceptor)}"
        deleteMessageConfirm={{
          title: 'Remove staff',
          message: `Are you sure you’d like to remove <strong>${userNameFriendly(ns)}</strong> from this the rotation? ${removeFacultyExtra(
            ns,
            MatchRole.Preceptor
          )}`,
          confirmLabel: 'Remove',
          confirmClass: 'btn-danger',
        }}
      />
    {/each}
    {#if canDeleteOrAdd(null)}
      <AddMatchUser role={MatchRole.Preceptor} {match} bind:matchUsers={match.matchUsers} class="ml2" />
    {/if}
  </div>
</FormGroup>

<script>
  import { MatchStatus, MatchRole } from 'config/enums.js'
  import UserCard from 'components/UserCard.svelte'
  import AddMatchUser from 'components/AddMatchUser.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'

  export let match
  export let coordinatorsAndPreceptors
  export let removeFacultyExtra
  export let deleteMatchUser
  export let userNameFriendly

  function canDeleteOrAdd(ns) {
    return match.isEditable && (match.isCoordinator || match.isSchoolCoordinator) && (ns == null || ns.roleValues?.includes(MatchRole.Preceptor)) // allow coordinators to change matches even after they're completed for reporting purposes
  }
</script>
