{#each users.slice(0, numToShow) as user}
  <slot {user} />
{/each}
{#if otherCount > 0}
  <div class="other-count">
    {otherCount} more
  </div>
{/if}

<script>
  export let users

  const numToShow = 5

  $: userCount = users.length
  $: otherCount = userCount - numToShow
</script>

<style>
  .other-count {
    font-size: 12px;
    color: #999;
  }
</style>
