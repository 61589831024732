<Collapsible label="hour logging" name="match-modal-hour-logging">
  <div slot="label" class="toggle-label">
    <div class="flex-row">
      <h3 class="m0 mr1 flex-grow">Hour logging</h3>
      {#if canSetExpectedHours}<Btn
          class="btn btn-sm btn-outline-primary"
          icon="time"
          title="Optionally configure the number of hours expected for the students."
          stopPropagation
          on:click={() => (settingExpectedHours = true)}>Set required hours</Btn
        >{/if}
    </div>
    <div class="flex-row flex-wrap">
      {#each studentsToShow as user}
        <HourLoggerBtn
          {match}
          {matchDaysComputed}
          totalScheduledHours={totalScheduledHoursByUserId[user.userId]}
          {user}
          class="mt1 mr1"
          on:click={() => (modalParams = { user })}
        />
      {/each}
    </div>
  </div>

  <Calendar {startDate} {endDate} let:day>
    {#if !day.disabled && matchDaysDict[day.formatted]}
      {#each studentsToShow as user}
        <HourLoggerBtn
          class="full-width mt1"
          {match}
          {matchDaysComputed}
          {user}
          date={day.formatted}
          on:click={() => (modalParams = { user, date: day.formatted })}
        />
      {/each}
    {/if}
  </Calendar>
</Collapsible>

{#if modalParams}
  <HourLoggerModal
    {match}
    {matchDaysComputed}
    totalScheduledHours={totalScheduledHoursByUserId[modalParams.user.userId]}
    {...modalParams}
    on:change={onHourLogsUpdated}
    on:close={() => (modalParams = null)}
  />
{/if}

{#if settingExpectedHours}
  <SetExpectedHoursModal users={students} onSave={onSaveExpectedHours} onClose={() => (settingExpectedHours = false)} />
{/if}

<script>
  import Collapsible from './Collapsible.svelte'
  import HourLoggerBtn from './HourLoggerBtn.svelte'
  import HourLoggerModal from './HourLoggerModal.svelte'
  import Calendar from './Calendar.svelte'
  import { buildMatchDayLookup, getImplicitMatchDays, getTotalScheduledHoursByUserId } from 'services/capacity-usage.js'
  import persona from 'stores/persona.js'
  import user from 'stores/user.js'
  import Btn from './bootstrap/Btn.svelte'
  import SetExpectedHoursModal from './SetExpectedHoursModal.svelte'

  export let match
  export let students
  export let matchChanged
  export let onSaveExpectedHours

  let modalParams = null
  let settingExpectedHours = false

  $: startDate = match.startDate
  $: endDate = match.endDate
  $: matchDaysComputed = match.matchDays?.length > 0 ? match.matchDays : getImplicitMatchDays(match)
  $: matchDaysDict = buildMatchDayLookup(matchDaysComputed)
  $: totalScheduledHoursByUserId = getTotalScheduledHoursByUserId(matchDaysComputed, match.shifts, match.matchUsers)
  $: studentsToShow = $persona.isStudent ? students.filter(s => s.userId === $user.userId) : students // students should only see their hours
  $: canSetExpectedHours = !$persona.isStudent

  function onHourLogsUpdated(e) {
    // when hours are updated, update the match viewmodel accordingly
    matchChanged = true
    const { user, logs } = e.detail
    match.hourLogsByUserId[user.userId] = logs
  }
</script>
