{#if _value != null && _value !== ''}
  {#if type != 'checkbox'}
    <svelte:element this={labelTag} class={labelClass}>{label}</svelte:element>
  {/if}

  <div class={valueClass} data-test="custom-field-{name}">
    {#if type === 'text' || type === 'radiogroup'}
      {_value}
    {:else if type === 'numeric'}
      <div class="flex-row flex-align-center g05">
        <Icon lg name="hashtag" class="color-text-teal" />
        <span>{readableNumber(_value)}</span>
      </div>
    {:else if type === 'textarea'}
      <LongDesc class="m0 pre-wrap" max={longDescMax} text={_value} />
    {:else if type === 'date'}
      <div class="flex-row flex-align-center g05">
        <Icon lg name="calendar" class="color-text-teal" />
        <span>{_value}</span>
      </div>
    {:else if type === 'email'}
      <EmailAddressBtn email={_value} class={btnClass} />
    {:else if type === 'phone'}
      <TelephoneNumberBtn number={_value} class={btnClass} />
    {:else if type === 'rating'}
      <div class="flex-row flex-align-center g05">
        {#each ratingOptions as i (i)}
          {@const filled = _value >= i}
          <Icon lg name={filled ? 'star-filled' : 'star'} class={filled ? 'text-warning' : ''} />
        {/each}
      </div>
    {:else if type === 'yesno'}
      <div class="flex-row flex-align-center g05">
        {#if _value}
          <Icon lg name="check" class="text-success" />
          <span>Yes</span>
        {:else}
          <Icon lg name="times" class="text-danger" />
          <span>No</span>
        {/if}
      </div>
    {:else if type === 'checkboxgroup' || type === 'select'}
      {@const items = getValueArray()}
      {#if items.length === 1}
        {_value}
      {:else if compact}
        <FriendlyList {items} max={1} punctuation toggleable />
      {:else}
        <ul>
          {#each items as item}
            <li>{item}</li>
          {/each}
        </ul>
      {/if}
    {:else if type === 'checkbox'}
      {#if _value}
        <Icon lg name="check-square" class="icon-checkbox color-text-teal" />
      {:else}
        <Icon lg name="times" class="text-danger" />
      {/if}
      <svelte:element this={labelTag} class="inline">{label}</svelte:element>
    {/if}
  </div>
{/if}

<script>
  import { readableNumber } from 'services/string-utils.js'
  import EmailAddressBtn from 'components/EmailAddressBtn.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import TelephoneNumberBtn from 'components/TelephoneNumberBtn.svelte'

  export let field
  export let value = null
  export let compact = false
  export let longDescMax = compact ? 25 : 60

  const ratingOptions = [1, 2, 3, 4, 5]

  $: labelTag = compact ? 'h5' : 'h2'
  $: labelClass = compact ? 'mt0 mb05' : 'mb05'
  $: valueClass = compact ? 'mb1' : 'mb2'
  $: btnClass = compact ? 'btn btn-default btn-sm' : null
  $: label = field.label
  $: name = field.uniqueName ?? field.name
  $: type = field.type
  $: _value = value ?? null

  function getValueArray() {
    return _value.toString().split(',')
  }
</script>
