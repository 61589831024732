{#if stepSubmission}
  <Modal showClose={false}>
    <span slot="title">
      {#if otherCount !== 0}
        <h4 data-test="other-step-submissions">
          Would you like to
          <span class="text-{actionColor}">{actionDesc}</span>
          “{stepSubmission.step.name}” on
          {#if loading}other rotations{:else if multipleOthers}these other {otherCount} rotations{:else}this other rotation{/if}
          too?
        </h4>
      {/if}
    </span>
    <div class="modal-body">
      {#if loading}
        <Spinner />
        <span class="help-block-inline">
          Looking for other active <Icon lg autoColor name="rotation" /> rotations where “{stepSubmission.step.name}”
          <StepCompleterExplanation {stepSubmission} lowercase />…
        </span>
      {:else if otherCount === 0}
        <HelpBlock>
          No other active <Icon lg autoColor name="rotation" /> rotations where “{stepSubmission.step.name}”
          <StepCompleterExplanation {stepSubmission} lowercase />
        </HelpBlock>
      {:else}
        {#if multipleOthers}
          <div class="mb1">
            {#if allSelected}
              <Btn class="btn-sm" on:click={deSelectAll}>Deselect all</Btn>
            {:else}
              <Btn class="btn-sm btn-primary" on:click={selectAll}>Select all</Btn>
            {/if}
          </div>
        {/if}
        {#each otherSubmissions as ss (ss.submissionKeyGuaranteedUnique)}
          <StepSubmissionRowWithMatchInfo
            bind:checked={selectedSubmissions[ss.submissionKey]}
            {multipleOthers}
            stepSubmission={ss}
            {matchModalMatchId}
            {onActionCompleted}
          />
        {/each}
      {/if}
    </div>
    <div class="modal-footer">
      {#if otherSubmissions != null && otherCount > 0}
        <Btn
          on:click={applyToSelected}
          loading={applying}
          disabled={selectedSubmissionsCount === 0 || loading}
          class="btn-primary"
          dataTest="step-action-apply"
        >
          Yes,
          {actionDesc}
          {#if loading}
            them
          {:else if multipleOthers}
            {#if allSelected}all{:else}selected {selectedSubmissionsCount}/{otherCount}{/if}
          {:else}it{/if}
        </Btn>
      {/if}
      <Btn on:click={onClose} dataTest="step-action-close"
        >{#if loading}Leave other submissions how they are{:else if otherCount === 0}Close{:else}No, just this rotation{/if}</Btn
      >
    </div>
  </Modal>
{/if}

<script>
  import { StepAction } from 'config/enums.js'
  import { toServerModel } from 'services/step-submission-service.js'
  import Btn from './bootstrap/Btn.svelte'
  import HelpBlock from './fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from './Modal.svelte'
  import Spinner from './Spinner.svelte'
  import StepCompleterExplanation from './StepCompleterExplanation.svelte'
  import StepSubmissionRowWithMatchInfo from './StepSubmissionRowWithMatchInfo.svelte'

  export let stepAction
  export let matchModalMatchId
  export let onClose
  export let onActionCompleted

  let applying = false
  let selectedSubmissions = {}

  $: loading = stepAction.loading
  $: action = stepAction.action
  $: stepSubmission = stepAction.stepSubmission // TODO: prod says this can be null sometimes. determine why and make it not be null...for now, just check null.
  $: otherSubmissions = stepAction.otherSubmissions
  $: otherCount = otherSubmissions?.length ?? 0
  $: multipleOthers = otherCount > 1
  $: actionDesc = getActionDesc(action.type)
  $: actionColor = getActionColor(action.type)
  $: if (otherCount != null) selectAll() // select all when initially open up
  $: selectedSubmissionsCount = Object.keys(selectedSubmissions).filter(k => selectedSubmissions[k]).length
  $: allSelected = selectedSubmissionsCount === otherSubmissions?.length

  function getActionDesc(type) {
    switch (type) {
      case StepAction.AddFile:
        return 'add this file to'
      case StepAction.DeleteFile:
        return 'remove this file from'
      case StepAction.SaveForm:
        return 'apply these changes to'
      case StepAction.Reject:
        return 'reject'
      case StepAction.Verify:
        return 'verify'
      case StepAction.Waive:
        return 'waive'
      case StepAction.UnWaive:
        return 'unwaive'
      case StepAction.Skip:
        return 'skip'
      case StepAction.Unskip:
        return 'unskip'
      case StepAction.SetDueDate:
        return 'change the due date for'
      default:
        return 'apply this change to'
    }
  }

  function getActionColor(type) {
    switch (type) {
      case StepAction.Reject:
        return 'danger'
      case StepAction.Verify:
        return 'success'
      case StepAction.SetDueDate:
      case StepAction.Waive:
      case StepAction.UnWaive:
        return 'warning'
      case StepAction.Skip:
      case StepAction.Unskip:
        return 'info'
      default:
        return null
    }
  }

  async function applyToSelected() {
    const submissions = otherSubmissions.filter(sub => selectedSubmissions[sub.submissionKey])
    applying = true
    try {
      await action.invoke(submissions.map(toServerModel), true)
    } finally {
      applying = false
    }
    onClose()
  }

  function selectAll() {
    selectedSubmissions = {}
    otherSubmissions.forEach(sub => (selectedSubmissions[sub.submissionKey] = true))
  }

  function deSelectAll() {
    selectedSubmissions = {}
  }
</script>
