<Grid let:row rows={guests} class="middle flex-wrap" name="participants-detail-grid" participantColor={true}>
  <GridCol {row} header="School">
    <CapacityGuestProfilePictureAndName capacityGuest={row} />
  </GridCol>
  <GridCol {row} header="Agreement">
    {#if !row.agreement}
      <NoAgreementSpecified />
    {:else}
      {#each [row.agreement] as a}
        <AgreementProfilePicturesAndName
          noTooltip
          name={a.name}
          orgName={a.orgName}
          orgRelativeName={a.orgRelativeName}
          orgProfilePicture={a.orgProfilePicture}
          teamName={a.teamName}
          teamOrgId={a.teamOrgId}
          schoolName={a.schoolName}
          schoolRelativeName={a.schoolRelativeName}
          schoolProfilePicture={a.schoolProfilePicture}
          schoolTeamName={a.schoolTeamName}
          schoolTeamOrgId={a.schoolTeamOrgId}
        />
      {/each}
    {/if}
  </GridCol>
  <GridCol {row} header="Rotation request restriction dates">
    <StartAndEndDate startDate={row.schedulingStartDate} endDate={row.schedulingEndDate} />
  </GridCol>
  <GridCol {row} header="Request coordinator approval?">
    {row.autoAcceptMatches ? 'Yes' : 'No'}
  </GridCol>
  <GridCol {row} header="Release unused groups">
    <AutoReleaseUnscheduledMatchesWhenLabel concise value={row.autoReleaseUnscheduledMatchesWhen} />
  </GridCol>
  <GridCol {row} header="Guaranteed rotations" class="text-right">
    {readableNumber(row.guaranteedRotations) ?? ''}
  </GridCol>
  <GridCol {row} header="Max rotation requests" class="text-right">
    <Unlimited size="lg" value={row.maximumRotations} readable={true} />
  </GridCol>
  <GridCol {row} header="Max students" class="text-right">
    <Unlimited size="lg" value={row.maximumStudents} readable={true} />
  </GridCol>
  <GridCol {row} header="Rotations">
    <div class="flex-column">
      <em>
        {#if row.approvedRotations}
          {readableNumber(row.approvedRotations)} approved rotations
        {/if}
      </em>
      <em>
        {#if row.pendingRoations}
          {readableNumber(row.pendingRoations)} pending rotations
        {/if}
      </em>
    </div>
  </GridCol>
</Grid>

<script>
  import { readableNumber } from 'services/string-utils'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import CapacityGuestProfilePictureAndName from './CapacityGuestProfilePictureAndName.svelte'
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Unlimited from 'components/Unlimited.svelte'
  import AutoReleaseUnscheduledMatchesWhenLabel from './AutoReleaseUnscheduledMatchesWhenLabel.svelte'

  export let guests
</script>
