{#if capacityGuest}
  <div data-test="guaranteed-rotation-count">
    <div>
      <!-- TODO(nursing-mati): Confirm if guaranteed rotations used should be matchCount (Match status >= 10 AND <= 50) -->
      {#if capacityGuest.guaranteedMatchCountGranted || capacityGuest.guaranteedMatches}
        {#if isDraft}
          <span>{capacityGuest.guaranteedMatches}</span>
          <Icon sm name={capacity.allowGroups ? 'users' : 'user'} />
          <span>{singularOrPlural(capacityGuest.guaranteedMatches, 'rotation')} guaranteed</span>
        {:else}
          <span>{capacityGuest.matchCount}</span>
          <span>/</span>
          <span
            >{capacityGuest.guaranteedMatchCountGranted}
            {singularOrPlural(capacityGuest.guaranteedMatchCountGranted, 'rotation')} guaranteed</span
          >
        {/if}
      {:else}
        <span>No rotations guaranteed</span>
      {/if}
    </div>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import { singularOrPlural } from 'services/string-utils.js'

  export let capacity
  export let guestOrgId

  $: isDraft = !capacity.openForScheduling
  $: capacityGuest = capacity.guests?.find(g => g.guestOrgId === guestOrgId)
</script>
