<!-- Later on when we have a custom staff pickers, make this like FilterTypeStudent and then combine FilterTypeStudent in to use this too -->
<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={user}>
  <ProfilePic id={user.userId} name={user.name} initials={user.initials} src={user.profilePicture} medium class="mr1" />
  <span>{user.name}</span>

  <div slot="selectedItem" class="inline-flex-row flex-align-center g05" let:item={selectedUser}>
    {#if selectedUser}
      <ProfilePic id={selectedUser.userId} initials={selectedUser.initials} name={selectedUser.name} src={selectedUser.profilePicture} small />
      <strong>{selectedUser.name}</strong>
    {/if}
  </div>
</FilterTypeListFilter>

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  export const meta = {
    ...baseMeta,
    canHaveMultiple: false,
    hasDoesntHave: true,
    idKey: 'userId',
    filterProp: 'userIds',
    filterPropType: 'string',
    allowNull: true,
    excludable: false,
    toMany: true,
    editLabel: null,
  }
</script>

<script>
  import ProfilePic from 'components/ProfilePic.svelte'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
