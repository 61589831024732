{#if href}
  <a {href} on:click={e => (href == null ? e.preventDefault() : null)} class={classAttr} data-test={dataTestAttr} use:tip={title}>
    <CardContent {id} {name} {initials} {dataTest} {image} {imgHref} {icon} {imgTitle} {deleted}>
      <slot />
    </CardContent>
  </a>
{:else}
  <div class={classAttr} data-test={dataTestAttr} use:tip={title}>
    <CardContent {id} {name} {initials} {dataTest} {image} {imgHref} {icon} {imgTitle} {deleted}>
      <slot />
    </CardContent>
  </div>
{/if}

<script>
  import tip from 'decorators/tip.js'
  import CardContent from 'components/Card.Content.svelte'

  let className = ''
  export { className as class }
  export let dataTest = null
  export let href = null
  export let image = null
  export let imgHref = null
  export let name = null
  export let id = null
  export let title = null
  export let imgTitle = null
  export let deleted = false
  export let initials = null

  const icon = null
  $: classAttr = `card${className ? ` ${className}` : ''}`
  $: dataTestAttr = dataTest == null ? null : dataTest
</script>
