import { derived, writable } from 'svelte/store'

import api from 'services/api.js'
import sockets from 'services/sockets.js'

function createStore() {
  const { subscribe, set } = writable({})
  const load = async () => {
    set(null)
    set(await api.messenger.getUnreadCounts(api.noMonitor))
  }
  sockets.on('ConnectionsChanged', load)
  return {
    subscribe,
    load,
    set,
  }
}
const unreadMessageCounts = createStore()

const totalUnreadMessageCount = derived(unreadMessageCounts, $unreadMessageCounts => {
  const counts = $unreadMessageCounts
  if (counts == null) return null

  let total = 0
  for (const i in counts) {
    total += counts[i]
  }
  return total
})

export { unreadMessageCounts, totalUnreadMessageCount }
