import { tick } from 'svelte'
import { unreadMessageCounts } from './unread-message-counts.js'
import { writable } from 'svelte/store'
import api from 'services/api.js'
import connections from './connections.js'
import featureFlagStore from 'stores/feature-flags.js'
import intercom from 'services/intercom-proxy.js'
import pendo from 'services/pendo-proxy.js'
import personaService from 'services/persona-service.js'
import savedViews from 'stores/saved-views.js'
import unreadNotificationCount from './unread-notification-count.js'

function createStore() {
  const { subscribe, set } = writable(null)

  async function load(profileData = null, newPersonaValuesForUser = null) {
    profileData ??= await api.profile.getUserProfile(api.noMonitor)
    setUser(profileData, newPersonaValuesForUser)
    await Promise.all([loadCounts(), savedViews.load()])
    await tick() // so stores can get set
  }

  function setUser(user, newPersonaValuesForUser) {
    set(user)
    personaService.setUser(user, newPersonaValuesForUser)
    intercom.setUser(user)
    pendo.setUser(user)
    featureFlagStore.setUser(user)
  }

  return {
    subscribe,
    load,
    set: setUser,
  }
}

export default createStore()

function loadCounts() {
  return Promise.all([connections.load(), unreadNotificationCount.load(), unreadMessageCounts.load()])
}
