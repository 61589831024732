<RelatedEntityCount {count} {name} {unit} {iconFirst} {iconFirstLabel} icon="agreement" iconLg iconAutoColor />

<script>
  import RelatedEntityCount from 'components/RelatedEntityCount.svelte'

  export let name = 'agreement'
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  export let unit = 'Agreement'
  export let iconFirst = false
  export let iconFirstLabel = ''
</script>
