<Router>
  <div>
    {#if !hideMatchFilters}
      <div class="mb1">
        <MatchFilters {excludedFilterTypes} bind:filters={criteria.filters} label="Add rotation filter" onChanged={() => load('filters changed')} />
      </div>
    {/if}

    <div class="toolbar mb1">
      <ZoomPicker bind:value={criteria.zoom} on:change={() => load('zoom')} />

      <InputSelect
        bind:value={criteria.groupBy}
        options={groupByOptionsFiltered}
        on:change={() => {
          load('group by changed')
          criteria.contextFilter = null // reset context filter values when changing group by
        }}
        name="groupBy"
        prefixLabel="and by"
      />

      {#if contextFilterOptions}
        <InputSelect
          bind:value={criteria.contextFilter}
          options={contextFilterOptions}
          on:change={setFiltered}
          name="contextFilter"
          placeholder="All {pluralize(groupByName)}"
          filterable={contextFilterOptions?.length > 5}
          multiple
        />
      {/if}
      <Filter bind:text={criteria.keyword} />
    </div>

    <div class="toolbar mb1">
      <div class="flex-grow">
        <DatePicker
          range
          bind:value={criteria.startDate}
          bind:endValue={criteria.endDate}
          allowNextPrev
          on:changed={() => load('start/end')}
          usePresetRanges
          showTodayBtn
        />
      </div>
    </div>

    {#if loading || loadingFilter}
      <Spinner />
    {/if}

    {#if capacityData}
      {#if criteria.zoom === 'd'}
        <Calendar startDate={criteria.startDate} endDate={criteria.endDate} let:day>
          {#if capacityData[day.formattedDashes] && capacityData[day.formattedDashes].buckets.length > 0}
            <Buckets {capacityData} key={day.formattedDashes} {criteria} {baseHref} />
          {/if}
        </Calendar>
      {:else}
        <div class="grid" data-test="calendar">
          {#each Object.keys(capacityData) as key}
            <div>
              <div class="bucket-title">
                <!-- Using a span so the tooltip isn't triggered from a large whitespace area -->
                <span use:tip={key.replace(/-/g, '/').replace(/_/g, ' – ')}>
                  {capacityData[key].keyFriendly}
                </span>
              </div>
              <div>
                <Buckets {capacityData} {key} {criteria} {baseHref} />
              </div>
            </div>
          {/each}
        </div>
      {/if}
    {/if}
  </div>

  <Route path="/:key/:groupedById/*" let:params>
    <BucketDetails
      {...params}
      {capacityDataAll}
      {shifts}
      {baseHref}
      {groupByName}
      {criteria}
      on:close={onCloseDetails}
      on:matchModalClosed={onMatchModalClosed}
    />
  </Route>
</Router>

<script>
  import _filter from 'services/filter.js'
  import { formatEnumKey } from 'services/formatters.js'
  import { GroupBy, PersonaType, FilterType } from 'config/enums.js'
  import { onDestroy, tick } from 'svelte'
  import { pluralize } from 'services/string-utils.js'
  import { Router, Route, navigate } from 'svelte-routing'
  import api from 'services/api.js'
  import BucketDetails from 'components/CapacityCalendar.BucketDetails.svelte'
  import Buckets from 'components/CapacityCalendar.Buckets.svelte'
  import Calendar from 'components/Calendar.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import enumToOptions from 'services/enum-to-options.js'
  import Filter from 'components/Filter.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import MatchFilters from 'components/MatchFilters.svelte'
  import matchRequestTypeHelpers from 'services/match-request-type-helpers.js'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import Spinner from 'components/Spinner.svelte'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'
  import ZoomPicker from 'components/ZoomPicker.svelte'

  export let baseHref = null
  export let criteria
  export let hideMatchFilters = false
  export let groupByOptions = null

  const setFilteredDebounced = _.debounce(setFiltered, 300)
  const excludedFilterTypes = [
    FilterType.KeywordSearch,
    FilterType.MatchClosedDate,
    FilterType.MatchCloseReason,
    FilterType.MatchEndDate,
    FilterType.MatchHasIncompleteSteps,
    FilterType.MatchHasProposedChanges,
    FilterType.MatchHasStepsICanComplete,
    FilterType.MatchHasStepsICanVerify,
    FilterType.MatchPastStatuses,
    FilterType.MatchStartDate,
    FilterType.MatchStatusCategory,
    FilterType.MatchStatuses,
    FilterType.StepSubmissionTargetUser,
  ]

  let currentXHR = null
  let currentXHRParams = null
  let loading = false
  let loadingFilter = false
  let capacityDataAll = null
  let shifts = null
  let capacityData = null
  let contextFilterOptions
  let groupByName

  // can't set this in capacity-calendar.js because that's set prior to $persona getting set...
  $: if (criteria && criteria.groupBy == null)
    criteria.groupBy = $persona.personaType === PersonaType.SchoolStaff ? GroupBy.School : GroupBy.ClinicalSite
  $: if (criteria) {
    const allSame =
      criteria.filled === criteria.pending &&
      criteria.pending === criteria.waitlisted &&
      criteria.waitlisted === criteria.overfilled &&
      criteria.overfilled === criteria.overfilledMaybe
    criteria.showAll = allSame
  }
  $: search = criteria?.keyword
  $: capacityDataAll, search, setFilteredDebounced()
  $: groupByOptionsFiltered = enumToOptions(GroupBy)
    .filter(o => o.optionValue !== GroupBy.GuestTeam) // for now, we don't give ability to filter by GuestTeam since it will never be set on match--we'll implement that later one
    .map(x => ({ label: x.optionLabel, value: x.optionValue }))
    .filter(g => groupByOptions === null || groupByOptions.some(gg => gg === g.value))

  $: if ($personaFilters.orgId) load('persona orgId set')

  onDestroy(() => {
    if (currentXHR) currentXHR.abort()
  })

  // eslint-disable-next-line no-unused-vars
  export async function load(reason) {
    // eslint-disable-next-line no-console
    // console.log('get capacity data', reason)
    if (criteria.startDate == null || criteria.endDate == null) return
    const urlParams = { orgId: $persona.orgId }
    const desiredXHRParams = _.cloneDeep({ urlParams, body: criteria })
    if (currentXHR) {
      if (validator.equals(desiredXHRParams, currentXHRParams)) {
        return
      } else {
        currentXHR.abort()
      }
    }
    loading = true
    currentXHR = api.capacityCalendarReport.run(urlParams, criteria, api.noMonitor)
    currentXHRParams = desiredXHRParams
    const data = await currentXHR
    currentXHR = null
    currentXHRParams = null
    capacityDataAll = data.timeBuckets
    shifts = data.shifts
    groupByName = getGroupByName()
    contextFilterOptions = {}
    Object.keys(capacityDataAll).forEach(key => {
      capacityDataAll[key].buckets = capacityDataAll[key].buckets.map(b => {
        // combine normalized info with the timebucket's numbers for this group
        b = {
          ...data.buckets[b.groupedById.toLowerCase()],
          ...b,
        }
        b.title = getShortTitle(b)
        contextFilterOptions[b.groupedById] = b.title
        return b
      })
    })
    capacityData = capacityDataAll
    contextFilterOptions =
      Object.keys(contextFilterOptions).length <= 1
        ? null
        : Object.keys(contextFilterOptions).map(k => ({ label: contextFilterOptions[k], value: k }))
    loading = false
  }

  function onMatchModalClosed(e) {
    const changed = e.detail
    if (!changed) return
    load('match modal closed with changes')
  }

  function setFiltered() {
    if (capacityDataAll == null) return
    loadingFilter = true
    setTimeout(async () => {
      if (criteria) {
        const hasKeyword = !validator.empty(criteria.keyword)
        const hasContextFilter = !validator.empty(criteria.contextFilter)
        let _capacityData
        if (hasKeyword || hasContextFilter) {
          _capacityData = _.cloneDeep(capacityDataAll)
          Object.keys(_capacityData).forEach(key => {
            if (hasContextFilter) _capacityData[key].buckets = _capacityData[key].buckets.filter(b => criteria.contextFilter.includes(b.groupedById))
            if (hasKeyword) _capacityData[key].buckets = _filter(_capacityData[key].buckets, criteria.keyword)
          })
        } else {
          _capacityData = capacityDataAll
        }
        await tick()
        capacityData = _capacityData
      }
      loadingFilter = false
    })
  }

  function getGroupByName() {
    return formatEnumKey(Object.keys(GroupBy).find(k => GroupBy[k] === criteria.groupBy))
  }

  function getShortTitle(bucket) {
    switch (criteria.groupBy) {
      case GroupBy.Shift:
        return `${bucket.shift} - ${bucket.capacity}` // shifts will probably be named very generically, so include their capacity name
      case GroupBy.Opportunity:
        return bucket.capacity
      case GroupBy.RequestType:
        return matchRequestTypeHelpers.optionsByValue[bucket.matchRequestType].label
      case GroupBy.Agreement:
        return bucket.agreement
      case GroupBy.Service:
        return bucket.service
      case GroupBy.ClinicalSite:
        return bucket.host
      case GroupBy.ClinicalTeam:
        return bucket.hostTeam
      case GroupBy.School:
        return bucket.guest
      case GroupBy.GuestTeam:
        return bucket.guestTeam
      case GroupBy.Discipline:
        return bucket.discipline
      case GroupBy.Student:
      case GroupBy.Coordinator:
      case GroupBy.SchoolCoordinator:
      case GroupBy.SchoolFaculty:
      case GroupBy.Preceptor:
        return bucket.userFullName
      default:
        // eslint-disable-next-line no-console
        console.warn(`no GroupBy found for '${criteria.groupBy}'`)
        return bucket.service // just show something
    }
  }

  function onCloseDetails() {
    navigate(baseHref)
  }
</script>

<style>
  .grid {
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    min-width: 800px;
  }

  .grid > div {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 5px;
    position: relative;
  }

  .grid .bucket-title {
    font-size: 18px;
    margin-bottom: 3px;
    font-weight: bold;
  }
</style>
