<blockquote><SafeHtml value={messageHtml} /></blockquote>

<script>
  import SafeHtml from './SafeHtml.svelte'
  export let messageHtml
</script>

<style lang="scss">
  @import '../../css/helpers';

  blockquote {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 13px;
    background-color: #f1efef;
    border-left: 3px solid $primary;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(a.user-mention) {
    background-color: $lightgray;
    color: #000;
    border-radius: 2px;
    padding: 0 5px;

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(a.user-mention-me) {
    background-color: $info;
    color: $light;

    &:hover {
      color: $light;
    }
  }
</style>
