<div class={className} use:tip={tooltip}>
  {#if !hideIcons}
    <Icon name="time" class={startIconClass} />
  {/if}
  {#if startTime === endTime}
    <span>{dateService.formatTime(startTime)} only</span>
  {:else if endTime == null}
    <span>{dateService.formatTime(startTime)} onwards</span>
  {:else}
    <span>{dateService.formatTime(startTime)}</span>
    <span>–</span>
    {#if !hideIcons}
      <Icon name="time" class={endIconClass} />
    {/if}
    <span>
      {dateService.formatTime(endTime)}
      <span class={diffClass}>({diffTimeHrsFormatted})</span>
    </span>
  {/if}
</div>

<script>
  import dateService from 'services/date-service.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let startTime
  export let endTime
  export let tooltip = null
  let className = 'inline-flex-row flex-align-center g05'
  export { className as class }
  export let diffClass = 'small'
  export let hideIcons = false
  export let startIconClass = 'color-text-dark-green'
  export let endIconClass = 'color-text-light-red'

  $: diffTimeHrsFormatted = dateService.diffTimeHrsFormatted(startTime, endTime)
</script>
