<Form on:submit={submit}>
  <div class="modal-body">
    <div class="flex-row shiftday">
      <div class="m1">
        <FormGroup>
          <label>Set dates for schedule <RequiredMarker /></label>
          <InputOptionalDatePicker
            name="shift-day-dates"
            bind:startValue={input.date}
            bind:endValue={input.repeatEndDate}
            range
            defaultStartValue={capacity.startDate}
            defaultEndValue={capacity.endDate}
            min={capacity.startDate}
            max={capacity.endDate}
          >
            <svelte:fragment slot="no-value-label">
              Use window dates
              {#if shift.startDate !== null}
                (<StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />)
              {:else}
                (No set dates)
              {/if}
            </svelte:fragment>
          </InputOptionalDatePicker>
        </FormGroup>

        <FormGroup>
          <svelte:fragment slot="validationMessage">Please select a rotation status. {hasValue}</svelte:fragment>
          <label class="mb0">Set specific hours <RequiredMarker /></label>
          <InputOptionalTimePicker name="shift-day-times" bind:startValue={input.startTime} bind:endValue={input.endTime} showTotal range>
            <svelte:fragment slot="no-value-label">
              Use availability window times
              {#if shift.startTime !== null && shift.startTime !== undefined}
                (<StartAndEndTime startTime={shift.startTime} endTime={shift.endTime} diffClass={null} />)
              {:else}
                (no set times)
              {/if}
            </svelte:fragment>
          </InputOptionalTimePicker>
        </FormGroup>

        <FormGroup>
          <h3>Set repeating schedule</h3>

          <div class="flex-row flex-align-start">
            <InputSelect options={repeatUnitOptions} bind:value={input.repeatUnit}></InputSelect>
            <div class="flex-grow" />
          </div>
        </FormGroup>

        <FormGroup class="mt1">
          {#if input.repeatUnit === RepeatUnit.Weekly}
            <DayOfWeekBubblePicker bind:value={input.repeatDaysOfWeek} />
          {:else if input.repeatUnit === RepeatUnit.Monthly}
            <DayOfMonthBoxPicker bind:value={input.repeatDaysOfMonth} />
          {/if}
        </FormGroup>

        <FormGroup class="mt1">
          <label class="col-sm-3 control-label mt1">Repeats every</label>
          <div class="input-group">
            <InputNumber class="input-number" condense bind:value={input.repeatEvery} min={0} />
            {#if repeatText}
              <span class="input-group-addon">{pluralCount(repeatText, input.repeatEvery, 'omitNumber')}</span>
            {/if}
          </div>
        </FormGroup>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="flex-row flex-align-center g05">
      <Btn type="submit" class="btn-primary" dataTest="apply" icon={input.isNew ? 'plus' : 'check'} disabled={!input.isNew && !hasChanges}>
        {#if input.isNew}
          Add schedule
        {:else}
          Update schedule
        {/if}
      </Btn>
      <Btn icon="close" on:click={onClose}>Cancel</Btn>

      {#if !input.isNew}
        <div class="flex-grow" />
        <Btn
          icon="delete"
          on:click={deleteShiftDay}
          class="btn-danger"
          confirm={{
            title: 'Delete schedule',
            message: `Are you sure you want to delete this schedule?`,
            confirmLabel: 'Delete',
            confirmClass: 'btn-danger',
            confirmIcon: 'delete',
          }}
        >
          Delete
        </Btn>
      {/if}
    </div>
  </div>
</Form>

<script>
  import { hasValue } from '@amcharts/amcharts4/.internal/core/utils/Type'
  import { pluralCount } from 'services/string-utils.js'
  import { RepeatUnit } from 'config/enums.js'
  import Btn from './bootstrap/Btn.svelte'
  import DayOfMonthBoxPicker from 'components/fields/DayOfMonthBoxPicker.svelte'
  import DayOfWeekBubblePicker from 'components/fields/DayOfWeekBubblePicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputNumber from 'components/fields/InputNumber.svelte'
  import InputOptionalDatePicker from 'components/InputOptionalDatePicker.svelte'
  import InputOptionalTimePicker from 'components/InputOptionalTimePicker.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'
  import validator from 'services/validator'

  export let shiftDay
  export let shift
  export let capacity
  export let onSubmit
  export let onDelete
  export let onClose

  const repeatUnitOptions = [
    {
      label: 'Day',
      value: RepeatUnit.Daily,
      repeatText: 'day',
    },
    {
      label: 'Days & weeks',
      value: RepeatUnit.Weekly,
      repeatText: 'week',
    },
    {
      label: 'Month',
      value: RepeatUnit.Monthly,
      repeatText: 'month',
    },
  ]

  let input = {}
  let inputInitial = {}

  $: shiftDay, setInput()
  $: hasChanges = !validator.equals(comparableInput(input), comparableInput(inputInitial))
  $: repeatText = repeatUnitOptions.find(ru => ru.value === input.repeatUnit)?.repeatText

  function setInput() {
    input = _.cloneDeep(shiftDay)
    input.date ??= null
    input.repeatEndDate ??= null
    input.repeatUnit ??= RepeatUnit.Weekly
    input.repeatEvery ??= 1
    input.repeatDaysOfWeek ??= [2, 3, 4, 5, 6]
    input.repeatDaysOfMonth ??= [1]
    input.startTime ??= null
    input.endTime ??= null
    inputInitial = _.cloneDeep(input)
  }

  function comparableInput(value) {
    const comparable = _.cloneDeep(value)
    delete comparable.isNew
    cleanInput(comparable)
    input.repeatDaysOfWeek.sort()
    input.repeatDaysOfMonth.sort()
    return comparable
  }

  function cleanInput(value) {
    if (value.repeatUnit !== RepeatUnit.Weekly) value.repeatDaysOfWeek = []
    if (value.repeatUnit !== RepeatUnit.Monthly) value.repeatDaysOfMonth = []
  }

  function submit() {
    cleanInput(input)
    onSubmit(input)
    onClose()
  }

  function deleteShiftDay() {
    onDelete(shiftDay)
    onClose()
  }
</script>

<style lang="scss">
  h3 {
    margin-bottom: 0px !important;
  }

  .shiftday {
    margin-bottom: 10px;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin-left: 3px;
      margin-right: 3px;
      white-space: nowrap;
    }
  }

  .input-group {
    width: 115px;
  }
</style>
