<InputSelect options={staffRoleOptions} bind:value let:option {name} {disabled} {multiple} {placeholder} class={className} on:change showOthersTip>
  <div class="flex-row flex-align-center g05 full-width">
    <div class="flex-grow">{option.optionLabel}</div>
    <Help><SafeHtml value={roleService.getStaffRoleDescription(option.optionValue)} /></Help>
  </div>

  <div slot="label">
    <div class="flex-row flex-align-center g05">
      {option.optionLabel}<slot />
      <Help><SafeHtml value={roleService.getStaffRoleDescription(option.optionValue)} /></Help>
    </div>
  </div>

  <div slot="labelOther">
    <div class="text-left">
      <!-- Don't show the help icon; nesting tooltips is gross. -->
      {option.optionLabel}
    </div>
  </div>
</InputSelect>

<script>
  import Help from 'components/Help.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import roleService from 'services/role-service.js'
  import persona from 'stores/persona.js'
  import InputSelect from './InputSelect.svelte'

  export let value
  export let disabled = false
  export let name
  export let multiple
  export let placeholder = 'Select role'
  let className = null
  export { className as class }
  export let allRolesEnabled = false

  $: staffRoleOptions = roleService.getStaffRoleOptions($persona, allRolesEnabled)
</script>
