<FriendlyList {items} punctuation boldPunctuation={false} or={false} max="Infinity" let:item><strong class="strongish">{item}</strong></FriendlyList>

<script>
  import { groupByContiguousFriendly } from 'services/array-utils.js'
  import { toStudentYear } from 'services/formatters.js'
  import toOrdinalNumber from 'services/to-ordinal-number.js'
  import FriendlyList from 'components/FriendlyList.svelte'

  export let studentYears // Expected to have .length > 0; you should handle the if/else externally.

  $: items = buildItems(studentYears)

  function buildItems(studentYears) {
    if (!studentYears) return []
    const sorted = [...studentYears].sort((a, b) => a - b)
    const ranges = groupByContiguousFriendly(sorted)
    return ranges.map(range => {
      if (range.length === 1) return toStudentYear(range[0])
      return `${toOrdinalNumber(range[0])}–${toOrdinalNumber(range[1])} year`
    })
  }
</script>
