<tr>
  <td class="text-center"><img height="32" src="/images/icons/{iconSrc}" alt="{name} logo" /></td>
  <td>{name}</td>
  <td>
    {#if googlePlayHref}
      <a target="_blank" class={downloadIconClass} href={googlePlayHref} use:tip={`${name} for Android`}><Icon lg name="google-play" /></a>
    {/if}
  </td>
  <td>
    {#if appStoreHref}
      <a target="_blank" class={downloadIconClass} href={appStoreHref} use:tip={`${name} for iOS`}><Icon lg name="appstore-ios" /></a>
    {/if}
  </td>
  <td>
    {#if desktopHref}
      <a target="_blank" class={downloadIconClass} href={desktopHref} use:tip={`${name} for Desktop`}><Icon lg name="desktop" /></a>
    {/if}
  </td>
</tr>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let iconSrc
  export let name
  export let googlePlayHref = null
  export let appStoreHref = null
  export let desktopHref = null
  export let downloadIconClass = 'text-gray'
</script>

<style>
  tr > td {
    padding: 5px;
  }
</style>
