<Grid
  let:row
  let:subRow={capacityGuest}
  let:rowspan
  {loading}
  rows={capacities}
  subRowsSelector={couldShowSubRows ? row => row.guests : null}
  subRowKeySelector={couldShowSubRows ? subRow => subRow.capacityGuestId : null}
  name="capacities"
  pagesOfLabelSingular="opportunity"
  {pageSize}
  bind:currentPage
  paginatorTotalCount={totalCount}
  href={$isQuickFiltering ? null : `/opportunities/details/[capacityId]/[guestOrgId]`}
  hrefButtonIconSelector={row => (personaService.canEditCapacity(row) ? 'edit' : 'eye')}
>
  {#if show.status}
    <GridCol {row} {rowspan} header="Status" class="shrink text-center">
      <CapacityStatusIcon capacity={row} />
    </GridCol>
  {/if}

  <GridCol {row} {rowspan} header="Opportunity">
    {@const rowSpecialties = computeCapacitySpecialties(row)}
    <div class="flex-column g05">
      <div>
        <Icon sm name={row.allowGroups ? 'users' : 'user'} title={row.allowGroups ? 'Group opportunity' : 'Individual opportunity'} />
        <a href={`/opportunities/details/${row.capacityId}`} data-test="opportunity-name">{row.name}</a>
      </div>

      {#if show.allowCoordinatorScheduling && row.allowCoordinatorScheduling}
        <!-- same icon as schedule students page... kind of lame -->
        <div class="flex-row flex-align-center g05">
          <span>Available on the</span>
          <Icon name="plus" />
          <strong class="strongish">Schedule students</strong>
          <span>page</span>
        </div>
      {/if}

      {#if show.postToSearchPage && row.postToSearchPage}
        <div class="flex-row flex-align-center g05">
          <span>Available on the</span>
          <Icon name="search" />
          <strong class="strongish">Find clinical experiences</strong>
          <span>page</span>
        </div>
      {/if}

      {#if (show.rotationTypes && row.rotationTypes?.length) || (show.specialties && rowSpecialties.length)}
        <div class="flex-row flex-align-center flex-wrap g05">
          {#if show.rotationTypes && row.rotationTypes?.length}
            {#each row.rotationTypes as rt}
              <QuickFilterTag
                icon="briefcase-medical"
                class="text-rotation-type"
                value={rt}
                type="Experience type"
                {filtersComponent}
                filterType={FilterType.RotationTypes}
                configKey="rotationTypes"
                configValue={rt}
                simulationFilterOption={simpleFilterOption(rt)}
                dataTest="rotation-type-quick-filter-tag-{rt}"
              />
            {/each}
          {/if}

          {#if show.specialties && rowSpecialties.length}
            {#each rowSpecialties as s}
              <QuickFilterTag
                icon="file-certificate"
                class="text-specialty"
                value={s}
                type="Specialty"
                {filtersComponent}
                filterType={FilterType.Specialties}
                configKey="specialties"
                configValue={s}
                simulationFilterOption={simpleFilterOption(s)}
                dataTest="specialty-quick-filter-tag-{s}"
              >
                <svelte:fragment slot="tooltip">
                  {#if !row.specialties.length}
                    <div class="em mt1">Inherited from <Icon name="shapes" class="color-text-purple" /> {row.service.name}</div>
                  {/if}
                </svelte:fragment>
              </QuickFilterTag>
            {/each}
          {/if}
        </div>
      {/if}

      {#if show.maxMatches}
        <div class="flex-row flex-align-center g05">
          {#if row.maxMatches}
            {readableNumber(row.maxMatches)}
          {:else}
            <Icon lg name="infinity" class="text-info" />
            Unlimited
          {/if}
          {pluralCount('rotation', row.maxMatches, 'omitNumber')}
          available to all participants
        </div>
      {/if}

      {#if show.staff && row.staff?.length && isHealth}
        {@const staffs = row.staff}
        <span class="small em">
          <div class="flex-row g05">
            {#each staffs as staff}
              <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} small class="mb05" />
            {/each}
          </div>
        </span>
      {/if}
    </div>
  </GridCol>

  {#if show.owner || showTeams || show.locations}
    <GridCol {row} {rowspan} header="Owner & location">
      <CapacityLocations capacity={row} {filtersComponent} {show} />
    </GridCol>
  {/if}

  {#if show.studentYears || show.disciplines || show.matchLeadTimeDays}
    <GridCol {row} {rowspan} header="Requirements">
      {#if show.matchLeadTimeDays}
        <!-- If we ever make a QuickFilterTag for Lead time, remove the padding; it's only for alignment -->
        <div style="padding: 3px 6px;">
          <CapacityLeadTimeLabel capacity={row} />
        </div>
      {/if}
      {#if show.studentYears}
        <CapacityStudentYears capacity={row} {filtersComponent} />
      {/if}
      {#if show.disciplines}
        <div class="flex-row flex-align-center flex-wrap g05">
          <CapacityDisciplines capacity={row} {filtersComponent} />
        </div>
      {/if}
      {#if show.requestAvailabilityPolicy}
        <!-- This shifts slightly in the column when toggling quick filters -->
        <CapacityRequestAvailabilityPolicyLabel capacity={row} />
      {/if}
    </GridCol>
  {/if}

  {#if show.service && showService}
    <GridCol {row} {rowspan} header="Service">
      {#if row.service}
        <QuickFilterTag
          icon="shapes"
          class="color-text-purple"
          value={row.service.name}
          type="Service"
          href="/services/{row.service.serviceId}"
          {filtersComponent}
          filterType={FilterType.Services}
          configKey="serviceIds"
          configValue={row.service.serviceId}
          simulationFilterOption={row.service}
          dataTest="service-quick-filter-tag-{row.service.name}"
        />
      {:else}
        <QuickFilterTag
          icon="shapes"
          class="color-text-purple"
          value="None specified"
          type="No service"
          tooltipFilterByPrefix="Filter by"
          {filtersComponent}
          filterType={FilterType.Services}
          configKey="serviceIds"
          configValue={null}
          simulationFilterOption={{
            serviceId: null,
            name: 'None specified',
          }}
          dataTest="service-quick-filter-tag-none-specified"
        >
          <NoServiceSpecified />
        </QuickFilterTag>
      {/if}
    </GridCol>
  {/if}

  {#if show.guestConfirmMatchesByDate || (showAgreement && (show.school || show.agreement))}
    <GridCol {row} header="Participants">
      {#if capacityGuest == null}
        <NoGuestSpecified />
      {:else if capacityGuest.guestOrgId}
        <div class="flex-column g05">
          {#if show.school}
            <QuickFilterTag
              icon="school"
              class="color-text-blue"
              value={capacityGuest.guestOrgName}
              type="School"
              href="/orgs/{capacityGuest.guestOrgId}/{capacityGuest.guestOrgRelativePath}"
              {filtersComponent}
              filterType={FilterType.GuestOrgs}
              configKey="schoolOrgIds"
              configValue={capacityGuest.guestOrgId}
              simulationFilterOption={{ orgId: capacityGuest.guestOrgId, name: capacityGuest.guestOrgName }}
              dataTest="school-org-quick-filter-tag-{capacityGuest.guestOrgName}"
            >
              <CapacityGuestProfilePictureAndName {capacityGuest} />
            </QuickFilterTag>
          {/if}

          {#if show.agreement}
            {#if capacityGuest.agreement}
              <div class="flex-row flex-align-center">
                {#if show.school}
                  <span class="small text-gray em">via</span>
                {/if}

                <QuickFilterTag
                  icon="agreement"
                  iconProps={{ lg: true, autoColor: true }}
                  value={capacityGuest.agreement.name}
                  type="Agreement"
                  href="/settings/agreements/{capacityGuest.agreementId}"
                  {filtersComponent}
                  filterType={FilterType.Agreements}
                  configKey="agreementIds"
                  configValue={capacityGuest.agreementId}
                  simulationFilterOption={capacityGuest.agreement}
                  dataTest="agreement-quick-filter-tag-{capacityGuest.agreement.name}"
                />
              </div>
            {:else}
              <QuickFilterTag
                icon="agreement"
                iconProps={{ lg: true, autoColor: true }}
                value="None specified"
                tooltipFilterByPrefix="Filter by"
                type="No agreement"
                href={null}
                {filtersComponent}
                filterType={FilterType.Agreements}
                configKey="agreementIds"
                configValue={null}
                simulationFilterOption={null}
                dataTest="agreement-quick-filter-tag-none-specified"
              >
                <NoAgreementSpecified />
              </QuickFilterTag>
            {/if}
          {/if}
        </div>
        {#if show.guestConfirmMatchesByDate && capacityGuest.confirmDesiredMatchCountByDate}
          <div
            class="flex-row flex-align-center g05"
            use:tip={{ content: `Confirm rotations by ${capacityGuest.confirmDesiredMatchCountByDate}`, options: { placement: 'left' } }}
          >
            <Icon name="calendar" class="color-text-blue" />
            {capacityGuest.confirmDesiredMatchCountByDate}
          </div>
        {/if}
      {:else}
        <!-- TODO(nursing): Filtering by Open (any school) does not work -->
        <QuickFilterTag
          icon="school"
          class="color-text-blue"
          value={capacityGuest.guestOrgName}
          type="Open to any school"
          tooltipFilterByPrefix="Filter by"
          {filtersComponent}
          filterType={FilterType.GuestOrgs}
          configKey="schoolOrgIds"
          configValue={capacityGuest.guestOrgId}
          simulationFilterOption={{ orgId: null, name: 'Open (any school)' }}
          dataTest="school-org-quick-filter-tag-open-any-school"
        >
          <CapacityGuestProfilePictureAndName {capacityGuest} />
        </QuickFilterTag>
      {/if}
    </GridCol>
  {/if}

  {#if show.startAndEndDate || show.shifts}
    <GridCol {row} header="Availability" class="shrink">
      <div class="flex-row g1">
        <div class="flex-column g05">
          {#if show.startAndEndDate}
            <StartAndEndDate
              startDate={capacityGuest?.schedulingStartDate || row.startDate}
              endDate={capacityGuest?.schedulingEndDate || row.endDate}
              tooltip={{ content: 'When this opportunity is available', options: { placement: 'left' } }}
            />
          {/if}

          <!-- TODO(nursing): Review the show logic here; appears Mata incorporated some of it in ShiftCard itself, however after merging this outer show/hide is different than what he encountered -->
          {#if show.shifts && row.shifts?.length && capacityGuest != null && isHealth}
            <ShiftCards capacity={row} shifts={row.shifts} {show} />
          {/if}
        </div>
      </div>
    </GridCol>
  {/if}

  {#if show.guaranteedMatchCount || show.matchStatusCount || show.matchesSummary}
    <GridCol {row} header="Rotations" name="rotations">
      {#if capacityGuest != null}
        <div class="nowrap">
          {#if show.guaranteedMatchCount}
            <CapacityGuaranteedMatchCount capacity={row} guestOrgId={capacityGuest.guestOrgId} />
          {/if}
          {#if show.matchStatusCount}
            <CapacityMatchStatusCount capacity={row} guestOrgId={capacityGuest.guestOrgId} />
          {/if}
          {#if show.matchesSummary}
            <CapacityMatchesSummary capacity={row} {capacityGuest} showActions {confirmMatchesHref} {releaseMatchesHref} {onUpdate} />
          {/if}
        </div>
      {/if}
    </GridCol>
  {/if}

  <GridCol {row} class="text-right nowrap">
    <OverflowMenu dataTest="overflow-actions-{row.capacityId}">
      <CapacityActions
        all
        capacity={row}
        {capacityGuest}
        {viewOrEditHref}
        {staffHref}
        {copyHref}
        {confirmMatchesHref}
        {releaseMatchesHref}
        {onDeleteClicked}
      />
    </OverflowMenu>
  </GridCol>
</Grid>

<script>
  import CapacityRequestAvailabilityPolicyLabel from './CapacityRequestAvailabilityPolicyLabel.svelte'

  import { computeCapacitySpecialties } from 'components/InheritedTagPicker.svelte'
  import { FilterType, PersonaType, FeatureType } from 'config/enums.js'
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import { simpleFilterOption } from 'services/capacity-usage.js'
  import CapacityActions from 'components/CapacityActions.svelte'
  import CapacityDisciplines from 'components/CapacityDisciplines.svelte'
  import CapacityGuaranteedMatchCount from 'components/CapacityGuaranteedMatchCount.svelte'
  import CapacityLeadTimeLabel from 'components/CapacityLeadTimeLabel.svelte'
  import CapacityLocations from 'components/CapacityLocations.svelte'
  import CapacityMatchStatusCount from 'components/CapacityMatchStatusCount.svelte'
  import CapacityMatchesSummary from 'components/CapacityMatchesSummary.svelte'
  import CapacityStatusIcon from 'components/CapacityStatusIcon.svelte'
  import CapacityStudentYears from 'components/CapacityStudentYears.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import NoGuestSpecified from 'components/NoGuestSpecified.svelte'
  import NoServiceSpecified from 'components/NoServiceSpecified.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import OverflowMenu from 'components/OverflowMenu.svelte'
  import persona from 'stores/persona'
  import personaService from 'services/persona-service.js'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import ShiftCards from 'components/ShiftCards.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import tip from 'decorators/tip.js'
  import ProfilePic from 'components/ProfilePic.svelte'

  export let capacities = null
  export let totalCount = null
  export let loading = false
  export let viewOrEditHref = null
  export let staffHref = null
  export let copyHref = null
  export let confirmMatchesHref = null
  export let releaseMatchesHref = null
  export let onDeleteClicked = _.noop
  export let onUpdate = _.noop
  export let currentPage = 1
  export let pageSize = 10
  export let filtersComponent = null
  export let showAgreement = true
  export let showService = true

  $: show = $showDropdowns.capacityGrid
  $: couldShowSubRows =
    show.school || show.agreement || showAgreement || show.shifts || show.locations || show.requestDates || show.guaranteedMatchCount
  $: anyRowsHaveTeam = capacities?.some(c => c.teamName != null)
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)
  $: showTeams = hasTeamFeature && show.team && anyRowsHaveTeam
</script>
