{#if !validator.empty(text)}
  <div class="long-desc{className ? ` ${className}` : ''}" on:click={stopPropagationIfAnchor}>
    {#if expanded || !canToggle}
      <SafeHtml value={text} />
    {:else}
      {shortened}
    {/if}

    {#if canToggle}
      <a on:click|stopPropagation={() => (expanded = !expanded)} href={null} class="small em">
        show {#if expanded}less{:else}more{/if}
      </a>
    {/if}
  </div>
{/if}

<script>
  import { partialDesc } from 'services/string-utils.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'

  export let max = 150
  export let text = null
  let className = 'mb1'
  export { className as class }

  let expanded = false

  $: shortened = text ? partialDesc(text, max) : ''
  $: canToggle = text && shortened.length < text.length

  function stopPropagationIfAnchor(e) {
    if (e.target.tagName === 'A') e.stopPropagation()
  }
</script>

<style lang="scss">
  .long-desc > :last-child {
    margin-bottom: 0 !important;
  }

  .long-desc {
    cursor: default;
    white-space: break-spaces;
  }
</style>
