import user from 'stores/user.js'

let $user = null
user.subscribe(u => $user = u)

export function youOrName(usr) {
  if (usr == null) return ''
  return usr.userId === $user.userId ? 'you' : usr.name
}

// will be some similar functions later on... "your"...