{#if personaService.canUseAnyFeatureType(FeatureType.CustomTags)}
  <Modal on:close={onClose}>
    <h4 slot="title">
      <Icon fw name="tag" />
      Manage custom tags
    </h4>

    <Form on:submit={submit}>
      <div class="modal-body">
        {#if unavailableCustomTags.length}
          <div class="mb3">
            <div class="mb05">
              <strong>Custom tags you can’t change</strong>
            </div>
            <div class="flex-row flex-align-center g1">
              {#each unavailableCustomTags as customTag (customTag.customTagId)}
                <CustomTag {customTag} tooltipPlacement="right" tooltipInteractive={false} />
              {/each}
            </div>
          </div>
        {/if}

        <FormGroup>
          <div>
            {#if unavailableCustomTags.length}
              <label for="match-custom-tags">Custom tags you can change</label>
            {/if}
            <CustomTagsPicker
              name="match-custom-tags"
              orgId={$persona.orgId}
              placeholder="None"
              max={4}
              multiple
              bind:value={customTagIds}
              bind:unavailableCustomTagIds
              bind:options={customTagOptions}
              leaveDisabledOptionsSelected
            />
          </div>
        </FormGroup>
      </div>

      <div class="modal-footer flex-row flex-align-center g05">
        <SubmitBtn dataTest="done-managing-custom-tags-btn">Done</SubmitBtn>
        <Btn on:click={onClose}>Cancel</Btn>
        {#if personaService.hasStaffPermission(Permission.ManageCustomTags, $persona.orgId, $persona.teamId)}
          <div class="flex-grow text-right">
            <a href="/settings/custom-tags">View all custom tags</a>
          </div>
        {/if}
      </div>
    </Form>
  </Modal>
{:else}
  <RequiresUpgradeModal {onClose} action="manage custom tags" />
{/if}

<script>
  import { FeatureType, Permission } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CustomTag from 'components/CustomTag.svelte'
  import CustomTagsPicker from 'components/fields/CustomTagsPicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import RequiresUpgradeModal from './RequiresUpgradeModal.svelte'

  export let match
  export let onClose

  let customTagOptions = []
  let customTagIds = match.customTags.map(ct => ct.customTagId)
  let unavailableCustomTagIds = []

  $: unavailableCustomTags = match.customTags.filter(ct => unavailableCustomTagIds.includes(ct.customTagId))

  function submit() {
    match.customTags = [...unavailableCustomTags, ...customTagOptions.filter(ct => customTagIds.includes(ct.customTagId))]
    onClose()
  }
</script>
