<div class="progress-bar progress-bar-{color}" use:tip={title} style="width: {percent}%; {style ?? ''}">
  <slot />
</div>

<script>
  import tip from 'decorators/tip.js'

  export let color = 'success'
  export let title = null
  export let percent = 50
  export let style = null
</script>
