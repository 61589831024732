<svelte:element
  this={useAnchor ? 'a' : 'button'}
  bind:this={btnElem}
  {type}
  class={classOrDefault}
  class:active
  class:disabled={disabledOrLoading}
  disabled={disabledOrLoading ? true : null}
  use:tip={title}
  use:onInteract={clickProxy}
  on:keydown
  on:mouseenter
  on:mousemove
  on:mouseleave
  on:focus
  on:blur
  {id}
  href={_href}
  {role}
  tabindex={_tabindex}
  {target}
  {rel}
  data-test={dataTest}
  in:scale={{ duration: animate ? 300 : 0 }}
>
  <BtnContent {loading} {icon} {iconRight} {iconRightProps} {iconProps}><slot /></BtnContent>
</svelte:element>

<script>
  import { createEventDispatcher } from 'svelte'
  import { createTo, getBase } from 'stores/req.js'
  import { scale } from 'svelte/transition'
  import BtnContent from 'components/bootstrap/BtnContent.svelte'
  import confirms from 'stores/confirms.js'
  import onInteract from 'decorators/on-interact.js'
  import tip from 'decorators/tip.js'

  export let type = 'button' // note that "submit" buttons should not handle click and should not use "confirm"--instead put that logic in the form's on:submit handler
  export let href = null
  export let to = null
  export let disabled = false
  export let loading = false
  export { className as class }
  export let title = null
  export let id = null
  export let dataTest = null
  export let color = null
  export let clearBtnStyling = false
  export let confirm = null
  export let autofocus = false
  export let active = false
  export let stopPropagation = false
  export let preventDefault = false
  export let icon = null
  export let iconRight = null
  export let iconProps = null
  export let iconRightProps = null
  export let animate = false
  export let target = null
  export let rel = null
  export let tabindex = null

  const dispatch = createEventDispatcher()
  const base = getBase()
  let className = null
  let btnElem
  let useAnchor = null
  let role = null
  let _tabindex = null
  let classOrDefault = null

  $: _href = to != null ? createTo(to, $base) : href
  $: useAnchor = !!_href || (clearBtnStyling && type === 'button')
  $: clearBtnStyling, className, color, buildBtnClass()
  $: disabledOrLoading = disabled || loading
  $: role = useAnchor && !_href ? 'button' : null
  $: _tabindex = tabindex ? tabindex : _href || !useAnchor ? null : '0'
  $: if (autofocus && btnElem) focus()

  function buildBtnClass() {
    if (clearBtnStyling) {
      classOrDefault = className
    } else {
      const classes = []
      const additionalClasses = className?.split(' ').map(c => c.trim()) ?? []
      if (!additionalClasses.includes('btn')) classes.push('btn')
      if (color == null && !additionalClasses.some(c => c.startsWith('btn-'))) classes.push('btn-default')
      if (color) classes.push(`btn-${color}`)
      classes.push(...additionalClasses)
      classOrDefault = classes.join(' ')
    }
  }

  export function focus() {
    btnElem?.focus?.()
  }

  function clickProxy(e) {
    if (disabled) {
      e.stopPropagation()
      e.preventDefault()
      return
    }

    if (confirm) {
      e.stopPropagation()
      e.preventDefault()
      const onConfirm = () => {
        dispatch('click', e)
      }
      const confirmOptions = typeof confirm === 'string' ? { onConfirm, message: confirm } : { ...confirm, onConfirm }
      confirms.add(confirmOptions)
      return
    }

    if (stopPropagation) e.stopPropagation()
    if (preventDefault) e.preventDefault()

    dispatch('click', e) // By passing the event, the listener can still stopPropagation/preventDefault.
  }
</script>
