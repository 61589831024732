{#if editing}
  <InputCheckboxGroup bind:value={filter.matchRequestTypes} {options} autofocusFirstItem />
{:else}
  {meta.label} is
  {#if filter && selected.length}
    <FriendlyList items={selected} max={3} or punctuation let:item><strong>{formatEnumKey(item.optionLabel)}</strong></FriendlyList>
  {:else}
    …?
  {/if}
{/if}

<script context="module">
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    type: FilterType.MatchRequestType,
    label: 'Request type',
    icon: 'hourglass',
    iconClass: 'color-text-steel',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.matchRequestTypes)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.matchRequestTypes = reader.readIntArray()
      return config
    },

    create() {
      return {
        matchRequestTypes: [],
      }
    },

    validate(filter) {
      return filter.matchRequestTypes?.length > 0
    },
  }
</script>

<script>
  import FriendlyList from 'components/FriendlyList.svelte'
  import { formatEnumKey } from 'services/formatters.js'
  import { MatchRequestType } from 'config/enums.js'
  import enumToOptions from 'services/enum-to-options.js'

  export let filter
  export let editing = false

  const options = enumToOptions(MatchRequestType)

  $: selected = options.filter(r => filter.matchRequestTypes?.includes(r.optionValue))
</script>
