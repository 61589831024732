import LocalStorageStore from 'stores/local-storage-store.js'
import api from 'services/api.js'
import environment from './environment.js'
import sockets from 'services/sockets.js'
import validator from './validator.js'

let lastEmailChecked = null
let lastEmailCheckedResponse = null

sockets.on('SsoProvidersChanged', _.debounce(reloadProvidersByEmail, 300))

export const savedEmail = LocalStorageStore(`email`, '')

function reloadProvidersByEmail() {
  lastEmailCheckedResponse = null
  getProvidersByEmail(lastEmailChecked)
}

export async function getProvidersByEmail(email) {
  if (validator.empty(email) || !validator.email(email)) return []
  if (email === lastEmailChecked && lastEmailCheckedResponse) return lastEmailCheckedResponse // don't recheck the same email
  lastEmailChecked = email
  lastEmailCheckedResponse = await api.sso.getProvidersByEmail({ email }, api.noMonitor)
  return lastEmailCheckedResponse
}

export function startSso(provider) {
  const ssoUrl = getProviderInitiatedSsoUrl(provider)
  window.location = ssoUrl
}

export function getProviderInitiatedSsoUrl(provider) {
  return `${environment.linkApi}/api/account/start-sso?provider=${provider}`
}
