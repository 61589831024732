<span class="small em">
  {#each _fileTypes as ft, i}{i > 0 ? ', ' : ''}{ft.description}{/each}
</span>

<script>
  import api from 'services/api.js'

  const defaultValue = []
  export let fileTypes = defaultValue
  export let fileTypeIds = defaultValue

  let _fileTypes = fileTypes

  if (fileTypes === defaultValue) load()
  $: _fileTypes = fileTypeIds === defaultValue ? fileTypes : fileTypes.filter(ft => fileTypeIds.includes(ft.fileTypeId))

  async function load() {
    fileTypes = await api.fileType.listAvailableFileTypes(api.noMonitor)
  }
</script>
