{#if editing}
  <FormGroup>
    <InputToggle name="allow-coordinator-scheduling" bind:checked={filter.allowCoordinatorScheduling}>
      Available on the
      <Icon name="plus" lg class="text-gray" />
      <strong class="strongish">Schedule students</strong> page
    </InputToggle>
  </FormGroup>
{:else if filter.allowCoordinatorScheduling}
  Available on the
  <Icon name="plus" lg class="text-gray" />
  <strong class="strongish">Schedule students</strong> page
{:else}
  <strong>NOT</strong> available on the
  <Icon name="plus" lg class="text-gray" />
  <strong class="strongish">Schedule students</strong> page
{/if}

<script context="module">
  import { FilterType } from 'config/enums.js'

  const label = '<strong class="strongish">Schedule students</strong> page availability'

  export const meta = {
    type: FilterType.CapacityAllowCoordinatorScheduling,
    label,
    editLabel: null,
    hideLabelIcon: true,
    icon: 'plus',
    iconClass: 'text-gray',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.allowCoordinatorScheduling)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.allowCoordinatorScheduling = reader.readBool()
      return config
    },

    create() {
      return { allowCoordinatorScheduling: true }
    },

    validate() {
      return true
    },
  }
</script>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'

  export let filter
  export let editing = false
  // export let appliedMeta
</script>
