<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={o} />

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    idKey: 'optionValue',
    type: FilterType.RotationTypes,
    label: 'Experience types',
    icon: 'briefcase-medical',
    iconClass: 'text-rotation-type',
    canHaveMultiple: true,
    filterProp: 'rotationTypes',
    optionsEndpoint: 'getRotationTypes',
    filterPropType: 'string',
    allowNull: true,
    excludable: false,
    toMany: true,
    toManySuffix: 'of the following experience types',
    editLabel: null,
    labelApplied: 'experience type',
    hasDoesntHave: true,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
