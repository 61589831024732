{#if showDetail || (matchDaysDict[day] && matchDaysDict[day][shift.shiftId])}
  <div data-test="sp-day" class="shift {used ? 'color-bg' : `color-text`}-{shift.color}" class:used>
    <div class="pull-right">
      {#if bucket}
        {#if bucket.overfilled > 0}
          <Icon name="alert-triangle" class="text-danger" title="Overfilled" />
        {/if}
        {#if bucket.overfilledMaybe > 0}
          <Icon name="alert-triangle" class="text-warning" title="Might overfill" />
        {/if}
      {/if}
      {#if shiftDayInfo.selected && !shiftDayInfo.available}
        <Icon name="calendar" class="text-warning" title="Violates {shift.name} shift schedule" />
      {/if}
    </div>
    {#if !showDetail && peopleCount > 0}
      <div data-test="sp-people">
        {#if allPeople}
          {#if peopleCount === 1}{schedulableUsers[schedulableUserIds[0]][0].name}{:else}everyone{/if}
        {:else}
          {peopleCount}
          {#if peopleCount === 1}person{:else}people{/if}
        {/if}
      </div>
    {/if}
    {#if showDetail}
      <div class="shift-name">
        <div class="checkbox checkbox-primary" class:disabled on:mousedown={stopPropagation} on:click={clickShift}>
          <!-- must keep parent checkbox in sync manually: https://github.com/sveltejs/svelte/issues/4836 -->
          <input
            data-test="all-people-checkbox"
            type="checkbox"
            {disabled}
            checked={allPeople && used}
            indeterminate={!allPeople && used}
            bind:this={shiftBoxEl}
            on:change={e => {
              e.target.checked = allPeople && used
              e.target.indeterminate = !allPeople && used
            }}
          />
          <label>
            <strong>{shift.name}</strong>
          </label>
        </div>
      </div>
    {/if}

    {#if bucket}
      <MatchUsageBar {bucket} showTooltip infinite={bucket.infinite} />
    {/if}

    {#if showDetail && schedulableUserIds}
      <div>
        {#if schedulableUserIds.length === 1}
          <div>{schedulableUsers[schedulableUserIds[0]][0].name}</div>
        {:else}
          {#each schedulableUserIds as userId}
            <div
              data-test="checkbox-{schedulableUsers[userId][0].name}"
              class="checkbox checkbox-primary"
              class:disabled
              on:mousedown={stopPropagation}
              on:click={() => clickPerson(shift, schedulableUsers[userId][0])}
            >
              <input type="checkbox" {disabled} checked={used && shiftMatchDayUsersDict.has(userId)} />
              <label>
                <ProfilePic
                  src={schedulableUsers[userId][0].profilePicture}
                  id={userId}
                  name={schedulableUsers[userId][0].name}
                  initials={schedulableUsers[userId][0].initials}
                  small
                  class="mr1"
                />
                {schedulableUsers[userId][0].name}
              </label>
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  </div>
{/if}

<script>
  import MatchUsageBar from 'components/MatchUsageBar.svelte'
  import Icon from 'components/Icon.svelte'
  import { createEventDispatcher } from 'svelte'
  import ProfilePic from 'components/ProfilePic.svelte'

  export let matchDaysDict
  export let day
  export let shift
  export let schedulableUsers
  export let bucket
  export let showDetail = false
  export let disabled
  export let shiftDayInfo

  const dispatch = createEventDispatcher()
  let shiftBoxEl

  $: shiftMatchDays = matchDaysDict[day] && matchDaysDict[day][shift.shiftId] ? matchDaysDict[day][shift.shiftId] : []
  $: used = shiftMatchDays.length > 0
  $: allPeople = Object.keys(matchDaysDict).length === 0 || shiftMatchDays.some(d => d.userId == null)
  $: schedulableUserIds = Object.keys(schedulableUsers)
  $: peopleCount = allPeople ? schedulableUserIds.length : shiftMatchDays.length
  $: userIds = allPeople ? schedulableUserIds : _.uniq(shiftMatchDays.map(d => d.userId))
  $: shiftMatchDayUsers = showDetail ? userIds.filter(userId => schedulableUsers[userId]).map(userId => schedulableUsers[userId][0]) : null
  $: shiftMatchDayUsersDict = shiftMatchDayUsers ? new Set(shiftMatchDayUsers.map(u => u.userId)) : new Set()
  $: selectedDays = new Set([day])

  function clickShift() {
    if (disabled) return
    dispatch('toggleShift', { shift, deSelect: used, days: selectedDays })
  }

  function clickPerson(shift, person) {
    if (disabled) return
    dispatch('togglePerson', { shift, person, deSelect: used && shiftMatchDayUsersDict.has(person.userId), days: selectedDays })
  }

  function stopPropagation(e) {
    // prevent multi-select on mousedown when interacting with checkboxes
    e.stopPropagation()
  }
</script>

<style>
  .shift {
    padding: 2px;
    margin-bottom: 2px;
    border-radius: 3px;
    border: 1px solid;
    filter: contrast(0.5);
  }
  .shift.used {
    color: #eee;
    border: none;
    filter: contrast(1);
  }
  .shift-name {
    margin-bottom: 3px;
  }
</style>
