<!-- Changing something in here? Consider making a similar change to CapacityShiftsForm. -->

<div class="flex-row">
  <div>
    <ShiftGrid {capacity} {orgs} onClickShift={viewShift} let:row selectable={false}>
      <GridCol {row}>
        <a href={null} on:click={() => viewShift(row)} data-test="view-shift">
          <Icon name="eye" title="View shift" />
        </a>
      </GridCol>
    </ShiftGrid>
  </div>

  <div class="ml1 flex-grow">
    <CalendarControls bind:start bind:end />
    <!--todo: update here too--i.e. show calendar/list picker and readonly list and calendar...consider extracting component and passing a disabled prop that simply disables the edit shift form...-->
    <Calendar startDate={start} endDate={end} let:day min={capacity.startDate} max={capacity.endDate}>
      {#if !day.disabled}
        {#each capacity.shifts as shift}
          {#if shiftOccursOnDate(capacity, shift, day)}
            <div class="shift color-bg-{shift.color}" use:onInteract={() => viewShift(shift)} use:tip={'View shift'}>
              {shift.name}
            </div>
          {/if}
        {/each}
      {/if}
    </Calendar>
  </div>
</div>

{#if viewingShift}
  <Modal lg on:close={resetViewingShift}>
    <h4 slot="title">
      <div class="small">Shift</div>
      {viewingShift.name}
    </h4>
    <ShiftView shift={viewingShift} {capacity} {orgs} onClose={resetViewingShift} />
  </Modal>
{/if}

<script>
  import { shiftOccursOnDate } from 'services/calendar-service.js'
  import api from 'services/api.js'
  import Calendar from 'components/Calendar.svelte'
  import CalendarControls from 'components/CalendarControls.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import onInteract from 'decorators/on-interact.js'
  import ShiftGrid from 'components/ShiftGrid.svelte'
  import ShiftView from 'components/ShiftView.svelte'
  import tip from 'decorators/tip.js'

  export let capacity

  let start
  let end
  let viewingShift
  let orgs = {}

  $: if (capacity.shifts) capacity.shifts = orderByStartTime(capacity.shifts)
  $: startDate = capacity.startDate
  $: endDate = capacity.endDate
  $: startDate, endDate, setStartEnd()
  $: capacity, loadOrgs()

  async function loadOrgs() {
    if (!capacity) return
    const selectedOrgIds = new Set(capacity.shifts.map(sh => sh.locationId).filter(orgId => orgId != null))
    if (selectedOrgIds.size === 0) return
    const res = await api.org.list(
      {
        selectedOrgIds: [...selectedOrgIds],
        pageSize: 0,
        excludeTotalCount: true,
      },
      api.noMonitor
    )
    orgs = _.keyBy(res.selectedOrgs, o => o.orgId)
  }

  function orderByStartTime(shifts) {
    return _.orderBy(shifts, [s => (s.startTime == null ? 0 : parseInt(s.startTime.replace(':', '')))])
  }

  function setStartEnd() {
    if (capacity.startDate) start = dayjs(capacity.startDate)
    else if (start == null) start = dayjs()
    start = start.startOf('month')
    end = start.endOf('month')
    resetViewingShift()
  }

  function resetViewingShift() {
    viewingShift = null
  }

  function viewShift(shift) {
    viewingShift = shift
  }
</script>

<style>
  div.shift {
    margin: 2px;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: block;
    text-decoration: none;
    cursor: pointer;
  }
</style>
