import { StepSubmissionStatus } from 'config/enums.js'

function makeStatusHelper(key, icon, label, iconClass, iconHref = null) {
  return { key, icon, label, iconProps: { class: iconClass }, iconHref }
}

const statuses = {
  [StepSubmissionStatus.Incomplete]: makeStatusHelper(StepSubmissionStatus.Incomplete, 'time', 'Incomplete', 'text-warning'),
  [StepSubmissionStatus.AwaitingVerification]: makeStatusHelper(
    StepSubmissionStatus.AwaitingVerification,
    'time',
    'Awaiting verification',
    'text-purple'
  ),
  [StepSubmissionStatus.Rejected]: makeStatusHelper(StepSubmissionStatus.Rejected, 'alert-triangle', 'Rejected', 'color-text-dark-red'),
  [StepSubmissionStatus.Completed]: makeStatusHelper(StepSubmissionStatus.Completed, 'check', 'Completed', 'text-success'),
  [StepSubmissionStatus.Waived]: makeStatusHelper(StepSubmissionStatus.Waived, 'waived', 'Waived', 'color-text-dark-green'),
  [StepSubmissionStatus.Verified]: makeStatusHelper(StepSubmissionStatus.Verified, 'Ready', 'Verified', 'text-success'),
  [StepSubmissionStatus.Skipped]: makeStatusHelper(StepSubmissionStatus.Skipped, 'skipped', 'Skipped', 'color-text-dark-green'),
}

function get(status) {
  return statuses[status]
}

export default {
  get(status) {
    return get(status)
  },
}
