import { NameSuggestionStatus } from 'config/enums.js'

function makeStatusHelper(key, icon, label, iconClass, iconHref = null) {
  return { key, icon, label, iconProps: { class: iconClass }, iconHref }
}

const statuses = {
  [NameSuggestionStatus.AiSuggestionless]: makeStatusHelper(
    NameSuggestionStatus.AiSuggestionless,
    'brain-circuit',
    'AI suggestionless',
    'text-danger'
  ),
  [NameSuggestionStatus.AiSuggested]: makeStatusHelper(NameSuggestionStatus.AiSuggested, 'brain-circuit', 'AI suggested', 'text-info'),
  [NameSuggestionStatus.CnSuggested]: makeStatusHelper(
    NameSuggestionStatus.CnSuggested,
    '',
    'CN overrode suggestion',
    null,
    '/images/logo-no-words.png'
  ),
  [NameSuggestionStatus.UserSubmitted]: makeStatusHelper(NameSuggestionStatus.UserSubmitted, 'check', 'User submitted', 'text-success'),
}

function get(status) {
  return statuses[status]
}

export default {
  get(status) {
    return get(status)
  },
}
