<div class="action-panel" bind:this={actionPanelEl}>
  {#if match.isEditable}
    {#if !match.submitted}
      <HelpBlock class="mb1 text-center">
        On submit, clinical site coordinators will be notified of your request. They may accept, reject, propose changes, or contact you with further
        questions.
      </HelpBlock>
    {/if}
    {#if canReopen && rotationHasEnded}
      <Alert type="warning">This rotation has ended, so it cannot be reopened. You’ll need to start a new rotation instead.</Alert>
    {/if}
  {/if}

  <div class="action-panel-btns">
    <div class="flex-grow">
      <Btn on:click={() => close()} icon="back">Back</Btn>
    </div>

    {#if $persona.personaType !== PersonaType.Student && !personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)}
      <Btn class="btn-primary" icon="subscription" href="/pricing">Upgrade subscription</Btn>
    {:else}
      {#if canReopen}
        <MatchReopen {match} {onMatchChanged} {disabled} />
      {/if}

      {#if match.isEditable}
        {#if !match.submitted}
          <Btn
            loading={submitting}
            {disabled}
            class="btn-success"
            on:click={submitMatchRequest}
            dataTest="save-and-submit-match-btn"
            icon="save"
            animate
          >
            Save and submit request
          </Btn>
        {/if}

        {#if hasChanges}
          {#if changesCanBeProposed}
            <Btn
              on:click={() => {
                proposingChangesOpen = true
                changeComment = getDefaultChangeComment(change.changes, matchInitial)
              }}
              icon="plus"
              animate
              class="btn-primary"
              dataTest="propose-changes-btn"
              loading={savingMatch}
            >
              Propose changes
            </Btn>
          {/if}
          {#if changesCanBeSaved}
            <Btn loading={savingMatch} {disabled} icon="save" animate on:click={saveMatch} class="btn-primary" dataTest="save-match-btn"
              >Save changes</Btn
            >
          {/if}
        {/if}

        {#if !hasChanges && canAccept}
          <Btn
            class="btn btn-success"
            icon="check"
            animate
            on:click={acceptMatch}
            loading={accepting}
            {disabled}
            dataTest="accept-match-btn"
            confirm={getCapacityMatchUsageWarning(match.capacity, 1)}
          >
            Accept
            {match.waitlist ? 'from waitlist' : ''}
          </Btn>
        {/if}

        {#if !hasChanges && canWaitlist}
          <Btn class="btn btn-sky" icon="Waitlisted" animate on:click={() => (waitlistModalOpen = true)} {disabled} dataTest="waitlist-match-btn">
            Add to waitlist
          </Btn>
        {/if}

        {#if !hasChanges}
          <MatchPause {match} {onMatchChanged} />
        {/if}

        {#if canCancel}
          <Btn class="btn-danger" icon="Closed" animate on:click={() => (closeReasonModalOpen = true)} dataTest="close-match-btn">
            {cancelMatchText}
          </Btn>
        {:else}
          {#if match.isFaculty}
            <Btn
              class="btn-danger"
              confirm={{
                title: `Leave rotation${match.isStudent ? ' as faculty' : ''}`,
                message: `Are you sure you’d like to remove yourself ${match.isStudent ? 'as faculty on' : 'from'} this rotation?`,
                confirmLabel: 'Leave rotation',
                confirmClass: 'btn-danger',
              }}
              on:click={removeSelfAsFaculty}
              dataTest="remove-self-as-faculty"
              loading={removingSelf}
              {disabled}
              icon="Closed"
              animate
            >
              Leave rotation
              {#if match.isStudent}as faculty{/if}
            </Btn>
          {/if}
          {#if match.isStudent && match.capacity.allowStudentChanges}
            <Btn
              class="btn-danger"
              confirm={{
                title: `Leave rotation${match.isFaculty ? ' as student' : ''}`,
                message: `Are you sure you’d like to remove yourself ${match.isFaculty ? 'as a student on' : 'from'} this rotation?`,
                confirmLabel: 'Leave rotation',
                confirmClass: 'btn-danger',
              }}
              on:click={removeSelfAsStudent}
              dataTest="remove-self-as-student"
              loading={removingSelf}
              {disabled}
              icon="Closed"
              animate
            >
              Leave rotation
              {#if match.isFaculty}as student{/if}
            </Btn>
          {/if}
        {/if}
      {/if}
    {/if}
  </div>
</div>

{#if waitlistModalOpen}
  <Modal title="Add to wait list" on:close={() => (waitlistModalOpen = false)}>
    <MatchFormWaitlist {match} onClose={() => (waitlistModalOpen = false)} {onMatchChanged} />
  </Modal>
{/if}

{#if proposingChangesOpen}
  <Modal title="Propose changes" on:close={() => (proposingChangesOpen = false)}>
    <div class="modal-body">
      <Form on:submit={proposeChanges}>
        <FormGroup valid={validator.required(changeComment)} validationMessage="Please provide some explanation of the changes you're proposing.">
          <label>
            Comments / explanation
            <RequiredMarker />
          </label>
          <InputTextarea bind:value={changeComment} placeholder="Comments" />
        </FormGroup>
        <FormGroup>
          <label>Due by (optional)</label>
          <DatePicker name="change-due-by" bind:value={changeDueDate} min={today} />
        </FormGroup>
        <FormGroup>
          <label>Review your changes</label>
          <div class="well">
            {#if change}
              <MatchChange match={changeFromPrev(match, change.changes.previousState)} change={change.changes} />
            {/if}
          </div>
        </FormGroup>
        <Btn type="submit" class="btn-primary" dataTest="propose-changes-confirm-btn" icon="plus" loading={proposingChanges}>Propose changes</Btn>
      </Form>
    </div>
  </Modal>
{/if}

{#if closeReasonModalOpen}
  <Modal title="Close rotation" on:close={() => (closeReasonModalOpen = false)}>
    <MatchFormClose {match} on:submit={closeMatch} loading={closing} on:cancel={() => (closeReasonModalOpen = false)} />
  </Modal>
{/if}

<script>
  import { changeFromPrev, getDefaultChangeComment } from 'services/match-merger.js'
  import { getCapacityMatchUsageWarning } from 'services/capacity-usage.js'
  import { MatchStatus, MatchRole, ChangeSource, PersonaType, FeatureType } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import MatchChange from 'components/MatchChange.svelte'
  import MatchFormClose from 'components/MatchFormClose.svelte'
  import MatchFormWaitlist from 'components/MatchFormWaitlist.svelte'
  import MatchPause from 'components/MatchModal.Actions.Pause.svelte'
  import MatchReopen from 'components/MatchModal.Actions.Reopen.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  // import SubscriptionActivationButtons from './SubscriptionActivationButtons.svelte'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'

  export let actionPanelEl = null
  // match
  export let match
  export let onMatchChanged
  export let matchInitial
  export let change
  export let hasChanges
  export let changesCanBeProposed
  export let changesCanBeSaved
  export let changeDueDate
  export let changeComment
  export let savingMatch

  // validation
  export let formValidator

  // methods
  export let saveMatch
  export let buildSaveMatchModel
  export let close // close the match modal
  export let disabled

  const today = dayjs()

  let proposingChanges = false
  let submitting = false
  let accepting = false
  let removingSelf = false
  let closing = false

  let closeReasonModalOpen = false
  let waitlistModalOpen = false
  let proposingChangesOpen = false

  $: cancelMatchText = match.status == MatchStatus.PendingClinicalSite && !match.isStudent ? 'Reject / Close' : 'Cancel rotation'
  $: rotationHasEnded = dayjs().startOf('day').isAfter(dayjs(match.endDate).startOf('day'))
  $: canAccept =
    match != null &&
    (match.status === MatchStatus.PendingClinicalSite || match.status === MatchStatus.Waitlisted) &&
    match.isCoordinator &&
    $persona.personaType === PersonaType.ProviderStaff

  $: canCancel = (() => {
    if (match == null) return false

    // students and faculty can't cancel a group rotation. They can remove themselves though.
    const students = match.matchUsers ? match.matchUsers.filter(mu => mu.matchRole === MatchRole.Student) : []
    const isGroup = students.length > 1
    const userIsOnlyStudentOrFaculty = !match.isCoordinator && !match.isSchoolCoordinator && !match.isPreceptor
    if (isGroup && userIsOnlyStudentOrFaculty) return false

    return match.status != MatchStatus.Closed && match.status < MatchStatus.Completed
  })()

  $: canWaitlist =
    match?.status < MatchStatus.Active &&
    !match.waitlist &&
    match.isCoordinator &&
    $persona.personaType === PersonaType.ProviderStaff &&
    match.startDate != null &&
    dayjs(match.startDate).diff(dayjs(), 'days') > 0

  $: canReopen = (() => {
    if (match.status !== MatchStatus.Closed) return false
    const isSchoolSide = $persona.isStudent || $persona.personaType === PersonaType.SchoolStaff
    return match.closedSource === ChangeSource.Provider ? !isSchoolSide : isSchoolSide && match.isSchoolCoordinator
  })()

  const removeSelf = async role => {
    removingSelf = true
    try {
      await getMatchController().removeSelf({ matchId: match.matchId, role }, api.noMonitor)
      toaster.toast({ message: 'You have successfully removed yourself from this rotation.', type: 'info', icon: 'edit' })
      close(true) // navigate back to list now that this user is off this match
    } finally {
      removingSelf = false
    }
  }
  const removeSelfAsStudent = () => removeSelf(MatchRole.Student)
  const removeSelfAsFaculty = () => removeSelf(MatchRole.SchoolFaculty)

  async function acceptMatch() {
    accepting = true
    try {
      await getMatchController().acceptMatch({ matchId: match.matchId }, api.noMonitor)
      await onMatchChanged({ detailsHard: true, activity: true })
      toaster.toast({ message: 'Rotation accepted', type: 'success', icon: 'edit' })
    } finally {
      accepting = false
    }
  }

  async function submitMatchRequest() {
    const firstInvalid = formValidator.getFirstInvalid()
    if (firstInvalid != null) {
      firstInvalid.getElement().focus()
      return
    }

    const model = await buildSaveMatchModel()
    submitting = true
    try {
      await getMatchController().submitMatchRequest({ matchId: match.matchId }, model, api.noMonitor)
      await onMatchChanged({ detailsHard: true, activity: true })
      toaster.toast({ message: 'Your rotation request has been submitted! It is now pending clinical site approval.', type: 'info', icon: 'time' })
    } finally {
      submitting = false
    }
  }

  async function proposeChanges() {
    const data = await buildSaveMatchModel()
    formValidator.setSubmitted(true)
    proposingChanges = true
    try {
      await getMatchController().proposeChangeMatch({ matchId: match.matchId }, data, api.noMonitor)
      proposingChangesOpen = false
      await onMatchChanged({ detailsHard: true, activity: true }) // we hard set from server and effectively drop local changes that we just proposed
      toaster.toast({ message: 'Changes proposed', type: 'success', icon: 'edit' })
    } finally {
      proposingChanges = false
    }
  }

  async function closeMatch() {
    const data = {
      closedReason: match.closedReason,
      otherClosedReason: match.otherClosedReason,
    }
    closing = true
    try {
      await getMatchController().closeMatch({ matchId: match.matchId }, data, api.noMonitor)
      closeReasonModalOpen = false
      await onMatchChanged({ detailsHard: true, activity: true })
      toaster.toast({ message: 'Rotation closed', type: 'info', icon: 'edit' })
    } finally {
      closing = false
    }
  }
</script>

<style lang="scss">
  .action-panel {
    position: fixed;
    background-color: #fff;
    padding: 10px 80px 5px 10px;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 1px solid #eee;
    animation-name: slideUp;
    animation-duration: 0.6s;
    z-index: 1040;
    box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.08);
  }

  .action-panel-btns {
    display: flex;
    justify-content: center;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  .action-panel-btns > :global(.btn) {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  @media only screen and (max-width: 650px) {
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    .action-panel-btns > :global(.btn),
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    .action-panel-btns > :global(div > .btn) {
      padding: 7px;
      font-size: 10px;
    }
  }

  @keyframes slideUp {
    0% {
      bottom: -100px;
    }
    100% {
      bottom: 0;
    }
  }
</style>
