{#if conflicts}
  <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.matchUsers} />
{/if}
<Collapsible label="people" name="match-modal-people" remember={peopleCount <= 5}>
  <div slot="label">
    <div class="profile-pics">
      <div>
        <div class="title">{pluralCount('Student', students.length)}</div>
        <div class="flex-row flex-wrap flex-align-center">
          <UserCondensedList users={students} let:user>
            <UserCondensed {user} school={match.school} showStudentInfo />
          </UserCondensedList>
        </div>
      </div>

      {#if faculty.length !== 0}
        <div>
          <div class="title">{pluralCount('School faculty', faculty.length)}</div>
          <div class="flex-row flex-wrap flex-align-center">
            <UserCondensedList users={faculty} let:user>
              <UserCondensed {user} school={match.school} showStudentInfo={false} />
            </UserCondensedList>
          </div>
        </div>
      {/if}

      {#if coordinatorsAndPreceptors.length !== 0}
        <div>
          <div class="title">{pluralCount('Staff', coordinatorsAndPreceptors.length)}</div>
          <div class="flex-row flex-wrap flex-align-center">
            <UserCondensedList users={coordinatorsAndPreceptors} let:user>
              <UserCondensed {user} roles={user.roleNames} showStudentInfo={false} />
            </UserCondensedList>
          </div>
        </div>
      {/if}
    </div>
  </div>

  {#if students.length}
    <h3>
      <Icon name="user-graduate" />
      {pluralCount('Student', students, 'omitNumber')}
      <Badge count={students} min={2} />
    </h3>
    <Students bind:match {students} {userNameFriendly} {deleteMatchUser} />
  {/if}

  <h3>
    <Icon name="school" />
    School faculty
    <Badge count={faculty} min={0} />
  </h3>
  <Faculty bind:match {faculty} {userNameFriendly} {deleteMatchUser} {removeFacultyExtra} />

  <h3>
    <Icon name="hospital" />
    Staff
    <Badge count={coordinatorsAndPreceptors} min={0} />
  </h3>
  <Staff bind:match {coordinatorsAndPreceptors} {removeFacultyExtra} {deleteMatchUser} {userNameFriendly} />
</Collapsible>

<script>
  import { pluralCount } from 'services/string-utils.js'
  import { toFriendlyList } from 'services/string-utils.js'
  import Badge from 'components/Badge.svelte'
  import Collapsible from './Collapsible.svelte'
  import Faculty from 'components/MatchModal.Faculty.svelte'
  import Icon from 'components/Icon.svelte'
  import MergeConflictsMatch from './MergeConflictsMatch.svelte'
  import Staff from 'components/MatchModal.Staff.svelte'
  import Students from 'components/MatchModal.Students.svelte'
  import user from 'stores/user.js'
  import UserCondensed from 'components/UserCondensed.svelte'
  import UserCondensedList from './UserCondensedList.svelte'

  export let match
  export let students
  export let faculty
  export let coordinatorsAndPreceptors
  export let conflicts
  export let matchInitial

  // even if they explicitly opened the people section, let's close it if there are a lot of people
  // otherwise, students don't see where they need to complete steps
  $: peopleCount = _.size(students) + _.size(faculty) + _.size(coordinatorsAndPreceptors)

  function userNameFriendly(u) {
    if (u == null) return
    const loggedInUserId = $user != null ? $user.userId : null
    return loggedInUserId === u.userId ? 'yourself' : u.name
  }

  function deleteMatchUser(role) {
    return user => (match.matchUsers = match.matchUsers.filter(mu => !(mu.userId === user.userId && mu.matchRole === role)))
  }

  function removeFacultyExtra(person, roleType) {
    if (person.roles == null || person.roles.length === 1) return ''
    const youOrThem = $user.userId === person.userId ? 'you' : 'them'
    const otherRoles = toFriendlyList(otherRoleNames(person.roles, roleType))
    return person.roles.length > 1 ? `This will not remove ${youOrThem} as ${otherRoles} on this rotation.` : ''
  }

  function otherRoleNames(roles, roleVal) {
    return roles == null ? [] : roles.filter(r => r.val !== roleVal).map(r => r.name)
  }
</script>

<style>
  .profile-pics {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .profile-pics > div:not(:last-child):not(:first-child) {
    border-right: 1px solid #eee;
  }
  .profile-pics > div {
    margin-right: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
  }
  .profile-pics .title {
    font-size: 12px;
    color: #999;
    font-weight: 400;
    margin-bottom: 5px;
  }
</style>
