import api from 'services/api.js'
import sockets from 'services/sockets.js'
import { writable } from 'svelte/store'

function createStore() {
  const { subscribe, set } = writable({ pendingIncoming: [], active: [], pendingOutgoing: [] })
  const load = async () => {
    set(null)
    set(await api.connections.listConnections(api.noMonitor))
  }
  sockets.on('ConnectionsChanged', load)
  return {
    subscribe,
    set,
    load,
  }
}

export default createStore()
