<a {href} class="specific-underline{className ? ` ${className}` : ''}" target="_blank" use:tip={_tooltip}>
  <span class={textClass}><slot>{href}</slot></span>
  <Icon name="open-new" class="external-link" style="color: #AAA; font-size: 0.85em" />
</a>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let href
  export let placement = 'top'
  export let tooltip = 'Opens in another tab/window'
  let className = null
  export { className as class }
  export let textClass = 'underlineable'

  $: _tooltip = _.isString(tooltip) ? { content: tooltip, options: { placement } } : tooltip
</script>
