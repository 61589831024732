// Get svelte-routing stores that have info about the current route (params, uri, etc)

// svelte-routing stores the currently active route in a store inside component context
// there is only ever a single routerContext.routerBase (the first instance of Router): https://github.com/EmilTholin/svelte-routing/blob/master/src/Router.svelte
// not sure why it is implemented as a context rather than a store or set of stores. Related discussion: https://github.com/EmilTholin/svelte-routing/issues/83
// ideally, there would be a single router and ability to nest route components. Nested routes would inherit their parent's url as their base.
// But having multiple nested Router components isn't bad

import { LOCATION, ROUTER } from 'svelte-routing/src/contexts.js'

import { getContext } from 'svelte'
import { resolve } from 'svelte-routing/src/utils.js'

export function getLocation() {
  return getContext(LOCATION)
}

export function getBase() {
  return getContext(ROUTER)?.base
}

export function getActiveRoute() {
  return getContext(ROUTER)?.activeRoute
}

// Svelte-router Link component is kinda a pain to style (need to use :global or surround it with extra markup)
// Also it's nice to be able to call `navigate` with just the `to` rather than needing to know the whole url
// See \ClinicianNexus\node_modules\svelte-routing\src\Link.svelte for more details. Might also consider taking it's logic for determining if it's active/current.
export function createTo(to, base) {
  const baseUri = base?.uri ?? base
  return to === '/' ? baseUri : resolve(to, baseUri)
}
