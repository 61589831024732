<!-- Use this to easily swap two pieces of content without violating DRY. -->
<!-- This also avoids accessibility issues when using the CSS properties `flex-direction` and `order`. -->
<!-- See more: https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction#Accessibility_concerns -->
<!-- We could expand this to support 3+ slots, too. The only problem is Svelte doesn't support dynamic slot names. -->
{#if order === '0-1'}
  <slot name="0" />
  <slot name="1" />
{:else if order === '1-0'}
  <slot name="1" />
  <slot name="0" />
{/if}

<script>
  export let order = '0-1'
</script>
