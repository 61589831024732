<span class={_class} use:tip={tooltip}
  >{#if iconHref}<img src={iconHref} alt={label} style="width: {size}px; height: {size}px" />{:else}<Icon name={icon} {...iconProps} />{/if}
  {label}</span
>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let config
  let className = 'flex-row flex-align-center g05'
  export { className as class }
  export let lg = false
  export let tooltip = null

  $: color = config.color
  $: iconHref = config.iconHref
  $: icon = config.icon
  $: iconProps = config.iconProps ?? {}
  $: label = config.label
  $: size = lg || iconProps.lg ? 20 : 14

  $: _class = [color ? `text-${color}` : null, className].filter(Boolean).join(' ')
</script>
