import { FeatureType, PersonaType, StaffRole } from 'config/enums.js'

import enumToOptions from 'services/enum-to-options.js'

const roleDescriptions = {
  [StaffRole.Admin]: 'Has complete access to manage other staff, can configure everything, can act on all rotations, and can create/run reports',
  [StaffRole.Coordinator]: 'Can schedule and act on rotations',
  [StaffRole.Preceptor]: 'Can be assigned and act as a preceptor on rotations',
  [StaffRole.ReportViewer]: 'Can create/run reports',
  [StaffRole.Faculty]: 'Can be assigned as faculty to accompany students on rotations',
  [StaffRole.AgreementManager]: 'Can manage agreements with other organizations',
  [StaffRole.StepManager]: 'Can manage application, onboarding, and offboarding steps',
  [StaffRole.CapacityManager]:
    "Can manage opportunities; opportunity managers can <em>only</em> manage coordinators, preceptors, and other opportunity managers on opportunities that fit their role's criteria",
}

const allRoles = enumToOptions(StaffRole)
const capacityManagerRoles = [StaffRole.CapacityManager, StaffRole.Coordinator, StaffRole.Preceptor]

// If this logic changes, probably change GetStaffRoleOptions in RoleService.cs
class RoleService {
  getStaffRoleOptions(persona, allRolesEnabled = false) {
    if (persona.personaType === PersonaType.Student) return []
    const isCn = persona.personaType === PersonaType.CN
    // some roles require specific features
    const hasHealthFeature = isCn || persona.subscriptionFeatureTypes?.includes(FeatureType.HealthInstitution)
    const hasSchoolFeature = isCn || persona.subscriptionFeatureTypes?.includes(FeatureType.TeachingInstitution)
    // some roles are only assignable by admins
    const hasAdminRole = isCn || persona.orgStaffRoles.some(osr => osr.staffRole === StaffRole.Admin)
    return allRoles
      .filter(role => {
        switch (role.optionValue) {
          case StaffRole.Preceptor:
          case StaffRole.CapacityManager:
          case StaffRole.StepManager: // this one will eventually be usable by schools too
            return hasHealthFeature
          case StaffRole.Faculty:
            return hasSchoolFeature
          case StaffRole.AgreementManager:
            return hasSchoolFeature || hasHealthFeature
          default:
            return true
        }
      })
      .map(role => ({
        ...role,
        disabled: !allRolesEnabled && !isCn && !hasAdminRole && !capacityManagerRoles.includes(role.optionValue),
      }))
  }

  getStaffRoleDescription(staffRole) {
    return roleDescriptions[staffRole]
  }

  isRoleAssignableToServicesOrOpportunitiesOrDisciplines(staffRole) {
    return [StaffRole.Coordinator, StaffRole.Preceptor, StaffRole.CapacityManager, StaffRole.ReportViewer].includes(staffRole)
  }

  isRoleAutoAssignable(persona, staffRole) {
    const hasHealthFeature = persona.subscriptionFeatureTypes.includes(FeatureType.HealthInstitution)
    return staffRole === StaffRole.Preceptor || (staffRole === StaffRole.Coordinator && hasHealthFeature)
  }

  getRestrictionsPreventingAccessToOpportunity(capacity, orgStaffRole) {
    let orgIds
    let teamIds
    let serviceIds
    let capacityIds
    if (orgStaffRole.orgs) {
      orgIds = orgStaffRole.orgs?.map(o => o.orgId)
      teamIds = orgStaffRole.teams?.map(t => t.teamId)
      serviceIds = orgStaffRole.services?.map(s => s.serviceId)
      capacityIds = orgStaffRole.capacities?.map(c => c.capacityId)
    } else {
      orgIds = orgStaffRole.orgIds
      teamIds = orgStaffRole.teamIds
      serviceIds = orgStaffRole.serviceIds
      capacityIds = orgStaffRole.capacityIds
    }

    const restrictions = []
    // An empty array means the role is not restricted by the restriction type
    if (orgIds?.length && !orgIds.includes(capacity.orgId)) {
      restrictions.push('locations')
    }
    if (teamIds?.length && !teamIds.includes(capacity.teamId)) {
      restrictions.push('teams')
    }
    if (serviceIds?.length && !serviceIds.includes(capacity.serviceId)) {
      restrictions.push('services')
    }
    if (capacityIds?.length && !capacityIds.includes(capacity.capacityId)) {
      restrictions.push('opportunities')
    }

    const cleanOsrDisciplines = orgStaffRole.disciplines?.map(d => (d.label ?? d)?.trim().toUpperCase()) ?? []
    const cleanCapacityDisciplines = capacity.disciplines?.map(d => d.trim().toUpperCase()) ?? []

    if (
      cleanOsrDisciplines.length &&
      cleanCapacityDisciplines.length &&
      !cleanOsrDisciplines.some(item => cleanCapacityDisciplines.includes(item)) // no matches
    ) {
      restrictions.push('disciplines')
    }
    return { staffRoleName: orgStaffRole.staffRoleName, restrictions }
  }

  getCapacityAccessibilityTooltip(capacity, orgStaffRoles) {
    if (!orgStaffRoles) return
    for (const sr of orgStaffRoles) {
      const roleRestrictions = this.getRestrictionsPreventingAccessToOpportunity(capacity, sr)
      if (!roleRestrictions.restrictions.length) {
        // if at least one staff role is not restricted, the user is allowed to see the opportunity
        return ''
      }
    }
    return 'This user doesn’t have permission to view this opportunity. They will still be visible to applicants, but the user won’t be able to see this opportunity or its associated rotations.'
  }
}

// export singleton
export default new RoleService()
