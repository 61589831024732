<div class="flex-column g05">
  <div class="flex-row flex-justify-between">
    <StartAndEndDate {startDate} {endDate} />
    {#if hasActionsSlot}
      <div class="flex-justify-end">
        <slot name="actions" />
      </div>
    {/if}
  </div>

  {#if startTime}
    <StartAndEndTime {startTime} {endTime} class="flex-row flex-align-center g05" />
  {:else}
    <span class="small">No set times</span>
  {/if}

  {#if repeatEvery !== 1 || repeatUnit === RepeatUnit.Weekly || repeatUnit === RepeatUnit.Monthly}
    <div class="text-wrap">
      {#if repeatEvery !== 1 || repeatUnit === RepeatUnit.Monthly}
        <RepeatsEvery {repeatSchedule} />
      {/if}
      {#if repeatUnit === RepeatUnit.Weekly}
        <DayOfWeekBubblePicker sm readOnly onlyValues class={repeatEvery === 1 ? null : 'mt05'} value={repeatDaysOfWeek} />
      {:else if repeatUnit === RepeatUnit.Monthly}
        <DayOfMonthLabel value={repeatDaysOfMonth} maxWordCount={Infinity} />
      {/if}
    </div>
  {/if}
  {#if shiftDay == null}
    <div class="flex-row g1">
      <em class="small">No specific schedule assigned</em>
      <Help>This availability window doesn’t have any explicit schedules; it has the same availability as the opportunity itself</Help>
    </div>
  {/if}
</div>

<script>
  import { buildRepeatSchedule } from 'services/calendar-service.js'
  import { RepeatUnit } from 'config/enums.js'
  import DayOfMonthLabel from 'components/fields/DayOfMonthLabel.svelte'
  import DayOfWeekBubblePicker from './fields/DayOfWeekBubblePicker.svelte'
  import Help from 'components/Help.svelte'
  import RepeatsEvery from 'components/RepeatsEvery.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'

  export let capacity
  export let shift
  export let shiftDay

  $: repeatSchedule = buildRepeatSchedule(capacity, shift, shiftDay)
  $: startDate = repeatSchedule.startDate
  $: endDate = repeatSchedule.endDate
  $: startTime = repeatSchedule.startTime
  $: endTime = repeatSchedule.endTime
  $: repeatUnit = repeatSchedule.repeatUnit
  $: repeatEvery = repeatSchedule.repeatEvery
  $: repeatDaysOfWeek = repeatSchedule.repeatDaysOfWeek
  $: repeatDaysOfMonth = repeatSchedule.repeatDaysOfMonth

  const hasActionsSlot = !!$$props.$$slots?.actions
</script>
