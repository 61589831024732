<Btn
  {type}
  {icon}
  {iconRight}
  {iconProps}
  {iconRightProps}
  {title}
  {dataTest}
  {disabled}
  {loading}
  {stopPropagation}
  {color}
  {clearBtnStyling}
  class={className}
  on:click
>
  <slot />
</Btn>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'

  export let disabled = false
  export let loading = false
  export let color = 'primary'
  let className = ''
  export { className as class }
  export let title = null
  export let dataTest = null
  export let type = 'submit'
  export let stopPropagation = false
  export let icon = null
  export let iconProps = null
  export let clearBtnStyling = false
  export let iconRight = null
  export let iconRightProps = null
</script>
