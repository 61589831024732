<div class="waffle-grid-container mb2" on:mousemove={handleTooltip}>
  {#if guests?.find(g => g.guestOrgId === hoveredGuest)}
    <ParticipantTooltip guest={guests?.find(g => g.guestOrgId === hoveredGuest)} />
  {/if}
  <div class="waffle-grid" style="grid-template-columns: repeat({columnCount}, 30px); grid-template-rows: repeat({rowCount}, 30px);">
    {#if isParticipantView && guests}
      {#each [...filteredGuests, ...remainingGuests] as guest}
        {@const cells = new Array(guest.approvedRotations + guest.pendingRotations).fill(guest.guestOrgId)}
        {#each cells as cell, index}
          {@const isPending = index >= guest.approvedRotations}
          {@const overMaxRotations = index >= guest.maximumRotations}
          <div
            class="color-text-{guest.color}  {remainingGuests.includes(guest) ? 'remaining-guest' : ''}"
            on:mouseenter={event => handleHoverGuest(event, guest.guestOrgId)}
            on:focus={event => handleHoverGuest(event, guest.guestOrgId)}
            on:mouseleave={() => (hoveredGuest = null)}
            on:on:blur={() => (hoveredGuest = null)}
          >
            {#if overMaxRotations}
              <Icon
                cell
                name={overMaxRotations ? 'square-exclamation' : 'square-exclamation'}
                style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: {guest.guestOrgId ===
                  hoveredGuest && isPending
                  ? '.6'
                  : '1'};"
              />
            {:else}
              <Icon
                cell
                name="square"
                style="--path-0-color: var(--user-{guest.color}); --path-0-opacity: {guest.guestOrgId === hoveredGuest && isPending ? '.6' : '1'};"
              />
            {/if}
          </div>
        {/each}
        {#if guest.guaranteedRotations > 0}
          {@const guestGuaranteedRotations = guest.guaranteedLeft > 0 ? new Array(guest.guaranteedLeft).fill(guest.guestOrgId) : []}
          {#each guestGuaranteedRotations as guaranteedRotation}
            <div
              class="color-text-{guest.color} {remainingGuests.includes(guest) ? 'remaining-guest' : ''}"
              on:mouseenter={event => handleHoverGuest(event, guest.guestOrgId)}
              on:focus={event => handleHoverGuest(event, guest.guestOrgId)}
              on:mouseleave={() => (hoveredGuest = null)}
              on:on:blur={() => (hoveredGuest = null)}
            >
              <Icon cell name="square" style="--path-0-opacity: {guest.guestOrgId === hoveredGuest ? '.3' : '1'}" />
            </div>
          {/each}
        {/if}
      {/each}
    {/if}
    {#if isStatusView}
      {#if hoveredStatus}
        {@const matchStatus = matchStatusHelper.get(hoveredStatus)}
        <div
          class="org-tooltip p1 card-left-thick-{matchStatus.reportColor}"
          class:showTooltip={hoveredStatus}
          style="border-left: 5px solid {matchStatus.reportColor};"
        >
          <div class="strongish flex-row flex-align-center g05" style="color: {matchStatus.reportColor}; ">
            <Icon x2 name={matchStatus.icon} class="ml05" />
            {matchStatus.label}
          </div>
        </div>
      {/if}
      {#each capacityMatchStatusCounts as statusCount}
        {@const cellsPerStatus = new Array(statusCount.count).fill(statusCount.key)}
        {#each cellsPerStatus as cell}
          <div
            class="waffle-cell"
            class:hovered-status={statusCount.key === hoveredStatus}
            style="background-color: {matchStatusHelper.get(statusCount.key).reportColor}"
            on:mouseenter={handleHoverStatus(statusCount.key)}
            on:focus={handleHoverStatus(statusCount.key)}
            on:mouseleave={() => (hoveredStatus = null)}
            on:on:blur={() => (hoveredStatus = null)}
          />
        {/each}
      {/each}
    {/if}
    {#if emptyCells.length}
      {#each emptyCells as cell, i}
        <div style="color: #eee;" on:mouseenter={() => (hoveredGuest = null)} on:focus={() => (hoveredGuest = null)}>
          <Icon cell name="square" />
        </div>
      {/each}
    {/if}
  </div>
</div>

<script>
  import ParticipantTooltip from './ParticipantDetail.ParticipantTooltip.svelte'

  import { MatchStatus } from 'config/enums'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'

  // waffle stuff
  export let capacity
  export let guests = []
  export let filteredGuests = []
  export let isParticipantView = false
  export let isStatusView = false
  export let capacityMatchStatusCounts = []

  const maxRotations = capacity ? capacity.guests.reduce((acc, g) => acc + g.maximumRotations, 0) : 0
  $: remainingGuests = guests.filter(g => !filteredGuests.includes(g))
  $: totalCells = capacityMatchStatusCounts
    ? capacityMatchStatusCounts.reduce((acc, s) => acc + s.count, 0) < maxRotations
      ? maxRotations
      : capacityMatchStatusCounts.reduce((acc, s) => acc + s.count, 0)
    : 0
  $: capacity.statusCounts = capacity.statusCounts.filter(s => s.status !== MatchStatus.Closed && s.status !== MatchStatus.Unsubmitted)
  $: hoveredGuest = null
  $: hoveredStatus = null
  $: totalPendingCells = capacity
    ? capacity.statusCounts
        .filter(s => s.status > MatchStatus.Unsubmitted && s.status < MatchStatus.Onboarding)
        .reduce((acc, status) => acc + status.count, 0)
    : 0
  $: totalGuaranteedCells = guests ? guests.reduce((acc, guest) => acc + guest.guaranteedRotations, 0) : 0
  $: emptyCells = isParticipantView
    ? maxRotations
      ? Array(maxRotations - totalPendingCells - totalGuaranteedCells).fill(null)
      : []
    : maxRotations
      ? Array(maxRotations - capacity.statusCounts.reduce((acc, s) => acc + s.count, 0)).fill(null)
      : []
  $: rowCount = totalCells < 100 ? Math.ceil(Math.sqrt(totalCells)) : 10
  $: columnCount = isParticipantView
    ? maxRotations > totalPendingCells + totalGuaranteedCells
      ? Math.ceil(maxRotations / rowCount)
      : Math.ceil((totalPendingCells + totalGuaranteedCells) / rowCount)
    : maxRotations > capacity.statusCounts.reduce((acc, s) => acc + s.count, 0)
      ? Math.ceil(maxRotations / rowCount)
      : Math.ceil(capacity.statusCounts.reduce((acc, s) => acc + s.count, 0) / rowCount)

  function handleHoverGuest(event, guestOrgId) {
    event.stopPropagation()
    hoveredGuest = guestOrgId
  }

  function handleHoverStatus(status) {
    if (hoveredStatus !== status) {
      hoveredStatus = status
    }
  }

  function handleTooltip(event) {
    const isOpen = hoveredGuest === 0
    if (!isOpen && !hoveredGuest && !hoveredStatus) return
    const tooltip = document.querySelector('.org-tooltip')
    const x = event.clientX
    const y = event.clientY
    if (hoveredGuest || isOpen) {
      tooltip.style.left = x + 100 + 'px'
      tooltip.style.top = y - 80 + 'px'
    }
    if (hoveredStatus) {
      tooltip.style.left = x + 100 + 'px'
      tooltip.style.top = y - 20 + 'px'
    }
  }
</script>

<style lang="scss">
  /* waffle stuff */
  .waffle-grid-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    position: relative;
    display: flex;
  }
  .waffle-grid {
    padding: 3px;
    display: grid;
    grid-auto-flow: column;
    margin: auto;
  }
  .waffle-cell {
    background-color: #eee;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 2px solid #f7f7f7;
    font-size: 2rem;
    font-weight: bolder;
  }

  .hovered-status {
    opacity: 0.5;
  }

  .org-tooltip {
    position: fixed;
    pointer-events: none;
    background-color: #fff;
    display: none;
    border-radius: 5px;
    z-index: 1000;
  }
  .showTooltip {
    display: block;
  }

  .remaining-guest {
    opacity: 0.1 !important;
  }
</style>
