<div class="flex-row flex-justify-center pb2 mt1">
  {#each MatchStatuses as option, i}
    {@const MatchStatus = matchStatusHelper.get(option.optionValue)}
    <div class="flex-row flex-align-center g05 mr2 text-{MatchStatus.color}">
      {#if useCounts}
        <span class="mr05 strong">
          {readableNumber(matches.filter(m => m.status === MatchStatus.key).length)}
        </span>
      {:else}
        <Icon name="square" />
      {/if}
      <Icon name={MatchStatus.icon} />
      <strong>
        {option.optionLabel}
      </strong>
    </div>
  {/each}
</div>

<script>
  import { MatchStatus } from 'config/enums'
  import { readableNumber } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import enumToOptions from 'services/enum-to-options'
  import matchStatusHelper from 'services/match-status-helper.js'

  export let matches = []

  $: useCounts = matches.length ? true : false
  $: MatchStatuses = enumToOptions(MatchStatus).filter(o => o.optionValue !== MatchStatus.Closed && o.optionValue !== MatchStatus.Unsubmitted)
</script>
