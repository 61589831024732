{#if editing && isStepSelectionOptional}
  <HelpBlock>Step selection is optional</HelpBlock>
{/if}
<FilterTypeListFilter
  bind:filter
  {editing}
  {appliedMeta}
  filterOptions={_filterOptions}
  let:option={step}
  let:previousOption={previousStep}
  let:i
  let:item={selectedStep}
  inputCheckboxGroupClass="mt0"
>
  <svelte:fragment slot="before-input">
    {#if includeOrgName && step.orgId !== previousStep?.orgId}
      <FilterTypeStepOrg selectedStepIds={filter.stepIds} {step} {i} filterOptions={_filterOptions} {anyNestedOrgs} {onChangeSelectedStepIds} />
    {/if}
  </svelte:fragment>
  <Step {step} />
  <Step step={selectedStep} class="strong" slot="selectedItem" />
</FilterTypeListFilter>

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import FilterTypeStepOrg from 'components/FilterTypeStep.Org.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.Steps,
    idKey: 'stepId',
    label: 'Step',
    labelApplied: 'step',
    hasDoesntHave: true,
    icon: 'clipboard-list',
    iconClass: 'color-text-brown',
    canHaveMultiple: true,
    filterProp: 'stepIds',
    optionsEndpoint: 'getSteps',
    manyToMany: true,
  }
</script>

<script>
  import Step from 'components/Step.svelte'
  import persona from 'stores/persona'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
  export let isStepSelectionOptional
  export let interceptor

  $: personaOrgId = $persona.orgId
  $: includeOrgName = !!filterOptions?.some(o => o.orgId !== personaOrgId)
  $: anyNestedOrgs = !!filterOptions?.some(o => o.orgName !== o.orgRelativeName)
  $: stepType = interceptor?.stepType
  $: _filterOptions = stepType != null ? filterOptions?.filter(o => o.stepType === stepType) : filterOptions

  function onChangeSelectedStepIds(stepIds) {
    filter.stepIds = stepIds
  }
</script>
