import { tabbable, isFocusable } from './tabbable.js'
import { valueOrHandler } from './helpers.js'
import Key from 'config/key.js'

const focusTrapInitialFocusAttribute = 'data-focus-trap-initial-focus'
let activeFocusDelay

const activeFocusTraps = (function () {
  const trapQueue = []
  return {
    activateTrap(trap) {
      if (trapQueue.length) {
        const activeTrap = trapQueue[trapQueue.length - 1]
        if (activeTrap !== trap) {
          activeTrap.pause(false)
        }
      }

      const trapIndex = trapQueue.indexOf(trap)
      if (trapIndex === -1) {
        trapQueue.push(trap)
      } else {
        // move this existing trap to the front of the queue
        trapQueue.splice(trapIndex, 1)
        trapQueue.push(trap)
      }
    },

    deactivateTrap(trap) {
      const trapIndex = trapQueue.indexOf(trap)
      if (trapIndex !== -1) trapQueue.splice(trapIndex, 1)
      for (let i = trapQueue.length - 1; i >= 0; i--) {
        const trap = trapQueue[i]
        if (trap.state.manuallyPaused) continue
        trap.unpause(false)
        break
      }
    },
  }
})()

const createFocusTrap = function (elements, userOptions) {
  const doc = document

  const config = {
    returnFocusOnDeactivate: true,
    escapeDeactivates: true,
    delayInitialFocus: true,
    ...userOptions,
  }

  const state = {
    containers: [],

    // list of objects identifying the first and last tabbable nodes in all containers/groups in the trap
    // it's possible that a group has no tabbable nodes if nodes get removed while the trap is active,
    // but the trap should never get to a state where there isn't at least one group with at least one tabbable node;
    // that would lead to an error condition that would result in an error being thrown
    tabbableGroups: [], // { container, firstTabbableNode, lastTabbableNode }

    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: false,
    paused: false,
    manuallyPaused: false,
  }

  let trap // eslint-disable-line prefer-const -- some private functions reference it, and its methods reference private functions, so we must declare here and define later

  const containersContain = elem => state.containers.some(ctnr => ctnr.contains(elem))

  const getNodeForOption = optionName => {
    const optionValue = config[optionName]
    if (!optionValue) return null
    let node = optionValue
    if (typeof optionValue === 'string') {
      node = doc.querySelector(optionValue)
      if (!node) throw new Error(`\`${optionName}\` refers to no known node`)
    }
    if (typeof optionValue === 'function') {
      node = optionValue()
      if (!node) throw new Error(`\`${optionName}\` did not return a node`)
    }
    return node
  }

  const getInitialFocusNode = () => {
    const node =
      getNodeForOption('initialFocus') ??
      (containersContain(doc.activeElement) ? doc.activeElement : state.tabbableGroups[0]?.firstTabbableNode) ??
      getNodeForOption('fallbackFocus')
    if (!node) throw new Error('Your focus-trap needs to have at least one focusable element')
    return node
  }

  const updateTabbableNodes = () => {
    state.tabbableGroups = state.containers
      .map(container => {
        const tabbableNodes = tabbable(container, config.tabbableOptions)
        return !tabbableNodes.length
          ? null
          : {
              container,
              firstTabbableNode: tabbableNodes[0],
              lastTabbableNode: tabbableNodes[tabbableNodes.length - 1],
            }
      })
      .filter(group => group)

    // throw if no groups have tabbable nodes and we don't have a fallback focus node either
    if (!state.tabbableGroups.length && !getNodeForOption('fallbackFocus'))
      throw new Error('Your focus-trap must have at least one container with at least one tabbable node in it at all times')
  }

  const tryFocus = node => {
    if (node === doc.activeElement) return
    if (!node || !node.focus) {
      tryFocus(getInitialFocusNode())
      return
    }

    // if we haven't yet set a focused node, this is the first time focus-trap is focusing an element
    const focusTrapInitialFocus = state.mostRecentlyFocusedNode == null

    // dispatchEvent fires the focus event, but doesn't focus the element,
    // and doesn't seem like you can add custom props to the event that gets
    // fired from `elem.focus()`.
    // So let's just add an attribute for the calling code to check instead
    // of looking at a prop on the focus event.
    // Alternatively, we could fire a custom event and handle that prior to handling focus,
    // but that'd end up being more code for the calling code, so meh.
    // const e = new FocusEvent('focus')
    // e.focusTrapInitialFocus = focusTrapInitialFocus
    // node.dispatchEvent(e)
    if (focusTrapInitialFocus) node.setAttribute(focusTrapInitialFocusAttribute, '')

    node.focus({ preventScroll: !!config.preventScroll })
    state.mostRecentlyFocusedNode = node
    // focus-trap wants to select the text by default.
    // This seems weird in practice, so we'll remove this functionality...
    // if (isInput(node)) node?.select()

    if (focusTrapInitialFocus) node.removeAttribute(focusTrapInitialFocusAttribute)
  }

  const getReturnFocusNode = previousActiveElement => getNodeForOption('setReturnFocus') || previousActiveElement

  // This needs to be done on mousedown and touchstart instead of click
  // so that it precedes the focus event.
  const checkPointerDown = e => {
    if (containersContain(e.target)) return // allow the click since it ocurred inside the trap

    if (valueOrHandler(config.clickOutsideDeactivates, e)) {
      // immediately deactivate the trap
      trap.deactivate({
        // if, on deactivation, we should return focus to the node originally-focused
        //  when the trap was activated (or the configured `setReturnFocus` node),
        //  then assume it's also OK to return focus to the outside node that was
        //  just clicked, causing deactivation, as long as that node is focusable
        //  if it isn't focusable, then return focus to the original node focused
        //  on activation (or the configured `setReturnFocus` node)
        // NOTE: by setting `returnFocus: false`, deactivate() will do nothing,
        //  which will result in the outside click setting focus to the node
        //  that was clicked, whether it's focusable or not; by setting
        //  `returnFocus: true`, we'll attempt to re-focus the node originally-focused
        //  on activation (or the configured `setReturnFocus` node)
        returnFocus: config.returnFocusOnDeactivate && !isFocusable(e.target),
      })
      return
    }

    // This is needed for mobile devices.
    // (If we'll only let `click` events through,
    // then on mobile they will be blocked anyways if `touchstart` is blocked.)
    if (valueOrHandler(config.allowOutsideClick, e)) return // allow the click outside the trap to take place

    // otherwise, prevent the click
    e.preventDefault()
  }

  // In case focus escapes the trap for some strange reason, pull it back in.
  const checkFocusIn = e => {
    const targetContained = containersContain(e.target)
    // In Firefox when you Tab out of an iframe the Document is briefly focused.
    if (targetContained || e.target instanceof Document) {
      if (targetContained) state.mostRecentlyFocusedNode = e.target
    } else {
      // Focus escaped! pull it back in to where it just left
      e.stopImmediatePropagation()
      tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode())
    }
  }

  // Hijack Tab events on the first and last focusable nodes of the trap,
  // in order to prevent focus from escaping. If it escapes for even a
  // moment it can end up scrolling the page and causing confusion so we
  // kind of need to capture the action at the keydown phase.
  const checkTab = e => {
    updateTabbableNodes()

    let destinationNode = null

    if (state.tabbableGroups.length) {
      // make sure the target is actually contained in a group
      const containerIndex = state.tabbableGroups.findIndex(({ container }) => container.contains(e.target))

      if (containerIndex < 0) {
        // target not found in any group: quite possible focus has escaped the trap,
        //  so bring it back in to...
        if (e.shiftKey) {
          // ...the last node in the last group
          destinationNode = state.tabbableGroups[state.tabbableGroups.length - 1].lastTabbableNode
        } else {
          // ...the first node in the first group
          destinationNode = state.tabbableGroups[0].firstTabbableNode
        }
      } else if (e.shiftKey) {
        // REVERSE
        const startOfGroupIndex = state.tabbableGroups.findIndex(({ firstTabbableNode }) => e.target === firstTabbableNode)
        if (startOfGroupIndex >= 0) {
          const destinationGroupIndex = startOfGroupIndex === 0 ? state.tabbableGroups.length - 1 : startOfGroupIndex - 1

          const destinationGroup = state.tabbableGroups[destinationGroupIndex]
          destinationNode = destinationGroup.lastTabbableNode
        }
      } else {
        // FORWARD
        const lastOfGroupIndex = state.tabbableGroups.findIndex(({ lastTabbableNode }) => e.target === lastTabbableNode)
        if (lastOfGroupIndex >= 0) {
          const destinationGroupIndex = lastOfGroupIndex === state.tabbableGroups.length - 1 ? 0 : lastOfGroupIndex + 1

          const destinationGroup = state.tabbableGroups[destinationGroupIndex]
          destinationNode = destinationGroup.firstTabbableNode
        }
      }
    } else {
      destinationNode = getNodeForOption('fallbackFocus')
    }

    if (destinationNode) {
      e.preventDefault()
      tryFocus(destinationNode)
    }
  }

  const checkKey = e => {
    switch (e.which || e.keyCode) {
      case Key.Escape:
        if (config.escapeDeactivates !== false) {
          e.preventDefault()
          trap.deactivate()
        }
        return
      case Key.Tab:
        checkTab(e)
        return
    }
  }

  const checkClick = e => {
    if (valueOrHandler(config.clickOutsideDeactivates, e)) return
    if (containersContain(e.target)) return
    if (valueOrHandler(config.allowOutsideClick, e)) return
    e.preventDefault()
    e.stopImmediatePropagation()
  }

  const addListeners = () => {
    if (!state.active) return

    // There can be only one listening focus trap at a time
    activeFocusTraps.activateTrap(trap)

    // Delay ensures that the focused element doesn't capture the event
    // that caused the focus trap activation.
    activeFocusDelay = config.delayInitialFocus ? setTimeout(() => tryFocus(getInitialFocusNode())) : tryFocus(getInitialFocusNode())

    const listenerOptions = { capture: true, passive: false }
    doc.addEventListener('focusin', checkFocusIn, true)
    doc.addEventListener('mousedown', checkPointerDown, listenerOptions)
    doc.addEventListener('touchstart', checkPointerDown, listenerOptions)
    doc.addEventListener('click', checkClick, listenerOptions)
    doc.addEventListener('keydown', checkKey, listenerOptions)
    return trap
  }

  const removeListeners = () => {
    if (!state.active) return
    doc.removeEventListener('focusin', checkFocusIn, true)
    doc.removeEventListener('mousedown', checkPointerDown, true)
    doc.removeEventListener('touchstart', checkPointerDown, true)
    doc.removeEventListener('click', checkClick, true)
    doc.removeEventListener('keydown', checkKey, true)
    return trap
  }

  trap = {
    state,
    activate(activateOptions) {
      if (state.active) return this
      updateTabbableNodes()
      state.active = true
      state.paused = false
      state.nodeFocusedBeforeActivation = doc.activeElement
      const onActivate = activateOptions?.onActivate ?? config.onActivate
      if (onActivate) onActivate()
      addListeners()
      return this
    },

    deactivate(deactivateOptions) {
      if (!state.active) return this
      clearTimeout(activeFocusDelay)
      removeListeners()
      state.active = false
      state.paused = false
      activeFocusTraps.deactivateTrap(trap)
      const onDeactivate = deactivateOptions?.onDeactivate ?? config.onDeactivate
      const returnFocus = deactivateOptions?.returnFocus ?? config.returnFocusOnDeactivate
      if (onDeactivate) onDeactivate()
      if (returnFocus) setTimeout(() => tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation)))
      return this
    },

    pause(manuallyPaused = true) {
      state.manuallyPaused ||= manuallyPaused
      if (state.paused || !state.active) return this
      state.paused = true
      removeListeners()
      return this
    },

    unpause(manuallyUnpaused = true) {
      if (!state.paused || !state.active || (state.manuallyPaused && !manuallyUnpaused)) return this
      state.paused = false
      updateTabbableNodes()
      addListeners()
      return this
    },

    updateContainerElements(containerElements) {
      state.containers = []
        .concat(containerElements)
        .filter(e => e)
        .map(e => (typeof e === 'string' ? doc.querySelector(e) : e))
      if (state.active) updateTabbableNodes()
      return this
    },
  }

  // initialize container elements
  trap.updateContainerElements(elements)

  return trap
}

export { createFocusTrap, focusTrapInitialFocusAttribute }
