<InputTextarea bind:inputEl class="message-box" bind:value={message} {name} {placeholder} {autofocus} {disabled} {maxlength} />

<script>
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import MessageBoxUser from './MessageBox.User.svelte'
  import { onDestroy } from 'svelte'
  import Tribute from 'tributejs'

  let inputEl
  export let message = ''
  export let disabled = false
  export let placeholder = ''
  export let mentionableUsers = []
  export let name = null
  export let autofocus = false
  export let maxlength = null

  export function focus() {
    if (inputEl) inputEl.focus()
  }

  let tribute

  $: if (inputEl && mentionableUsers && mentionableUsers.length > 0) {
    tribute = new Tribute({
      values: mentionableUsers,
      selectTemplate: item => (item == null || item.original == null ? null : '@' + item.original.userName),
      menuItemTemplate: item => {
        if (item?.original == null) return
        const profilePicDiv = document.createElement('div')
        new MessageBoxUser({
          target: profilePicDiv,
          props: {
            user: item.original,
          },
        })
        return profilePicDiv.innerHTML // we don't attach the div to the dom, but can still get its html (works in chrome, ff, edge chromium)
      },
      noMatchTemplate: () => '',
      lookup: user => user.name + user.userName,
    })
    detachBox()
    attachBox()
  }

  onDestroy(() => {
    detachBox()
    tribute = null
  })

  function detachBox() {
    if (inputEl == null || tribute == null || tribute.detach == null) return
    tribute.detach(inputEl)
    inputEl.removeAttribute('data-tribute') // tribute should probably do this and also the check in attach
  }

  function attachBox() {
    if (inputEl == null || tribute == null || tribute.attach == null) return
    const alreadyAttached = inputEl.hasAttribute('data-tribute')
    if (!alreadyAttached) tribute.attach(inputEl)
  }
</script>
