import { writable } from 'svelte/store'
import api from 'services/api.js'

const model = {
  loading: false,
  loadingFavorites: false,
  views: [],
}

function createStore() {
  const { subscribe, set } = writable(null)

  const load = async () => {
    model.loading = true
    set(model)
    model.views = await api.savedSearch.list(api.noMonitor)
    model.loading = false
    set(model)
  }

  return {
    subscribe,
    load,
    set,

    async createView(view) {
      model.loading = true
      set(model)
      const res = await api.savedSearch.create(view)
      await load()
      return res
    },

    async updateView(view) {
      model.loading = true
      set(model)
      const res = await api.savedSearch.update(view)
      await load()
      return res
    },

    async deleteView(view) {
      // Update the in-memory collection so the UI updates immediately
      model.views = model.views.filter(v => v.savedSearchId !== view.savedSearchId)
      set(model)
      const res = await api.savedSearch.delete({ savedSearchId: view.savedSearchId }, api.noMonitor)
      await load()
      return res
    },

    async hideView(view) {
      const res = await api.savedSearch.setVisible({ savedSearchId: view.savedSearchId, visible: false })
      await load()
      return res
    },

    async setFavorite(view, favorite) {
      // Update the in-memory collection so the UI updates immediately
      const original = model.views.find(v => v.savedSearchId === view.savedSearchId)
      original.favorite = favorite
      if (favorite) {
        original.favoriteOrderIndex = view.favoriteOrderIndex
      } else {
        original.favoriteOrderIndex = null
      }
      model.loadingFavorites = true
      set(model)
      const res = await api.savedSearch.setFavorite({ savedSearchId: view.savedSearchId, favorite }, api.noMonitor)
      await load()
      model.loadingFavorites = false
      set(model)
      return res
    },

    async saveFavOrder(orderedSavedSearchIds) {
      // Update the in-memory collection so the UI updates immediately
      const favViews = orderedSavedSearchIds.map(id => model.views.find(v => v.savedSearchId === id))
      let i = 0
      for (const view of favViews) view.favoriteOrderIndex = i++
      model.loadingFavorites = true
      model.views = model.views.filter(v => !orderedSavedSearchIds.includes(v.savedSearchId)).concat(favViews)
      set(model)
      const res = await api.savedSearch.saveFavOrder(orderedSavedSearchIds, api.noMonitor)
      await load()
      model.loadingFavorites = false
      set(model)
      return res
    },
  }
}

const savedViews = createStore()
export default savedViews
