{#if capacity}
  <Modal {lg} {xl} on:close={onClose}>
    <h4 slot="title" data-test="title">
      {#if capacity.capacityId > 0}
        <div class="small">Opportunity</div>
        {capacity.name}
      {:else if creatingCopy}
        <div class="small">Copying opportunity</div>
        {creatingCopyOriginalName}
      {:else}
        New opportunity
      {/if}
    </h4>
    {#if personaService.canEditCapacity(capacity)}
      {#if capacity.allowGroups === undecided && !capacity.capacityId}
        <CapacityTypeSelector bind:allowGroups={capacity.allowGroups} />
      {:else}
        <CapacityForm
          {saving}
          {agreementDisciplines}
          {serviceId}
          {capacity}
          {creatingCopy}
          {onClose}
          {onCapacityChanged}
          {allowedServiceIds}
          {ancestorOrgsById}
          baseHref={_baseHref}
          {baseHrefOpportunities}
        />
      {/if}
    {:else if personaService.canViewCapacityAsHost(capacity)}
      <CapacityHostView {capacity} {capacityGuest} {onClose} baseHref={_baseHref} />
    {:else}
      <CapacityGuestView {capacity} {capacityGuest} {baseHref} {onClose} {onUpdate} />
    {/if}
  </Modal>
{/if}

<script>
  import { RequestAvailabilityPolicy } from 'config/enums.js'
  import api from 'services/api.js'
  import CapacityForm from 'components/CapacityForm.svelte'
  import CapacityGuestView from 'components/CapacityGuestView.svelte'
  import CapacityHostView from 'components/CapacityHostView.svelte'
  import CapacityTypeSelector from 'components/CapacityTypeSelector.svelte'
  import getValueFromCollectionBySubpath from 'services/get-value-from-collection-by-subpath.js'
  import Modal from 'components/Modal.svelte'
  import personaFilters from 'stores/persona-filters.js'
  import personaService from 'services/persona-service.js'

  export let baseHref
  export let baseHrefOpportunities
  export let subpath // comes from <Route>.path template
  export let restPath
  export let saving
  export let capacities
  export let newCapacityInput = {}
  export let serviceId
  export let allowedServiceIds = []
  export let listBodyIncludes
  export let onClose = _.noop
  export let onCapacityChanged = _.noop
  export let onUpdate = _.noop
  export let ancestorOrgsById = {}
  export let isDetailsView = false

  export let capacity

  let capacityGuest
  let agreementDisciplines
  let creatingCopyOriginalName = null
  let creatingCopy = false
  const undecided = Symbol('Undecided')
  $: if (!capacity) subpath, capacities, setCapacity()
  $: _baseHref = isDetailsView ? `${baseHrefOpportunities}/edit` : `${baseHrefOpportunities}/edit/${subpath}`
  $: canEditCapacity = capacity && personaService.canEditCapacity(capacity)
  $: needsIndividualOrGroupDecision = canEditCapacity && capacity?.maxStudentsInGroup === undecided && !capacity.capacityId
  $: xl = canEditCapacity && !needsIndividualOrGroupDecision
  $: lg = !xl

  function setCapacity() {
    if (capacity) return
    const maybeValue = getValueFromCollectionBySubpath(capacities, subpath, 'capacityId', newCapacity, copyCapacity, loadCapacity)
    if (maybeValue) {
      capacity = maybeValue
      let guestOrgId = $personaFilters.orgId
      const maybeGuestOrgId = restPath?.match(/^\d+/)
      if (maybeGuestOrgId?.length) guestOrgId = parseInt(maybeGuestOrgId[0], 10)
      // TODO(nursing): We probably should be climbing the org hierarchy to find the first guest available to the guestOrgId.
      capacityGuest = capacity.guests?.find(c => c.guestOrgId === guestOrgId)
    }
  }

  async function loadCapacity(capacityId, shouldCopy) {
    const response = await api.capacity.list({ ...listBodyIncludes, selectedCapacityIds: [capacityId], pageSize: 0, excludeTotalCount: true })
    const selected = response.selectedCapacities[0]
    capacity = shouldCopy ? copyCapacity(selected) : selected
  }

  function copyCapacity(capacity) {
    creatingCopy = true
    creatingCopyOriginalName = capacity.name
    const copy = _.cloneDeep(capacity)
    copy.name += ' (copy)'
    copy.capacityId = 0
    copy.rotationCount = 0
    let shiftId = -1
    let shiftDayId = -1
    copy.shifts.forEach(ds => {
      ds.capacityId = 0
      ds.shiftId = shiftId--
      if (ds.shiftDays) {
        ds.shiftDays.forEach(sd => {
          sd.shiftDayId = shiftDayId--
        })
      }
    })
    return copy
  }

  function newCapacity() {
    return {
      serviceId,
      name: '',
      description: '',
      openForScheduling: true,
      postToSearchPage: null,
      allowCoordinatorScheduling: null,
      allowHostCoordinatorScheduling: null,
      allowGuestCoordinatorScheduling: null,
      allowStudentsToLeaveGroupMatch: null,
      noNewMatchesForDayWhenOthersHaveBeen: null,
      allowGroups: undecided,
      startDate: new Date(),
      endDate: null,
      allowStudentScheduling: false,
      allowStudentChanges: true,
      maxMatches: null,
      shifts: [
        {
          name: 'All day',
          description: null,
          maxMatchCountPerDay: null,
          maxStudentsInGroup: null,
          matchDayLimitPerWeek: null,
          startTime: null,
          endTime: null,
          color: 'green',
          shiftDays: [],
          dirty: true,
        },
      ],
      staffIds: [],
      staff: [],
      rotationTypes: [],
      specialties: [],
      disciplines: [],
      studentYears: [],
      requestAvailabilityPolicy: RequestAvailabilityPolicy.MultipleAvailableShifts,
      guests: [],
      ...newCapacityInput,
    }
  }
</script>
