<div class="flex-row flex-align-center g1" style={fullWidth ? 'min-width: 565px' : 'width: 565px'}>
  <Filter
    bind:text={keywordSearch}
    placeholder="Search students by name, discipline, or school"
    on:change={onKeywordSearchChanged}
    class="flex-grow"
  />

  <slot name="after-keyword-search" />
</div>

<div bind:this={filtersContainerElem} style="grid-area: filters" class="flex-row flex-align-center flex-wrap g1">
  <slot />
  <Filters
    bind:this={filtersComponent}
    bind:filters
    {includedFilterTypes}
    {excludedFilterTypes}
    interceptors={_interceptors}
    {metaMapFuncs}
    {onChanged}
    {onCleared}
    class={null}
    dropdownStyle={filtersDropdownStyle}
    filterOptionsController="studentFilterOptions"
  />
</div>

<script context="module">
  import { buildFilterTypesArray } from 'components/Filters.svelte'

  const includedFilterTypes = [
    // This comment is here so the formatter keeps these on separate lines.
    FilterType.GuestOrgs,
    FilterType.Course,
    FilterType.StudentYears,
    FilterType.StudentGraduationDate,
    FilterType.GuestOrgCoordinators,
    FilterType.Disciplines,
    FilterType.KeywordSearch,
    FilterType.StudentCustomTags,
  ]

  const metaMapFuncs = {
    [FilterType.GuestOrgCoordinators]: meta => {
      meta.canHaveMultiple = true
      return meta
    },
    [FilterType.Disciplines]: meta => {
      // We allow this for the student picker and grid, but not for rotations.
      // At that point, students should have their discipline set. If they don't,
      // we'll make it intentionally inconvenient so they have to do it.
      meta.excludable = true
      meta.allowNull = true
      return meta
    },
    [FilterType.Course]: meta => {
      meta.excludable = false
      meta.toMany = true
      meta.toManySuffix = 'of the following courses'
      meta.editLabel = null
      meta.allowNull = true
      meta.toManyNoneCheckboxLabel = 'Include students without courses'
      return meta
    },
    [FilterType.StudentYears]: meta => {
      meta.excludable = true
      meta.allowNull = false
      meta.optionValues = [1, 2, 3, 4, 10]
      return meta
    },
  }

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }
</script>

<script>
  import { FilterType } from 'config/enums.js'
  import { onDestroy } from 'svelte'
  import Filter from 'components/Filter.svelte'
  import Filters from 'components/Filters.svelte'
  import validator from 'services/validator.js'

  export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  export let excludedFilterTypes = []
  export let fullWidth = false
  export let interceptors = {}

  $: _interceptors = {
    [FilterType.KeywordSearch]: {
      canRemove: false,
      isAvailable: false,
    },
    ...interceptors,
  }

  let keywordSearch = filters.find(f => f.type === FilterType.KeywordSearch)?.config.keyword ?? ''
  let filtersContainerElem = null
  let filtersDropdownStyle = null

  $: filtersContainerElem, initResizeObserver()
  let observer = null
  function initResizeObserver() {
    if (filtersContainerElem == null || observer != null) return
    observer = new ResizeObserver(() => {
      if (!fullWidth) {
        filtersDropdownStyle = null
        return
      }
      if (filtersContainerElem == null) return
      filtersDropdownStyle = `width: ${filtersContainerElem.offsetWidth}px; max-width: unset`
    })
    observer.observe(filtersContainerElem)
  }

  onDestroy(() => {
    observer?.disconnect()
  })

  function onCleared() {
    keywordSearch = ''
    onKeywordSearchChanged()
  }

  function onKeywordSearchChanged() {
    filters = filters.filter(f => f.type !== FilterType.KeywordSearch)
    if (!validator.empty(keywordSearch)) filters.push({ type: FilterType.KeywordSearch, config: { keyword: keywordSearch } })
    onChanged(filters)
  }
</script>
