{#if staff}
  <Modal on:close={onClose}>
    <h3 slot="title" data-test="title">Remove staff</h3>

    <div class="modal-body delete-body" data-test="confirm-delete-message">
      Are you sure you want to remove
      <strong>{nameFriendly}</strong>
      from {isCapacityStaff ? 'this opportunity' : 'your staff'}
    </div>

    <div class="modal-footer">
      <Btn class="btn-danger" icon="delete" on:click={_onDelete} loading={deleting}>Remove</Btn>
      <Btn class="btn-default" icon="close" on:click={onClose}>Cancel</Btn>
    </div>
  </Modal>
{/if}

<script>
  import Modal from 'components/Modal.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import user from 'stores/user.js'

  export let staff
  export let isCapacityStaff
  export let onClose
  export let onDelete

  let deleting = false

  $: nameFriendly = staff ? (isCurrentUser(staff.userId) ? 'yourself' : staff.name) : ''

  function isCurrentUser(userId) {
    return $user?.userId === userId
  }

  async function _onDelete() {
    deleting = true
    try {
      await onDelete(staff.userId)
    } finally {
      deleting = false
    }
  }
</script>
