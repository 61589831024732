<QuickDropdown
  label="Show…"
  icon="sliders-simple"
  btnClass="btn btn-default"
  labelClass="flex-row flex-align-center g05"
  dropdownClass="p2"
  dataTest="capacities-show-dropdown"
>
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <h4>Status</h4>
      <InputToggle {...toggleParams} bind:checked={$showDropdowns.capacityGrid.status} name="show-status">
        <Icon lg name="calendar-check" class="text-success" />
        Status
      </InputToggle>
    </div>
    <div class="flex-column g05">
      <h4>Opportunity</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.postToSearchPage} name="show-posted-on-search-page">
        <Icon lg name="search" />
        Posted on search page
      </InputToggle>

      <InputToggle
        {...toggleParams}
        class="m0"
        bind:checked={$showDropdowns.capacityGrid.allowCoordinatorScheduling}
        name="show-allow-coordinator-scheduling"
      >
        <Icon lg name="plus" />
        Allows coordinator scheduling
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.rotationTypes} name="show-rotation-types">
        <Icon lg name="briefcase-medical" class="text-rotation-type" />
        Experience types
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.specialties} name="show-specialties">
        <Icon lg name="file-certificate" class="text-specialty" />
        Specialties
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.maxMatches} name="show-max-matches">
        <Icon lg autoColor name="rotation" />
        Rotations available to all participants
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Owner & location</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.owner} name="show-owner">
        <Icon lg name="hospital" class="color-text-orange" />
        Owner location
      </InputToggle>

      {#if hasTeamFeature}
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.team} name="show-team">
          <Icon lg name="users" class="color-text-orange" />
          Team
        </InputToggle>
      {/if}

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.locations} name="show-location">
        <Icon lg name="hospital" class="color-text-orange" />
        Location
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Requirements</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.matchLeadTimeDays} name="show-lead-time">
        <Icon lg name="calendar-clock" class="color-text-dark-green" />
        Lead time
      </InputToggle>

      <InputToggle
        {...toggleParams}
        class="m0"
        bind:checked={$showDropdowns.capacityGrid.requestAvailabilityPolicy}
        name="show-request-availability-policy"
      >
        Request Availability Policy
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.studentYears} name="show-student-years">
        <Icon lg name="calendars" class="color-text-teal" />
        Student years
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.disciplines} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Disciplines
      </InputToggle>
      {#if $showDropdowns.capacityGrid.disciplines}
        <div class="ml3">
          <InputOptionalNumber
            name="show-discipline-count"
            bind:value={$showDropdowns.capacityGrid.disciplineCount}
            autocomplete="off"
            noValueLabelClass="flex-row flex-align-center g05"
            noValueIsNull
            min={0}
            valueLabelPostfix={pluralCount('discipline', $showDropdowns.capacityGrid.disciplineCount, 'omitNumber')}
            inputNumberClass="input-group-inline-grid"
            inputNumberStyle="width: 50px"
          >
            <svelte:fragment slot="no-value-label">
              All <Icon name="book" class="color-text-teal" /> disciplines
            </svelte:fragment>

            <span class="input-group-addon inline-flex-row flex-align-center g05">
              <Icon name="book" class="color-text-teal" />
              {pluralCount('discipline', $showDropdowns.capacityGrid.disciplineCount, 'omitNumber')}
            </span>
          </InputOptionalNumber>
        </div>
      {/if}
    </div>

    {#if showService}
      <div class="flex-column g05">
        <h4>Service</h4>
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.service} name="show-service">
          <Icon lg name="shapes" class="color-text-purple" />
          Service
        </InputToggle>
      </div>

      <div class="flex-column g05">
        <h4>Participants</h4>

        {#if showAgreement}
          <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.school} name="show-school">
            <Icon lg name="school" class="color-text-blue" />
            School
          </InputToggle>

          <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.agreement} name="show-agreement">
            <Icon lg name="agreement" autoColor />
            Agreement
          </InputToggle>
        {/if}
        <InputToggle
          {...toggleParams}
          class="m0"
          bind:checked={$showDropdowns.capacityGrid.guestConfirmMatchesByDate}
          name="show-guest-confirm-matches-by-date"
        >
          <Icon lg name="calendar" class="color-text-blue" />
          Confirmation deadline
        </InputToggle>
      </div>
    {/if}

    <div class="flex-column g05">
      <h4>Availability</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.startAndEndDate} name="show-start-and-end-date">
        <Icon lg name="calendar" class="color-text-dark-green" />
        Start and
        <Icon lg name="calendar" class="color-text-light-red" />
        end dates
      </InputToggle>

      {#if isHealth}
        <!-- TODO(Nursing): Show opportunity staff? -->
        <!-- <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.staff} name="show-staff">
          <Icon lg name="users" class="color-text-orange" />
          Staff
        </InputToggle> -->

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.shifts} name="show-shifts">
          <Icon lg name="shift" class="color-text-steel" />
          Availability windows
        </InputToggle>

        {#if $showDropdowns.capacityGrid.shifts}
          <div class="flex-column g05 ml2">
            <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.shiftLimits} name="show-shifts-locations">
              <Icon lg autoColor name="road-barrier" />
              Limits
            </InputToggle>

            <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.shiftLocations} name="show-shifts-locations">
              <Icon lg name="hospital" class="color-text-orange" />
              Locations
            </InputToggle>

            <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.shiftStaff} name="show-shifts-staff">
              <Icon lg name="users" class="color-text-orange" />
              Staff
            </InputToggle>

            <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.shiftSchedule} name="show-shifts-schedule">
              <Icon lg name="calendars" class="color-text-steel" />
              Schedules
            </InputToggle>
          </div>
        {/if}
      {/if}
    </div>

    <div class="flex-column g05">
      <h4>Rotations</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.guaranteedMatchCount} name="show-guaranteed-match-count">
        <Icon lg name="rotation" class="color-text-green" />
        Guaranteed rotation count
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.matchStatusCount} name="show-match-status-count">
        <Icon lg name="rotation" class="color-text-teal" />
        Rotation status count
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityGrid.matchesSummary} name="show-matches-summary">
        <Icon lg name="rotation" class="color-text-steal" />
        Rotation summary
      </InputToggle>
    </div>
  </div>
</QuickDropdown>

<script>
  import { PersonaType, FeatureType } from 'config/enums'
  import { pluralCount } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import persona from 'stores/persona'
  import personaService from 'services/persona-service.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import InputOptionalNumber from 'components/InputOptionalNumber.svelte'

  export let showAgreement = true
  export let showService = true

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.capacityGrid) {
      if (typeof $showDropdowns.capacityGrid[key] !== 'boolean') continue
      $showDropdowns.capacityGrid[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>
