<InputSelect {options} bind:value {disabled} selectDropdownClass="" let:option>
  <AutoReleaseUnscheduledMatchesWhenLabel value={option.value} extraClass="full-width" labelClass="flex-grow" />

  <div slot="label">
    <AutoReleaseUnscheduledMatchesWhenLabel value={option.value} />
  </div>
</InputSelect>

<script>
  import { AutoReleaseUnscheduledMatchesWhen } from 'config/enums.js'
  import AutoReleaseUnscheduledMatchesWhenLabel from 'components/AutoReleaseUnscheduledMatchesWhenLabel.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'

  export let value
  export let isOpenAnySchool
  export let disabled

  const allOptions = [
    { value: AutoReleaseUnscheduledMatchesWhen.Never },
    { value: AutoReleaseUnscheduledMatchesWhen.Confirmed },
    { value: AutoReleaseUnscheduledMatchesWhen.PastConfirmDesiredMatchCountByDate },
    { value: AutoReleaseUnscheduledMatchesWhen.PastSchedulingEndDate },
  ]

  $: options = isOpenAnySchool
    ? allOptions.filter(o => [AutoReleaseUnscheduledMatchesWhen.Never, AutoReleaseUnscheduledMatchesWhen.PastSchedulingEndDate].includes(o.value))
    : allOptions
</script>
