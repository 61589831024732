<Grid
  let:row
  {loading}
  rows={userDocuments}
  name="user-document-grid"
  class="middle"
  pagesOfLabelSingular="document"
  paginatorControls="bottom"
  {pageSize}
  {rowClasses}
  bind:currentPage
  rowKeySelector={row => row.userDocumentId}
  paginatorTotalCount={totalCount}
  {getRowTipConfig}
  {onRowClick}
>
  <GridCol {row} header="Name" name="name" class="overflowUserDocumentName">
    <div class="flex-row flex-align-center g1">
      <Icon
        name={row.fileTypeIcon}
        lg
        class={row.disabled ? 'disabled-icon' : row.userDocumentId === selectedUserDocumentId ? 'selected-icon' : 'text-primary'}
        title={row.fileType}
      />
      <FileName value={row.nameOriginal} insideOf="grid" />
    </div>
  </GridCol>

  <MediaQuery {...mediaQueries.size}>
    <GridCol {row} header="Size" name="size">
      <div>{humanFileSize(row.size)}</div>
    </GridCol>
  </MediaQuery>

  <MediaQuery {...mediaQueries.dateUploaded}>
    <GridCol {row} header="Uploaded" name="dateUploaded">
      <FromNow date={row.dateCreated} showTooltip localize />
    </GridCol>
  </MediaQuery>

  <GridCol {row} class="text-right nowrap">
    <MediaQuery {...mediaQueries.actionsOverflow}>
      <OverflowMenu dataTest="overflow-actions-{row.nameOriginal}">
        <UserDocumentActions userDocument={row} {onViewClicked} {mediaQueries} />
      </OverflowMenu>
    </MediaQuery>
  </GridCol>
</Grid>

<script context="module">
  const min = minWidth => ({ minWidth })
  const always = { query: 'all' }
</script>

<script>
  import { humanFileSize } from 'services/string-utils.js'
  import FromNow from 'components/FromNow.svelte'
  import FileName from 'components/FileName.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'
  import OverflowMenu from 'components/OverflowMenu.svelte'
  import UserDocumentActions from 'components/UserDocumentActions.svelte'

  export let userDocuments = []
  export let loading = false
  export let pageSize = 10
  export let currentPage = 1
  export let totalCount = null
  export let onRowClicked = _.noop
  export let onViewClicked = null

  let selectedUserDocumentId = null

  const rowClasses = {}
  const mediaQueries = {
    size: min(700),
    dateUploaded: min(900),
    actions: always,
    actionsOverflow: always,
    actionView: always,
    actionEdit: always,
    actionDelete: always,
    actionDownload: always,
  }
  $: userDocuments, selectedUserDocumentId, addRowClasses()

  function addRowClasses() {
    for (const ud of userDocuments) {
      if (ud.disabled) {
        rowClasses[ud.userDocumentId] = 'disabled-row'
      } else if (ud.userDocumentId === selectedUserDocumentId) {
        rowClasses[ud.userDocumentId] = 'selected-row'
      } else {
        rowClasses[ud.userDocumentId] = ''
      }
    }
  }

  function getRowTipConfig(row) {
    return row.disabled ? 'This file is already uploaded to this step' : null
  }

  function onRowClick(row) {
    if (row.disabled) return
    selectedUserDocumentId = row.userDocumentId
    onRowClicked(row.userDocumentId)
  }
</script>

<style>
  @media (max-width: 768px) {
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(.overflowUserDocumentName) {
      max-width: 180px;
    }
  }
  @media (min-width: 768px) {
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(.overflowUserDocumentName) {
      max-width: 450px;
    }
  }
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.overflowUserDocumentName),
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(td.overflowUserDocumentName > div) {
    overflow: auto;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(tr.selected-row > td.overflowUserDocumentName a) {
    color: white !important;
  }
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.selected-row) {
    background: #00a89d !important;
    color: white !important;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.selected-icon) {
    color: white !important;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.disabled-row) {
    background: #e3e1e1 !important;
    color: rgb(64, 64, 64);
    cursor: not-allowed !important;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.disabled-icon) {
    color: rgb(64, 64, 64);
  }
</style>
