<slot />

<script>
  import { setContext } from 'svelte'
  import { writable } from 'svelte/store'

  export let submitted = false

  const _submitted = writable(submitted)
  setContext('submitted', _submitted)

  let formGroups = []
  setContext('addValidation', addValidation)

  export function getFirstInvalid() {
    setSubmitted(true)
    return formGroups.find(fg => !fg.isValid())
  }

  function addValidation(fg) {
    formGroups = formGroups.concat(fg)
    return () => {
      formGroups = formGroups.filter(f => f != fg)
    }
  }
  export function setSubmitted(val) {
    submitted = val // so calling code can two-way bind up to itself and know if the form has been submitted
    $_submitted = val
  }
</script>
