<div class="well" transition:slide|local data-test="hourlog-form">
  <Form on:submit={save}>
    <FormGroup valid={!overlaps && log.date}>
      <label for="log-date">Date <RequiredMarker /></label>
      <DatePicker name="log-date" bind:value={log.date} placeholder="Date" allowNextPrev {min} {max} />
      {#if overlaps}<span class="text-danger">This hour log would overlap with another hour log</span>{/if}

      {#if dateInFuture}
        <Alert type="warning" class="mt1">Date is in the future.</Alert>
      {/if}
    </FormGroup>

    <FormGroup valid={log.from != null && log.to != null}>
      <label for="log-time">Time <RequiredMarker /></label>
      <InputTime name="log-time" bind:startValue={log.from} bind:endValue={log.to} range />
    </FormGroup>

    {#if hourLogsMustBeVerified}
      <FormGroup valid={log.supervisorUserIds?.length > 0}>
        <label for="supervisors">Supervisor(s) <RequiredMarker /></label>
        <Help>One or more people who can verify these hours.</Help>
        <div class="scrollable-md">
          <InputCheckboxGroup name="supervisors" bind:value={log.supervisorUserIds} valueSelector={o => o.userId} options={supervisors} let:option>
            <div class="flex-row">
              <ProfilePic src={option.profilePicture} initials={option.initials} name={option.name} id={option.userId} class="mr1" medium />
              <div>
                <div>{option.name}</div>
                <span class="help-block-inline">{formatEnumKey(option.matchRoleName)}</span>
              </div>
            </div>
          </InputCheckboxGroup>
        </div>
      </FormGroup>
    {/if}

    <FormGroup valid={log.type != null}>
      <label for="type">Type <RequiredMarker /></label>
      <InputSelect options={enumToOptions(HourType)} name="type" bind:value={log.type} />
    </FormGroup>

    <FormGroup valid={log.orgId != null}>
      <!--
        I don't think we need to give ability to select from _all_ locations,
        since the org staff can simply add the location to the opportunity's shift/locations accordingly.
        But might be helpful for one-off situations where the student did some work at a location where
        the opportunity _usually_ isn't located?
      -->

      {#if match.locations.length > 1}
        <label for="location">Location <RequiredMarker /></label>
        <InputSelect options={match.locations} name="location" bind:value={log.orgId} valueSelector={o => o.orgId} />
      {:else}
        <label for="location">Location</label>
        <div>{match.locations?.length ? match.locations[0].name : match.org.name}</div>
      {/if}
    </FormGroup>

    <FormGroup>
      <label for="note">Notes</label>
      <InputTextarea name="note" bind:value={log.note} maxlength={300} />
    </FormGroup>

    <Btn icon="save" class="btn-primary" type="submit" loading={saving} disabled={overlaps} dataTest="save-hour-log">Save</Btn>
    <Btn on:click={cancel}>Cancel</Btn>
  </Form>
</div>

<script>
  import { anyOverlapping, prepareHourFromServer, prepareHourForServer } from 'services/hourlog-service.js'
  import { createEventDispatcher } from 'svelte'
  import { formatEnumKey } from 'services/formatters.js'
  import { HourType } from 'config/enums.js'
  import { slide } from 'svelte/transition'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import enumToOptions from 'services/enum-to-options.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputTextarea from './fields/InputTextarea.svelte'
  import InputTime from 'components/fields/InputTime.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import toaster from 'services/toaster.js'

  export let log
  export let logs
  export let match
  export let supervisors
  export let min
  export let max
  export let hourLogsMustBeVerified

  const dispatch = createEventDispatcher()
  let saving = false
  // let range = false

  $: updating = log.hourLogId > 0
  $: serverLog = prepareHourForServer(log)
  $: overlaps = anyOverlapping(prepareHourFromServer(serverLog), logs) // server should assert this too
  $: dateInFuture = dayjs(log.date).isAfter(dayjs().startOf('day'))

  async function save() {
    saving = true
    const route = {
      matchId: log.matchId,
      hourLogId: log.hourLogId,
    }
    // if adding, consider iterating to log.rangeEnd, filling in all userMatchDays in between (basically automate clicking the save button)
    const body = updating ? serverLog : [serverLog]
    const saveTask = updating ? api.hourLog.update(route, body, api.noMonitor) : api.hourLog.add(route, body, api.noMonitor)
    await saveTask.finally(() => (saving = false))
    // TODO: backend should websocket a match updated notification for poeple looking at this match modal
    toaster.toast({
      message: 'Hour log saved',
      type: 'success',
      icon: 'time',
    })
    dispatch('saved')
  }

  function cancel() {
    dispatch('cancel')
  }
</script>
