{#each items as item}
  <div class="flex-row flex-align-center g05" use:tip={{ content: type, options: { theme } }} data-test={dataTest}>
    <Icon name={icon} class="text-{theme}" />
    <span>{item}</span>
  </div>
{/each}

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let items
  export let type
  export let icon
  export let theme
  export let dataTest = null
</script>
