<FormGroup>
  {#each faculty as s}
    <UserCard
      showStudentInfo={false}
      user={s}
      school={match.school}
      showEmail
      showPhone
      matchStatus={s.status}
      showOnboardingWarning={s.status < MatchStatus.Ready && match.status >= MatchStatus.Active}
      onDelete={canDeleteOrAdd ? deleteMatchUser(MatchRole.SchoolFaculty) : null}
      deleteMessage="Remove {userNameFriendly(s)} from the rotation as faculty. {removeFacultyExtra(s, MatchRole.SchoolFaculty)}"
      deleteMessageConfirm={{
        title: 'Remove faculty',
        message: `Are you sure you’d like to remove <strong>${userNameFriendly(s)}</strong> from the rotation? ${removeFacultyExtra(
          s,
          MatchRole.SchoolFaculty
        )}`,
        confirmLabel: 'Remove',
        confirmClass: 'btn-danger',
      }}
    />
  {/each}
  {#if canDeleteOrAdd}
    <AddMatchUser role={MatchRole.SchoolFaculty} {match} bind:matchUsers={match.matchUsers} />
  {/if}
</FormGroup>

<script>
  import { MatchRole, MatchStatus } from 'config/enums.js'
  import AddMatchUser from 'components/AddMatchUser.svelte'
  import UserCard from 'components/UserCard.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'

  export let match
  export let faculty
  export let userNameFriendly
  export let deleteMatchUser
  export let removeFacultyExtra

  $: canDeleteOrAdd = match.isEditable && (match.isCoordinator || match.isSchoolCoordinator) // allow coordinators to change matches even after they're completed for reporting purposes
</script>
