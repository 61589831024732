<InputRadioGroup
  {name}
  bind:value={radioValue}
  {options}
  let:option
  {disabled}
  {firstOptionClass}
  {lastOptionClass}
  labelClass="flex-row flex-align-center g05"
>
  {#if option.value === 'n-m'}
    <InputNumber
      sm
      {disabled}
      {allowDecimal}
      {style}
      {autocomplete}
      {min}
      bind:value={finiteMin}
      name="{name}-n-m-min"
      on:click={() => (radioValue = option.value)}
      on:keydown={e => onKeyDown(option, e)}
      canBeVisiblyInvalid={radioValue === option.value}
    />
    –
    <InputNumber
      sm
      {disabled}
      {allowDecimal}
      {style}
      {autocomplete}
      min={Number.isNaN(finiteMin) ? min : Math.max(min, finiteMin)}
      {max}
      bind:value={finiteMax}
      name="{name}-n-m-max"
      on:click={() => (radioValue = option.value)}
      on:keydown={e => onKeyDown(option, e)}
      canBeVisiblyInvalid={radioValue === option.value}
    />
  {:else if option.value === 'n-infinity'}
    <InputNumber
      sm
      {allowDecimal}
      {style}
      {autocomplete}
      {min}
      bind:value={finiteMinNoMax}
      name="{name}-n-infinity-min"
      on:click={() => (radioValue = option.value)}
      on:keydown={e => onKeyDown(option, e)}
      canBeVisiblyInvalid={radioValue === option.value}
    />
    –
    <slot name="infinity-label" />
  {:else if option.value === 'infinity'}
    <div class="flex-row flex-align-center g05{infinityClass ? ` ${infinityClass}` : ''}">
      <slot name="infinity-label" />
    </div>
  {/if}
</InputRadioGroup>

<script>
  import Key from 'config/key.js'
  import InputNumber from 'components/fields/InputNumber.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'

  export let name
  export let blankValue = null
  export let infinityValue = Infinity
  export let valueMin = 0
  export let valueMax = infinityValue
  export let defaultFiniteMin = null
  export let defaultFiniteMax = null
  export let min = 0
  export let max = Infinity
  export let allowDecimal = false
  export let firstOptionClass = null
  export let lastOptionClass = null
  export let infinityClass = 'py05'
  export let excludeOptions = []
  export let disabled = false

  const style = 'width: 50px'
  const autocomplete = 'off'

  const options = [{ value: 'n-m' }, { value: 'n-infinity' }, { value: 'infinity' }].filter(o => !excludeOptions.includes(o.value))

  let finiteMin = valueMin === infinityValue ? defaultFiniteMin : valueMin
  let finiteMax = valueMax === infinityValue ? defaultFiniteMax : valueMax
  let finiteMinNoMax = finiteMin
  let radioValue = valueMin === infinityValue ? 'infinity' : valueMax === infinityValue ? 'n-infinity' : 'n-m'

  function blankValueOr(value) {
    return value == null ? blankValue : value
  }

  $: updateValues(finiteMin, finiteMax, finiteMinNoMax, radioValue)
  function updateValues(finiteMin, finiteMax, finiteMinNoMax, radioValue) {
    if (radioValue === 'n-m') {
      valueMin = blankValueOr(finiteMin)
      valueMax = blankValueOr(finiteMax)
      if (!Number.isNaN(finiteMin)) finiteMinNoMax = finiteMin
    } else if (radioValue === 'n-infinity') {
      valueMin = blankValueOr(finiteMinNoMax)
      valueMax = infinityValue
      if (!Number.isNaN(finiteMinNoMax)) finiteMin = finiteMinNoMax
    } else if (radioValue === 'infinity') {
      valueMin = infinityValue
      valueMax = infinityValue
    }
  }

  const nonIntentKeys = [Key.Tab, Key.Shift, Key.Ctrl, Key.Escape, Key.Left, Key.Right]
  function onKeyDown(option, e) {
    const kbe = e.detail
    const k = kbe.which
    const key = kbe.key
    const ctrl = kbe.ctrlKey
    if (nonIntentKeys.includes(k)) return
    // Selecting everything or copying something doesn't feel like intention to change the radio value.
    if (ctrl && 'ac'.includes(key)) return
    radioValue = option.value
  }
</script>
