import { MatchRequestType } from 'config/enums.js'

// if labels change, update C# [Display] attributes (but we should probably just generate display values as part of the gen-enums script...)
const options = [
  { value: MatchRequestType.StudentRequested, label: 'Requested by a student' },
  { value: MatchRequestType.HostCoordinatorScheduled, label: 'Scheduled by a clinical site coordinator' },
  { value: MatchRequestType.GuestCoordinatorScheduled, label: 'Scheduled by a school coordinator' },
]

const optionsByValue = _.keyBy(options, o => o.value)

export default {
  options,
  optionsByValue,
}
