{#if customAffix}
  <InputText {name} bind:value bind:this={customInputText} autocomplete="off" />
{:else}
  <InputSelect {name} options={_options} bind:value={_value} let:option>
    <div class="flex-row flex-align-center g05 full-width">
      {#if option.value === null || option.value === custom}
        <span class="em small">{option.name}</span>
      {:else}
        {option.name}
      {/if}
    </div>
    <div slot="label" class="flex-row flex-align-center g05">
      {#if option.value !== null}
        {option.name}
      {/if}
    </div>
  </InputSelect>
{/if}

<script>
  import { buildOption } from 'services/option-builder.js'
  import InputSelect from './fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import validator from 'services/validator.js'

  export let name
  export let value = null
  export let options

  const custom = Symbol('Custom')
  let customAffix = false
  let customInputText
  let _value = validator.empty(value) ? null : value

  $: _options = [
    // Comment to prevent formatter single-line
    buildOption('None', null),
    ...options,
    buildOption('Custom', custom),
  ]

  $: reactToPrivateValue(_value)

  function reactToPrivateValue(_value) {
    if (_value === custom) {
      value = ''
      customAffix = true
      focus()
    } else {
      value = _value
      customAffix = !_options.some(o => o.value === value)
    }
  }

  function focus() {
    setTimeout(() => customInputText?.focus(), 0)
  }
</script>
