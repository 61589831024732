<ProfilePic {id} {name} {xxs} {xs} {small} {medium} {large} {xLarge} {inputSized} {special} {title} />

<script>
  import ProfilePic from 'components/ProfilePic.svelte'

  export let orgId
  export let name
  export let title = null

  export let xxs = false
  export let xs = false
  export let small = false
  export let medium = false
  export let large = false
  export let xLarge = false
  export let inputSized = false
  export let allowSpecial = false

  const char7 = String.fromCharCode(7)
  $: id = `${orgId}${char7}${name}`

  $: special = allowSpecial && name == null
</script>
