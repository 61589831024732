<!-- Changing something in here? Consider making a similar change to ServiceForm. -->

<div class="modal-body flex-column g2">
  {#if service == null}
    <Loading />
  {:else}
    <div>
      <label for="owner-org" class="flex-row flex-align-center g05">
        Owner location {#if service.teamOrgId}/ team {/if}
      </label>
      <div class="flex-column g05">
        <OrgProfilePictureAndName
          name={service.orgName}
          relativeName={service.orgRelativeName}
          profilePicture={service.orgProfilePicture}
          size="medium"
          useOverflowEllipsis
        />

        {#if service.teamOrgId}
          <TeamProfilePictureAndName medium orgId={service.teamOrgId} name={service.teamName} />
        {/if}
      </div>
    </div>

    <div>
      <label for="name" class="flex-row flex-align-center g05">
        <Icon name="shapes" class="color-text-purple" />
        Service name
      </label>
      <div>{service.name}</div>
    </div>

    <div class="md-text-container">
      <label for="description">Description</label>
      {#if service.descriptionHtml}
        <SafeHtml value={service.descriptionHtml} />
      {:else}
        <div class="em small">None</div>
      {/if}
    </div>

    <div>
      <label for="agreement-ids" class="flex-row flex-align-center g05">
        <Icon name="agreement" lg autoColor />
        Which agreements can use this service?
      </label>
      {#if service.agreements.length}
        <div class="flex-column g05">
          {#each service.agreements as agreement}
            <AgreementProfilePicturesAndName
              name={agreement.name}
              orgName={agreement.orgName}
              orgRelativeName={agreement.orgRelativeName}
              orgProfilePicture={agreement.orgProfilePicture}
              teamName={agreement.teamName}
              teamOrgId={agreement.teamOrgId}
              schoolName={agreement.schoolName}
              schoolRelativeName={agreement.schoolRelativeName}
              schoolProfilePicture={agreement.schoolProfilePicture}
              schoolTeamName={agreement.schoolTeamName}
              schoolTeamOrgId={agreement.schoolTeamOrgId}
            />
          {/each}
        </div>
      {:else}
        <div class="em small">Any agreement</div>
      {/if}
    </div>

    <div>
      <label class="flex-row flex-align-center g05" for="capacity-ids">
        <Icon name="list" class="color-text-purple" />
        Opportunities in this service
      </label>
      <OpportunitiesView serviceId={service.serviceId} baseHref="/services/{service.serviceId}" />
    </div>
  {/if}
</div>

<script>
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import Icon from 'components/Icon.svelte'
  import Loading from 'components/Loading.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import OpportunitiesView from 'pages/authorized/org/OpportunitiesView.svelte'

  export let service
</script>
