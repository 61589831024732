{#if showingAnything}
  <div class="flex-row g2">
    <div class="flex-column g1 flex-grow">
      <div class="flex-column g05">
        {#if show.dates}
          <StartAndEndDate startDate={course.startDate} endDate={course.endDate} />
        {/if}
        {#if show.progress}
          <CourseProgress
            bind:showDetails={show.progressDetails}
            scheduled={course.totalScheduled}
            completed={course.totalCompleted}
            extraScheduled={course.totalExtraScheduled}
            extraWorked={course.totalExtraWorked}
            unscheduled={course.totalUnscheduled}
          />
        {/if}

        {#if show.hours && course.hoursRequired}
          <div>
            <Icon lg name="time" class="color-text-brown" />
            {course.hoursRequired}
            {singularOrPlural(course.hoursRequired, 'hour')} required
          </div>
        {/if}

        {#if show.students && course.studentCountGoal}
          <div><Icon lg name="graduation-cap" />&nbsp;<CourseStudentCount {course} />{singularOrPlural(course.studentCountGoal, 'student')}</div>
        {/if}

        {#if show.matches}
          <div><Icon lg name="rotation" autoColor /> {pluralCount('rotation', course.matchCount)}</div>
        {/if}
      </div>

      {#if show.rotationTypes && course.rotationTypes?.length}
        <div>
          <TagCloud ctx={{ rotationTypes: course.rotationTypes }} />
        </div>
      {/if}
    </div>

    <div>
      {#if show.org || (show.team && course.teamOrgId)}
        <div class="course-owner">
          <div class="flex-row flex-align-center g05 mb05">
            <Icon name="hospital" class="color-text-orange" />
            <h4 class="leading-none m0">Provided by</h4>
          </div>

          <div class="flex-column g05">
            {#if show.org}
              <OrgProfilePictureAndName
                name={course.orgName}
                relativeName={course.orgRelativeName}
                profilePicture={course.orgProfilePicture}
                formattedAddress={course.orgAddress?.formattedAddress}
                size="small"
                useOverflowEllipsis
              />
            {/if}

            {#if show.team && course.teamOrgId}
              <TeamProfilePictureAndName small orgId={course.teamOrgId} name={course.teamName} />
            {/if}
          </div>
        </div>
      {/if}
      {#if show.description}
        <LongDesc max={130} text={course.descriptionHtml} class={null} />
      {/if}
    </div>
  </div>
{/if}

<script>
  import { pluralCount, singularOrPlural } from 'services/string-utils'
  import showDropdowns from 'stores/show-dropdowns.js'
  import CourseProgress from './CourseProgress.svelte'
  import CourseStudentCount from './CourseStudentCount.svelte'
  import Icon from './Icon.svelte'
  import LongDesc from './LongDesc.svelte'
  import OrgProfilePictureAndName from './OrgProfilePictureAndName.svelte'
  import StartAndEndDate from './StartAndEndDate.svelte'
  import TagCloud from './TagCloud.svelte'
  import TeamProfilePictureAndName from './TeamProfilePictureAndName.svelte'

  export let course

  $: show = $showDropdowns.coursePicker
  $: showingAnything =
    show.description || show.dates || show.org || show.team || show.matches || show.students || show.hours || show.progress || show.rotationTypes
</script>
