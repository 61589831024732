{#if show}
  <Btn icon="phone" href="tel:{number}" {dataTest} class={className}>{number}</Btn>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import validator from 'services/validator.js'

  export let number
  export let dataTest = 'phone-number-btn'
  export { className as class }
  let className = null

  $: show = !validator.empty(number)

  // TODO: look into making sure we +1 for the tel link -- sounds like "E.164" format is recommended by Twilio:
  // https://support.twilio.com/hc/en-us/articles/223183008-Formatting-International-Phone-Numbers
  // use StringExtensions.cs.FormatPhone as a starting point to format accordingly
</script>
