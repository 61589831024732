<!-- interacts with toaster service to render messages
  to send a toast from a svelte component in this app:
  toaster.toast({
    message,
    type,
    icon = null,
    url = null, // the url to navigate to when the user clicks the toast
    notification = null, // for formal databased notifications, we set it here, so clicking it will update db dateClicked / mark it as clicked
    onClick = null, // when the user clicks the toast
    onCancel = null, // when the user dismisses the toast
    onWaitComplete = null // when time runs out and the toast is removed from the DOM
    duration = null, // optionally provide an explicit duration
  })
  -->

{#if $toasts?.length > 0}
  <ToastContainer>
    {#each $toasts as toast (toast.id)}
      <Toast {toast} onDismiss={() => toasts.del(toast)} onMessageClick={() => toasts.del(toast)} />
    {/each}
  </ToastContainer>
{/if}

<script>
  import Toast from 'components/Toast.svelte'
  import toasts from 'services/toaster.js'
  import ToastContainer from './ToastContainer.svelte'
</script>
