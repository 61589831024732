// *** Any changes to this function need to be synchronized to C# FormService.CheckConditionValue, and should be well-tested on both client and server
// server tests: FormServiceTests.cs
// client tests: DynamicForm.spec.js

import { ComparisonType } from 'config/enums.js'

export default function checkConditionValue(value, comparisonType, conditionValue, conditionValues) {
  switch (comparisonType) {
    // todo might want to verify it has a valid date / number / etc for IsEmpty / IsNotEmpty... but meh
    case ComparisonType.IsEmpty:
      return value == null || value === ''
    case ComparisonType.IsNotEmpty:
      return value != null && value !== ''
    case ComparisonType.Is:
      return is(conditionValues, value, conditionValue)
    case ComparisonType.IsNot:
      return !is(conditionValues, value, conditionValue)
    case ComparisonType.Contains:
      return contains(conditionValues, value, conditionValue)
    case ComparisonType.DoesNotContain:
      return !contains(conditionValues, value, conditionValue)
    case ComparisonType.GreaterThan:
      return conditionValue != null && value != null && parseFloat(value) > parseFloat(conditionValue)
    case ComparisonType.LessThan:
      return conditionValue != null && value != null && parseFloat(value) < parseFloat(conditionValue)
    case ComparisonType.IsBefore:
      return conditionValue != null && value != null && dayjs(value).isBefore(dayjs(conditionValue))
    case ComparisonType.IsAfter:
      return conditionValue != null && value != null && dayjs(value).isAfter(dayjs(conditionValue))
    case ComparisonType.IsYes:
    case ComparisonType.IsChecked:
      return value === true || value === 'true'
    case ComparisonType.IsNo:
      return value === false || value === 'false'
    case ComparisonType.IsNotChecked:
      return value !== true && value !== 'true'
  }
  return false
}

function is(conditionValues, value, conditionValue) {
  return conditionValues != null
    ? Array.isArray(value)
      ? // if checkbox list, could have multiple values, so check if user checked ALL of the values specified
        conditionValues.every(v => value.some(vv => valueStringLower(vv) === valueStringLower(v)))
      : // else, could be dropdown, so check if user checked any of the values specified
        conditionValues.some(v => valueStringLower(v) === valueStringLower(value))
    : // otherwise, simple single-value field, so check if user input same value
      valueStringLower(conditionValue) === valueStringLower(value)
}

function contains(conditionValues, value, conditionValue) {
  return conditionValues != null
    ? Array.isArray(value)
      ? // if checkbox list, could have multiple values, so check if user checked ANY of the values specified
        conditionValues.some(v => value.some(vv => valueStringLower(vv) === valueStringLower(v)))
      : // else, could be dropdown, so check if user checked any of the values specified
        conditionValues.some(v => valueStringLower(v) === valueStringLower(value))
    : // otherwise, simple single-value field, so check if user input a value that contains the condition value
      valueStringLower(value).includes(valueStringLower(conditionValue))
}

function valueStringLower(val) {
  return val == null ? null : val.toString().toLowerCase()
}
