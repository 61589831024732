{#if isServiceView || isAgreementView}
  <Opportunities bind:filters bind:currentPage bind:saving {...opportunitiesComponentArgs} />
{:else}
  <Router>
    <Route path="/details/:capacityId/*" let:params>
      {@const capacityId = parseInt(params.capacityId, 10)}
      {@const capacity = capacities?.find(c => c.capacityId === capacityId)}
      <OpportunitiesDetails
        baseHref={opportunitiesHref + '/details'}
        {filters}
        {shareHref}
        {capacity}
        {saveCapacity}
        bind:saving
        bind:loading
        load={() => loadDebounced()}
      />
    </Route>
    <Route path={'*'}>
      <MainContent>
        <h1 class="mb05">Opportunities</h1>
        <Opportunities bind:filters bind:currentPage bind:saving {...opportunitiesComponentArgs} />
      </MainContent>
    </Route>
  </Router>
{/if}

<script context="module">
  import { ArchiveActiveFilter, CapacityListProperty, DateTimeComparison, DateTimeComparisonProviderType, FilterType } from 'config/enums.js'

  function buildOpportunityArchiveActiveFilter(archiveActiveValue) {
    return archiveActiveValue === ArchiveActiveFilter.All
      ? []
      : [
          {
            type: FilterType.CapacityEndDate,
            config: {
              comparisonProviderType: DateTimeComparisonProviderType.RelativeDays,
              comparisonProvider: {
                comparison: archiveActiveValue === ArchiveActiveFilter.Active ? DateTimeComparison.IsAfterOrEqual : DateTimeComparison.IsBefore,
                isNull: null,
                daysFromToday: 0,
                weeksFromNow: 0,
                dayOfWeek: 1,
                monthsFromNow: 0,
                dayOfMonth: 1,
                yearsFromNow: 0,
                quartersFromNow: 0,
                rangeBound: 10,
                date: null,
              },
            },
          },
        ]
  }

  export const activeOpportunitiesFilters = buildOpportunityArchiveActiveFilter(ArchiveActiveFilter.Active).concat({
    type: FilterType.CapacityOpenForScheduling,
    config: {
      openForScheduling: true,
    },
  })
</script>

<script>
  import { buildFilterTypes, buildIgnoredFilterTypes } from 'components/CapacityFilters.svelte'
  import { buildShareHref, parseShareHref } from 'components/ShareHref.svelte'
  import { FilterDecoder, FilterEncoder } from 'services/filters/index.js'
  import { getLocation } from 'stores/req.js'
  import { Router, Route, navigate } from 'svelte-routing'
  import { sortOptions } from 'components/CapacitySortDropdown.svelte'
  import api from 'services/api.js'
  import MainContent from 'components/MainContent.svelte'
  import Opportunities from './Opportunities.svelte'
  import OpportunitiesDetails from './Opportunities.Details.svelte'
  import personaFilters from 'stores/persona-filters.js'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'

  export const defaultSortProperty = CapacityListProperty.Automatic
  export const defaultSortAscending = false

  export const defaultArchiveActiveValue = ArchiveActiveFilter.Active
  export let archiveActiveValue = defaultArchiveActiveValue
  export let sortProperty = defaultSortProperty
  export let sortAscending = defaultSortAscending
  export let agreementId = null // used when showing on the agreement form
  export let serviceId = null // used when showing on the service form
  export let baseHref = null // used when showing in a modal
  export let onBeforeSave = _.noop

  export let currentPage = 1
  export let pageSize = 10
  export let loading = false
  export let capacities = null
  export let totalCount = null
  export let filters = []

  let currentXhr = null
  let currentXhrBody = null
  let saving = false

  const loadDebounced = _.debounce(load, 200)
  const location = getLocation()
  const opportunitiesHref = '/opportunities'

  // Set by <StaffRoleGridModal> the first time it's opened, then cached here via two-way binding
  // so if the user opens another staff modal, we won't have to make another request
  // TODO: discuss this--this is weird and we should just rely on our client-side caching instead. otherwise it likely makes sense to get fresh data in case it has changed.
  let orgs = null
  let teams = null

  $: showAgreement = agreementId == null
  $: showService = serviceId == null
  $: isAgreementView = agreementId != null
  $: isServiceView = serviceId != null

  $: _baseHrefOpportunities = baseHref == null ? opportunitiesHref : baseHref + opportunitiesHref
  // reload when persona, sort, page, or filters change
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersTeamId = $personaFilters.teamId

  $: filterTypes = buildFilterTypes()
  $: ignoredFilterTypes = buildIgnoredFilterTypes()
  $: filterEncoder = new FilterEncoder(filterTypes, ignoredFilterTypes)
  $: filterDecoder = new FilterDecoder(filterTypes, ignoredFilterTypes)
  $: commonShareArgs = {
    filterDecoder,
    sortOptions,
    defaultSortProperty,
    defaultSortAscending,
    defaultArchiveActiveValue,
  }
  $: shareHref = buildShareHref({
    baseHref: _baseHrefOpportunities,
    filterSets: [{ filters, filterEncoder }],
    sortProperty,
    sortAscending,
    archiveActiveValue,
    ...commonShareArgs,
  })
  $: locationOrigin = $location.origin
  $: locationOrigin, loadInitial()
  $: if (showService && showAgreement && !hadModalOpen) navigate(shareHref, { replace: true })

  $: personaFiltersOrgId, personaFiltersTeamId, sortProperty, sortAscending, currentPage, loadDebounced()
  $: personaFiltersOrgId, personaFiltersTeamId, resetOrgsAndTeams()

  $: hiddenAgreementIdFilter = !isAgreementView
    ? null
    : {
        config: { agreementIds: [agreementId], exclude: false },
        type: FilterType.Agreements,
      }

  $: hiddenServiceIdFilter = !isServiceView
    ? null
    : {
        config: { serviceIds: [serviceId], exclude: false },
        type: FilterType.Services,
      }

  $: hiddenArchiveActiveFilters = buildOpportunityArchiveActiveFilter(archiveActiveValue)

  $: hiddenFilters = [hiddenAgreementIdFilter, hiddenServiceIdFilter, ...hiddenArchiveActiveFilters].filter(_.identity)
  $: excludedFilterTypes = [isServiceView ? FilterType.Services : null, isAgreementView ? FilterType.Agreements : null].filter(_.identity)

  $: opportunitiesComponentArgs = {
    agreementId,
    serviceId,
    baseHref,
    archiveActiveValue,
    defaultArchiveActiveValue,
    sortProperty,
    defaultSortProperty,
    sortAscending,
    defaultSortAscending,
    pageSize,
    loading,
    capacities,
    totalCount,
    filters,
    hiddenArchiveActiveFilters,
    hiddenAgreementIdFilter,
    hiddenServiceIdFilter,
    hiddenFilters,
    excludedFilterTypes,
    saveCapacity,
    exportExcel: () => exportExcel,
    reload: () => loadDebounced(),
  }

  function resetOrgsAndTeams() {
    orgs = null
    teams = null
  }

  let hadModalOpen = $location.pathname.includes(`${opportunitiesHref}/`)
  function loadInitial() {
    const { pathname } = $location
    if (!pathname.includes(opportunitiesHref)) return
    if (pathname.includes(`${opportunitiesHref}/`)) {
      hadModalOpen = true
      return
    }
    if (hadModalOpen) {
      hadModalOpen = false
      return
    }
    const parsed = parseShareHref($location, commonShareArgs)
    if (!parsed) {
      // Reset state in case they came in with a query string and then clicked the nav.
      sortProperty = defaultSortProperty
      sortAscending = defaultSortAscending
      filters = []
      archiveActiveValue = defaultArchiveActiveValue
      return loadDebounced()
    }
    sortProperty = parsed.sortProperty
    sortAscending = parsed.sortAscending
    filters = parsed.filters ?? []
    archiveActiveValue = parsed.archiveActiveValue
    loadDebounced()
  }

  async function load() {
    loading = true
    let thisXhr = null
    try {
      thisXhr = getGridData()
      const response = await thisXhr
      if (thisXhr === currentXhr) {
        totalCount = response.totalCount
        capacities = response.capacities
      }
    } finally {
      if (thisXhr === currentXhr) {
        loading = false
        currentXhr = null
        currentXhrBody = null
      }
    }
  }
  const listBodyIncludes = {
    includeOrgAndTeam: true,
    includeOrgAncestorIds: true,
    includeAddresses: true,
    includeDateExceptions: true,
    includeLocations: true,
    includeServices: true,
    includeGuests: true,
    includeTags: true,
    includeShifts: true,
    includeStaff: true,
    includeStatusCounts: true,
    includeAgreements: true,
  }

  function buildGetDataBodyBase() {
    return {
      filters: _.cloneDeep(filters.concat(hiddenFilters)),
      sortProperty,
      sortAscending,
      ...listBodyIncludes,
    }
  }

  function getGridData() {
    const body = {
      ...buildGetDataBodyBase(),
      pageSize,
      offset: pageSize * (currentPage - 1),
    }

    if (currentXhr && validator.equals(currentXhrBody, body)) {
      return currentXhr
    }

    currentXhr = api.capacity.list(body, api.noMonitor)
    currentXhrBody = body
    return currentXhr
  }

  async function saveCapacity(capacity, successMessage) {
    saving = true
    const editing = capacity.capacityId > 0
    try {
      await onBeforeSave()
      // TODO: Consider changing this logic to pluck the fields we want to send instead of sending everything but the fields we don't want to send.
      // We need to clone deep here, otherwise the UI will momentarily show undefined because the original object loses guest details
      const body = _.cloneDeep(capacity)
      delete body.locations
      delete body.orgName
      delete body.orgRelativeName
      delete body.orgRelativePath
      delete body.orgProfilePicture
      delete body.orgAddress
      delete body.orgAncestorIds
      delete body.rotationCount
      delete body.service
      delete body.statusCounts
      delete body.dateCreated
      delete body.dateModified
      delete body.hasStarted
      delete body.isAvailable
      delete body.hasEnded
      body.staff = body.staff.map(({ userId, staffOrgId, titles }) => ({ userId, staffOrgId, titles }))

      for (const guest of body.guests) {
        if (guest.guestOrgId === 0) guest.guestOrgId = null
        delete guest.agreement
        delete guest.guestOrgAddress
        delete guest.guestOrgName
        delete guest.guestOrgProfilePicture
        delete guest.guestOrgRelativeName
        delete guest.guestOrgRelativePath
      }

      for (const shift of body.shifts) {
        shift.staff = shift.staff?.map(({ userId, staffOrgId }) => ({ userId, staffOrgId }))
        shift.guests = shift.guests?.map(({ guestOrgId }) => guestOrgId)
      }

      const request = editing ? api.capacity.update({ capacityId: capacity.capacityId }, body, api.noMonitor) : api.capacity.add(body, api.noMonitor)
      await request
      toaster.toast({ message: successMessage, type: 'success', icon: 'check' })
    } finally {
      saving = false
    }
  }

  function exportExcel() {
    const body = buildGetDataBodyBase()
    return api.capacity.export(body, api.noMonitor)
  }
</script>
