<div
  use:tip={tooltip}
  class={detailsView ? 'details-view mr2' : ''}
  style={detailsView ? (isOpen ? 'background-color: #daf3d8' : 'background-color: #eee') : ''}
>
  {#if detailsView}
    <div class="flex text-center" style={isOpen ? 'color: #034f05;' : 'color: #000;'}>
      <div>
        <strong>
          {#if isOpen}
            Status: Visible
          {:else}
            Status: Draft
          {/if}
        </strong>
      </div>
      <div>
        <strong>
          {#if openForScheduling}
            Open for scheduling
          {:else}
            Not visible
          {/if}
        </strong>
      </div>
      {#if openForScheduling}
        <span style="color: #034f05;">
          <Icon name="calendar-check" x2 />
        </span>
      {:else}
        <span style="color: #000;">
          <Icon name="hide" x2 />
        </span>
      {/if}
    </div>
  {:else if isSchool || isHealth}
    {#if isFull}
      <Icon name="calendar-check" lg class="text-success" />
      <div class={textClass}>Full</div>
    {:else if isUpcoming}
      <Icon name="calendar" lg class="text-warning" />
      <div class={textClass}>Upcoming</div>
    {:else if isEnded}
      <div use:tip={'Opportunity window ended'}>
        <Icon name="calendar-xmark" lg class="text-danger" />
        <div class={textClass}>Ended</div>
      </div>
    {:else if isOpen}
      <div use:tip={'Open for scheduling'}>
        <Icon name="calendar" lg class="color-text-light-green" />
        <div class={textClass} data-test="status-open">Open</div>
      </div>
    {:else if isDraft}
      <div use:tip={'Draft'}>
        <Icon name="edit" lg class="color-text-steel" />
        <div class={textClass} data-test="status-draft">Draft</div>
      </div>
    {/if}
    <!-- this implementation needs rework later
    {#if isActionNeeded}
    <div use:tip={'[Short explanation of what needs action]'}>
      <Icon name="triangle-exclamation" lg class="text-warning" />
      <div class={textClass}>
        Action<br />needed
      </div>
    </div>
  {/if} -->
  {/if}
</div>

<script>
  import { PersonaType } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import persona from 'stores/persona'

  export let tooltipElem

  export let capacity
  export let detailsView = false

  const textClass = 'initialism'

  $: openForScheduling = capacity.openForScheduling
  $: hasStarted = capacity.hasStarted
  $: hasEnded = capacity.hasEnded
  //$: totalRotationsFilled = capacity.rotationCount
  //$: totalMaxRotations = _.sumBy(capacity.guests, g => g.maxMatches || 0) || Infinity
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: isSchool = $persona.personaType === PersonaType.SchoolStaff
  $: tooltip = tooltipElem ? { content: tooltipElem, options: { interactive: false } } : null

  // Status is...
  $: isOpen = openForScheduling
  $: isUpcoming = openForScheduling && !hasStarted && !hasEnded
  $: isEnded = hasEnded
  $: isFull = false // TODO(nursing): Implement this correctly
  // From a school perspective, the capacity is full if their org's rotation count for specific statuses meets/exceeds their
  // participant's max rotations or if the students across those rotations meets/exceeds their participant's max students.
  // From a health system perspective, the capacity is full if every participant would also be seeing that it's full.
  $: isDraft = !openForScheduling
</script>

<style>
  .details-view {
    margin: 0 0 0 12px;
    display: flex;
    height: 96px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
</style>
