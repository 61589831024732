{#if stepSubmission.canCompleteNow}
  <div class="step-file-complete">
    <FileUploadWithUserDocs
      acceptableFileTypes={step.acceptableFileTypes}
      {adding}
      {onUploadFile}
      {onUploadDocument}
      fileUploadId={stepSubmission.submissionKey}
      files={stepSubmission.stepSubmissionFiles}
    />
    {#if collectExpirationSuggestion}
      <StepSubmissionSuggestedExpirationDateModal bind:stepSubmission onSubmit={action} />
    {/if}
  </div>
{/if}

{#if stepSubmission.canDownloadData}
  <FileUploadItems
    bind:downloadTextLinkComponent
    canDelete={stepSubmission.canCompleteNow}
    files={stepSubmission.stepSubmissionFiles}
    {deleting}
    {onDeleteFile}
    {onActionAndCloseModal}
    {matchModalMatchId}
    {startDateOverride}
    {endDateOverride}
  >
    <svelte:fragment slot="file-viewer-footer">
      {#if stepSubmission}
        <div>
          <StepActionsCoordinator
            {stepSubmission}
            {matchModalMatchId}
            {startDateOverride}
            {endDateOverride}
            onAction={onActionAndCloseModal}
            btnSm={false}
          />
          {#if stepSubmission.verificationExpirationDate}
            <HelpBlock><StepVerificationExpiration verificationExpirationDate={stepSubmission.verificationExpirationDate} /></HelpBlock>
          {/if}
        </div>
      {/if}
    </svelte:fragment>
  </FileUploadItems>
{/if}

<script>
  import { StepAction } from 'config/enums.js'
  import api from 'services/api.js'
  import toaster from 'services/toaster.js'
  import StepSubmissionSuggestedExpirationDateModal from 'components/StepSubmissionSuggestedExpirationDateModal.svelte'
  import { shouldCollectVerificationExpirationSuggestion } from 'components/StepSubmissionSuggestedExpirationDate.svelte'
  import FileUploadWithUserDocs from 'components/fields/FileUploadWithUserDocs.svelte'
  import FileUploadItems from 'components/fields/FileUploadItems.svelte'
  import StepActionsCoordinator from 'components/StepActions.Coordinator.svelte'
  import StepVerificationExpiration from 'components/StepVerificationExpiration.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let matchModalMatchId
  export let isInsideStepForm = false

  const deleting = {}
  let files = null
  let adding = false
  let downloadTextLinkComponent
  let collectExpirationSuggestion = false
  let action = null

  $: step = stepSubmission.step

  async function onActionAndCloseModal(...args) {
    await onAction(...args)
    downloadTextLinkComponent?.closePreview()
  }

  async function onUploadFile(e) {
    const formData = e.data
    if (formData == null) return
    action = async () => {
      adding = true
      try {
        files = await api.step.addUserFiles(formData, api.noMonitor)
        await onAction({
          type: StepAction.AddFile,
          invoke: uploadStepFile,
          reset,
        })
        collectExpirationSuggestion = false
      } finally {
        adding = false
      }
    }
    if (!isInsideStepForm && shouldCollectVerificationExpirationSuggestion(stepSubmission)) collectExpirationSuggestion = true
    else action()
  }

  async function onUploadDocument(e) {
    const { userDocumentId } = e
    if (userDocumentId == null) return
    action = async () => {
      adding = true
      try {
        const file = await api.step.addUserDocument(userDocumentId, api.noMonitor)
        files = [file]
        await onAction({
          type: StepAction.AddFile,
          invoke: uploadStepFile,
          reset,
        })
        collectExpirationSuggestion = false
      } finally {
        adding = false
      }
    }
    if (!isInsideStepForm && shouldCollectVerificationExpirationSuggestion(stepSubmission)) collectExpirationSuggestion = true
    else action()
  }

  async function onDeleteFile(stepSubmissionFile) {
    deleting[stepSubmissionFile.url] = true
    files = [stepSubmissionFile]
    try {
      await onAction({
        type: StepAction.DeleteFile,
        invoke: deleteStepFile,
        reset,
      })
    } finally {
      deleting[stepSubmissionFile.url] = false
    }
  }

  async function uploadStepFile(stepSubmissions) {
    const updatedMatch = await api.step.addFilesToMatchSteps(
      {
        endDateOverride,
        startDateOverride,
      },
      {
        stepSubmissions,
        files,
        verificationExpirationDateSuggestion: stepSubmission.verificationExpirationDateSuggestion,
      },
      api.noMonitor
    )
    const message = `File successfully uploaded${affectsNMatchesMsg(stepSubmissions)}`
    toaster.toast({ message, type: 'success', icon: 'check' })
    return updatedMatch
  }

  async function deleteStepFile(stepSubmissions) {
    const updatedMatch = await api.step.deleteStepSubmissionFiles({ matchId: stepSubmission.matchId }, { stepSubmissions, files }, api.noMonitor)
    const message = `File removed${affectsNMatchesMsg(stepSubmissions)}`
    toaster.toast({ message, type: 'success', icon: 'check' })
    return updatedMatch
  }

  function affectsNMatchesMsg(stepSubmissions) {
    return stepSubmissions.length === 1 ? '' : `. Affects ${stepSubmissions.length} submissions`
  }

  function reset() {
    files = null
  }
</script>

<style>
  .step-file-complete {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media only screen and (max-width: 700px) {
    .step-file-complete {
      align-items: flex-start;
    }
  }
</style>
