// import { formatRelativeName, getParentNames } from 'services/orgs-service.js'
// import { PersonaType } from 'config/enums.js'

import { isDevEnvironment } from './environment.js'

class PendoProxy {
  constructor() {
    this.isDevEnvironment = isDevEnvironment
    this.isInitialized = false
  }

  isAvailable() {
    return window.pendo != null
  }

  update() {
    if (!this.isAvailable() || this.isDevEnvironment) return
    if (!this.isInitialized) {
      window.pendo.initialize(window.pendoSettings)
      this.isInitialized = true
    } else {
      window.pendo.updateOptions(window.pendoSettings)
    }
  }

  setUser(user) {
    if (user == null) return

    window.pendoSettings = {
      visitor: {
        // pendo pre-defined keys
        id: user.userId,
        email: user.email,
        full_name: user.name,
        // role: not sure what to use here

        // CN custom keys:
        // From Pendo docs: please use Strings, Numbers, or Bools for value types
        is_student: user.isStudent,
        discipline: user.discipline ?? (user.otherDiscipline != null ? `${user.otherDiscipline} (not in system)` : null),
        ...(user.isStudent
          ? {
              student_year: user.studentYear,
              student_graduation_date: user.studentGraduationDate,
            }
          : {}),

        is_preceptor: user.isPreceptor,
        is_health_coordinator: user.isHealthCoordinator,
        is_health_admin: user.isHealthAdmin,
        is_faculty: user.isFaculty,
        is_school_coordinator: user.isSchoolCoordinator,
        is_school_admin: user.isSchoolAdmin,

        // companies:
        //   user?.personas?.length > 0
        //     ? user.personas.map(p => ({
        //         company_id: p.orgId,
        //         name: p.label,
        //         // TODO(orgfeatures-2) when persona types are merged, is_health, is_school, and is_consortium should be changed to:
        //         // p.subscriptionFeatureTypes.includes(FeatureType.<something>)
        //         is_health: p.personaType === PersonaType.ProviderStaff,
        //         is_school: p.personaType === PersonaType.SchoolStaff,
        //         is_consortium: p.personaType === PersonaType.ConsortiumStaff,
        //         subscriptionStatus: p.subscriptionStatus,
        //         // TODO: include their feature package name instead maybe...
        //         // subscriptionFeatures: p.subscriptionFeatureTypes?.map(ft => invertedFeatureType[ft]).join(', ') ?? null, // don't set this--get an error from intercom: STATUS 422 "custom_attributes invalid, length too long: subscriptionFeatures"
        //         relativeName: formatRelativeName(p.relativeName),
        //         rootOrgName: getParentNames(p.relativeName)?.[0],
        //       }))
        //     : null,
      },

      // account: {
      //     id:              <account-id-goes-here> // Highly recommended, required if using Pendo Feedback
      //     // name:         // Optional
      //     // is_paying:    // Recommended if using Pendo Feedback
      //     // monthly_value:// Recommended if using Pendo Feedback
      //     // planLevel:    // Optional
      //     // planPrice:    // Optional
      //     // creationDate: // Optional

      //     // You can add any additional account level key-values here,
      //     // as long as it's not one of the above reserved names.
      // }
    }

    if (!user.isStudent) {
      delete window.pendoSettings.student_year
      delete window.pendoSettings.student_graduation_date
    }

    this.update()
  }
}

export default new PendoProxy()
