import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { appWasUpdated } from 'services/app-updates.js'
import { detect } from 'detect-browser'
import personaStore from 'stores/persona.js'
import userStore from 'stores/user.js'

let lastStack = null

let $user
userStore.subscribe(u => ($user = u))

let $persona
personaStore.subscribe(p => ($persona = p))

const { userAgent } = navigator
const detected = detect()
detected.isCypress = !!window.Cypress
const browser = JSON.stringify(detected)
let appInsights = null
let instrumentationKey = null

// To use source maps, see here: https://github.com/microsoft/ApplicationInsights-JS/blob/master/README.md#source-map-support
// TODO: We can also set up an Azure Blob storage place to put our source maps. For now, we just use our `npm run unminify` node script which requires source maps to be generated locally.
export async function handleErrors() {
  const data = await window.initialPromise
  instrumentationKey = data.instrumentationKey
  preventDefaultIfIgnoredError()
  if (shouldLogToAppInsights()) handleErrorsAppInsights()
  else handleErrorsConsole()
}

function shouldLogToAppInsights() {
  // someone saved our app and then opened the html file in a browser and got a bunch of errors about trying to fetch cross domain lol, so just don't load app insights if we're not loading the app over http (as opposed to file://)
  return instrumentationKey && window.location.toString().startsWith('http')
}

function preventDefaultIfIgnoredError() {
  // e is an `ErrorEvent` instance: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
  window.addEventListener('error', e => ignoreIfBenign(e, e))
  window.onerror = function (message, source, lineno, colno, error) {
    if (isErrorBenign(error)) return true // for window.onerror--"When the function returns true, this prevents the firing of the default event handler." https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
  }

  // `e.reason` is an `Error` instance for unhandledrejection event objects: https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
  window.addEventListener('unhandledrejection', e => {
    lastStack = e?.reason?.stack
    ignoreIfBenign(e, e.reason)
  })
}

function ignoreIfBenign(event, error) {
  if (isErrorBenign(error)) {
    event.preventDefault()
    event.stopImmediatePropagation()
  }
}

export function isErrorBenign(e) {
  const errorMsg = (e?.message || e || '').toString()

  // server errors from our api will be logged, so no need to log an unhandled promise rejection error
  // note we need it after the calling code has had a chance to `.catch` it (a few places catch errors from our `api` methods and do some custom handling)
  const ignoreStatuses = [400, 401, 403, 404, 500]
  if (ignoreStatuses.includes(e?.response?.status)) return true

  // if resize observer limit error, we can safely ignore. see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  // "This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign (your site will not break)"
  if (/resizeobserver/i.test(errorMsg)) return true

  // if chunk load failed, user is probably on old version of application, so tell them to reload the page
  if (
    errorMsg != null &&
    (receivedIndexHtmlWhileTryingToGetJsOrCssFile(e) || /Loading chunk \d+ failed/i.test(errorMsg) || /Loading CSS chunk \d+ failed/i.test(errorMsg))
  ) {
    // we also send a websocket on connect/re-connect which should handle most scenarios.
    // But it's possible that the re-connect could fail if they lose internet connection
    // for too long and signalr gives up trying to reconnect
    appWasUpdated()
    return true
  }

  // ignore abort errors
  // for instance, if user navs away from kanban while it's loading, we send abort signal to server to cancel the query
  const abortErrorFuzzyMessages = [
    'user aborted a request',
    'aborterror',
    'cancelled',
    'canceled',
    'cancelado',
    'failed to fetch',
    'networkerror when attempting to fetch resource.',
    'operation was aborted', // ff and safari use this one
    'fetch is aborted',
  ]
  if (abortErrorFuzzyMessages.some(m => errorMsg.toLowerCase().includes(m))) return true

  // Annoying non-descriptive error coming from Azure Insights error handling client-side code.
  // Not sure what the underlying error is, but just want it to shut up for now.
  // Later on, we should reach out to Azure Insights again and track down where in their code this happens.
  if (/Object Not Found Matching Id:\d+/i.test(errorMsg)) return true

  // This error provides no additional info. Nothing we can really do.
  // Let's only ignore it if there's no leading/trailing text though -- just in case something comes through in the future.
  // Also, due to the PromiseRejectionEvent prefix, this'll only ever be ignored via the telemetry initializer, never the window event listeners.
  // If we ever get stacktraces available again for promise rejections, it may be useful to remove this.
  if (/^\s*PromiseRejectionEvent: \[object Event\]\s*$/i.test(errorMsg)) return true

  return false
}

function receivedIndexHtmlWhileTryingToGetJsOrCssFile(e) {
  const endsInJs = /\.(js|css)$/
  return (
    e && (endsInJs.test(e.filename) || endsInJs.test(e.errorSrc) || endsInJs.test(e.url)) && /Uncaught SyntaxError: Unexpected token/.test(e.message)
  )
}

function handleErrorsAppInsights() {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      enableUnhandledPromiseRejectionTracking: true,
      // We only want to log errors for debugging.
      disableAjaxTracking: true,
      disableFetchTracking: true,
    },
  })

  appInsights.loadAppInsights()

  appInsights.addTelemetryInitializer(item => {
    const customDimensions = buildCustomDimensions(true)
    const props = (item.baseData.properties = item.baseData.properties || {})
    for (const key in customDimensions) props[key] = customDimensions[key]
    const error = item.data
    props.CN_Stack = error?.stack ?? lastStack // remove once this issue is resolved: https://github.com/microsoft/ApplicationInsights-JS/issues/1675
    if (isErrorBenign(error)) return false // Don't track item
  })
}

function handleErrorsConsole() {
  window.addEventListener('error', e => {
    // eslint-disable-next-line no-console
    console.log('App Insights not configured. Unhandled error:', buildLog(e, e.error))
  })
  window.addEventListener('unhandledrejection', e => {
    // eslint-disable-next-line no-console
    console.log('App Insights not configured. Unhandled promise rejection:', buildLog(e, e.reason))
  })
}

function buildLog(event, error) {
  error = error instanceof Error ? error : new Error(typeof error === 'string' ? error : JSON.stringify(error))
  const summary = event.message
    ? { message: event.message, filename: event.filename, lineNumber: event.lineno, columnNumber: event.colno }
    : buildSummary(error)
  return {
    isServerFailure: error?.status != null,
    dateTime: new Date().toString(),
    ...summary,
    error,
    customDimensions: buildCustomDimensions(),
  }
}

function buildSummary(error) {
  // Works for Firefox
  if (error.fileName)
    return {
      filename: error.fileName,
      message: error.message,
      lineNumber: error.lineNumber,
      columnNumber: error.columnNumber,
    }
  // Works for Chrome, Edge Chromium, IE11; not Firefox
  return parseStack(error?.stack || '')
}

function buildCustomDimensions(shouldStringify = false) {
  // window.location is stored in item.tags['ai.operation.name'] and/or item.data.url
  const alwaysDimensions = {
    CN_IsLoggedIn: !!$user,
    CN_UserAgent: userAgent,
    CN_Browser: browser,
    CN_Screen: shouldStringify ? JSON.stringify(getScreen()) : getScreen(),
    CN_Location: window.location.toString(), // Azure doesn't seem to get this sometimes for some odd reason
  }
  if (!$user) return alwaysDimensions
  const isImpersonating = $user.isImpersonating ? 'Yes' : 'No'
  const orgId = $persona?.orgId
  const personaTypeName = $persona?.personaTypeName
  return {
    ...alwaysDimensions,
    CN_IsImpersonating: isImpersonating,
    CN_UserId: $user?.userId,
    CN_Username: $user?.userName,
    CN_OrgId: orgId,
    CN_PersonaType: personaTypeName,
  }
}

const stackTraceRegex =
  /^(?<message>[^\r\n]+)[\r\n]{1,2}\s*at\s.*?\(?(?<filename>https?:\/\/[^/]+\/[^:]+|webpack:\/\/[^:]+?|eval code)\??:(?<lineNumber>\d+):?(?<columnNumber>\d+)/
function parseStack(stack) {
  const result = stackTraceRegex.exec(stack)
  if (!result) return {}
  const { groups } = result
  return {
    message: groups.message,
    filename: groups.filename,
    lineNumber: Number.parseInt(groups.lineNumber),
    columnNumber: Number.parseInt(groups.columnNumber),
  }
}

function getScreen() {
  const { availWidth, availHeight, width, height } = window.screen
  const { angle, type } = window.screen.orientation || { angle: 0, type: 'unknown' }
  return { availWidth, availHeight, width, height, angle, orientation: type, zoom: window.devicePixelRatio }
}

const WillReject = message =>
  new Promise((resolve, reject) => {
    if (window.doesNotExist) resolve()
    else reject(new Error(message))
  })

export const WillRejectUnhandled = message => {
  const willReject = WillReject(message)
  willReject.then(_.noop)
  // Intentionally do not chain; see https://stackoverflow.com/a/41180264/221867
  willReject.catch(_.noop)
}

export function throwError() {
  throw new Error(window.__throwError.message)
}

export function flushAppInsights() {
  appInsights.flush()
}

// when you don't want to throw because it'll break the entire page, but you still want to know something went wrong
export function trackErrorWithoutThrow(exception) {
  appInsights?.trackException({ exception })
  // eslint-disable-next-line no-console
  console.error(exception)
}
