{#if showOrgFilteredWarning || showTeamFilteredWarning}
  <Alert type="warning" dataTest="persona-filter-warning" class="mb1">
    <div class="flex-row">
      <div class="flex-grow">
        This view is filtered to
        {#if showOrgFilteredWarning}the location <strong data-test="persona-filter-warning-location">{$personaFilters.org?.name}</strong
          >{#if showTeamFilteredWarning}{and}{/if}{/if}{#if showTeamFilteredWarning}
          the team <strong data-test="persona-filter-warning-team">{$personaFilters.team.name}</strong>{/if}.
      </div>
      <a href="/" on:click|preventDefault={() => resetPersonaFilters()} data-test="persona-filter-warning-show-all">Show all</a>
    </div>
  </Alert>
{/if}

<script>
  import personaFilters from 'stores/persona-filters.js'
  import persona from 'stores/persona.js'
  import Alert from './bootstrap/Alert.svelte'
  import { PersonaType } from 'config/enums.js'

  export let warnForTeamFilter = true

  // Svelte doesn't want to acknowledge the whitespace if this word is inlined in the template.
  const and = ' and '

  $: isCN = $persona.personaType === PersonaType.CN
  $: showOrgFilteredWarning = !isCN && $personaFilters.orgId != $persona.orgId && $personaFilters.org?.name
  $: showTeamFilteredWarning = warnForTeamFilter && !isCN && $personaFilters.teamId != null && $personaFilters.team?.name

  function resetPersonaFilters() {
    $personaFilters.orgId = $persona.orgId
    $personaFilters.teamId = null
  }
</script>
