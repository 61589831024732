export default function getValueFromCollectionBySubpath(collection, subpathOrSubpaths, keyOrKeys, newFn, copyFn, loadAndSetAsyncFn) {
  const subpaths = Array.isArray(subpathOrSubpaths) ? subpathOrSubpaths : [subpathOrSubpaths]
  const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys]

  if (!subpaths[0]) return
  if (subpaths[0] === '+') return newFn()
  if (!collection) return

  let hasNewKey = false
  let shouldCopy = false
  const keyValues = []
  for (let i = 0; i < subpaths.length; i++) {
    const regex = i === 0 ? /^(?:(?<plus>\+?)|(?<new>-?))(?<key>\d*)$/ : /^(?<new>-?)(?<key>\d*)$/
    const match = subpaths[i].match(regex)
    if (!match) return
    shouldCopy = shouldCopy || match.groups.plus
    const isNewKey = !!match.groups.new
    hasNewKey = hasNewKey || isNewKey
    const positiveKeyValue = parseInt(match.groups.key)
    const keyValue = isNewKey ? -positiveKeyValue : positiveKeyValue
    keyValues.push(keyValue)
    collection = collection.filter(o => o[keys[i]] === keyValue)
  }

  const found = collection[0]

  if (found) return shouldCopy ? copyFn(found) : found
  // Could throw an error if it's temporary and not found. But, eh.
  if (!hasNewKey && loadAndSetAsyncFn) {
    loadAndSetAsyncFn(keyValues.length === 1 ? keyValues[0] : keyValues, shouldCopy)
  }
  return null
}
