<UnauthorizedLayout backHref="/login" backText="Back to log in">
  {#if emailSent}
    <h2>Success!</h2>
    <p class="success-message">If we have an account for {email} you should receive instructions for resetting your password soon.</p>
    <Btn href="/login" class="btn-block btn-xl btn-primary btn-low-contrast-white">Return to login</Btn>
  {:else}
    <h2>Forgot password</h2>
    <p>Please enter the email address associated with your account and we’ll send an email with instructions to reset your password.</p>
    <Form on:submit={forgotPassword}>
      <FormGroup valid={validator.email(email)} class="testing-fg">
        <label for="email" class="block text-left">Email</label>

        <InputText bind:value={email} class="form-control" autofocus placeholder="your-name@example.com" name="email" />
      </FormGroup>
      <FormGroup>
        <Btn
          type="submit"
          class="btn-block btn-login btn-{!sending ? 'primary btn-low-contrast-white' : 'disabled disabled'} btn-x1"
          loading={sending}
          dataTest="btn-reset-pw"
        >
          {sending ? 'Sending…' : 'Reset password'}
        </Btn>
      </FormGroup>
    </Form>
  {/if}
</UnauthorizedLayout>

<script>
  import api from 'services/api.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import validator from 'services/validator.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import UnauthorizedLayout from 'components/UnauthorizedLayout.svelte'
  import { savedEmail } from 'services/sso-service.js'

  let email = $savedEmail
  let emailSent = false
  let sending = false

  async function forgotPassword() {
    sending = true
    try {
      await api.account.forgotPassword({ email })
      // we don't care if it actually sent or not.. don't want bots/etc to know whether emails exist in our system or not
      emailSent = true
    } finally {
      sending = false
    }
  }
</script>

<style lang="scss">
  h2 {
    font-size: 24px;
    line-height: 28px;
    margin: 0 auto 10px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 40px;
  }

  .success-message {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 70px !important;
  }
</style>
