{#if capacity.staff?.length}
  <div class="flex-column g1">
    <h3 class="mb05">Currently added staff</h3>
    <span>Staff added again will get any new titles assigned to them (no titles will be removed).</span>
    <span class="small mt05">{capacity.staff.length} staff</span>
    <div class="flex-row g05">
      {#each capacity.staff as staff}
        <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} small slottedTipOptions={{ maxWidth: 350, placement: 'bottom' }}>
          <div slot="tooltip" class="flex-row flex-align-center g1 text-left">
            <div><ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} small /></div>
            <div class="flex-column">
              <div>
                <strong class="strongish">{staff.name}</strong>
                {#if staff.capacityAccessibilityTooltip}
                  <Icon lg name="hide" class="text-danger ml05" title={staff.capacityAccessibilityTooltip} />
                {:else}
                  <Icon lg name="eye" class="text-success ml05" />
                {/if}
              </div>
              {#if staff.orgTitle}
                <span>{staff.orgTitle}</span>
              {/if}
              {#if staff.externalId}
                <span>{staff.externalId}</span>
              {/if}
              {#if staff.email}
                <IconTextLink icon="email" text={staff.email} href="mailto:{staff.email}" />
              {/if}
              {#if staff.titles?.length}
                <span>Added titles: {staff.titles.join(', ')}</span>
              {/if}
            </div>
          </div>
        </ProfilePic>
      {/each}
    </div>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import roleService from 'services/role-service'

  export let capacity

  $: capacity, setViewRestrictionMessages()

  function setViewRestrictionMessages() {
    capacity.staff?.forEach(staff => {
      staff.capacityAccessibilityTooltip = roleService.getCapacityAccessibilityTooltip(capacity, staff.orgStaffRoles)
    })
  }
</script>
