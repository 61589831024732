<!-- Changing something in here? Consider making a similar change to CapacityShiftsView. -->
<div>
  <div class="flex-row flex-align-center g05 mb1">
    <CapacityShiftsFormCalendarActionsDropdown bind:capacity {selectedDays} {addShift} {clearSelectedDays} />

    <Btn
      dataTest="clear-selections"
      class="btn btn-sm"
      icon="close"
      color="outline-danger"
      disabled={!selectedDaysArray.length}
      on:click={clearSelectedDays}>Clear selection</Btn
    >
    <Btn
      class="btn btn-sm btn-outline-primary {useCalendarFilters ? 'btn-lightprimary' : 'btn-default'}"
      on:click={() => (useCalendarFilters = !useCalendarFilters)}
    >
      <Icon name="filter" class="color-text-teal" />
      {#if filters?.length}
        <Badge label={filters?.length} sm class="text-light" color="primary" />
      {/if}
    </Btn>

    <BtnGroupPicker
      {options}
      bind:value={showList}
      btnClass="btn-sm btn-outline-lightgray"
      btnActiveClass="btn-default"
      btnInactiveClass="btn-outline-lightgray"
      name="shift-view-picker"
    />
    {#if !showList}
      <CalendarControls newView={true} bind:start bind:end />
    {/if}
  </div>
  {#if useCalendarFilters}
    <div class="flex-row flex-align-center g05 mb1">
      <CapacityAvailabilityFilters bind:filters onChanged={updateFilteredShifts} />
    </div>
  {/if}
  <Tip>{dragSelectTip}</Tip>
</div>

{#if showList}
  <ShiftGrid {capacity} {orgs} onClickShift={editShift} let:row selectable={false}>
    <GridCol {row} class="shrink">
      <a href={null} on:click={() => editShift(row)} data-test="edit-shift">
        <Icon name="edit" title="Edit shift" />
      </a>
    </GridCol>
  </ShiftGrid>
  <IconTextLink text="Add availability window" onClick={addShift} />
{:else}
  <Calendar
    startDate={start}
    endDate={end}
    let:day
    min={capacity.startDate}
    max={capacity.endDate}
    bind:selected={selectedDays}
    {dayClasses}
    dateSelector={date => formattedDateExceptions.includes(date)}
  >
    <div slot="icons">
      {@const dayShifts = shiftsByDate[day.formatted] ?? []}
      {@const dayShiftCount = dayShifts?.length}
      {@const anyShifts = dayShiftCount > 0}
      {@const allShifts = dayShiftCount === capacity.shifts.length}
      {@const anyInfiniteMatchCountPerDay = dayShifts.some(shift => shift.maxMatchCountPerDay === 0)}
      {@const peopleCount = anyInfiniteMatchCountPerDay ? 0 : _.sumBy(dayShifts, shift => shift.maxMatchCountPerDay)}
      <Badge label={allShifts ? 'All' : anyShifts ? dayShiftCount : 'None'} icon={anyShifts ? 'eye' : 'hide'} xs />
      <Badge label={anyInfiniteMatchCountPerDay ? '' : peopleCount} icon={anyInfiniteMatchCountPerDay ? 'infinity' : null} iconRight="users" xs />
    </div>
    {@const dayShifts = shiftsByDate[day.formatted] ?? []}
    {#if formattedDateExceptions.includes(day.formatted)}
      <div class="flex flex-column flex-align-center">Exception</div>
    {:else if !day.disabled && dayShifts?.length > 0}
      {#each dayShifts as shift}
        <div
          data-test="shift"
          class="shift color-bg-{shift.color}"
          use:tip={'Edit availability window'}
          use:onInteract={() => editShift(shift)}
          on:mousedown|stopPropagation
        >
          <div class="flex-column flex-align-center g05">
            <ShiftTimeDisplay {capacity} {shift} dayjsDay={day.day} />
            <div class="flex-grow">{shift.name}</div>
            <Badge
              label={shift.matchCountPerDay === 0 ? '' : shift.matchCountPerDay}
              icon={shift.matchCountPerDay === 0 ? 'infinity' : null}
              iconRight="users"
              xs
            />
          </div>
        </div>
      {/each}
    {/if}
  </Calendar>
{/if}

{#if editingShift}
  <Modal lg on:close={resetEditingShift}>
    <h4 slot="title">
      {#if editingShift.isNew}
        New availability window
      {:else}
        <div class="small">Availability window</div>
        {editingShift.name}
      {/if}
    </h4>
    <ShiftForm bind:shift={editingShift} bind:capacity onSubmit={onShiftSubmit} onDelete={onShiftDelete} onClose={resetEditingShift} />
  </Modal>
{/if}

<script>
  import { dragSelectTip } from 'decorators/dragnselect.js'
  import { FilterType } from 'config/enums.js'
  import { shiftOccursOnDate, datesToShiftDays, mergeShiftDays } from 'services/calendar-service.js'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from './bootstrap/Btn.svelte'
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import Calendar from 'components/Calendar.svelte'
  import CalendarControls from 'components/CalendarControls.svelte'
  import CapacityAvailabilityFilters from 'components/CapacityAvailabilityFilters.svelte'
  import CapacityShiftsFormCalendarActionsDropdown from 'components/CapacityShiftsForm.CalendarActionsDropdown.svelte'
  import colorService from 'services/color-service.js'
  import dateService from 'services/date-service.js'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import Modal from 'components/Modal.svelte'
  import onInteract from 'decorators/on-interact'
  import ShiftForm from 'components/ShiftForm.svelte'
  import ShiftGrid from 'components/ShiftGrid.svelte'
  import ShiftTimeDisplay from 'components/ShiftTimeDisplay.svelte'
  import Tip from 'components/Tip.svelte'
  import tip from 'decorators/tip.js'

  export let capacity

  const buildOption = (value, label, dataTest, title, icon) => ({ value, label, dataTest, title, icon })
  const options = [
    buildOption(false, 'Calendar', 'calendar-tab', 'View availability windows in a calendar view', 'calendar'),
    buildOption(true, 'List', 'list-tab', 'View availability windows in a simple list', 'list'),
  ]

  let start
  let end
  let editingShift
  let orgs = {}
  let selectedDays = new Set()
  let showList = false
  let shiftsByDate = {}
  let useCalendarFilters = false
  let filters = []
  let filteredShifts = []

  $: capacity, loadOrgs()

  // Variables to reduce reactivity
  $: startDate = capacity.startDate
  $: endDate = capacity.endDate
  $: shifts = capacity.shifts
  $: dateExceptions = capacity.dateExceptions

  $: startDate, endDate, setInitialStartEnd()
  $: shifts, updateFilteredShifts()
  $: filteredShifts, start, end, setShiftsByDate()
  $: selectedDaysArray = selectedDays ? Array.from(selectedDays) : []
  $: formattedDateExceptions = dateExceptions.map(de => dateService.datestamp(de))
  $: dayClasses = Object.fromEntries(formattedDateExceptions.map(date => [date, 'excluded']))

  async function loadOrgs() {
    if (!capacity) return
    const selectedOrgIds = new Set(capacity.shifts.map(sh => sh.locationId).filter(orgId => orgId != null))
    if (selectedOrgIds.size === 0) return
    const res = await api.org.list(
      {
        selectedOrgIds: [...selectedOrgIds],
        pageSize: 0,
        excludeTotalCount: true,
      },
      api.noMonitor
    )
    orgs = _.keyBy(res.selectedOrgs, o => o.orgId)
  }

  function setShiftsByDate() {
    shiftsByDate = {}
    let currentDate = dayjs(start)
    while (currentDate.isSameOrBefore(end)) {
      const formattedDate = currentDate.format('M/D/YYYY')
      shiftsByDate[formattedDate] = filteredShifts.filter(shift => shiftOccursOnDate(capacity, shift, currentDate))
      currentDate = currentDate.add(1, 'day')
    }
  }

  function setInitialStartEnd() {
    if (capacity.startDate) start = dayjs(capacity.startDate)
    else if (start == null) start = dayjs()
    start = start.startOf('month')
    end = start.endOf('month')
    resetEditingShift()
  }

  function onShiftSubmit(shift) {
    shift.isNew = false
    const index = capacity.shifts.findIndex(s => s.shiftId === shift.shiftId)
    if (index < 0) capacity.shifts.push(shift)
    else capacity.shifts[index] = shift
    capacity.shifts = capacity.shifts
    clearSelectedDays()
  }

  function onShiftDelete(shift) {
    capacity.shifts = capacity.shifts.filter(s => s.shiftId !== shift.shiftId)
  }

  function editShift(shift) {
    editingShift = shift
  }

  function resetEditingShift() {
    editingShift = null
  }

  function clearSelectedDays() {
    selectedDays = new Set()
  }

  function addShift() {
    const shiftId = Math.min(0, ...capacity.shifts.map(s => s.shiftId)) - 1
    const newShift = {
      shiftId,
      name: null,
      maxMatchCountPerDay: null,
      maxStudentsInGroup: null,
      matchDayLimitPerWeek: null,
      color: colorService.getBestAvailableColor(capacity.shifts.map(s => s.color)),
      startTime: null,
      endTime: null,
      shiftDays: mergeShiftDays([], datesToShiftDays(selectedDaysArray)), // mergeShiftDays so they get simplified. but consider making the function not require the 2nd param?
      locationId: capacity?.locationIds[0] || null, // default to first capacity location if any
      dirty: true,
      isNew: true,
    }
    editShift(newShift)
  }

  function updateFilteredShifts() {
    filteredShifts = shifts
    for (const filter of filters) {
      const { allowUnassigned, schoolOrgIds, exclude, unitsMin, unitsMax } = filter.config
      // TODO(nursing): This can't work because shift.guestOrgId isn't a thing; instead it'll be shift.guests[..].orgId
      if (filter.type === FilterType.GuestOrgs) {
        if (schoolOrgIds.length) {
          if (!exclude && allowUnassigned)
            filteredShifts = filteredShifts.filter(shift => schoolOrgIds.includes(shift.guestOrgId) || shift.guestOrgId == null)
          else if (!exclude) filteredShifts = filteredShifts.filter(shift => schoolOrgIds.includes(shift.guestOrgId))
          else if (exclude && allowUnassigned)
            filteredShifts = filteredShifts.filter(shift => !schoolOrgIds.includes(shift.guestOrgId) || shift.guestOrgId == null)
          else if (exclude) filteredShifts = filteredShifts.filter(shift => schoolOrgIds.includes(shift.guestOrgId))
        } else {
          if (allowUnassigned) filteredShifts = filteredShifts.filter(shift => shift.guestOrgId == null)
          else filteredShifts = filteredShifts.filter(shift => shift.guestOrgId != null)
        }
      }

      if (filter.type === FilterType.CapacityStudentCount) {
        if (!unitsMin && !unitsMax) {
          filteredShifts = filteredShifts.filter(shift => shift.maxMatchCountPerDay === 0)
        }
        if (unitsMin && !unitsMax) {
          filteredShifts = filteredShifts.filter(shift => shift.maxMatchCountPerDay >= unitsMin || shift.maxMatchCountPerDay === 0)
        }
        if (unitsMin && unitsMax) {
          filteredShifts = filteredShifts.filter(shift => {
            const { unitsMin, unitsMax } = filter.config
            return shift.maxMatchCountPerDay >= unitsMin && shift.maxMatchCountPerDay <= unitsMax
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  div.shift {
    margin: 2px;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: block;
    text-decoration: none;
    cursor: pointer;
  }
</style>
