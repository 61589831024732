<div class="optional-datepicker">
  <div class="radio radio-primary">
    <input {disabled} type="radio" name="{name}-no-value" id="{name}-no-value" bind:group={hasValue} value={false} on:click={valueSetToNull} />
    <label for="{name}-no-value" class={noValueLabelClass}><slot name="no-value-label">{noValueLabel}</slot></label>
  </div>
  <div class="radio radio-primary">
    <input {disabled} type="radio" name="{name}-has-value" id="{name}-has-value" bind:group={hasValue} value={true} on:click={valueSet} />
    <label for="{name}-has-value">
      <InputTime
        name="shift-times"
        bind:startValue={internalStartValue}
        bind:endValue={internalEndValue}
        on:focus={onFocusHasTimeField}
        {showTotal}
        {iconClass}
        {startPlaceholder}
        {endPlaceholder}
        {range}
        {disabled}
      >
        <svelte:fragment slot="rangeEndLabel">
          <span>to</span>
          <Icon name="time" class={iconEndClass} />
        </svelte:fragment>
      </InputTime>
    </label>
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import InputTime from 'components/fields/InputTime.svelte'

  export let noValueLabelClass = null
  export let showTotal = false
  export let defaultStartValue = null
  export let defaultEndValue = null
  export let startValue = null
  export let endValue = null
  export let name = 'optional-time-picker'
  export let noValueLabel = 'No set times'
  export let disabled = false
  export let range = false
  export let startPlaceholder = 'Start time'
  export let endPlaceholder = 'End time'
  export let iconClass = 'color-text-dark-green'
  export let iconEndClass = 'color-text-light-red'

  let internalStartValue = startValue
  let internalEndValue = endValue
  let hasValue = false

  if (startValue === null && endValue === null) {
    startValue = defaultStartValue
    endValue = defaultEndValue
  }

  function valueSetToNull() {
    internalStartValue = null
    internalEndValue = null
  }

  function valueSet() {
    hasValue = true
  }

  function onFocusHasTimeField() {
    hasValue = true
  }

  $: hasValue = internalStartValue != null || internalEndValue != null

  $: {
    if (hasValue) {
      startValue = internalStartValue
      endValue = internalEndValue
    } else {
      startValue = defaultStartValue
      endValue = defaultEndValue
    }
  }
</script>
