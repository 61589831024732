{#if show}
  <Modal on:close={onClose}>
    <h4 slot="title">
      <div class="small">Release guaranteed rotations for</div>
      {capacity.name}
    </h4>

    <Form on:submit={submitReleaseMatches}>
      <div class="modal-body">
        <CapacityConfirmOrReleaseMatchesModal
          orgName={capacity.orgName}
          confirmDesiredMatchCountByDate={capacityGuest?.confirmDesiredMatchCountByDate}
        />

        <FormGroup {valid} validationMessage="Please enter a number between 0 and {capacityGuest.guaranteedMatchCountRemaining}.">
          <p>
            Participant
            <Icon name="school" class="color-text-blue" />
            <strong>{capacityGuest.guestOrgName}</strong> has used
            <strong>{capacityGuest.matchCount} of their {capacityGuest.guaranteedMatchCountRemaining} guaranteed rotations</strong>
            on this opportunity so far. <br /> How many do would you like to release?
          </p>
          <div>
            <InputNumberWithUnit
              bind:value={releasedMatches}
              min={1}
              max={capacityGuest.guaranteedMatchCountRemaining}
              unit="rotation"
              class="input-group-inline-grid"
            />
          </div>
        </FormGroup>
      </div>

      <div class="modal-footer">
        <SubmitBtn class="btn btn-primary" dataTest="release-matches-submit-btn" loading={isSaving} stopPropagation>
          <Icon lg name="slots-release" />
          {#if valid}
            Release {pluralCount('rotation', releasedMatches)}
          {:else}
            Release rotations
          {/if}
        </SubmitBtn>
        <Btn icon="close" dataTest="release-matches-cancel-btn" on:click={onClose}>Cancel</Btn>
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import { canConfirmAndReleaseCapacityGuestMatches } from 'services/capacity-usage.js'
  import { pluralCount } from 'services/string-utils.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityConfirmOrReleaseMatchesModal from 'components/CapacityConfirmOrReleaseMatchesModal.Preamble.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InputNumberWithUnit from 'components/InputNumberWithUnit.svelte'
  import Modal from 'components/Modal.svelte'
  import SubmitBtn from './bootstrap/SubmitBtn.svelte'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'

  export let capacityId
  export let capacity
  export let capacityGuest
  export let onClose = _.noop
  export let onUpdate = _.noop

  let isSaving = false
  let releasedMatches = null

  $: if (capacityId && capacity == null) loadCapacity()
  $: show = canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest, false)

  async function loadCapacity() {
    const response = await api.capacity.list({ selectedCapacityIds: [capacityId], pageSize: 0, includeGuests: true, excludeTotalCount: true })
    capacity = response.selectedCapacities[0]
  }

  $: valid = capacity && capacityGuest && validator.intRange(releasedMatches, 0, capacityGuest.guaranteedMatchCountRemaining)

  async function submitReleaseMatches() {
    isSaving = true
    try {
      await api.capacity.releaseMatches(
        {
          capacityId: capacity.capacityId,
          guestOrgId: capacityGuest.guestOrgId,
        },
        { newGuaranteedMatches: capacityGuest.guaranteedMatchCountRemaining - releasedMatches }
      )
      toaster.toast({
        message: 'Rotations updated.',
        type: 'success',
        icon: 'check',
      })
      onUpdate()
    } finally {
      isSaving = false
    }
  }
</script>
