<div class="flex-row flex-align-center g05">
  {#if stepSubmission.canVerify && stepSubmission.completed && !stepSubmission.waived && !stepSubmission.skipped}
    <!-- precheck steps are verified via precheck -->
    {#if stepSubmission.step.stepInputType === StepInputType.PreCheck}
      {#if $persona.hasPreCheckClientAccess}
        <a
          href="{environment.linkApi}/api/precheck/link/clinic/{$persona.preCheckClientId}"
          target="_blank"
          class="btn btn-info"
          class:btn-sm={btnSm}
        >
          Review in PreCheck
        </a>
      {/if}
    {:else}
      <StepVerify {stepSubmission} {startDateOverride} {endDateOverride} {matchModalMatchId} {onAction} {btnSm} />
      <StepReject {stepSubmission} {startDateOverride} {endDateOverride} {matchModalMatchId} {onAction} {btnSm} />
    {/if}
  {/if}
  {#if canWaive}
    <StepWaive {stepSubmission} {startDateOverride} {endDateOverride} {onAction} {btnSm} />
  {/if}

  {#if canSkip && !canWaive}
    <StepSkip {stepSubmission} {startDateOverride} {endDateOverride} {onAction} {btnSm} />
  {/if}
</div>

<script>
  import { StepInputType, StepSubmissionStatus, StepVerificationType } from 'config/enums'
  import environment from 'services/environment'
  import persona from 'stores/persona'
  import StepVerify from './step-inputs/StepVerify.svelte'
  import StepReject from './step-inputs/StepReject.svelte'
  import StepWaive from './step-inputs/StepWaive.svelte'
  import StepSkip from './step-inputs/StepSkip.svelte'

  export let stepSubmission
  export let matchModalMatchId
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm

  $: canWaive = (() => {
    if (!stepSubmission.canWaive) return false
    if (isStepCompletedAndVerifiedOrNoVerificationNeeded()) return false
    return true
  })()

  $: canSkip = (() => {
    if (!stepSubmission.canSkip) return false
    if (isStepCompletedAndVerifiedOrNoVerificationNeeded()) return false
    return true
  })()

  function isStepCompletedAndVerifiedOrNoVerificationNeeded() {
    if (
      stepSubmission.completed &&
      (stepSubmission.step.verificationType === StepVerificationType.NoVerificationRequired ||
        stepSubmission.status === StepSubmissionStatus.Verified)
    )
      return true
  }
</script>
