<div class="mb2">
  <Collapsible open={true}>
    <h3 slot="label" class="mb0">
      {#if showName}
        <div class="small">Opportunity overview</div>
        {capacity.name}
      {:else}
        Opportunity information
      {/if}
    </h3>
    <div class="opportunity-information-grid">
      <div>
        <h3 class="mb2">General information</h3>
        <div class="flex-row">
          <div class="general-info-left">
            <p class="mb05"><strong>Location</strong></p>
            <div class="mb2">
              <CapacityLocations {capacity} />
            </div>
            <p class="mb05"><strong>Service & experience type</strong></p>

            <div class="mb05">
              {#if capacity.service}
                <p class="mb05">
                  <a href="/services/{capacity.serviceId}"><Icon name="shapes" class="color-text-purple mr05" />{capacity.service.name}</a>
                </p>
              {/if}
              {#each capacity.rotationTypes as rotationType}
                <p class="mb05">
                  <Icon name="briefcase-medical" class="color-text-purple mr05" />{rotationType[0] + rotationType.slice(1)}
                </p>
              {/each}
              {#each capacity.specialties as specialty}
                <p class="mb05"><Icon name="file-certificate" class="text-specialty mr05" />{specialty[0] + specialty.slice(1)}</p>
              {/each}
            </div>
            <p class="mb05"><strong>Lead time</strong></p>
            <CapacityLeadTimeLabel {capacity} verbose={false} />
          </div>
          <div class="general-info-right">
            <p class="mb05"><strong>Rotation scheduling settings</strong></p>
            <div class="mb05">
              <div class="mb05">
                {#if allowOverlap}
                  <div class="flex-row g05">
                    <Icon name="overlap" class="color-text-black" />
                    <div>Allows overlap</div>
                  </div>
                {:else}
                  <div class="flex-row g05">
                    <Icon name="bars" class="color-text-black" />
                    <div>No new requests when full</div>
                  </div>
                {/if}
              </div>
            </div>
            <div class="mb05">
              {#if capacity.postToSearchPage}
                <div class="flex-row g05">
                  <Icon name="search-success" autoColor />
                  <div>
                    <div>Allow students to search and apply</div>
                    {#if capacity.allowStudentScheduling}
                      <div class="mt05">Students can set detailed schedules</div>
                    {/if}
                  </div>
                </div>
              {:else}
                <Icon name="search-fail" autoColor />
                <strong>DOESN’T</strong> allow students to search and apply
              {/if}
            </div>
            <div class="mb05">
              {#if capacity.allowCoordinatorScheduling}
                <div class="flex-row g05">
                  <Icon name="calendar" class="color-text-black" />
                  <div>
                    <div>Allows coordinator scheduling</div>
                    {#if capacity.allowGuestCoordinatorScheduling && capacity.allowHostCoordinatorScheduling}
                      <div class="mt05">Internal <strong>AND</strong> participant coordinators can set detailed schedules</div>
                    {:else if capacity.allowHostCoordinatorScheduling}
                      <div class="mt05">Internal coordinators can set detailed schedules</div>
                    {:else if capacity.allowGuestCoordinatorScheduling}
                      <div class="mt05">Particpant coordinators can set detailed schedules</div>
                    {:else}
                      <div />
                    {/if}
                    {#if capacity.allowStudentChanges}
                      <div class="mt05">Students are allowed to request changes</div>
                    {:else}
                      <div class="mt05">Students are <strong>NOT</strong> allowed to request changes</div>
                    {/if}
                  </div>
                </div>
              {:else}
                <Icon name="cancel" class="color-text-black" /> <strong>DOESN’T</strong> allow coordinator scheduling
              {/if}
            </div>
            {#if isGroup}
              <div class="flex-row g05">
                <Icon name="users" class="color-text-black" />
                {#if capacity.allowStudentsToLeaveGroupMatch}
                  <div class="mb05">Students are allowed to leave groups once added</div>
                {:else}
                  <div>
                    Students are <strong>NOT</strong> allowed to leave groups once added
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      </div>
      <div>
        <h3 class="mb2">Participant & availability information</h3>
        <div class="flex-row mb05 mr1 g2">
          <div>
            <div class="mr2">
              <p class="mb05"><strong>Opportunity dates</strong></p>
              <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />
            </div>
            <p class="mb05"><strong>Available rotations</strong></p>
            <div class="mb05">
              {#if capacity.maxMatches}
                {#if capacity.maxMatches === 1}
                  {capacity.maxMatches}
                  <Icon name="user" class="color-text-teal mr05" />
                {:else if capacity.maxMatches > 1}
                  {capacity.maxMatches}
                  <Icon name="users" class="color-text-teal mr05" />
                {/if}
              {:else}
                <Icon name="infinity" lg class="text-info mr05" />
                Unlimited
              {/if}
              {#if isGroup}
                group
              {/if}
              {#if capacity.maxMatches === 1}
                rotation available
              {:else}
                rotations available
              {/if}
              {#if isGroup}
                <div>
                  <div class="mb05">
                    {#if capacity.maxStudentsInGroup > 1}
                      {capacity.maxStudentsInGroup}
                      <Icon name="user" xs class="color-text-teal mr05" /> students/group
                    {:else}
                      <Icon name="infinity" lg class="text-info mr05" />
                      Unlimited students/group
                    {/if}
                  </div>
                </div>
              {/if}
            </div>
            <p class="mb05"><strong>Student requirements</strong></p>
            <div class="mb05">
              {#if capacity.minStudentYear}
                <div class="flex-row flex-align-center g05 mb05"><Icon name="calendars" class="color-text-teal" />3rd year students or higher</div>
              {:else}
                <div class="flex-row flex-align-center g05 mb05">
                  <Icon name="calendars" class="color-text-teal" /><em class="text-gray">Any year student</em>
                </div>
              {/if}
            </div>
            <p class="mb05"><strong>Disciplines</strong></p>
            <div class="mb05">
              {#if capacity}
                {#if capacity.disciplines.length}
                  <div use:tip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}>
                    Custom disciplines set
                  </div>
                {:else}
                  <div use:tip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}>
                    Inherit school disciplines
                  </div>
                {/if}
              {/if}
            </div>
            <p class="mb05"><strong>Schedule information</strong></p>
            <CapacityRequestAvailabilityPolicyLabel {capacity} />
          </div>
          {#if capacity.guests.length <= 1}
            {@const guest = capacity.guests[0]}
            {#if guest}
              <ParticipantCardDetailed {capacity} {guest} />
            {/if}
          {:else}
            <div style="max-height: 300px; overflow-y:auto; overflow-x:hidden;">
              {#each capacity.guests.sort((a, b) => a.capacityGuestOrgId - b.capacityGuestOrgId) as guest}
                <ParticipantCardLimited {capacity} {guest} />
              {/each}
            </div>
          {/if}
        </div>
      </div>
    </div></Collapsible
  >
</div>
<div class="hide">
  <div bind:this={tooltipElem} class="flex-column g05 text-left py05">
    <em>
      {#if capacity.disciplines.length}
        Inherited from
      {:else}
        Subset inherited from
      {/if}
      {#if capacity.service}
        <Icon name="shapes" class="color-text-purple" />
        {capacity.service.name}
      {/if}
    </em>
    {#if capacity.disciplines.length}
      {#each capacity.disciplines as discipline}
        <div><Icon name="book" class="color-text-teal" /> {discipline}</div>
      {/each}
    {:else}
      <div class="flex-row flex-align-center g05">
        <Icon name="book" class="color-text-teal" /><em>Any discipline</em>
      </div>
    {/if}
  </div>
</div>

<script>
  import ParticipantCardDetailed from './ParticipantCardDetailed.svelte'
  import CapacityRequestAvailabilityPolicyLabel from 'components/CapacityRequestAvailabilityPolicyLabel.svelte'
  import CapacityLeadTimeLabel from 'components/CapacityLeadTimeLabel.svelte'
  import ParticipantCardLimited from './ParticipantCard.Limited.svelte'
  import CapacityLocations from 'components/CapacityLocations.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import Icon from 'components/Icon.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import tip from 'decorators/tip.js'

  export let capacity
  export let showName = false

  let tooltipElem

  $: allowOverlap = !capacity.noNewMatchesForDayWhenOthersHaveBeen
  $: isGroup = capacity.allowGroups
</script>

<style>
  .opportunity-information-grid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1rem;
  }
</style>
