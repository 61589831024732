<!--if onboarding deadline coming up, warn. And give clinic coordinator(s) ability to extend it a bit-->
{#if matchInitial.startDate != null && matchInitial.anyStepsThatMustBeCompletedPriorToOnboardingDeadline}
  {#if onboardingShouldBeDoneSoon}
    <Alert type="warning">
      Onboarding
      {#if onboardingDeadlineIsToday}
        is due today.
      {:else}
        {#if onboardingDeadlineInPast}was{:else}will be{/if}
        due
        {onboardingDeadlineFriendly}.
      {/if}

      {#if match.isCoordinator}
        <a on:click={addExtension} href={null}>Extend onboarding deadline to {newExtensionFormatted}</a>
        <Spinner {loading} />
      {/if}
    </Alert>
  {:else if matchInitial.startDate !== matchInitial.onboardingDeadline}
    <Alert type="info" dataTest="onboarding-deadline-extended-msg"
      >Onboarding deadline was extended to {extensionFormatted} ({onboardingDeadlineFriendly}).</Alert
    >
  {/if}
{/if}

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Spinner from 'components/Spinner.svelte'

  export let match
  export let matchInitial
  export let saveMatch

  const warn = 3
  const daysToExtendBy = 4 // how many days to extend from today
  let loading = false

  $: onboardingDeadlineDate = dayjs(matchInitial.onboardingDeadline)
  $: onboardingDeadlineFriendly = dayjs().startOf('day').to(onboardingDeadlineDate)
  $: onboardingShouldBeDoneSoon = (() => {
    const twoDaysPrior = onboardingDeadlineDate.startOf('day').subtract(warn, 'days')
    return dayjs().isAfter(twoDaysPrior)
  })()
  $: onboardingDeadlineIsToday = onboardingDeadlineDate.isSame(dayjs().startOf('day'))
  $: onboardingDeadlineInPast = onboardingDeadlineDate.isBefore(dayjs().startOf('day'))
  $: extensionFormatted = onboardingDeadlineDate.format('M/D/YYYY')
  $: newExtensionFormatted = dayjs().add(daysToExtendBy, 'day').format('M/D/YYYY')

  async function addExtension() {
    // move it up a few days...
    match.onboardingDeadline = dayjs().startOf('day').add(daysToExtendBy, 'days')
    loading = true
    try {
      await saveMatch()
    } finally {
      loading = false
    }
  }
</script>
