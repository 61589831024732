import { Token, tokens } from 'services/filters/tokens.js'

import { trackErrorWithoutThrow } from 'services/errors.js'
import validator from 'services/validator.js'

const escapableTokensArray = Object.values(Token)
const escapableTokensSpaced = escapableTokensArray.join(' ')

export class FilterDecoder {
  #filterTypes = {}
  #ignoredFilterTypes = {}

  constructor(filterTypes, ignoredFilterTypes = []) {
    this.#filterTypes = filterTypes
    this.#ignoredFilterTypes = ignoredFilterTypes
  }

  decode(buffer) {
    buffer = (buffer?.trim() ?? '') + Token.FilterSeparatorOrEmptyArray // Make processing the last filter easier
    const decoder = new InternalDecoder(buffer, validator.numeric.bind(validator))
    const rootFrame = decoder.decode()
    const filterFrames = rootFrame.children
    const filters = []
    for (let i = 0; i < filterFrames.length; i++) {
      const frame = filterFrames[i]
      const type = frame.readInt()
      const meta = this.#filterTypes[type]
      if (!meta && this.#ignoredFilterTypes.includes(type)) {
        continue
      }
      const config = meta.create == null ? {} : meta.decode(frame, meta)
      filters.push({ type, config })
    }
    return filters
  }
}

export class CriteriaDecoder {
  decode(buffer, criteriaConfig) {
    buffer = (buffer?.trim() ?? '') + Token.FilterSeparatorOrEmptyArray // Make processing the last filter easier
    const decoder = new InternalDecoder(buffer)
    const rootFrame = decoder.decode()
    const dataFrames = rootFrame.children
    const extra = []
    for (let i = 0; i < dataFrames.length; i++) {
      const frame = dataFrames[i]
      const { propName, method } = this.#getKeyValueByName(criteriaConfig, frame)
      if (propName) {
        const value = method()
        extra[propName] = value
      }
    }
    return extra
  }

  #getKeyValueByName(criteriaConfig, frame) {
    const name = frame.readArg()
    for (const propName in criteriaConfig) {
      if (criteriaConfig[propName].name === name) {
        const method = (frame[criteriaConfig[propName].method] || frame.readArg).bind(frame)
        return { propName, method }
      }
    }
    return null
  }
}

class Frame {
  #readIndex = 0

  constructor(parent) {
    this.parent = parent
    this.children = []
  }

  readArg() {
    if (this.#readIndex >= this.children.length) throw new Error('No arguments left to read.')
    const index = this.#readIndex
    this.#readIndex++
    return this.children[index]
  }

  readBool(allowNull = false, allowUndefined = false) {
    const arg = this.readArg()
    if (arg === Token.True) return true
    if (arg === Token.False) return false
    return this.readFallback('bool', arg, allowNull, allowUndefined)
  }

  readDate(allowNull = false, allowUndefined = false) {
    const arg = this.readArg()
    if (arg) {
      if (!validator.nonStrictCheckDate(arg)) return this.readFallback('date', arg, allowNull, allowUndefined)
      if (validator.date(arg, 'YYYY-MM')) return dayjs(arg).format('YYYY-MM')
      return dayjs(arg).format('M/D/YYYY')
    }
    return this.readFallback('date', arg, allowNull, allowUndefined)
  }

  readInt(allowNull = false, allowUndefined = false) {
    const arg = this.readArg()
    if (!validator.numeric(arg)) return this.readFallback('int', arg, allowNull, allowUndefined)
    return parseInt(arg, 10)
  }

  readIntArray(allowNull = false, allowUndefined = false, allowNullElements = false, allowUndefinedElements = false) {
    const arg = this.readArg()
    if (!(arg instanceof Frame)) return this.readFallback('int array', arg, allowNull, allowUndefined)
    const array = []
    const { children } = arg
    for (let i = 0; i < children.length; i++) {
      array.push(arg.readInt(allowNullElements, allowUndefinedElements))
    }
    return array
  }

  readBoolArray(allowNull = false, allowUndefined = false) {
    const arg = this.readArg()
    if (arg) {
      const sum = Number(`0x${arg}`)
      const bits = sum.toString(2)
      return bits
        .split('')
        .map(bit => bit === '1')
        .reverse()
    }
    return this.readFallback('bool array', arg, allowNull, allowUndefined)
  }

  readArray(allowNull = false, allowUndefined = false, allowNullElements = false, allowUndefinedElements = false) {
    const arg = this.readArg()
    if (!(arg instanceof Frame)) return this.readFallback('array', arg, allowNull, allowUndefined)
    const array = []
    const { children } = arg
    for (let i = 0; i < children.length; i++) {
      array.push(arg.readArg(allowNullElements, allowUndefinedElements))
    }
    return array
  }

  readIntOrBool(allowNull = false, allowUndefined = false) {
    const arg = this.readArg()
    if (arg === Token.True) return true
    if (arg === Token.False) return false
    if (!validator.numeric(arg)) return this.readFallback('int or bool', arg, allowNull, allowUndefined)
    return parseInt(arg, 10)
  }

  readFallback(type, arg, allowNull, allowUndefined) {
    if (allowNull && arg === Token.Null) return null
    if (allowUndefined && arg === Token.Undefined) return undefined
    const expectedAdditional = allowNull ? (allowUndefined ? `, null, or undefined` : ` or null`) : allowUndefined ? ` or undefined` : ''

    // Track instead of throw, since throwing causes UI to show an infinite loading indicator.
    // Probably should catch and track at a higher level though, so any throw in here doesn't result in that.
    // Then convert this back to a throw.
    trackErrorWithoutThrow(
      `Fix saved search at: ${window.location}. Look at the saved search in the db and look for missing properties, like "comparison", for instance. Invalid value (${arg}); expected a ${type}${expectedAdditional}.`
    )
    return null
  }
}

// We're basically gonna build an array of arrays.
// The root-level array is gonna be [filterType1, filterType2, ..., filterTypeN]
// Each filterType will be its own Frame, where the first child is the type, and the rest are the arguments.
// If an arg is an array, it'll be another Frame.
// This encoding will support nested arrays; we may not need that in practice though.
class InternalDecoder {
  #state = 'filter'
  #escape = false
  #emptyArray = false
  #rootFrame = new Frame(null)
  #currentFrame = this.#rootFrame
  #currentBuffer = ''
  // #currentToken = null
  // #position = null
  #buffer
  #validator

  constructor(buffer, validatorMethod = null) {
    this.#buffer = buffer
    this.#validator = validatorMethod
  }

  decode() {
    // Will always at least contain the trailing filter separator token.
    if (this.#buffer.length <= 1) return this.#rootFrame

    this.pushFrame()
    for (let i = 0; i < this.#buffer.length; i++) {
      const token = this.#buffer[i]
      const nextToken = this.#buffer[i + 1] ?? ''
      // this.#currentToken = token
      // this.#position = i

      switch (this.#state) {
        case 'filter':
          switch (token) {
            case Token.FilterSeparatorOrEmptyArray:
              // If they put consecutive filter separator tokens, let's just ignore the empty filters.
              if (!this.#currentBuffer.length) break
              // Otherwise, we have an arg-less filter.
              this.popFrame()
              this.pushFrame()
              this.#state = 'filter'
              break
            case Token.ArgSeparator:
              // If we're expecting a filter, and they put an arg separator token, we're expecting args to follow.
              // So, an empty filter is invalid here.
              if (!this.#currentBuffer.length) throw new Error('Invalid filter; expected a filter type.')
              this.pushCurrentBuffer()
              this.#state = 'arg'
              break
            default:
              if (this.#validator != null && !this.#validator(token)) {
                const msg = `Invalid token (${token}) at buffer[${i}]; expected one of: ${Token.FilterSeparatorOrEmptyArray} ${Token.ArgSeparator} 0 1 2 3 4 5 6 7 8 9`
                throw new Error(msg)
              }
              this.#currentBuffer += token
              break
          }
          break
        case 'arg':
          if (this.#escape) {
            if (!escapableTokensArray.includes(token))
              throw new Error(`Invalid token (${token}) at buffer[${i}]; expected an escapeable token, one of: ${escapableTokensSpaced}`)
            this.#currentBuffer += token
            this.#escape = false
            break
          }
          switch (token) {
            case Token.ArrayStart:
              if (this.#currentBuffer.length) {
                this.#currentBuffer += token
              } else {
                this.pushFrame()
              }
              break
            case Token.ArrayEnd:
              if (!this.#emptyArray) this.pushCurrentBuffer()
              this.#emptyArray = false
              this.#state = 'end-arg'
              this.popFrame()
              break
            case Token.ArgSeparator:
              this.pushCurrentBuffer()
              break
            case Token.FilterSeparatorOrEmptyArray:
              if (this.#currentFrame.parent === this.#rootFrame) {
                // We're at the end of a filter.
                this.popFrame()
                this.pushFrame()
                this.#state = 'filter'
                break
              } else if (!this.#currentBuffer.length && this.#currentFrame.children.length === 0 && this.#currentFrame.parent !== this.#rootFrame) {
                // We're inside an empty array
                this.#emptyArray = true
                break
              } else {
                throw new Error(`Invalid token (${Token.FilterSeparatorOrEmptyArray}) at buffer[${i}].`)
              }
            case Token.Null:
            case Token.Undefined:
            case Token.True:
            case Token.False:
              if (this.#currentBuffer.length) {
                this.#currentBuffer += token
              } else {
                this.#currentBuffer = token
                this.pushCurrentBuffer()
                this.#state = 'end-arg'
              }
              break
            case Token.Escape:
              if (tokens.includes(nextToken)) {
                this.#escape = true
              } else {
                this.#currentBuffer += token
              }
              break
            default:
              this.#currentBuffer += token
              break
          }
          break
        case 'end-arg':
          switch (token) {
            case Token.ArgSeparator:
              // Regardless of if we're between filter args or array elements, the next thing we'll do is read an arg.
              this.#state = 'arg'
              break
            case Token.FilterSeparatorOrEmptyArray:
              // We're at the end of a filter, not after opening an array.
              this.popFrame()
              this.pushFrame()
              this.#state = 'filter'
              break
            case Token.ArrayEnd:
              // No need to handle the case of an empty array, we just consumed an array element of some sort.
              this.popFrame()
              break
            default:
              const msg = `Invalid token (${token}) at buffer[${i}]; expected one of: ${Token.ArgSeparator} ${Token.FilterSeparatorOrEmptyArray} ${Token.ArrayEnd}`
              throw new Error(msg)
          }
          break
      }
    }

    // Throw away the empty frame added by the trailing filter separator token.
    this.#rootFrame.children.pop()
    return this.#rootFrame
  }

  pushFrame() {
    const parent = this.#currentFrame
    const frame = new Frame(parent)
    parent.children.push(frame)
    this.#currentFrame = frame
    return frame
  }

  popFrame() {
    if (this.#currentFrame.parent === null) throw new Error('Cannot pop root frame.')
    if (this.#currentBuffer.length) this.pushCurrentBuffer()
    const frame = this.#currentFrame
    this.#currentFrame = this.#currentFrame.parent
    this.#currentBuffer = ''
    return frame
  }

  pushCurrentBuffer() {
    // This currently loses the information about whether or not the buffer
    // contained an escape sequence and is a literal string or if it was a special token
    // such as null, undefined, true, or false. That's mostly okay, though, because
    // the filter readers expect certain data types in certain places. If that becomes a
    // problem, we can push an object with that info instead of just the string.
    this.#currentFrame.children.push(decodeURIComponent(this.#currentBuffer))
    this.#currentBuffer = ''
  }
}
