<div class="flex-column g05">
  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g1 text-left py05">
      {#if !show.shiftLimits}
        <div class="flex-column g05">
          <h4 class="leading-none m0">Limits</h4>
          <ShiftCardLimits {capacity} {shift} />
        </div>
      {/if}

      <div class="flex-column g05">
        <h4 class="leading-none m0">{pluralCount('Participant', shift.guests, 'omitNumber')}</h4>
        {#if shift.guests?.length}
          <FriendlyList items={shift.guests} let:item={capacityGuest} max={Infinity}>
            <CapacityGuestProfilePictureAndName {capacityGuest} />
          </FriendlyList>
        {:else}
          <span>Available to all opportunity participants</span>
        {/if}
      </div>

      <div class="flex-column g05">
        <h4 class="leading-none m0">{pluralCount('Location', shift.locations, 'omitNumber')}</h4>
        {#if shift.locations?.length}
          <FriendlyList items={shift.locations} let:item={location} max={Infinity}>
            <OrgProfilePictureAndName
              name={location.name}
              profilePicture={location.profilePicture}
              relativeName={location.relativeName}
              class="flex-row flex-align-center g05"
            />
          </FriendlyList>
        {:else}
          <span>Available at all opportunity locations</span>
        {/if}
      </div>

      {#if shift.staff?.length}
        <div class="flex-column g05">
          <h4 class="leading-none m0">Staff visible to applicants</h4>
          {#each shift.staff as user}
            <UserProfilePicAndName {user} profilePicSmall tagName="span" gapClass="g05" />
          {/each}
        </div>
      {/if}

      {#if !show.shiftSchedule && shift.shiftDays.length}
        <div class="flex-column g05">
          <h4 class="leading-none m0">{pluralCount('Schedule', shift.shiftDays, 'omitNumber')}</h4>
          <ShiftCardSchedules {capacity} {shift} />
        </div>
      {/if}
    </div>
  </div>

  <div
    class="flex-column g05 {isDetailsView ? 'card' : 'shift-card'} card-left-{shift.color} rounded-lg p1"
    style={isDetailsView ? 'width: fit-content;' : ''}
    use:tip={tooltipElem
      ? { content: tooltipElem, options: { placement: 'left', interactive: true, theme: 'light-gray-scrollable', ...tipOptions } }
      : null}
  >
    <div>{shift.name}</div>

    {#if show.shiftLimits}
      <ShiftCardLimits {capacity} {shift} />
    {/if}

    {#if show.shiftLocations && shift.locations?.length}
      <div class="flex-row flex-align-center g05">
        <Icon name="hospital" class="color-text-orange" />
        <span>Available at {pluralCount('location', shift.locations)}</span>
      </div>
    {/if}

    {#if show.shiftStaff && shift.staff?.length}
      <div class="flex-row flex-align-center g05 flex-wrap">
        {#each shift.staff as s}
          <ProfilePic id={s.userId} name={s.name} src={s.profilePicture} small class="mb05" />
        {/each}
      </div>
    {/if}

    {#if show.shiftSchedule && shift.shiftDays.length}
      <ShiftCardSchedules {capacity} {shift} leadingHr />
    {/if}
  </div>
</div>

<script>
  import { pluralCount } from 'services/string-utils'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import ShiftCardLimits from 'components/ShiftCard.Limits.svelte'
  import ShiftCardSchedules from 'components/ShiftCard.Schedules.svelte'
  import tip from 'decorators/tip.js'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'

  export let capacity
  export let shift
  export let show
  export let isDetailsView = false
  export let tipOptions = {}

  let tooltipElem = null
</script>

<style>
  .shift-card {
    width: fit-content;
    box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-size: 13px;
  }

  .shift-tooltip {
    width: -webkit-fill-available;
  }
</style>
