{#if match.startDate}
  <span class="small" data-test="date-stamp">
    {dateService.datestamp(match.startDate)}
    {match.endDate != null ? ' - ' + dateService.datestamp(match.endDate) : ''}
  </span>
{/if}

<script>
  import dateService from 'services/date-service.js'

  export let match = {}
</script>
