import * as LDClient from 'launchdarkly-js-client-sdk'
import LocalStorageStore from 'stores/local-storage-store.js'
import initial from 'stores/initial.js'

// Using LocalStorageStore so if SDK fails, it at least remembers the last values from localStorage
const { subscribe, set, update } = LocalStorageStore('launch-darkly-feature-flags', {
  // Default values; LaunchDarkly SDK will override on load
  'dashboard-touchups': false,
  'name-overhaul': false,
})

let _clientId = null
let _user = null
const flagsToOverride = {}
// console.log('feature flags js init')

initial.subscribe(v => {
  _clientId = v.launchDarklyClientId
  initClientIfPossible()
})

function initClientIfPossible() {
  if (_clientId == null || _user == null) return
  const context = {
    kind: 'user',
    key: _user.userId,
    email: _user.email,
    name: _user.name,
    username: _user.username,
    isCn: _user.isCn,
  }

  const client = LDClient.initialize(_clientId, context)

  const setAllFlags = () => {
    const allFlags = client.allFlags()
    const flags = { ...allFlags, ...flagsToOverride }
    set(flags)
    // console.log(`LaunchDarkly flags`, allFlags, flagsToOverride, flags)
    // console.log(`LaunchDarkly flags - ${event}`, allFlags, flagsToOverride, flags)
  }

  client.on('ready', () => {
    setAllFlags('ready')
    client.flush()
  })

  client.on('change', () => {
    setAllFlags('change')
  })

  // client.on('failed', () => console.log('LaunchDarkly failed'))
}

// Export a read-only store to rest of app.
export default {
  subscribe,
  setUser: function (user) {
    // console.log('setUser', user)
    _user = user
    initClientIfPossible()
  },
  overrideFeatureFlag: function (flag, value) {
    flagsToOverride[flag] = value
    update(flags => {
      flags[flag] = value
      // console.log('overrideFeatureFlag', flagsToOverride, flag, value, flags)
      return flags
    })
  },
}
