<div class="flex-row flex-align-center g1 flex-grow">
  <ProfilePic src={user.profilePicture} name={user.name} id={user.userId} medium pseudoDisabled={user.deletedDateTime != null} />
  <div class="flex-row g1 pt1 flex-align-center flex-grow">
    <div class="flex-column flex-grow">
      <div class="flex-row flex-align-center g1">
        <label class="m0">{staff.user.name}</label>
        {#if showCapacityAccessibility}
          {#if staff.capacityAccessibilityTooltip}
            <Icon lg name="hide" class="text-danger" title={staff.capacityAccessibilityTooltip} />
          {:else}
            <Icon lg name="eye" class="text-success" />
          {/if}
        {/if}
      </div>
      {#if show.orgTitle}
        <div>{staff.title}</div>
      {/if}
      {#if show.externalId && staff.externalId}
        <div>{staff.externalId}</div>
      {/if}
      {#if show.email}
        <div class="nowrap">
          <IconTextLink icon="email" text={staff.user.email} href="mailto:{staff.user.email}" />
        </div>
      {/if}
    </div>

    <div class="details-right flex-column g1">
      {#if show.org}
        <OrgProfilePictureAndName
          name={staff.org.name}
          relativeName={staff.org.relativeName}
          profilePicture={staff.org.profilePicture}
          size="small"
          useOverflowEllipsis
        />
      {/if}
      {#if show.roles}
        <div>
          <StaffRoles {hasHealthFeature} {hasSchoolFeature} {hasTeamFeature} {hasCustomTagFeature} orgStaff={staff} showMultipleRestrictions />
        </div>
      {/if}
    </div>
  </div>
</div>

<script>
  import { FeatureType } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import personaService from 'services/persona-service.js'
  import StaffRoles from 'components/StaffRoles.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'

  const hasHealthFeature = personaService.canUseAnyFeatureType(FeatureType.HealthInstitution)
  const hasSchoolFeature = personaService.canUseAnyFeatureType(FeatureType.TeachingInstitution)
  const hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)
  const hasCustomTagFeature = personaService.canUseAnyFeatureType(FeatureType.CustomTags)

  export let staff = null
  export let showCapacityAccessibility = false
  // export let isHovered

  $: show = $showDropdowns.staffPicker
  $: user = staff.user
</script>

<style>
  .details-right {
    width: 170px;
  }
</style>
