<div
  class="progress{className ? ` ${className}` : ''}"
  class:progress-sm={sm}
  class:progress-md={md}
  class:progress-low-profile={lowProfile}
  use:tip={title}
>
  <slot />
</div>

<script>
  import tip from 'decorators/tip.js'

  let className = null
  export { className as class }
  export let sm = false
  export let md = false
  export let lowProfile = false
  export let title = null
</script>
