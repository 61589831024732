<Icon
  title={userEmail.emailIsConfirmed ? 'Email confirmed' : 'Email not confirmed'}
  name={userEmail.emailIsConfirmed ? 'check' : 'alert-triangle'}
  class={userEmail.emailIsConfirmed ? 'text-success' : 'text-warning'}
  fw
/>
{userEmail.email}

<script>
  import Icon from './Icon.svelte'
  export let userEmail
</script>
