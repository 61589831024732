{#if showLeft || showRight}
  <div class="details-container mt1" class:no-left={!showLeft}>
    {#if showLeft}
      <div class="flex-column g1 details-left">
        {#if show.email}
          <div data-test="student-email">
            {#each uniqueUserEmails as userEmail}
              <UserEmailWithConfirmedStatus {userEmail} />
            {/each}
          </div>
        {/if}
        {#if show.year || show.discipline}
          <div class="flex-row flex-align-center g05">
            {#if show.year}
              <Icon name="calendars" class="color-text-teal" dataTest="calendar-icon" />
            {/if}

            {#if show.discipline}
              <Icon name="book" class="color-text-teal" dataTest="book-icon" />
            {/if}

            <StudentPickerStudentYearWarning {student} {capacityStudentYears} />

            <span data-test="student-year-discipline">
              {#if show.year && student.studentYear}
                {toStudentYear(student.studentYear)}
              {/if}

              {#if show.discipline}
                {student.discipline || student.otherDiscipline || (show.year && student.studentYear ? 'student' : 'Student')}
              {/if}
            </span>
          </div>
        {/if}

        {#if show.graduationDate}
          <div class="flex-row flex-align-center g05">
            <Icon name="graduation-cap" class="color-text-teal" dataTest="graduation-cap-icon" />
            {#if student.studentGraduationDate}
              <span>
                {#if validator.inFuture(student.studentGraduationDate)}
                  <span class="text-success">Graduating</span>
                {:else}
                  <span class="text-warning">Graduated</span>
                {/if}
                {dateService.datestamp(student.studentGraduationDate)}
              </span>
              <small><HelpBlock inline class="m0">(<FromNow date={student.studentGraduationDate} />)</HelpBlock></small>
            {:else}
              <span data-test="grad-date-unknown">Graduation date unknown</span>
            {/if}
          </div>
        {/if}

        {#if show.customTags && $persona.personaType === PersonaType.SchoolStaff}
          <div class="flex-row flex-align-center g05">
            <FriendlyList items={student.studentRecords.filter(sr => sr.tags != null).flatMap(sr => sr.tags)} let:item={customTag}>
              <CustomTag {customTag} />
            </FriendlyList>
          </div>
        {/if}

        {#if show.stepSummary}
          <StudentStepSummary userId={student.userId} studentRecord={student.studentRecords[0]} />
        {/if}
      </div>
    {/if}

    {#if showRight}
      <div class="flex-column g2 details-right">
        <div class="student-school">
          <div class="flex-row flex-align-center g05 mb05">
            <Icon name="school" class="color-text-blue" dataTest="school-icon" />
            <h4 class="leading-none m0">Student at</h4>
          </div>

          <div class="flex-column g05">
            {#each undeletedStudentRecords as sr (sr.orgId)}
              <OrgProfilePictureAndName
                name={sr.orgName}
                relativeName={sr.orgRelativeName}
                profilePicture={sr.orgProfilePicture}
                size="medium"
                useOverflowEllipsis
              >
                {#if sr.active}
                  <StudentRecordStatus studentRecord={sr} />
                {/if}
              </OrgProfilePictureAndName>
            {/each}
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<script>
  import { toStudentYear } from 'services/formatters.js'
  import dateService from 'services/date-service.js'
  import FromNow from 'components/FromNow.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StudentPickerStudentYearWarning from 'components/fields/StudentPicker.StudentYearWarning.svelte'
  import StudentRecordStatus from 'components/StudentRecordStatus.svelte'
  import UserEmailWithConfirmedStatus from 'components/UserEmailWithConfirmedStatus.svelte'
  import validator from 'services/validator.js'
  import FriendlyList from 'components/FriendlyList.svelte'
  import CustomTag from 'components/CustomTag.svelte'
  import StudentStepSummary from 'components/StudentStepSummary.svelte'
  import { PersonaType } from 'config/enums.js'
  import persona from 'stores/persona.js'

  export let student = null
  export let capacityStudentYears = []
  // export let isSelected
  // export let isHovered

  $: show = $showDropdowns.studentPicker
  $: showLeft = show.year || show.discipline || show.graduationDate || show.email || show.tags
  $: showRight = show.school
  $: undeletedStudentRecords = student.studentRecords.filter(sr => sr.deleted == null)
  $: uniqueUserEmails = _.uniqBy(undeletedStudentRecords, sr => sr.email)
</script>

<style lang="scss">
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(student): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';

    &.no-left {
      grid-template-columns: auto;
      grid-template-areas: 'right';
    }
  }

  .details-left {
    grid-area: left;
  }

  .details-right {
    grid-area: right;
  }
</style>
