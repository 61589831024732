<div
  class="days flex-row flex-align-center{className ? ` ${className}` : ''}"
  class:g05={sm}
  class:g1={!sm}
  class:read-only={readOnly}
  class:sm
  {tabindex}
  on:keydown={handleKeydown}
  on:focus={onContainerFocused}
  on:blur={() => (containerIsFocused = false)}
>
  {#each options as day (day.value)}
    {@const disabled = disabledDays.includes(day.value)}
    {#if !onlyValues || (onlyValues && value.includes(day.value))}
      <div
        class="day text-center"
        class:selected={value.includes(day.value)}
        class:clickable={!disabled && !readOnly}
        class:disabled
        class:not-allowed={disabled}
        class:focused={containerIsFocused && day.value === focusedDay}
        data-test="day-{day.value}"
        on:mousedown={() => focusDayIfPossible(day.value)}
        on:click={() => toggleDay(day.value)}
      >
        {day.bubbleName}
      </div>
    {/if}
  {/each}
</div>

<script>
  import Key from 'config/key.js'
  import { options } from 'components/fields/DayOfWeekLabel.svelte'

  export let value = []
  export let disabledDays = []
  export let readOnly = false
  export let onlyValues = false
  export let sm = false
  let className = null
  export { className as class }

  $: tabindex = options.every(o => disabledDays.includes(o.value)) ? null : 0

  let containerIsFocused = false
  let focusedDay = null

  function onContainerFocused() {
    containerIsFocused = true
    if (focusedDay != null && !disabledDays.includes(focusedDay)) return
    focusedDay = 0
    focusDay(1)
    if (focusedDay === 0) focusedDay = null
  }

  function handleKeydown(e) {
    const key = e.which || e.keyCode
    switch (key) {
      case Key.Left:
      case Key.Up:
        focusDay(-1)
        break
      case Key.Right:
      case Key.Down:
        focusDay(1)
        break
      case Key.Space:
        toggleDay(focusedDay)
        break
    }
  }

  function focusDay(direction) {
    let dayToMaybeFocus = focusedDay + direction
    for (let i = 0; i < 7; i++) {
      if (dayToMaybeFocus < 1) {
        dayToMaybeFocus = 7
      } else if (dayToMaybeFocus > 7) {
        dayToMaybeFocus = 1
      }

      if (!disabledDays.includes(dayToMaybeFocus)) {
        focusedDay = dayToMaybeFocus
        break
      }

      dayToMaybeFocus += direction
    }
  }

  function focusDayIfPossible(day) {
    if (readOnly || disabledDays.includes(day)) return
    focusedDay = day
  }

  function toggleDay(day) {
    if (readOnly || disabledDays.includes(day)) return
    focusedDay = day
    if (value.includes(day)) {
      value = value.filter(d => d !== day)
    } else {
      value = [...value, day]
    }
    value.sort()
  }
</script>

<style lang="scss">
  @import '../../../css/helpers';

  .days {
    --size: 35px;

    &:focus {
      outline: none;
    }

    &.sm {
      --size: 25px;
      font-size: 11px;
    }
  }

  .day {
    width: var(--size);
    height: var(--size);
    line-height: var(--size);
    border-radius: 50%;
    background: #e6f7f5;
    color: $primary;
    border: 1px solid $primary;
    transition:
      background-color 0.3s,
      color 0.3s;

    &.clickable {
      &:hover {
        background: #cceeeb;
      }

      &.focused {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 4px;
      }
    }

    &:not(.clickable) {
      cursor: default;
    }

    &.disabled,
    &.disabled:hover {
      color: #77777a;
      opacity: 40%;
      background: #e8e8e9;
      border-color: #77777a;
    }

    &.selected {
      background: #00a89d;
      color: white;

      &.clickable {
        &:hover {
          background: #66cbc4;
          border-color: #66cbc4;
        }
      }
    }
  }
</style>
