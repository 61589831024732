<!-- TODO: Nice to have. Allow the user to group by rotation, person, org, step, whatever. -->
<!-- TODO: Nice to have. Reduce redundant information per row by utilizing dynamic `rowspan`s -->

{#if rows.length}
  <Grid
    name="user-document-visbility-grid"
    let:row
    paginatorTotalCount={rows.length}
    pageSize={Infinity}
    loading={false}
    {rows}
    class="middle"
    emptyMessage="Only you can see this document"
  >
    <GridCol {row} name="match" header="Rotation">
      <IconTextLink
        icon="rotation"
        iconProps={{ autoColor: true, lg: true }}
        text="View {row.match.displayName?.trim() || 'rotation'}"
        href="/dashboard/rotation-list/matches/{row.match.matchId}"
      />
    </GridCol>

    <GridCol {row} name="user" header="Person">
      <UserProfilePicAndName user={row.user} pronounsBelow profilePicSmall tagName="h4" pronounSetStyle="font-size: 11px" />
    </GridCol>

    <GridCol {row} name="roles" header="Roles">
      {#each row.roles as role}
        <MatchRole {role} />
      {/each}
    </GridCol>

    <GridCol {row} name="hostOrg" header="Health organization">
      <OrgProfilePictureAndName
        name={row.host.name}
        profilePicture={row.host.profilePicture}
        relativeName={row.host.relativeName}
        contentClass="text-left"
      />
    </GridCol>

    <GridCol {row} name="guestOrg" header="School">
      <OrgProfilePictureAndName
        name={row.guest.name}
        profilePicture={row.guest.profilePicture}
        relativeName={row.guest.relativeName}
        contentClass="text-left"
      />
    </GridCol>

    <GridCol {row} name="capacity" header="Opportunity">
      <div class="flex-row flex-align-center g05">
        <Icon name="list" class="color-text-purple" />
        <span>{row.capacity.name}</span>
      </div>
    </GridCol>

    <GridCol {row} name="step" header="Step">
      <Step step={row.step} />
    </GridCol>
  </Grid>
{:else}
  <div class="em small">Only you</div>
{/if}

<script>
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import MatchRole from 'components/MatchRole.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import Step from 'components/Step.svelte'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'

  export let userDocument
  export let userDocumentSharing

  $: rows = buildRows(userDocument, userDocumentSharing)

  const mapBy = (array, keySelector) => {
    const map = new Map()
    for (const item of array) {
      map.set(keySelector(item), item)
    }
    return map
  }

  function buildRows() {
    const matchById = mapBy(userDocumentSharing.matches, m => m.matchId)
    const stepsById = mapBy(userDocumentSharing.steps, s => s.stepId)
    const orgsById = mapBy(userDocumentSharing.orgs, o => o.orgId)
    const capacitiesById = mapBy(userDocumentSharing.capacities, c => c.capacityId)
    const usersById = mapBy(userDocumentSharing.users, u => u.userId)

    const matchRolesByMatchUser = new Map()
    for (const mur of userDocumentSharing.matchUserRoles) {
      const matchUserKey = `${mur.matchId}-${mur.userId}`
      const existing = matchRolesByMatchUser.get(matchUserKey)
      const matchRoles = existing || []
      matchRoles.push(mur.matchRole)
      if (!existing) matchRolesByMatchUser.set(matchUserKey, matchRoles)
    }

    const rows = []

    for (const msd of userDocument.matchStepDownloaders) {
      const match = matchById.get(msd.matchId)
      const matchUserKey = `${msd.matchId}-${msd.userId}`
      const matchRoles = matchRolesByMatchUser.get(matchUserKey)
      rows.push({
        match,
        step: stepsById.get(msd.stepId),
        user: usersById.get(msd.userId),
        host: orgsById.get(match.hostOrgId),
        guest: orgsById.get(match.guestOrgId),
        capacity: capacitiesById.get(match.capacityId),
        roles: matchRoles,
      })
    }
    return rows
  }
</script>
