<RelatedEntityCount
  {count}
  {name}
  {unit}
  {iconFirst}
  {iconFirstLabel}
  {iconTitle}
  icon="graduation-cap"
  {iconLg}
  {iconClass}
  {nullMeansInfinity}
  {symbol}
/>

<script>
  import RelatedEntityCount from 'components/RelatedEntityCount.svelte'

  export let name = 'students'
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  export let unit = 'Student'
  export let iconClass = 'color-text-teal'
  export let iconFirst = false
  export let iconFirstLabel = ''
  export let iconTitle = null
  export let nullMeansInfinity
  export let iconLg = false
  export let symbol = 'times'
</script>
