<input
  bind:this={inputEl}
  {placeholder}
  class="form-control"
  class:disabled
  data-test={dataTest}
  spellcheck="false"
  bind:value={valueRaw}
  {disabled}
  on:focus={onFocus}
  on:blur={onBlur}
  on:keydown={onKeyDown}
  on:keyup={onKeyUp}
/>

{#if focused}
  <div class="times-menu" bind:this={timesMenuEl}>
    {#each times as t, i}
      <div class:active={currentIndex === i} on:mousedown={() => selectTime(t.value, i)}>{t.value}</div>
    {/each}
  </div>
{/if}

<script context="module">
  const times = getTimeOptions()
  const defaultMenuOption = 48 // start list at 12pm instead of 12am if null value (times.map(t => t.value).indexOf('12:00pm'))

  function getTimeOptions() {
    const result = []
    for (let i = 0; i < 24; i++) {
      const amPm = i < 12 ? 'am' : 'pm'
      const hr24 = i
      const hr12 = i % 12 === 0 ? 12 : i % 12
      result.push({ value: `${hr12}:00${amPm}`, hr: hr24, min: 0 })
      result.push({ value: `${hr12}:15${amPm}`, hr: hr24, min: 15 })
      result.push({ value: `${hr12}:30${amPm}`, hr: hr24, min: 30 })
      result.push({ value: `${hr12}:45${amPm}`, hr: hr24, min: 45 })
    }
    return result
  }
</script>

<script>
  import dateService from 'services/date-service.js'
  import { createEventDispatcher, tick } from 'svelte'
  import Key from 'config/key.js'

  export let value
  export let disabled = false
  export let dataTest = null
  export let placeholder = null
  export let autofocus = false

  const dispatch = createEventDispatcher()
  let valueRaw
  let focused = false
  let currentIndex = 0
  let inputEl
  let timesMenuEl

  $: value, setInternalValues()
  $: if (autofocus && inputEl) focus()
  $: if (focused && currentIndex >= 0) scrollToCurrentIndex()

  tick().then(setInternalValues)

  export function focus() {
    setTimeout(() => inputEl?.focus?.())
  }

  function setInternalValues() {
    const formatted = dateService.formatTime(value)
    valueRaw = formatted
    const parsedValue = dateService.parseTime(value)
    fireChanged(parsedValue)
  }

  async function scrollToCurrentIndex() {
    await tick() // so UI can set active class on item
    if (timesMenuEl == null) return // might be null after tick
    const activeMenuItemEl = timesMenuEl.querySelector('.active')
    if (timesMenuEl && activeMenuItemEl) timesMenuEl.scrollTop = activeMenuItemEl.offsetTop // don't use activeMenuItemEl.scrollIntoView() since it scrolls entire page https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move/11041376
  }

  function onFocus(e) {
    focused = true
    selectAllText(e)
    const parsedValue = dateService.parseTime(valueRaw)
    currentIndex = getClosestTimeMenuOption(parsedValue)
  }

  function onBlur() {
    value = dateService.parseTime(valueRaw, true)
    focused = false
  }

  function onKeyDown(e) {
    const k = e.which || e.keyCode
    const setValToCurrentIndex = () => {
      value = dateService.parseTime(times[currentIndex].value)
      fireChanged(value)
    }
    switch (k) {
      // up/down traverses times
      case Key.Down:
        e.preventDefault()
        if (currentIndex < times.length - 1) currentIndex++
        else currentIndex = 0
        setValToCurrentIndex()
        return
      case Key.Up:
        e.preventDefault()
        if (currentIndex > 0) currentIndex--
        else currentIndex = times.length - 1
        setValToCurrentIndex()
        return
    }
  }

  function onKeyUp(e) {
    // if pressing up or down, this has already been handled
    const k = e.which || e.keyCode
    const upOrDown = k === Key.Down || k === Key.Up
    if (upOrDown) return

    // as user types, try to determine what they're typing as they type and set to closest list option
    // and also notify calling code so it can give quick feedback prior to officially updating the underlying value.
    // we wait to update value until they blur or select an option
    const parsedValue = dateService.parseTime(valueRaw, true)
    currentIndex = getClosestTimeMenuOption(parsedValue)
    fireChanged(parsedValue)
  }

  function selectTime(time, index) {
    currentIndex = index
    value = dateService.parseTime(time)
    // wait til changed event fires (after next tick, so we need to wait a full event loop). _Then_ indicate that we selected an option
    setTimeout(() => dispatch('selected'))
  }

  function fireChanged(parsedValue) {
    dispatch('changed', parsedValue)
  }

  function selectAllText(e) {
    e.target.select(0, e.target.value.length)
  }

  function getClosestTimeMenuOption(parsedValue) {
    if (valueRaw == null) return defaultMenuOption
    const parts = dateService.getTimeParts(parsedValue)
    if (parts == null) return defaultMenuOption
    let { hr, min } = parts
    hr = parseInt(hr)
    min = parseInt(min)
    let closest = 60
    let closestIndex = -1
    for (let i = 0; i < times.length; i++) {
      const time = times[i]
      if (time.hr !== hr) continue
      const diff = Math.abs(min - time.min)
      if (diff === 0) return i // not going to get any closer than this...
      if (diff < closest) {
        closest = diff
        closestIndex = i
      }
    }
    return closestIndex
  }
</script>

<style>
  .times-menu {
    cursor: pointer;
    position: absolute;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1055;
    top: 100%;
    width: 100px;
    border: 1px solid #eee;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-color: #fff;
  }
  .times-menu > div {
    padding: 5px 10px;
  }
  .times-menu > div.active,
  .times-menu > div:hover {
    background-color: #eee;
  }
</style>
