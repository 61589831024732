{#if filteredOptions == null}
  <Spinner />
{:else if filteredOptions.length === 0}
  <HelpBlock>No {filteredOptions.length < options.length ? 'other ' : ''}staff available.</HelpBlock>
{:else}
  <InputSelect
    {name}
    {placeholder}
    bind:value
    on:change
    {multiple}
    filterable={options && options.length > 5}
    filterStringSelector={o => [o.name]}
    options={filteredOptions}
    valueSelector={o => o.userId}
    {isOpen}
    let:option={staff}
  >
    <div class="flex-row flex-align-center g1">
      <div><ProfilePic name={staff.name} src={staff.profilePicture} small /></div>
      <div class="flex-column">
        <span>{staff.name}</span>
        {#if staff.titles?.length}
          <em>{staff.titles.join(', ')}</em>
        {/if}
      </div>
    </div>
  </InputSelect>
{/if}

<script>
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import Spinner from 'components/Spinner.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'

  export let name = 'staff-ids'
  export let options = null
  export let value = null
  export let multiple = true
  export let placeholder = 'Select staff'
  export let excludeUserIds = []
  export let isOpen = false

  $: filteredOptions = options == null ? null : options.filter(s => !excludeUserIds.some(es => es === s.userId))
</script>
