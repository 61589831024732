<QuickDropdown
  label="Show…"
  icon="sliders-simple"
  btnClass="btn btn-default"
  labelClass="flex-row flex-align-center g05"
  dropdownClass="p2"
  dataTest="student-picker-show-dropdown"
>
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.email} name="show-email">
      <Icon lg name="email" class="color-text-teal" />
      Email address
    </InputToggle>
    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.year} name="show-year">
        <Icon lg name="calendars" class="color-text-teal" />
        Year of study
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.discipline} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Discipline
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.graduationDate} name="show-graduation-date">
        <Icon lg name="graduation-cap" class="color-text-teal" />
        Graduation date
      </InputToggle>

      {#if canManageCustomTags && $persona.personaType === PersonaType.SchoolStaff}
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.customTags} name="show-custom-tags">
          <Icon lg name="tag" class="color-text-light-brown" />
          Custom tags
        </InputToggle>
      {/if}

      {#if canViewSteps}
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.stepSummary} name="show-step-summary">
          <Icon lg name="my-steps" class="color-text-brown" />
          Step summary
        </InputToggle>
      {/if}
    </div>

    <div class="flex-column g05">
      <h4>Student at</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.studentPicker.school} name="show-organization">
        <Icon lg name="school" class="color-text-blue" />
        School
      </InputToggle>
    </div>
  </div>
</QuickDropdown>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import personaService from 'services/persona-service.js'
  import { FeatureType, PersonaType } from 'config/enums.js'
  import persona from 'stores/persona.js'

  const canManageCustomTags = personaService.canUseAnyFeatureType(FeatureType.CustomTags)
  const canViewSteps = personaService.canUseAnyFeatureType(FeatureType.MySteps, FeatureType.SchoolComplianceRequirements)
  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.studentPicker) {
      if (typeof $showDropdowns.studentPicker[key] !== 'boolean') continue
      $showDropdowns.studentPicker[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>
