{#if viewOrEditHref || onViewClicked}
  <MediaQuery {...mediaQueries.actionView ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="eye"
      title={viewUserDocumentTitle}
      dataTest="view-user-document-btn"
      on:click={() => onViewClicked?.(userDocument)}
      href={viewOrEditHref ? fromTemplate(viewOrEditHref, userDocument) : null}>{viewUserDocumentLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if onRenameClicked}
  <MediaQuery {...mediaQueries.actionEdit ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="edit"
      title={renameUserDocumentTitle}
      dataTest="rename-user-document-btn"
      on:click={() => onRenameClicked(userDocument)}>{renameUserDocumentLabel}</Btn
    >
  </MediaQuery>
{/if}

<MediaQuery {...mediaQueries.actionDownload ?? always}>
  <Btn
    color="outline-gray{className ? ` ${className}` : ''}"
    icon="download"
    title={downloadUserDocumentTitle}
    dataTest="download-user-document-btn"
    on:click={() => downloadUserDocuments([userDocument])}>{downloadUserDocumentLabel}</Btn
  >
</MediaQuery>

{#if onDeleteClicked}
  <MediaQuery {...mediaQueries.actionDelete ?? always}>
    <Btn
      color="outline-danger{className ? ` ${className}` : ''}"
      icon="delete"
      title={deleteUserDocumentTitle}
      dataTest="delete-user-document-btn"
      on:click={() => onDeleteClicked([userDocument])}>{deleteUserDocumentLabel}</Btn
    >
  </MediaQuery>
{/if}

<script context="module">
  const always = { query: 'all' }
</script>

<script>
  import { downloadUserDocuments } from 'services/user-document-service.js'
  import { fromTemplate } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'

  export let mediaQueries = {}
  export let userDocument
  export let viewOrEditHref = null
  export let onViewClicked = null
  export let onDeleteClicked
  export let onRenameClicked

  export let downloadUserDocumentLabel = 'Download'
  export let downloadUserDocumentTitle = null
  export let deleteUserDocumentLabel = 'Delete'
  export let deleteUserDocumentTitle = null
  export let viewUserDocumentLabel = 'View'
  export let viewUserDocumentTitle = null
  export let renameUserDocumentLabel = 'Rename'
  export let renameUserDocumentTitle = null
  export { className as class }
  let className = null
</script>
