{#if errors.length > 0}
  <Modal on:close={clearModelState} color="danger" title={singularOrPlural(errors, 'Error')}>
    <div class="modal-body" data-test="errors">
      {#each errors as error}
        <p class="my1">
          <Icon name="alert-triangle" class="text-danger" />
          <SafeHtml value={error} />
        </p>
      {/each}
    </div>
    <div class="modal-footer">
      <Btn class="btn-danger" bind:this={dismissBtn} dataTest="dismiss-err" on:click={clearModelState}>Dismiss</Btn>
    </div>
  </Modal>
{/if}

<script>
  import { singularOrPlural } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import modelState from 'stores/model-state.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'

  let dismissBtn
  $: dismissBtn?.focus?.()

  $: errors = (() => {
    if ($modelState == null) return []

    if ($modelState.errorMessage) return [$modelState.errorMessage]

    const result = []
    if (typeof $modelState === 'string') return [validator.empty($modelState) ? 'An error occurred' : $modelState]

    const notEmpty = e => !validator.empty(e)
    if (Array.isArray($modelState)) return $modelState.filter(notEmpty)

    // translate { "": ["msg1", "msg2"], "name": ["msg3", "msg4"]} into ["msg1", "msg2", "msg3", "msg4"]
    for (const i in $modelState) {
      for (let k = 0; k < $modelState[i].length; k++) {
        result.push($modelState[i][k])
      }
    }
    return result.filter(notEmpty)
  })()

  function clearModelState() {
    $modelState = null
  }
</script>
