{#if total}
  <div use:tip={total ? { content: `View this student’s ${unit}`, options: { placement: 'left' } } : null}>
    <IconTextLink {icon} {iconProps} {href}>
      {completed}/{total}
      {unit} completed
    </IconTextLink>
  </div>
{:else}
  <div>
    <Icon name={icon} {...iconProps} />
    No {unit} assigned
  </div>
{/if}

<script context="module">
  import { buildShareHref } from './ShareHref.svelte'
  import { CriteriaFilterType, FilterType, SavedSearchType } from 'config/enums.js'
  import filterBuilder from 'services/filter-builder.js'

  export function buildViewStepsUrl(userId, stepTypes) {
    const filters = [
      {
        type: FilterType.Students,
        config: {
          exclude: false,
          userIds: [userId],
        },
      },
    ]
    if (stepTypes?.length) {
      filters.push({
        type: FilterType.StepTypes,
        config: {
          exclude: false,
          stepTypes,
        },
      })
    }
    // the count doesn't include completed/closed rotations, and the my steps page currently doesn't allow adding rotation-level filters, so let's just skip this for now, else you the my steps page will show complete/closed rotation match steps.
    // const matchFilters = [
    //   {
    //     type: FilterType.MatchStatusCategory,
    //     config: {
    //       statusCategory: ArchiveActiveFilter.All,
    //     },
    //   },
    // ]
    return buildShareHref({
      baseHref: 'step-submissions/temp/my-steps',
      filterSets: [
        {
          prefix: '',
          name: 'filters',
          type: CriteriaFilterType.StepSubmissionFilter,
          filters,
          ...filterBuilder(CriteriaFilterType.StepSubmissionFilter),
        },
        // {
        //   prefix: 'match',
        //   name: 'matchFilters',
        //   type: CriteriaFilterType.MatchFilter,
        //   filters: matchFilters,
        //   ...filterBuilder(CriteriaFilterType.MatchFilter),
        // },
      ],
      criteria: {
        filters,
        // matchFilters,
        savedSearchType: SavedSearchType.MyStepsReport,
      },
    })
  }
</script>

<script>
  import IconTextLink from 'components/IconTextLink.svelte'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let total
  export let completed
  export let unit
  export let userId
  export let stepTypes

  $: allCompleted = total === completed
  $: icon = allCompleted ? 'check' : 'alert-triangle'
  $: iconProps = {
    class: allCompleted ? 'text-success' : 'text-warning',
  }
  // TODO: make this url set a steptype filter when steptype filter is added to my-steps page
  $: href = buildViewStepsUrl(userId, stepTypes)
</script>
