import { PersonaType } from 'config/enums.js'
import api from 'services/api.js'
import personaStore from 'stores/persona.js'

let $persona
personaStore.subscribe(p => ($persona = p))

export default function getMatchController() {
  if ($persona == null) return null
  switch ($persona.personaType) {
    case PersonaType.Student:
      return api.matchStudent
    case PersonaType.ProviderStaff:
      return api.matchProvider
    case PersonaType.SchoolStaff:
      return api.matchSchool
    default:
      return null
  }
}
