<div class="result-count">
  {#if data.buckets.length === 0}None{:else}{data.buckets.length} {pluralizeGroupBy(data.buckets.length)}{/if}
</div>
<div>
  {#each data.buckets as bucket}
    <Bucket {key} {bucket} {criteria} textColor={bucket.color} {baseHref} />
  {/each}
</div>

<script>
  import { formatEnumKey } from 'services/formatters.js'
  import { GroupBy } from 'config/enums.js'
  import { pluralize } from 'services/string-utils.js'
  import Bucket from 'components/CapacityCalendar.Bucket.svelte'

  export let capacityData
  export let key
  export let criteria
  export let baseHref

  $: data = capacityData[key]
  $: groupByName = formatEnumKey(Object.keys(GroupBy).find(k => GroupBy[k] === criteria.groupBy))

  function pluralizeGroupBy(count) {
    return count > 1 ? pluralize(groupByName) : groupByName
  }
</script>
