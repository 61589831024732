<div class="flex-row flex-align-center g1" style={fullWidth ? 'min-width: 500px' : 'width: 500px'}>
  <Filter bind:text={keywordSearch} placeholder="Search services by name or organization" on:change={onKeywordSearchChanged} class="flex-grow" />

  <slot name="after-keyword-search" />
</div>

{#if $persona.org?.parentOrgId != null || $personaFilters.orgId != $persona.orgId}
  <InputCheckbox name="include-inherited-services" bind:checked={$includeInherited} labelClass="m0" class="flex-row flex-align-center g05">
    Include services from parent organization(s)
  </InputCheckbox>
{/if}

<div bind:this={filtersContainerElem} style="grid-area: filters" class="flex-row flex-align-center flex-wrap g1">
  <slot />
  <Filters
    bind:this={filtersComponent}
    bind:filters
    {includedFilterTypes}
    {excludedFilterTypes}
    interceptors={_interceptors}
    {metaMapFuncs}
    {onChanged}
    {onCleared}
    class={null}
    dropdownStyle={filtersDropdownStyle}
    filterOptionsController="serviceFilterOptions"
  />
</div>

<script context="module">
  import { buildFilterTypesArray, buildIgnoredFilterTypesArray } from 'components/Filters.svelte'

  const includedFilterTypes = [
    FilterType.Specialties,
    FilterType.Disciplines,
    FilterType.Agreements,
    FilterType.HostOrgs,
    FilterType.Teams,
    FilterType.CanManage,
    FilterType.KeywordSearch,
    FilterType.ExcludeInherited,
  ]

  const metaMapFuncs = {
    [FilterType.Agreements]: meta => {
      meta.allowsAnyOrExplicitly = true
      meta.labelApplied = 'agreement'
      meta.excludable = false
      meta.allowNull = true
      meta.toMany = true
      meta.toManySuffix = 'of the following agreements'
      meta.toManyNoneCheckboxLabel = 'Include services allowing any agreement'
      meta.editLabel = null
      return meta
    },
    [FilterType.HostOrgs]: meta => {
      meta.label = 'Organization'
      return meta
    },
    [FilterType.Teams]: meta => {
      meta.label = 'Team'
      return meta
    },
    [FilterType.Disciplines]: meta => {
      meta.label = 'Discipline'
      meta.allowsAnyOrExplicitly = true
      meta.labelApplied = 'discipline'
      meta.excludable = false
      meta.allowNull = true
      meta.toMany = true
      meta.toManySuffix = 'of the following disciplines'
      meta.toManyNoneCheckboxLabel = 'Include services allowing any discipline'
      meta.editLabel = null
      return meta
    },
    [FilterType.Specialties]: meta => {
      meta.hasDoesntHave = true
      meta.labelApplied = 'specialty'
      meta.excludable = false
      meta.allowNull = true
      meta.toMany = true
      meta.toManySuffix = 'of the following specialties'
      meta.toManyNoneCheckboxLabel = 'Include services without any specialties'
      meta.editLabel = null
      return meta
    },
  }

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }

  export function buildIgnoredFilterTypes(excludedFilterTypes = []) {
    return buildIgnoredFilterTypesArray(includedFilterTypes, excludedFilterTypes)
  }
</script>

<script>
  import { FilterType } from 'config/enums.js'
  import { onDestroy } from 'svelte'
  import Filter from 'components/Filter.svelte'
  import Filters from 'components/Filters.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import LocalStorageStore from 'stores/local-storage-store.js'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import validator from 'services/validator.js'

  export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  export let excludedFilterTypes = []
  export let fullWidth = false
  export let interceptors = {}

  $: _interceptors = {
    [FilterType.KeywordSearch]: {
      canRemove: false,
      isAvailable: false,
    },
    [FilterType.ExcludeInherited]: {
      canRemove: false,
      isAvailable: false,
    },
    ...interceptors,
  }

  let keywordSearch = filters.find(f => f.type === FilterType.KeywordSearch)?.config.keyword ?? ''
  let filtersContainerElem = null
  let filtersDropdownStyle = null

  const includeInherited = LocalStorageStore('services-include-inherited', true)
  $: $includeInherited, addOrRemoveInheritedFilter()

  $: filtersContainerElem, initResizeObserver()
  let observer = null
  function initResizeObserver() {
    if (filtersContainerElem == null || observer != null) return
    observer = new ResizeObserver(() => {
      if (!fullWidth) {
        filtersDropdownStyle = null
        return
      }
      if (filtersContainerElem == null) return
      filtersDropdownStyle = `width: ${filtersContainerElem.offsetWidth}px; max-width: unset`
    })
    observer.observe(filtersContainerElem)
  }

  onDestroy(() => {
    observer?.disconnect()
  })

  function onCleared() {
    keywordSearch = ''
    onKeywordSearchChanged()
  }

  function onKeywordSearchChanged() {
    filters = filters.filter(f => f.type !== FilterType.KeywordSearch)
    if (!validator.empty(keywordSearch)) filters.push({ type: FilterType.KeywordSearch, config: { keyword: keywordSearch } })
    onChanged(filters)
  }

  let includeInheritedHasChanged = false // Don't fire onChanged on the initial call
  function addOrRemoveInheritedFilter() {
    filters = filters.filter(f => f.type !== FilterType.ExcludeInherited)
    if (!$includeInherited) {
      filters.push({ type: FilterType.ExcludeInherited })
    }
    if (includeInheritedHasChanged) onChanged(filters)
    else includeInheritedHasChanged = true
  }
</script>
