<div class="flex-column flex-align-center">
  <QuickFilterTag
    icon="filter-status"
    class="color-text-steel"
    value={match.statusName}
    type="Status"
    {filtersComponent}
    filterType={FilterType.MatchStatuses}
    configKey="statuses"
    configValue={match.status}
    simulationFilterOption={{ statusId: match.status, name: match.statusName }}
    dataTest="status-tag"
  >
    <Icon
      name={match.statusName}
      class="text-{statusHelper.color}"
      title={!$isQuickFiltering ? (showStatusName ? statusHelper.describe(match) : statusHelper.label) : null}
    />
    {#if showStatusName}
      {statusHelper.label}
    {/if}
  </QuickFilterTag>

  <div class="flex-row flex-align-center" class:condensed class:last-open={$lastOpenRotation === match.matchId}>
    {#if match.hasCacheTaskQueueItem}
      <div class="non-quick-filterable-status-icon">
        <SyncAlertIcon sm class="text-warning" tooltip={{ content: tooltipElem, options: { interactive: true, placement: 'top' } }} />
      </div>
    {/if}

    {#if match.hasStepsICanComplete}
      <QuickFilterTag
        icon="circle"
        class="text-warning"
        value={match.hasStepsICanComplete}
        type="Has steps you can complete"
        {filtersComponent}
        filterType={FilterType.MatchHasStepsICanComplete}
        filterConfig={{}}
        simulationFilterOption={{ serviceId: match.serviceId, name: match.serviceName }}
        dataTest="has-steps-i-can-complete-tag"
      >
        <Icon name="circle" class="text-warning" dataTest="has-steps-i-can-complete-icon" />
      </QuickFilterTag>
    {/if}

    {#if match.hasStepsICanVerify}
      <QuickFilterTag
        icon="circle"
        class="text-purple"
        value={match.hasStepsICanVerify}
        type="Has steps you can verify"
        {filtersComponent}
        filterType={FilterType.MatchHasStepsICanVerify}
        filterConfig={{}}
        simulationFilterOption={{ serviceId: match.serviceId, name: match.serviceName }}
        dataTest="has-steps-i-can-complete-tag"
      >
        <Icon
          name="circle"
          class="text-purple"
          dataTest="has-steps-i-can-verify-icon"
          title={!$isQuickFiltering ? 'Has steps you can verify' : null}
        />
      </QuickFilterTag>
    {/if}

    {#if match.hasProposedChanges}
      <QuickFilterTag
        icon="has-changes"
        class="text-sky"
        value={match.hasProposedChanges}
        type="Has proposed changes"
        {filtersComponent}
        configKey="stepIds"
        filterType={FilterType.MatchHasProposedChanges}
        simulationFilterOption={{ serviceId: match.serviceId, name: match.serviceName }}
        dataTest="has-steps-i-can-complete-tag"
      >
        <Icon name="has-changes" class="text-sky" dataTest="has-proposed-changes-icon" title={!$isQuickFiltering ? 'Has proposed changes' : null} />
      </QuickFilterTag>
    {/if}

    {#if match.isDoubleBook}
      <div class="non-quick-filterable-status-icon">
        <Icon
          name="alert-triangle"
          class="text-warning"
          dataTest="is-double-book"
          title="This rotation has potential date conflicts with another rotation."
        />
      </div>
    {/if}

    {#if match.hasStepsDueSoon}
      <QuickFilterTag
        icon="circle"
        class="text-danger"
        value={match.hasStepsDueSoon}
        type="Has steps that are due soon"
        {filtersComponent}
        configKey="stepIds"
        filterType={FilterType.MatchHasStepsDueSoon}
        simulationFilterOption={{ serviceId: match.serviceId, name: match.serviceName }}
        dataTest="has-steps-due-soon-tag"
      >
        <Icon
          name="circle"
          class="text-danger"
          dataTest="has-step-due-soon-icon"
          title={!$isQuickFiltering ? 'This rotation has steps that are due soon.' : null}
        />
      </QuickFilterTag>
    {/if}
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import lastOpenRotation from 'stores/last-open-rotation.js'
  import matchStatusHelper from 'services/match-status-helper.js'
  import SyncAlertIcon from 'components/SyncAlertIcon.svelte'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import { FilterType } from 'config/enums'
  import isQuickFiltering from 'stores/is-quick-filtering.js'

  export let match = null
  export let condensed = false
  export let showStatusName = true
  export let filtersComponent = null

  const tooltipElem = null

  $: statusHelper = matchStatusHelper.get(match.status)
</script>

<style lang="scss">
  .non-quick-filterable-status-icon {
    padding: 2px 4px; // to line up with icons that are quick-filterable
  }
</style>
