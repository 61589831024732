<OrgProfilePictureAndName
  {name}
  {size}
  {useOverflowEllipsis}
  {nameClass}
  profilePicture={capacityGuest.guestOrgProfilePicture}
  relativeName={capacityGuest.relativeName}
  special={isOpenAnySchool}
/>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'

  export let capacityGuest
  export let size = 'small'
  export let useOverflowEllipsis = false
  export let nameClass = null

  $: isOpenAnySchool = capacityGuest.guestOrgId == null || capacityGuest.guestOrgId === 0
  $: name = isOpenAnySchool ? 'Open (any school)' : capacityGuest.guestOrgName
</script>
