import { writable } from 'svelte/store'

function createToastsStore() {
  const { subscribe, update } = writable([])
  const del = toast => update(x => x.filter(n => n.id !== toast.id))
  const clear = () => update(() => [])

  function toast({ message, type, icon, url, notification, html, onClick, onWaitComplete, onCancel, duration }) {
    // add the message...
    const toast = {
      // random too in case 2 toasts get added at same time
      id: Math.random() + '_' + new Date().getUTCMilliseconds(),
      // default to min 3 sec, max 7 sec, depending on message length
      // if calling code explicitly passes null or infinity, means no limit (so user must dismiss it)
      duration: duration === null || duration === Infinity ? null : duration ?? Math.min(Math.max(message.length * 50, 3000), 7000),
      message,
      html: !!html, // TODO(scheduling) add a component test for toasts with html attribute
      type,
      icon,
      url,
      notification,
      onClick,
      onWaitComplete,
      onCancel,
    }
    update(toasts => toasts.concat(toast))
  }

  return {
    subscribe,
    toast,
    del,
    clear,
  }
}

export default createToastsStore()
