<!-- Changing something in here? Consider making a similar change to ShiftDayForm. -->

<div class="flex-row flex-align-center g05">
  <RepeatsEvery {repeatSchedule} />

  <!-- Wrap in span so flex gap doesn't look weird -->
  {#if repeatUnit === RepeatUnit.Weekly}
    <span><DayOfWeekLabel value={repeatDaysOfWeek} /></span>
  {:else if repeatUnit === RepeatUnit.Monthly}
    <span><DayOfMonthLabel value={repeatDaysOfMonth} /></span>
  {/if}

  from <StartAndEndDate {startDate} {endDate} />

  {#if startTime != null || endTime != null}
    at <StartAndEndTime {startTime} {endTime} />
  {:else}
    with no set times
  {/if}

  {#if sameAsShift}
    <Help>This availability window doesn’t have any explicit schedules; it has the same availability as the opportunity itself</Help>
  {/if}
</div>

<script>
  import { buildRepeatSchedule } from 'services/calendar-service.js'
  import { RepeatUnit } from 'config/enums.js'
  import DayOfMonthLabel from 'components/fields/DayOfMonthLabel.svelte'
  import DayOfWeekLabel from 'components/fields/DayOfWeekLabel.svelte'
  import Help from 'components/Help.svelte'
  import RepeatsEvery from 'components/RepeatsEvery.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'

  export let capacity
  export let shift
  export let shiftDay = null
  export let sameAsShift = false

  $: repeatSchedule = buildRepeatSchedule(capacity, shift, shiftDay)
  $: startDate = repeatSchedule.startDate
  $: endDate = repeatSchedule.endDate
  $: startTime = repeatSchedule.startTime
  $: endTime = repeatSchedule.endTime
  $: repeatUnit = repeatSchedule.repeatUnit
  $: repeatDaysOfWeek = repeatSchedule.repeatDaysOfWeek
  $: repeatDaysOfMonth = repeatSchedule.repeatDaysOfMonth
</script>
