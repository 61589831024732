{#if editing}
  <FormGroup valid={validator.required(filter.keyword)}>
    <InputText bind:value={filter.keyword} name="keyword" autofocus />
  </FormGroup>
{:else}
  {appliedMeta.label} contains {#if filter.keyword?.trim()}<strong>{filter.keyword}</strong>{:else}…?{/if}
{/if}

<script context="module">
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import validator from 'services/validator.js'
  import { FilterType } from 'config/enums.js'

  const label = 'User name, org name, or group name'

  export const meta = {
    type: FilterType.KeywordSearch,
    label,
    editLabel: `${label} contains`,
    icon: 'search',
    iconClass: 'text-gray',
    canHaveMultiple: true,

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.keyword)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.keyword = reader.readArg()
      return config
    },

    create() {
      return {
        keyword: '',
      }
    },

    validate(filter) {
      return validator.required(filter.keyword)
    },
  }
</script>

<script>
  import InputText from 'components/fields/InputText.svelte'

  export let filter
  export let editing = false
  export let appliedMeta
</script>
