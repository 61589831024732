<form method="post" on:submit|preventDefault|stopPropagation={prepareUpload} enctype="multipart/form-data">
  <!-- label.btn for hidden file input to make bootstrap styled file input -->
  <span class="btn btn-{color} btn-file {className}" use:tip={title}>
    <input type="file" bind:this={fileUploadEl} bind:files on:change={prepareUpload} {id} />
    {#if loading}<Spinner />{:else}<Icon name={icon} />{/if}
    {#if label}{label}{:else if currentFileName}{currentFileName}{:else}Choose file{/if}
  </span>
</form>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import Spinner from 'components/Spinner.svelte'
  import { validateFile } from 'services/file-service.js'

  export let title = null
  export let color = 'default'
  export let label = null
  export let icon = 'upload'
  export let acceptableFileTypes = []
  export let id = 'fileUpload'
  export { className as class }
  export let loading = false
  export let onFileUploadReady

  let fileUploadEl
  let files
  let className = ''
  let currentFileName = null

  // when user chooses a file, we fire a fileUploadReady event
  function prepareUpload(event) {
    const file = files?.[0]
    if (!validateFile(file, acceptableFileTypes)) {
      resetFileUpload()
      return
    }
    // prep the form data
    const data = new FormData()
    data.append('files', file)
    // fire an event for parent to listen for
    onFileUploadReady({
      data,
      file, // calling code might want access simply to the file object, not form data
      original: event,
    })
    currentFileName = file.name
    fileUploadEl.value = null
  }

  function resetFileUpload() {
    fileUploadEl.value = null
    files = null
    onFileUploadReady({
      data: null,
      file: null,
    })
  }
</script>
