<SortDropdown {sortOptions} bind:sortProperty bind:sortAscending {onChange} />

<script context="module">
  import { ServiceListProperty } from 'config/enums.js'
  import { buildSortOption } from 'components/SortDropdown.svelte'

  export const sortOptions = [
    buildSortOption(ServiceListProperty.OrganizationRelativeName, 'Organization name'),
    buildSortOption(ServiceListProperty.ServiceName, 'Service name'),
    buildSortOption(ServiceListProperty.ActiveCapacityCount, 'Active opportunities', 'numeric'),
  ]
</script>

<script>
  import SortDropdown from 'components/SortDropdown.svelte'

  export let sortProperty = ServiceListProperty.OrganizationRelativeName
  export let sortAscending = true
  export let onChange
</script>
