{#if shouldCollectVerificationExpirationDate}
  <QuickDropdown dataTest={btnDataTest} noCaret btnClass={btnClasses} label={btnLabel} autoSized bind:isOpen={collectingVerificationExpirationDate}>
    <div class="p2">
      <Form on:submit={verify}>
        <FormGroup valid={!stepSubmission.step.requireVerificationExpiration || verificationExpirationDate != null}>
          <label for="verificationexpiration">
            When should this verification expire?
            {#if !stepSubmission.step.requireVerificationExpiration}
              (Optional)
            {:else}
              <RequiredMarker />
            {/if}
          </label>
          {#if hasValidSuggestion}
            <HelpBlock>
              {stepSubmission.completedUser.name} suggested {stepSubmission.verificationExpirationDateSuggestion} when they completed this step.
            </HelpBlock>
          {/if}
          <DatePicker
            name="verificationexpiration"
            placeholder="Verification expiration"
            min={today}
            bind:value={verificationExpirationDate}
            clearable
          />
        </FormGroup>
        <div class="mt1"><SubmitBtn dataTest="verify-step-btn" icon="save" {loading}>Submit</SubmitBtn></div>
      </Form>
    </div>
  </QuickDropdown>
{:else}
  <Btn on:click={verify} class={btnClasses} {loading} dataTest={btnDataTest}>{btnLabel}</Btn>
{/if}

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Form from 'components/Form.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import api from 'services/api.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import { StepAction, StepSubmissionStatus } from 'config/enums.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import { tick } from 'svelte'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm

  const btnClasses = `btn btn-success${btnSm ? ' btn-sm' : ''}`
  const btnDataTest = 'verify-btn'
  let verificationExpirationDate = null
  let loading = false
  let collectingVerificationExpirationDate = false

  $: isVerified = stepSubmission.status === StepSubmissionStatus.Verified
  $: btnLabel = !isVerified ? 'Verify' : `${stepSubmission.verificationExpirationDate ? 'Update' : 'Set'} verification expiration`
  $: today = dayjs().startOf('day')
  $: verificationExpirationDateSuggestionParsed = stepSubmission.verificationExpirationDateSuggestion
    ? dayjs(stepSubmission.verificationExpirationDateSuggestion)
    : null
  $: hasValidSuggestion = verificationExpirationDateSuggestionParsed ? verificationExpirationDateSuggestionParsed.isAfter(today) : false
  $: shouldCollectVerificationExpirationDate = stepSubmission.step.requireVerificationExpiration || isVerified
  $: collectingVerificationExpirationDate, setVerificationExpirationDate()

  async function verify() {
    loading = true
    try {
      await onAction({
        type: StepAction.Verify,
        invoke: invoke(verificationExpirationDate),
        alwaysShowOthers: true,
      })
      collectingVerificationExpirationDate = false
    } finally {
      loading = false
    }
  }

  // needs to be a closure so that when the popup is closed and the value is null'd out, we still have the value they had chosen
  function invoke(closure_verificationExpirationDate) {
    return stepSubmissions =>
      api.step.setStepsVerified(
        {
          startDateOverride,
          endDateOverride,
        },
        {
          stepSubmissions,
          verificationExpirationDate: closure_verificationExpirationDate,
        },
        api.noMonitor
      )
  }

  async function setVerificationExpirationDate() {
    await tick() // not sure why this is needed, but if it's not here, the datepicker box doesn't have the value set upon opening
    verificationExpirationDate = collectingVerificationExpirationDate
      ? stepSubmission.verificationExpirationDate ?? stepSubmission.verificationExpirationDateSuggestion
      : null
  }
</script>
