<BtnGroupPicker {options} bind:value class={className} {btnClass} {btnActiveClass} {btnInactiveClass} on:change name={dataTest} />

<script>
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import { CommentActivityFilter } from 'config/enums'

  export let value = CommentActivityFilter.All
  export let btnClass = 'btn-sm'
  let className = ''
  export { className as class }
  export let btnActiveClass
  export let btnInactiveClass
  export let tooltipComment = null
  export let tooltipActivity = null
  export let tooltipAll = null
  export let dataTest = null

  const buildOption = (value, label, dataTest, title) => ({ value, label, dataTest, title })
  $: options = [
    buildOption(CommentActivityFilter.All, 'All', 'all-tab', tooltipAll),
    buildOption(CommentActivityFilter.Comment, 'Comments', 'comment-tab', tooltipComment),
    buildOption(CommentActivityFilter.Activity, 'Activity', 'activity-tab', tooltipActivity),
  ]
</script>
