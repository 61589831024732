<span class={className}>
  We are processing changes on this rotation. Please wait a few seconds and then <IconTextLink
    icon="rotate-right"
    text="refresh"
    href={null}
    onClick={_onReload}
    {loading}
    class="nowrap"
  /> if you need current information.</span
>

<script>
  import IconTextLink from 'components/IconTextLink.svelte'

  let className = null
  export { className as class }
  export let onReload

  let loading = false

  async function _onReload() {
    loading = true
    try {
      await onReload()
    } finally {
      loading = false
    }
  }
</script>
