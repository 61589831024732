<FilterTypeStatus {filter} {editing} {appliedMeta} options={stepTypeHelper.getOptions()} />

<script context="module">
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import { FilterType, StepType } from 'config/enums.js'
  import stepTypeHelper from 'services/step-type-helper.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.StepTypes,
    filterProp: 'stepTypes',
    labelApplied: 'Type of step',
    label: 'Type of step',
    icon: null, // hmm, not sure. maybe some combo of all of them, but that'd probably be a visual mess.
    iconClass: 'color-text-steel',
    canHaveMultiple: true,
    enumeration: StepType,
    helper: stepTypeHelper,
  }
</script>

<script>
  export let filter
  export let editing = false
  export let appliedMeta
</script>
