{#if editing}
  <FormGroup>
    <InputToggle name="has-rotations" bind:checked={filter.hasRotations}>
      <Icon name="rotation" lg autoColor />
      Has rotations
    </InputToggle>
  </FormGroup>
{:else if filter.hasRotations}
  Has rotations
{:else}
  <strong>DOES NOT</strong>
  have rotations
{/if}

<script context="module">
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import { FilterType } from 'config/enums.js'

  const label = 'Has rotations'

  export const meta = {
    type: FilterType.CapacityGuestHasRotations,
    label,
    icon: 'rotation',
    iconProps: { autoColor: true },

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.hasRotations)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.hasRotations = reader.readBool()
      return config
    },

    create() {
      return { hasRotations: true }
    },

    validate() {
      return true
    },
  }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'

  export let filter
  export let editing = false
</script>
