{#if warningContext}
  <Modal lg on:close={() => (warningContext = null)} title="Saved with warnings">
    <div class="modal-body">
      <strong>{warningContext.name}</strong> was saved successfully, but you may want to know…
      <ul class="m1 pl1" style="list-style-type: none;">
        {#each warningContext.warnings as message, i (i)}
          <li><Icon name="alert-triangle" class="text-warning" dataTest="triangle-warning" /> {message}</li>
        {/each}
      </ul>
      <p class="mt1 mb0 text-danger">You <em>may</em> need to make other changes so all of their roles work as intended.</p>
    </div>
    <div class="modal-footer">
      <Btn color="primary" icon="thumbs-up" on:click={() => (warningContext = null)}>Got it</Btn>
    </div>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'

  export let warningContext = null
</script>
