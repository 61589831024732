<!--
  monitor user activity
  if user is idle for too long, pop a modal warning they're about to be logged out, showing countdown
  user can reset the timer by doing anything

  idle timeout is configurable per org, stored in orgsettings
  each user uses the most restrictive setting of any org they're a part of, or defaults to 240 minutes
  -->
<svelte:window
  on:mousemove={resetTimer}
  on:mousedown={resetTimer}
  on:click={resetTimer}
  on:keypress={resetTimer}
  on:touchstart={resetTimer}
  on:scroll={resetTimer}
/>

<ModalConfirm
  bind:show={isPrompting}
  on:confirm={logout}
  on:cancel={keepMeLoggedIn}
  confirmLabel="Log me out"
  confirmClass="btn-danger"
  cancelLabel="Keep me logged in"
  cancelClass="btn-success"
>
  <h4 slot="title">Your session is about to expire!</h4>
  You will be logged out in
  <strong>{secondsLeft}</strong>
  seconds due to inactivity.
</ModalConfirm>

<script>
  import ModalConfirm from 'components/ModalConfirm.svelte'
  import environment from 'services/environment.js'
  import { getRedirectUrl } from 'services/location-service'
  export let timeoutMinutes = 30
  let timer = null
  let isPrompting = false
  let secondsLeft = null
  let lastMinuteInterval = null

  $: if (timeoutMinutes > 0) resetTimer()

  function keepMeLoggedIn() {
    clearInterval(lastMinuteInterval)
    resetTimer()
  }

  function resetTimer() {
    clearTimeout(timer)

    // when timeout is 1 minute from running out, prompt the user to warn them / let them take action
    timer = setTimeout(prompt, (timeoutMinutes - 1) * 60 * 1000)
  }

  function prompt() {
    clearInterval(lastMinuteInterval)
    isPrompting = true
    secondsLeft = 60
    lastMinuteInterval = setInterval(() => {
      if (secondsLeft == 0) {
        clearInterval(lastMinuteInterval)
        logout()
      } else secondsLeft--
    }, 1000)
  }

  function logout() {
    // sending ?idleTimeout=1 so it shows up in logs for monitoring purposes, server doesn't do anything with it
    window.location = `${environment.linkApi}/api/home/logout?idleTimeout=1&redirect=${encodeURIComponent(getRedirectUrl())}`
  }
</script>
