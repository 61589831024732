{#if verificationExpirationDate}
  <div class:text-warning={verificationCloseToExpiration} class:text-danger={verificationIsExpired}>
    Verification {verificationIsExpired ? 'expired' : 'expires'}
    on {verificationExpirationDate} ({expiresFromNow})
  </div>
{/if}

<script>
  export let verificationExpirationDate

  $: today = dayjs().startOf('day')
  $: verificationExpirationDateParsed = verificationExpirationDate ? dayjs(verificationExpirationDate) : null
  $: verificationCloseToExpiration = verificationExpirationDateParsed ? verificationExpirationDateParsed.diff(today, 'days') <= 3 : false
  $: verificationIsExpired = verificationExpirationDateParsed ? verificationExpirationDateParsed.isBefore(today) : false
  $: expiresFromNow = verificationExpirationDateParsed
    ? verificationExpirationDateParsed.isSame(today)
      ? 'today'
      : verificationExpirationDateParsed.from(today)
    : null
</script>
