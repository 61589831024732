import { FeatureType, Permission } from 'config/enums.js'
import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewEvaluationStepsReport,
  featureTypes: [FeatureType.Evaluations],
  componentName: 'EvaluationStepsReport',
  name: 'Evaluation steps report',
  icon: 'Evaluation',
  color: 'black',
}
