<div class="flex-column g1" style="height: 130px;">
  <h3 class="mb05">Permission overview</h3>
  {#if anyStaffSelected}
    <CapacityStaffPermissionOverviewSection staffMembers={allowedStaff} all={!deniedStaff.length} />
    <CapacityStaffPermissionOverviewSection staffMembers={deniedStaff} all={!allowedStaff.length} isStaffDenied />
  {:else}
    <div class="flex-row flex-align-center g1">
      <Icon fw name="eye" />
      <span>No users have been selected</span>
    </div>
  {/if}
</div>

<script>
  import CapacityStaffPermissionOverviewSection from 'components/CapacityStaffPermissionOverview.Section.svelte'
  import Icon from 'components/Icon.svelte'

  export let userIds = []
  export let staffMembers = []

  $: selectedStaffMembers = staffMembers.filter(s => userIds?.includes(s.user.userId))
  $: allowedStaff = selectedStaffMembers.filter(s => !s.capacityAccessibilityTooltip)
  $: deniedStaff = selectedStaffMembers.filter(s => s.capacityAccessibilityTooltip)
  $: anyStaffSelected = allowedStaff.length || deniedStaff.length
</script>
