<div class="org-tooltip p1 card-left-thick-{guest.color} card" class:showTooltip={guest}>
  <div class="mb05">
    <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis={true} />
  </div>
  <hr class="m0" />
  <div class="mt05">
    <table>
      {#if guest.guaranteedMatches > 0}
        <tr>
          <td />
          <td style="font-style: italic;">{guest.guaranteedMatches} guaranteed rotations</td>
        </tr>
      {/if}
      {#if guest.approvedOverMax > 0}
        <tr>
          <td class="color-text-{guest.color}">
            <Icon x2 name="square" class="mr05" />
          </td>
          <td>{guest.approvedRotations - guest.approvedOverMax} approved</td>
        </tr>
        <tr>
          <td>
            <Icon
              x2
              name="square-exclamation"
              style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: 1;"
            />
          </td>
          <td>{guest.approvedOverMax} approved over maximum</td>
        </tr>
      {:else if guest.approvedRotations > 0}
        <tr>
          <td class="color-text-{guest.color}">
            <Icon x2 name="square" class="mr05" />
          </td>
          <td>{guest.approvedRotations} approved</td>
        </tr>
      {/if}
      {#if guest.pendingOverMax > 0}
        {#if guest.pendingRotations - guest.pendingOverMax > 0}
          <tr>
            <td class="color-text-{guest.color}">
              <Icon x2 name="square" class="mr05" style="opacity: .6" />
            </td>
            <td>{guest.pendingRotations - guest.pendingOverMax} pending</td>
          </tr>
        {/if}
        <tr>
          <td>
            <Icon
              x2
              name="square-exclamation"
              style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: .6;"
            />
          </td>
          <td>{guest.pendingOverMax} pending over maximum</td>
        </tr>
      {:else if guest.pendingRotations > 0}
        <tr>
          <td class="color-text-{guest.color}">
            <Icon x2 name="square" class="mr05" style="opacity: .6" />
          </td>
          <td>{guest.pendingRotations} pending</td>
        </tr>
      {/if}
      {#if guest.guaranteedLeft > 0}
        <tr>
          <td class="color-text-{guest.color}">
            <Icon x2 name="square" style="opacity: .3" class="mr05" />
          </td>
          <td>{guest.guaranteedLeft} guaranteed left</td>
        </tr>
      {/if}
    </table>
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import CapacityGuestProfilePictureAndName from './CapacityGuestProfilePictureAndName.svelte'

  export let guest
</script>

<style lang="scss">
  .org-tooltip {
    position: fixed;
    pointer-events: none;
    background-color: #fff;
    display: none;
    border-radius: 5px;
    z-index: 1000;
  }
  .showTooltip {
    display: block;
  }
</style>
