{#if canEdit}
  <MediaQuery {...mediaQueries.edit ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="edit"
      title={editCapacityTitle}
      dataTest="edit-capacity-btn-{capacity.capacityId}"
      href={fromTemplate(viewOrEditHref, capacity)}>{editCapacityLabel}</Btn
    >
  </MediaQuery>
{:else if canView}
  <MediaQuery {...mediaQueries.view ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="eye"
      title={viewCapacityTitle}
      dataTest="view-capacity-btn-{capacity.capacityId}"
      href={fromTemplate(viewOrEditHref, capacity)}>{viewCapacityLabel}</Btn
    >
  </MediaQuery>
  {#if $persona.personaType === PersonaType.SchoolStaff}
    <ConfirmMatchesBtn {capacity} {capacityGuest} class="btn btn-outline-gray" href={confirmMatchesHref} />
    <ReleaseMatchesBtn {capacity} {capacityGuest} class="btn btn-outline-gray" href={releaseMatchesHref} />
  {/if}
{/if}

{#if canCopy}
  <MediaQuery {...mediaQueries.copy ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="copy"
      title={copyCapacityTitle}
      dataTest="copy-capacity-btn-{capacity.capacityId}"
      href={fromTemplate(copyHref, capacity)}>{copyCapacityLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if canDelete}
  <MediaQuery {...mediaQueries.delete ?? always}>
    <Btn
      color="outline-danger{className ? ` ${className}` : ''}"
      icon="delete"
      title={deleteCapacityTitle}
      dataTest="delete-capacity-btn-{capacity.capacityId}"
      on:click={() => onDeleteClicked(capacity)}>{deleteCapacityLabel}</Btn
    >
  </MediaQuery>
{/if}

<hr class="my05" />

{#if canViewStaff}
  <MediaQuery {...mediaQueries.staff ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="staff-settings"
      title={staffTitle}
      dataTest="capacity-staff-btn-{capacity.capacityId}"
      href={fromTemplate(staffHref, capacity)}>{staffLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if show.service && capacity.service}
  <MediaQuery {...mediaQueries.service ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="shapes"
      dataTest="capacity-service-btn-{capacity.capacityId}"
      href="/services/{capacity.serviceId}"
    >
      View service
    </Btn>
  </MediaQuery>
{/if}

{#if show.organization}
  <MediaQuery {...mediaQueries.organization ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="hospital"
      dataTest="capacity-org-btn-{capacity.capacityId}"
      href="/orgs/{capacity.orgId}/{capacity.orgRelativePath}"
    >
      View organization
    </Btn>
  </MediaQuery>
{/if}

{#if show.school && capacityGuest?.guestOrgId != null}
  <MediaQuery {...mediaQueries.school ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="school"
      dataTest="capacity-school-btn-{capacity.capacityId}-{capacityGuest.guestOrgId}"
      href="/orgs/{capacityGuest.guestOrgId}/{capacityGuest.guestOrgRelativePath}"
    >
      View school
    </Btn>
  </MediaQuery>
{/if}

{#if show.agreement && capacityGuest?.agreementId != null}
  <MediaQuery {...mediaQueries.agreement ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="agreement"
      dataTest="capacity-agreement-btn-{capacity.capacityId}-{capacityGuest.agreementId}"
      href="/settings/agreements/{capacityGuest.agreementId}"
    >
      View agreement
    </Btn>
  </MediaQuery>
{/if}

<script context="module">
  const always = { query: 'all' }
</script>

<script>
  import { fromTemplate } from 'services/string-utils.js'
  import { PersonaType, Permission } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import ConfirmMatchesBtn from 'components/CapacityGuestView.ConfirmMatchesBtn.svelte'
  import ReleaseMatchesBtn from 'components/CapacityGuestView.ReleaseMatchesBtn.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import showDropdowns from 'stores/show-dropdowns.js'

  export let mediaQueries = {}
  export let capacity
  export let capacityGuest
  export let viewOrEditHref
  export let staffHref
  export let copyHref
  export let confirmMatchesHref
  export let releaseMatchesHref
  export let onDeleteClicked

  export let viewCapacityLabel = 'View opportunity'
  export let viewCapacityTitle = null
  export let editCapacityLabel = 'Edit opportunity'
  export let editCapacityTitle = null
  export let staffLabel = 'View staff'
  export let staffTitle = null
  export let copyCapacityLabel = 'Copy opportunity'
  export let copyCapacityTitle = null

  export let deleteCapacityLabel = 'Delete opportunity'
  export let deleteCapacityTitle = null
  export { className as class }
  let className = null

  $: show = $showDropdowns.capacityGrid
  $: canView = true
  $: canViewStaff = personaService.hasStaffPermission(Permission.ViewStaff, capacity.orgId, capacity.teamId, { anyTeam: true })
  $: canEdit = personaService.canEditCapacity(capacity)
  $: canDelete = canEdit
  $: canCopy = canEdit // TODO: Allow them to do this if they're an service/opportunity manager
</script>
