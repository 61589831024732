<span class="flex-row flex-align-center g05" title="{stepType}{stepInputType && !hideStepType ? ` ${stepInputType.toLowerCase()}` : ''} step">
  <Icon name={stepTypeIcon} class={iconClass} {...iconProps} />
  {#if stepInputTypeIcon && !hideStepType}<Icon name={stepInputTypeIcon} class={iconClass} {...iconProps} />{/if}
  <span class={className}>{step.name}</span>
</span>

<script context="module">
  import { StepInputType, StepType } from 'config/enums.js'
  const iconByStepType = _.invert(StepType)
  const iconByStepInputType = _.invert(StepInputType)
</script>

<script>
  import { formatEnumValue } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'

  export let step
  export let hideStepType = false
  let className = null
  export { className as class }
  export let iconProps = {}

  $: stepType = step.stepType == null ? '' : formatEnumValue(StepType, step.stepType)
  $: stepInputType = step.stepInputTypeName || step.stepInputType == null ? '' : formatEnumValue(StepInputType, step.stepInputType)
  $: stepTypeIcon = iconByStepType[step.stepType]
  $: stepInputTypeIcon = iconByStepInputType[step.stepInputType]
  $: iconClass = `text-${matchStatusHelper.getByStepType(step.stepType).color}`
</script>
