<InputNameAffix name="name-prefix" bind:value {options} />

<script>
  import { buildOption } from 'services/option-builder.js'
  import InputNameAffix from 'components/InputNameAffix.svelte'

  export let value = null

  const options = [buildOption('Dr.')]
</script>
