<!-- Changing something in here? Consider making a similar change to ServiceView. -->

<Form on:submit={confirmSubmit}>
  <div class="modal-body">
    {#if orgSettings == null}
      <Loading />
    {:else}
      <OwnerOrgTeamPicker
        bind:orgId={input.orgId}
        bind:teamId={input.teamId}
        requiredPermission={Permission.ManageOpportunitiesAndServices}
        bind:selectedTeam
        {onDefaultOrgId}
        {onDefaultTeamId}
      />

      <FormGroup valid={serviceNameIsNotEmpty} validationMessage="You must {mustChooseServiceName ? 'choose' : 'enter'} a name for the service.">
        <label for="name" class="flex-row flex-align-center g05">
          <Icon name="shapes" class="color-text-purple" />
          Service name
          <RequiredMarker />
        </label>
        {#if mustChooseServiceName}
          <InputSelect name="name" filterable bind:value={input.name} options={orgSettings.serviceNames} placeholder="Select a service name" />
        {:else}
          <InputText name="name" bind:value={input.name} placeholder="Service name" autofocus maxlength={200} />
        {/if}
      </FormGroup>

      <FormGroup>
        <label for="description">Description</label>
        <InputTextarea name="description" bind:value={input.description} placeholder="Description" />
      </FormGroup>

      <FormGroup>
        <div class="flex-row flex-align-center g05 mb05">
          <label class="flex-row flex-align-center g05 m0" for="specialties">
            <Icon name="file-certificate" class="text-specialty" />
            Specialties
          </label>
          <Help tipOptions={{ maxWidth: 300 }}>
            To help coordinators and applicants find the right services, select any specialties this service involves or focuses on.<br /><br />
            Opportunities in this service will be limited to the selections made here.
          </Help>
        </div>
        <SpecialtiesPicker bind:value={input.specialties} placeholder="None selected" />
      </FormGroup>

      <FormGroup>
        <div class="flex-row flex-align-center g05 mb05">
          <label class="flex-row flex-align-center g05 mb0" for="disciplines">
            <Icon name="book" class="color-text-teal" />
            Accept only the following disciplines
          </label>
          <Help tipOptions={{ maxWidth: 300 }}>Opportunities in this service will be limited to the selections made here.</Help>
        </div>

        <InheritedTagPicker
          bind:value={input.disciplines}
          type="discipline"
          inheritedFromSources={[
            {
              type: 'team',
              icon: 'users',
              iconProps: { class: 'color-text-orange' },
              name: selectedTeam?.name,
              items: selectedTeam?.disciplines,
            },
          ]}
          let:enabledOptions
          let:placeholder
          let:userExplicitlySelectedAllDisciplines
        >
          <DisciplinesPicker
            bind:value={input.disciplines}
            {placeholder}
            enabledOptions={enabledOptions?.filter(o => personaDisciplines == null || personaDisciplines.includes(o.value))}
            hideDisabledOptions
          >
            <svelte:fragment slot="prefix">
              {#if input.disciplines.length}
                {#if userExplicitlySelectedAllDisciplines}
                  Use
                {:else}
                  Only use
                {/if}
              {/if}
            </svelte:fragment>
          </DisciplinesPicker>
        </InheritedTagPicker>
      </FormGroup>

      {#if canManageAgreements}
        <FormGroup>
          <label for="agreement-ids" class="flex-row flex-align-center g05">
            <Icon name="agreement" lg autoColor />
            Which agreements can use this service?
          </label>
          <AgreementPicker
            bind:value={input.agreementIds}
            multiple
            modalTitle="Which agreements can use this service?"
            noServicesMsg="When you create agreements, you’ll be able to include them in this service."
            filters={[activeStatusesOnly]}
            placeholder="Any agreement"
          />
        </FormGroup>
      {/if}
    {/if}

    {#if service?.serviceId}
      <div>
        <label class="flex-row flex-align-center g05" for="capacity-ids">
          <Icon name="list" class="color-text-purple" />
          Opportunities in this service
        </label>
        <OpportunitiesView serviceId={service.serviceId} baseHref="/services/{service.serviceId}" />
      </div>
    {/if}
  </div>

  <div class="modal-footer flex-row flex-align-center g05">
    <SubmitBtn icon="save" disabled={!hasChanges} loading={saving} dataTest="save-service-btn">Save</SubmitBtn>
    <Btn icon="close" on:click={onClose}>Cancel</Btn>
  </div>
</Form>

<script>
  import Form from 'components/Form.svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import Loading from 'components/Loading.svelte'
  import OwnerOrgTeamPicker from 'components/OwnerOrgTeamPicker.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import AgreementPicker, { activeStatusesOnly } from 'components/fields/AgreementPicker.svelte'
  import DisciplinesPicker from 'components/fields/DisciplinesPicker.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import OpportunitiesView from 'pages/authorized/org/OpportunitiesView.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import personaService from 'services/persona-service.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SpecialtiesPicker from 'components/fields/SpecialtiesPicker.svelte'
  import { FeatureType, Permission, ServiceNameStrategy } from 'config/enums.js'
  import api from 'services/api.js'
  import { pluralCount } from 'services/string-utils.js'
  import toaster from 'services/toaster.js'
  import validator from 'services/validator.js'
  import confirms from 'stores/confirms.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import user from 'stores/user.js'
  import InheritedTagPicker from './InheritedTagPicker.svelte'

  export let service
  export let onClose = _.noop
  export let onSaved = _.noop

  const form = 'ServiceForm'

  let saving = false
  let input
  let inputInitial = {}
  let orgSettings = null
  let selectedTeam

  loadOrgSettings()

  $: serviceId = service?.serviceId
  $: isEditing = serviceId > 0
  $: canManageAgreements = personaService.canUseAnyFeatureType(FeatureType.AgreementManagement)
  $: if (service) setInput()
  $: hasChanges = !validator.equals(comparableInput(input), comparableInput(inputInitial))
  $: hasChanges ? unsavedForms.add(form) : unsavedForms.del(form)
  $: mustChooseServiceName = orgSettings?.serviceNameStrategy === ServiceNameStrategy.RequireStaffToChooseFromAList && orgSettings?.serviceNames
  $: serviceNameIsNotEmpty = !validator.empty(input.name)
  $: manageServiceRoles = isEditing
    ? personaService.getRolesWithPermission(Permission.ManageOpportunitiesAndServices, service.orgId, service.teamId, { serviceId })
    : null
  $: personaDisciplines =
    manageServiceRoles == null || manageServiceRoles.some(osr => !osr.disciplines?.length)
      ? null
      : _.uniq(manageServiceRoles.flatMap(osr => osr.disciplines ?? []))

  function comparableInput(value) {
    const agreementIds = _.cloneDeep(value.agreementIds).sort((a, b) => a - b)
    const comparable = {
      ...value,
      agreementIds,
    }
    delete comparable.capacities
    return comparable
  }

  function confirmSubmit() {
    const count = input?.capacities?.length
    // TODO: show a breakdown of what these rotations are. could at least show capacity.statusCounts
    if (count)
      confirms.add({
        title: 'Update service',
        message: `This service is in use by ${pluralCount('opportunity', count)}. Are you sure you’d like to update it?`,
        confirmLabel: 'Update',
        confirmClass: 'btn-warning',
        onConfirm: () => submit(),
      })
    else submit()
  }

  async function submit() {
    const data = _.cloneDeep(input)
    if (!validator.required(data.orgId) || !serviceNameIsNotEmpty) return false
    delete data.capacities // capacities are saved individually, so don't pass them to server unnecessarily
    const toastMsg = isEditing ? 'Service updated' : 'Service added'
    const task = isEditing ? api.service.update({ serviceId: data.serviceId }, data, api.noMonitor) : api.service.add(data, api.noMonitor)
    saving = true
    let savedService = null
    let success = false
    try {
      savedService = await task
      success = true
    } finally {
      saving = false
    }
    if (success) {
      toaster.toast({ message: toastMsg, type: 'success', icon: 'check' })
      unsavedForms.del(form)
      user.load() // refresh permissions in case we had to add the serviceId to explicit service role
      onSaved({ service: savedService })
      onClose()
    }
  }

  function setInput() {
    input = _.cloneDeep(service)
    if (!isEditing) {
      input.orgId ??= $personaFilters.orgId
      input.teamId ??= $personaFilters.teamId
    }
    input.name ??= ''
    input.description ??= ''
    input.specialties ??= []
    input.disciplines ??= []
    input.agreementIds ??= []
    inputInitial = _.cloneDeep(input)
  }

  function onDefaultOrgId(orgId) {
    inputInitial.orgId ??= orgId
  }

  function onDefaultTeamId(teamId) {
    inputInitial.teamId ??= teamId
  }

  async function loadOrgSettings() {
    orgSettings = await api.org.getSettings({ orgId: $persona.orgId }, api.noMonitor)
  }
</script>
