<textarea
  bind:this={inputEl}
  use:autosize
  {name}
  id={name}
  data-test={name}
  class="form-control {className}
  {disabled ? 'disabled' : ''}"
  bind:value
  {placeholder}
  {disabled}
  {maxlength}
  style="{maxHeight ? `max-height: ${maxHeight}px` : ''} {disabled ? 'resize: none' : ''}"
/>
<!-- probably resize:none must be set by default?
  autosize should handle this, right?
 -->
<MaxLength {value} {maxlength} />

<script>
  import autosize from 'decorators/autosize.js'
  import { getContext } from 'svelte'
  import MaxLength from './MaxLength.svelte'

  export let autofocus = false
  export let value = null
  export let name = null
  export let placeholder = null
  export let disabled = false
  export { className as class }
  export let maxlength = null
  export let inputEl = null
  export let maxHeight = null

  const initialValue = value
  const markDirty = getContext('markDirty')
  let className = ''

  $: if (autofocus && inputEl) focus()
  $: if (markDirty != null && value != null && value != initialValue) markDirty()

  export function focus() {
    setTimeout(() => inputEl?.focus?.())
  }
</script>
