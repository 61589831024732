<div class={className} class:has-error={invalid && canBeVisiblyInvalid} class:input-group-sm={sm} style={rootStyle}>
  {#if !condense}
    <span class="input-group-addon" on:click={focus}>
      <Icon name={icon} fw />
    </span>
  {/if}
  <!--
    type=text because type=number is kinda crappy. Decimal support is weak.
    ff/edge allow you to type in non-numeric characters and only clear on-blur
  -->
  <input
    bind:this={inputElem}
    type="text"
    class:disabled
    class:text-danger={invalid && canBeVisiblyInvalid}
    class="form-control"
    {name}
    id={name}
    data-test={name}
    bind:value={_value}
    on:click
    on:focus
    on:blur={() => (blurred = true)}
    {placeholder}
    {style}
    {autocomplete}
    {disabled}
    on:keydown={onKeyDown}
    on:keyup={onKeyUp}
    on:blur={internalChanged}
  />
  <slot {focus} />
</div>

<script>
  import validator from 'services/validator.js'
  import Key, { isNumberKey, isMaskSafeKey } from 'config/key.js'
  import Icon from 'components/Icon.svelte'
  import { getContext, createEventDispatcher, onDestroy } from 'svelte'

  export let autofocus = false
  export let selectAllOnFocus = false
  export let autocomplete = null
  export let value = null
  export let name = null
  export let placeholder = null
  export let disabled = false
  let className = 'input-group'
  export { className as class }
  export let allowDecimal = false
  export let condense = false
  export let min = -Infinity
  export let max = Infinity
  export let style = null
  export let step = 1
  export let sm = false
  export let icon = 'hashtag'
  export let canBeVisiblyInvalid = true
  export let width = null // should probably default to like 200, but don't want to go through and audit all usages to make sure they look ok currently...

  const initialValue = value
  // Allow digits. And decimal points if allowing decimals. And hyphen for negative numbers
  const nonNumericChars = allowDecimal ? /[^\d-.]/g : /[^\d-]/g
  const markDirty = getContext('markDirty')
  const dispatch = createEventDispatcher()

  let inputElem
  let blurred = false

  onDestroy(() => inputElem?.removeEventListener('focus', selectAll)) // want the dispatched focus event to be the normal browser event, not wrapped svelte event (mainly because I don't want to update calling code that uses it)

  // Using this to prevent parent component from briefly seeing a string value
  // That is, the parent should only see a Number, null, or NaN.
  let _value = Number.isNaN(value) ? '' : value

  $: if (inputElem && selectAllOnFocus) inputElem.addEventListener('focus', selectAll)
  $: _min = _.isString(min) ? parseFloat(min) : min == null ? -Infinity : min
  $: _max = _.isString(max) ? parseFloat(max) : max == null ? Infinity : max
  $: if (autofocus && inputElem) focus()
  $: _value, _min, _max, internalChanged()
  $: value, _min, _max, externalChanged()
  $: invalid = isInvalid(value, _min, _max) || isInvalid(_value, _min, _max)
  $: if (markDirty != null && blurred && value != initialValue) markDirty()
  $: rootStyle = width ? `width: ${width}px` : null

  export function focus() {
    setTimeout(() => inputElem?.focus?.())
  }

  function isInvalid(v, _min, _max) {
    if (Number.isNaN(v)) return true
    if (validator.empty(v)) return false // empty values are valid
    const str = v.toString()
    if (nonNumericChars.test(str)) return true
    try {
      const parsed = parseFloat(str)
      if (_min != null && parsed < _min) return true
      if (_max != null && parsed > _max) return true
    } catch {
      return true
    }
    return false
  }

  function internalChanged() {
    if (isInvalid(_value, _min, _max)) {
      if (!Number.isNaN(value)) value = NaN
      return
    }
    const newValue = validator.empty(_value) ? null : parseFloat(_value)
    if (value !== newValue) value = newValue
  }

  function externalChanged() {
    if (Number.isNaN(value)) return // Just leave the text alone and show invalid
    if (value == null) {
      if (_value !== '') _value = ''
      return
    }
    const parsed = parseFloat(value)
    const clamped = Math.clamp(parsed, _min, _max)
    const withoutTrailingPeriod = clamped.toString().replace(/\.$/, '')
    if (_value !== withoutTrailingPeriod) _value = withoutTrailingPeriod
  }

  function getNonEmptyValue() {
    if (validator.empty(_value)) return 0
    const parsed = parseFloat(_value)
    return isNaN(parsed) ? 0 : parsed
  }

  function stepValue(multiplier) {
    const delta = step * multiplier
    const newValue = getNonEmptyValue() + delta
    const clamped = Math.clamp(newValue, _min, _max)
    if (_value != clamped) _value = clamped
  }

  function onKeyDown(e) {
    dispatch('keydown', e)
    const k = e.which
    const key = e.key
    const ctrl = e.ctrlKey
    const shift = e.shiftKey
    const modifiers = ctrl || shift
    if (!modifiers && isNumberKey(k)) return
    if (!modifiers && allowDecimal && (k === Key.Period || k === Key.PeriodNumPad) && !_value.includes('.')) return
    if (!modifiers && min < 0 && (k === Key.Hyphen || k === Key.HyphenNumPad) && !_value.includes('-') && inputElem.selectionStart === 0) return
    // Allow select-all/cut/copy/paste/undo/redo
    if (ctrl && 'axcvyz'.includes(key)) return
    const multiplier = ctrl ? 5 : 1
    if (k === Key.Down) {
      !stepValue(-multiplier)
      return
    }
    if (k === Key.Up) {
      !stepValue(multiplier)
      return
    }
    // This must be last or else it'll return early
    if (isMaskSafeKey(k)) return
    e.preventDefault()
  }

  function onKeyUp(e) {
    internalChanged()
    dispatch('keyup', e)
  }

  function selectAll() {
    inputElem?.setSelectionRange(0, inputElem?.value?.length)
  }
</script>
