{#if show}
  <span class="today-icon" class:pad use:tip={'Today'}>
    <Icon name="caret-down" />
  </span>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  export let show = false
  export let pad = false
</script>

<style>
  .today-icon {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    transform: rotate(-45deg);
  }

  .pad {
    padding: 3px;
  }
</style>
