<BtnGroupPicker {options} bind:value class={className} {btnClass} on:change defaultValue={null} on:tab-off-start on:tab-off-end />

<script>
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'

  export let value = null
  export let btnClass = 'btn-sm'
  let className = 'mr1 mb1'
  export { className as class }

  const buildOption = (value, label, dataTest) => ({ value, label, dataTest })
  $: options = [
    buildOption(null, 'Show all', 'show-all-btn'),
    buildOption(true, 'Selected', 'show-selected-btn'),
    buildOption(false, 'Unselected', 'show-unselected-btn'),
  ]
</script>
