{#if message}
  <div data-test="subscription-status-warning" class="alert alert-{alertType} text-center my1">
    <div class:mb1={!isOnSubscriptionPage}>
      {message}
    </div>
    {#if !isOnSubscriptionPage}
      <Btn {href} class="btn-sm btn-{alertType}" dataTest="sub-warn-btn">{buttonLabel}</Btn>
    {/if}
  </div>
{/if}

<script>
  import { SubscriptionStatus } from 'config/enums.js'
  import dateService from 'services/date-service.js'
  import persona from 'stores/persona.js'
  import Btn from './bootstrap/Btn.svelte'
  import { getLocation } from 'stores/req.js'
  import { needsPayment } from 'services/subscription-service.js'
  // import subscriptionStatusColors from 'config/subscription-status-colors.js'

  const location = getLocation()
  $: status = $persona?.subscriptionStatus
  $: stripeStatus = $persona?.subscriptionStripeStatus
  $: needPayment = needsPayment(stripeStatus)
  $: isTrial = status === SubscriptionStatus.Trial
  $: trialEnd = $persona?.subscriptionTrialEnd
  $: trialEndFromnow = trialEnd ? dateService.fromnow(trialEnd) : 'soon' // shouldn't be null ever, but just in case
  $: alertType = isTrial ? 'info' : 'warning'
  $: href = '/settings/subscription'
  $: buttonLabel = status === SubscriptionStatus.Canceled ? 'Reactivate' : 'Manage subscription'
  $: isOnSubscriptionPage = ($location.pathname || '/').toLowerCase().includes('/settings/subscription')
  $: message = isTrial
    ? `Trial expires ${trialEndFromnow}.`
    : status === SubscriptionStatus.Canceled
    ? 'Your plan is canceled.'
    : status === SubscriptionStatus.Expired
    ? 'Your plan is expired.'
    : needPayment
    ? 'Your subscription requires a verified payment method.'
    : null
</script>
