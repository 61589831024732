<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={team} let:item={selectedTeam}>
  <TeamProfilePictureAndName
    medium
    orgId={team.orgId}
    name={team.name}
    orgRelativeName={team.orgRelativeName}
    specialName={appliedMeta.specialName}
  />

  <TeamProfilePictureAndName
    slot="selectedItem"
    xs
    orgId={selectedTeam?.orgId}
    name={selectedTeam?.name}
    class="inline-flex-row flex-align-center text-left"
    nameClass="strong"
    specialName={appliedMeta.specialName}
    deleted={selectedTeam == null}
  />
</FilterTypeListFilter>

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import { FeatureType } from 'config/enums.js'
  import personaService from 'services/persona-service'

  export const meta = {
    ...baseMeta,
    type: FilterType.Teams,
    idKey: 'teamId',
    label: 'Health team',
    icon: 'users',
    iconClass: 'color-text-orange',
    allowNull: true,
    excludable: true,
    filterProp: 'teamIds',
    optionsEndpoint: 'getTeams',
    specialName: 'All teams',
    shouldShow: () => personaService.canUseAnyFeatureType(FeatureType.TeamManagement),
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
