<Btn
  class="text-left btn btn-sm flex-row {status.color ? `btn-${status.color}` : ''} {className}"
  stopPropagation
  on:click
  title={status.desc ?? `Log hours for ${user.name}${date ? ` on ${date}` : ''}`}
>
  <div class="person flex-row">
    <ProfilePic
      small={forSingleDay}
      medium={!forSingleDay}
      initials={user.initials}
      id={user.userId}
      name={user.name}
      src={user.profilePicture}
      class="mr1"
    />
    <div>
      <div class:strong={!forSingleDay}><Icon name="time" /> {user.name}</div>
      <HourLoggerUserSummary
        totalExpectedHours={user.totalExpectedHours}
        totalLoggedHours={status.totalHrs}
        {totalScheduledHours}
        minimal={forSingleDay}
      />
    </div>
  </div>
</Btn>

<script>
  import Btn from './bootstrap/Btn.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import { getHoursSummary } from 'services/hourlog-service.js'
  import HourLoggerUserSummary from './HourLoggerUserSummary.svelte'
  import Icon from './Icon.svelte'

  export let user
  export let match
  export let matchDaysComputed
  export let date = null
  export { className as class }
  export let totalScheduledHours = null

  let className = ''

  $: status = getHoursSummary(user, match, matchDaysComputed, date)
  $: forSingleDay = date != null
</script>
