// return only results that, when serialized to json, contain the search text passed
export default function (list, search, omit = []) {
  list = list || null
  if (list == null) return null
  search = search?.trim()?.toLowerCase()
  if (!search) return list
  omit = ['profilePicture', ...omit] // omit profilePicture from the serialization to avoid false positives
  return _.filter(list, item =>
    _.isString(item)
      ? item.toLowerCase().includes(search)
      : JSON.stringify(_.omit(item, omit)).toLowerCase().includes(search)
  )
}