<Btn clearBtnStyling class="bucket" title={getTitle(bucket)} to="{encodeURIComponent(key)}/{bucket.groupedById}" dataTest="bucket">
  <div class="progress-label-container">
    <div class="progress-label {textColor ? `color-text-${textColor}` : ''}">
      {#if bucket.finite && bucket.finite.shiftsOverfilledCount > 0}
        <Icon name="alert-triangle" class="text-danger" dataTest="triangle-danger" />
      {/if}
      {#if bucket.finite && bucket.finite.shiftsOverfilledMaybeCount > 0}
        <Icon name="alert-triangle" class="text-warning" dataTest="triangle-warning" />
      {/if}
      {#if (bucket.finite && bucket.finite.matchesViolatingCapacitySchedule.length > 0) || (bucket.infinite && bucket.infinite.matchesViolatingCapacitySchedule.length > 0)}
        <Icon name="calendar" class="text-warning" dataTest="calendar-warning" />
      {/if}
      {#if (bucket.finite && bucket.finite.roomForWaitlisted > 0) || (bucket.infinite && bucket.infinite.roomForWaitlisted > 0)}
        <Icon name="Waitlisted" class="text-sky" dataTest="waitlisted" />
      {/if}
      {bucket.title}
    </div>
  </div>
  <MatchUsageBar bucket={bucket.finite} {criteria} />
  <MatchUsageBar bucket={bucket.infinite} {criteria} infinite />
</Btn>

<script>
  import Icon from 'components/Icon.svelte'
  import Btn from './bootstrap/Btn.svelte'
  import { pluralCount } from 'services/string-utils.js'
  import matchRequestTypeHelpers from 'services/match-request-type-helpers.js'
  import MatchUsageBar from 'components/MatchUsageBar.svelte'

  export let bucket
  export let key
  export let textColor
  export let criteria

  export function getTitle(bucket) {
    let parts = [
      bucket.host == null ? null : `<strong>Clinic:</strong> ${bucket.host}`,
      bucket.hostTeam == null ? null : `<strong>Clinic team:</strong> ${bucket.hostTeam}`,
      bucket.guest == null ? null : `<strong>School:</strong> ${bucket.guest}`,
      bucket.guestTeam == null ? null : `<strong>School team:</strong> ${bucket.guestTeam}`,
      bucket.userFullName == null ? null : `<strong>Name:</strong> ${bucket.userFullName}`,
      bucket.discipline == null ? null : `<strong>Discipline:</strong> ${bucket.discipline}`,
      bucket.service == null ? null : `<strong>Service:</strong> ${bucket.service}`,
      bucket.agreement == null ? null : `<strong>Agreement:</strong> ${bucket.agreement}`,
      bucket.capacity == null ? null : `<strong>Opportunity:</strong> ${bucket.capacity}`,
      bucket.matchRequestType == null
        ? null
        : `<strong>Request type:</strong> ${matchRequestTypeHelpers.optionsByValue[bucket.matchRequestType].label}`,
      bucket.shift == null ? null : `<strong>Shift:</strong> ${bucket.shift}${bucket.timeRange ? ` (${bucket.timeRange})` : ''}`,
      ...getWarnings(bucket),
    ].filter(x => x !== null)

    // if only one, just show it without its label
    if (parts.length === 1) parts = parts.map(p => p.replace(/^<strong>[^<]+<\/strong>/, ''))
    return `<div class="text-left">${parts.join('<br/>')}</div>`
  }

  function getWarnings(b) {
    if (b == null) return []
    const warnings = [
      b.finite ? getWarning(b.finite.shiftsOverfilledCount, t => `<span class="text-danger">${pluralCount('shift', t)} overfilled</span>`) : null,
      b.finite
        ? getWarning(b.finite.shiftsOverfilledMaybeCount, t => `<span class="text-warning">${pluralCount('shift', t)} might overfill</span>`)
        : null,
      getWarning(
        b.matchesViolatingCapacityScheduleCount,
        t => `<span class="text-warning">${pluralCount('opportunity schedule violation', t)}</span>`
      ),
      getWarning(
        b.roomForWaitlisted,
        t => `<span class="text-sky">Room for ${pluralCount('shift day', t)}. There may be room for waitlisted rotations</span>`
      ),
      b.infinite
        ? getWarning(
            b.infinite.shiftsInfiniteCount,
            t => `<span class="text-info">${pluralCount('shift', t)} ${t > 1 ? "don't" : "doesn't"} specify a per day limit</span>`
          )
        : null,
      // getWarning(b.finite?.maxMatchCountPerDay ?? 0, t => `<span class="text-success">${pluralCount('', t)}</span>`), // we really should show rotationCount limit here. Telling how many group/persons can do shifts the given date range are available seems awkward and confusing...
    ]
    return warnings
  }

  function getWarning(total, msgFunc) {
    return total === 0 ? null : msgFunc(total)
  }
</script>

<style>
  .bucket {
    cursor: pointer;
    margin: 10px 5px;
  }

  .progress-lg {
    height: 15px;
  }

  .progress-label-container {
    width: 100%;
    height: 18px;
    position: relative;
  }

  .progress-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #333;
    font-weight: bold;
    line-height: 18px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
