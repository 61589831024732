<FilterTypeFieldComparison {filter} {editing} {appliedMeta} let:option>
  <svelte:fragment slot="field">
    <NameSuggestionFieldLabel label={option.optionLabel} />
  </svelte:fragment>
</FilterTypeFieldComparison>

<script context="module">
  import { FilterType, NameSuggestionField } from 'config/enums.js'
  import enumToOptions from 'services/enum-to-options.js'
  import FilterTypeFieldComparison, { meta as baseMeta } from 'components/filter-types/FilterTypeFieldComparison.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.NameSuggestionFieldComparison,
    fields: enumToOptions(NameSuggestionField),
  }
</script>

<script>
  import NameSuggestionFieldLabel from 'components/NameSuggestionFieldLabel.svelte'

  export let filter
  export let editing = false
  export let appliedMeta
</script>
