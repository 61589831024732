<div class={className}>
  {#if !hideTotalCount}
    <HelpBlock name="result-count-{relativeName}" class="m0 nowrap">
      {#if totalCount > 0}
        {`${pluralCount(relativeName, totalCount)}`}
      {:else}
        No {pluralize(relativeName)}
      {/if}
    </HelpBlock>
  {/if}
  {#if totalCount > 0}
    <div class="flex-row flex-align-center g03">
      <!-- on mouseover, the pics expand to show all of them for easier quick filtering -->
      <!-- relative positioned container that reserves space for the initial size of pics -->
      <div class="relative" style="width: {initialWidth}px; height: {initialHeight}px;">
        <div class="more-container" bind:this={moreContainer} style="--left-margin: {filtersComponent && quickFilterProps ? -28 : -20}px">
          {#each rows as user, i}
            <div>
              {#if filtersComponent && quickFilterProps}
                <QuickFilterTag
                  {...quickFilterProps}
                  type={quickFilterProps.type + ', ' + user.name}
                  value={user.userId}
                  {href}
                  {filtersComponent}
                  configKey="userIds"
                  configValue={user.userId}
                  simulationFilterOption={{
                    userId: user.userId,
                    name: user.name,
                    profilePicture: user.profilePicture,
                  }}
                  dataTest="{relativeName}-filter-tag"
                  quickDropdownClass="block"
                >
                  <svelte:fragment slot="tooltipFilterByPostfix">
                    <span>
                      {quickFilterProps.type},
                      <strong class="strongish">{user.name}</strong>
                    </span>
                  </svelte:fragment>
                  <!-- TODO(nursing): What was meant by this comment? -->
                  <!-- check if simulationFilterOption needs more/less info... -->
                  <ProfilePic
                    name={user.name}
                    initials={user.initials}
                    src={user.profilePicture}
                    {xs}
                    {small}
                    {medium}
                    {large}
                    {href}
                    {containerStyle}
                    title={$isQuickFiltering ? null : user.name}
                    class="block"
                  />
                </QuickFilterTag>
              {:else}
                <ProfilePic
                  name={user.name}
                  initials={user.initials}
                  src={user.profilePicture}
                  {xs}
                  {small}
                  {medium}
                  {large}
                  {href}
                  {containerStyle}
                  class="block"
                  title={user.name}
                />
              {/if}
            </div>
          {/each}
        </div>
      </div>
      {#if totalCount > rows.length}
        <span> + {totalCount - rows.length} </span>
      {/if}
    </div>
  {/if}
</div>

<!-- TODO: bug -
  add quickfilter on student1
  click quickfilter on student2
  hover over 'add to existing filter' - note that it shows "student1 or student2"
  click 'add to existing filter' - note that it now only shows "student1"
  but if you click into the filter, or save the view, it populates the 2nd one
  so something about the way it's modifying the existing filter isn't right

  see if that's happening in other quick filters too -->
<script>
  import { FilterType } from 'config/enums'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import { allFilterTypes } from 'config/all-filter-types'
  import { pluralCount, pluralize } from 'services/string-utils'
  import HelpBlock from './fields/HelpBlock.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import QuickFilterTag from './QuickFilterTag.svelte'

  export let relativeName = 'user'
  export let size = 'small'
  export let href = null
  let className = 'flex-column flex-align-start g05'
  export { className as class }
  export let hideTotalCount = false
  export let rowsToShow = 2
  export let filtersComponent

  const getFilterTypeByType = type => allFilterTypes.find(ft => ft.type === type)
  const facultyMeta = getFilterTypeByType(FilterType.SchoolFaculty)
  const studentMeta = getFilterTypeByType(FilterType.Students)
  const preceptorMeta = getFilterTypeByType(FilterType.MatchPreceptors)
  const containerStyle = 'box-shadow: 0 0 0 1px white; border-radius: 5.5px;'

  $: totalCount = users.length
  $: rows = users.length > rowsToShow ? users.slice(0, rowsToShow) : users

  const allQuickFilterProps = {
    student: {
      type: 'Student',
      filterType: FilterType.Students,
      icon: studentMeta.icon,
      class: studentMeta.iconClass,
    },
    faculty: {
      type: 'Faculty',
      filterType: FilterType.SchoolFaculty,
      icon: facultyMeta.icon,
      class: facultyMeta.iconClass,
    },
    preceptor: {
      type: 'Preceptor',
      filterType: FilterType.MatchPreceptors,
      icon: preceptorMeta.icon,
      class: preceptorMeta.iconClass,
    },
  }
  $: quickFilterProps = allQuickFilterProps[relativeName]
  $: xs = size === 'xs'
  $: small = size === 'small'
  $: medium = size === 'medium'
  $: large = size === 'large'
  export let users

  let initialWidth = 0
  let initialHeight = 0
  let moreContainer
  $: if (moreContainer != null) {
    initialWidth = moreContainer.clientWidth
    initialHeight = moreContainer.clientHeight
  }
</script>

<style lang="scss">
  @import '../../css/helpers';

  .more-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: absolute;

    > div {
      transition: all 50ms;
      position: relative;

      &:not(:last-child) {
        margin-left: var(--left-margin);
      }
    }

    &:hover {
      z-index: 10;

      > div:not(:last-child) {
        margin-left: -5px;
      }
    }
  }
</style>
