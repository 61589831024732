import { writable } from 'svelte/store'

export const defaultPersona = {
  personaType: null,
  orgId: null,
  org: null,
  subscriptionFeatureTypes: [],
  links: [],
  permissions: [],
}
export default writable(defaultPersona)
