<div class="mb1 flex-row flex-wrap flex-align-center g1">
  <div>
    <label for="group-by">Group steps by</label>
    <QuickDropdown label={value.groupBy} dataTest="group-by" anyClickCloses dropdownClass="p1">
      <InputRadioGroup name="group-by" options={groupByOptions} bind:value={value.groupBy} />
    </QuickDropdown>
  </div>
  <InputToggle name="toggle-all-steps" bind:checked={value.showAllSteps}>Show steps that can’t currently be completed</InputToggle>
</div>

<script>
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'

  export let value

  const groupByOptions = ['user', 'step', 'completion status']
</script>
