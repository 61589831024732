{#if showLeft || showRight}
  <div class="details-container" class:no-left={showLeft}>
    {#if showLeft}
      <div class="flex-column g1 details-left">
        {#if show.description}
          <LongDesc class="m0" max={longDescMax} text={capacity.descriptionHtml} />
        {/if}

        {#if show.startAndEndDate}
          <div>
            <h4 class="leading-none m0">Schedule</h4>
            <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />
          </div>
        {/if}

        {#if show.matchLeadTimeDays}
          <div>
            <h4 class="leading-none m0">Lead time</h4>
            <Icon lg name="calendar-clock" class="color-text-dark-green" />
            {#if capacity.matchLeadTimeDays === 0}
              <em>None</em>
            {:else}
              <span>{pluralCount('day', capacity.matchLeadTimeDays)}</span>
            {/if}
          </div>
        {/if}

        {#if show.rotations}
          <div>
            <h4 class="leading-none m0">Rotations</h4>
            <CapacityMatchesSummary {capacity} {capacityGuest} progressClass={isHovered || isSelected ? 'bg-white' : null} />
          </div>
        {/if}

        {#if (show.rotationTypes && capacity.rotationTypes?.length) || (show.specialties && specialties?.length) || (show.disciplines && disciplines?.length)}
          <div>
            <TagCloud
              ctx={{
                rotationTypes: show.rotationTypes ? capacity.rotationTypes : [],
                specialties: show.specialties ? specialties : [],
                disciplines: show.disciplines ? disciplines : [],
              }}
            />
          </div>
        {/if}

        {#if show.service && capacity.service}
          <div class="flex-row flex-align-center g05">
            <Icon name="shapes" class="color-text-purple" />
            <h4 class="leading-none m0">{capacity.service.name}</h4>
          </div>

          {#if show.serviceDescription}
            <LongDesc class="m0" max={longDescMax} text={capacity.service.descriptionHtml} />
          {/if}
        {/if}
      </div>
    {/if}

    {#if showRight}
      <div class="flex-column g2 details-right">
        {#if show.organization || (show.team && capacity.teamOrgId)}
          <div class="capacity-owner">
            <div class="flex-row flex-align-center g05 mb05">
              <Icon name="hospital" class="color-text-orange" />
              <h4 class="leading-none m0">Provided by</h4>
            </div>

            <div class="flex-column g05">
              {#if show.organization}
                <OrgProfilePictureAndName
                  name={capacity.orgName}
                  relativeName={capacity.orgRelativeName}
                  profilePicture={capacity.orgProfilePicture}
                  formattedAddress={capacity.orgAddress?.formattedAddress}
                  size="medium"
                  useOverflowEllipsis
                />
              {/if}

              {#if show.team && capacity.teamOrgId}
                <TeamProfilePictureAndName medium orgId={capacity.teamOrgId} name={capacity.teamName} />
              {/if}
            </div>
          </div>
        {/if}

        {#if show.locations && capacity.locations.some(l => l.orgId !== capacity.orgId)}
          <div class="capacity-locations">
            <div class="flex-row flex-align-center g05 mb05">
              <Icon name="hospital" class="color-text-orange" />
              <h4 class="leading-none m0">
                {pluralCount('Location', capacity.locations, 'omitNumber')}
                <Badge count={capacity.locations} />
              </h4>
            </div>

            <div class="flex-column g1">
              <FriendlyList
                items={capacity.locations}
                max={5}
                otherPrefix="Show "
                other="other location"
                otherPlural="other locations"
                let:item={location}
              >
                <OrgProfilePictureAndName
                  name={location.name}
                  relativeName={location.relativeName}
                  profilePicture={location.profilePicture}
                  formattedAddress={location.address?.formattedAddress}
                  size="medium"
                  useOverflowEllipsis
                />
              </FriendlyList>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}

<script>
  import { computeCapacityDisciplines, computeCapacitySpecialties } from 'components/InheritedTagPicker.svelte'
  import { pluralCount } from 'services/string-utils.js'
  import Badge from 'components/Badge.svelte'
  import CapacityMatchesSummary from 'components/CapacityMatchesSummary.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import personaFilters from 'stores/persona-filters.js'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import TagCloud from 'components/TagCloud.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'

  export let capacity = null
  export let guestOrg = null
  export let isSelected
  export let isHovered

  $: show = $showDropdowns.capacityPicker
  $: showLeft =
    show.description || show.startAndEndDate || show.rotations || show.rotationTypes || show.specialties || show.disciplines || show.service
  $: showRight = show.organization || (show.team && capacity.teamOrgId) || (show.locations && capacity.locations.length)
  $: specialties = computeCapacitySpecialties(capacity)
  $: disciplines = computeCapacityDisciplines(capacity)
  // TODO(nursing): There's potential $personaFilters.orgId wouldn't match an actual guest,
  //                in which case it should probably climb up the tree to potentially find one.
  $: guestOrgId = guestOrg ? guestOrg.orgId : $personaFilters.orgId
  $: capacityGuest = guestOrgId ? capacity.guests?.find(g => g.guestOrgId === guestOrgId) : null

  const longDescMax = 60
</script>

<style lang="scss">
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(services): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';

    &.no-left {
      grid-template-columns: auto;
      grid-template-areas: 'right';
    }
  }

  .details-left {
    grid-area: left;
  }

  .details-right {
    grid-area: right;
  }
</style>
