<div class="toast border-{toast.type}" use:onMayInteract={e => (e.focusIn || e.mouseIn ? onMouseOver() : onMouseOut())} style="opacity: {opacity};">
  <div class="toast-body">
    <div class="toast-text" on:click={onClick} class:clickable>
      {#if toast.icon}
        <Icon name={toast.icon} fw lg class="text-{toast.type} mr1" />
      {/if}
      <div>
        <slot>
          {#if toast.html}
            <SafeHtml value={toast.message} />
          {:else}{toast.message}{/if}
        </slot>
      </div>
    </div>
    {#if onDismiss}
      <div class="toast-dismiss" data-test="toast-dismiss" on:click={dismiss}>
        <Icon name="close" title="Dismiss" lg />
      </div>
    {/if}
  </div>
  {#if toast.duration}
    <Progress lowProfile>
      <ProgressBar {percent} />
    </Progress>
  {/if}
</div>

<script>
  import SafeHtml from 'components/SafeHtml.svelte'
  import { onDestroy } from 'svelte'
  import { navigate } from 'svelte-routing'
  import Icon from 'components/Icon.svelte'
  import notifications from 'stores/notifications.js'
  import Progress from 'components/Progress.svelte'
  import ProgressBar from 'components/ProgressBar.svelte'
  import onMayInteract from 'decorators/on-may-interact.js'

  export let toast
  export let onDismiss = null
  export let onMessageClick = null

  let timer
  let remaining = toast.duration
  let opacity = 1

  $: percent = (remaining / toast.duration) * 100
  $: clickable = onMessageClick || toast.notification || toast.url || toast.onClick

  startTimer()

  function onClick() {
    if (toast.notification != null) notifications.markClicked(toast.notification)
    if (toast.url != null) navigate(toast.url) // if already on the same url, nothing happens. But favor using websockets to update views, so it won't matter
    if (toast.onClick != null) toast.onClick()
    onMessageClick?.()
  }

  function onMouseOver() {
    stopTimer()
    opacity = 1
  }

  function onMouseOut() {
    startTimer()
  }

  function startTimer() {
    if (toast.duration == null) return
    timer = setInterval(() => {
      remaining -= 100
      if (remaining < 0) {
        clearInterval(timer)
        timer = setInterval(() => {
          opacity -= 0.1
          if (opacity < 0) {
            if (toast.onWaitComplete) toast.onWaitComplete()
            onDismiss?.()
            clearInterval(timer)
          }
        }, 100)
      }
    }, 100)
  }

  function stopTimer() {
    if (toast.duration == null) return
    clearInterval(timer)
  }

  function dismiss(e) {
    e.stopPropagation()
    if (toast.onCancel) toast.onCancel()
    onDismiss?.()
  }

  onDestroy(() => stopTimer())
</script>

<style lang="scss">
  @import '../../css/helpers';

  // toast - use in conjunction with bg-{{color}}
  .toast {
    @include med-box-shadow();
    border-left: 5px solid $info;
    border-radius: 3px;
    margin: 0 0 15px 0;
    background: #f7f7f7;

    .toast-body {
      display: flex;
      flex-direction: row;
      align-items: center;

      .toast-text {
        flex-grow: 1;
        display: flex;
        max-width: 400px;
        align-self: flex-start;
        padding-left: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .toast-dismiss {
        cursor: pointer;
        padding: 10px; /*make dismiss button a bit larger clickable area for mobile*/
      }
    }

    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(.progress-bar) {
      background: #cfcfcf;
    }

    &:hover {
      @include big-box-shadow();
    }
  }
</style>
