<div class="my1">
  <InputToggle
    name="quick-filters-toggle"
    tooltip="{$isQuickFiltering ? 'Disable' : 'Enable'} quick filters within each row"
    containerClass="flex-row flex-align-center g05"
    labelClass="flex-row flex-align-center g05"
    class="m0"
    bind:checked={$isQuickFiltering}
    {onMayInteract}
    on:change={onChange}
  >
    Quick filters

    <svelte:fragment slot="after-label">
      <Help tipOptions={{ maxWidth: 450 }}>
        When <em>disabled</em>, click anywhere on the row to view its details.
        <br /><br />
        When <em>enabled</em>, click on the highlighted details to quickly manage filters.
      </Help>
    </svelte:fragment>
  </InputToggle>
</div>

<script>
  import Help from 'components/Help.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import shouldHighlightQuickFilters from 'stores/should-highlight-quick-filters.js'

  let mustBlur = false

  function onMayInteract(e) {
    $shouldHighlightQuickFilters = (!mustBlur && e.focusIn) || e.mouseIn
    if (mustBlur && !e.focusIn) mustBlur = false
  }

  function onChange() {
    $shouldHighlightQuickFilters = false
    mustBlur = true
  }
</script>
