<FilterTypeStatus {filter} {editing} {appliedMeta} options={matchStatusHelper.getOptions($persona)} />

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import { MatchStatus } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.MatchPastStatuses,
    canHaveMultiple: true,
    label: 'Status was ever',
    labelApplied: 'Status was ever',
    editLabel: 'Status was ever',
    icon: 'filter-past-status',
    iconClass: 'color-text-steel',
    filterProp: 'statuses',
    idKey: 'key',
    enumeration: MatchStatus,
    helper: matchStatusHelper,
  }
</script>

<script>
  import matchStatusHelper from 'services/match-status-helper.js'
  import persona from 'stores/persona.js'

  export let filter
  export let editing = false
  export let appliedMeta
</script>
