<RelatedEntityCount {count} {name} {unit} {iconFirst} {iconFirstLabel} icon="list" iconClass="color-text-purple" />

<script>
  import RelatedEntityCount from 'components/RelatedEntityCount.svelte'

  export let name = 'capacity'
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  export let unit = 'Active opportunity'
  export let iconFirst = false
  export let iconFirstLabel = 'active'
</script>
