import { Permission, DashboardViewCategory, FeatureType } from 'config/enums.js'

export default {
  category: DashboardViewCategory.Views,
  permission: Permission.ViewConsortiumRotations,
  featureTypes: [FeatureType.Consortium],
  componentName: 'ConsortiumRotations',
  name: 'Consortium rotations',
  icon: 'chart-bar',
  color: 'green',
  criteria: {
    stacked: true,
    orgs: [],
    statuses: [],
  },
  criteriaConfig: {
    orgs: { name: 'orgs', method: 'readArray' },
    statuses: { name: 'statuses', method: 'readArray' },
    stacked: { name: 'stacked', method: 'readBool' },
  },

  waitFor: { method: 'GET', route: /\/consortiums\/[0-9]+\/rotations/i },
  testFor: ['status-chart'],
}
