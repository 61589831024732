<div on:click class="help-block{inline ? '-inline inline' : ''}{className ? ` ${className}` : ''}" data-test={name}>
  <slot />
</div>

<script>
  let className = 'mb1'
  export { className as class }
  export let name = null
  export let inline = false
</script>
