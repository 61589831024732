function getEmail(user) {
  return user.emailAddress || user.email || user?.emailAddresses.find(ea => ea.isPrimary)?.email
}

export function buildUserDescription(user) {
  // This covers cases that will currently never be encountered,
  // but I'd rather that than a weird phrasing bug crop up in the future.
  const { name } = user
  const email = getEmail(user)
  const willBeSentToThisAddress = user.attemptedAddressIsPrimary || !('attemptedAddressIsPrimary' in user)
  const prefix = willBeSentToThisAddress ? '' : 'the primary email address of the account associated with '
  return name
    ? email
      ? `<strong>${name}</strong> (${prefix ? `at ${prefix}` : ''}<strong>${email}</strong>)`
      : `<strong>${name}</strong>`
    : email
    ? willBeSentToThisAddress
      ? `<strong>${email}</strong>`
      : `${prefix}<strong>${email}</strong>`
    : 'this user'
}

export function buildConfirm(options, prefix, user, postfix = '?') {
  return {
    ...options,
    message: `${prefix}${buildUserDescription(user)}${postfix}`,
  }
}
