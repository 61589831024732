{#if allFileTypes == null}
  <Spinner />
{:else}
  <FileUploadWithUserDocs
    acceptableFileTypes={allFileTypes.filter(ft => acceptableFileTypeIds.includes(ft.fileTypeId))}
    adding={false}
    {onUploadFile}
    {onUploadDocument}
    fileUploadId={name}
    files={value}
  />

  <FileUploadItems files={value} {onDeleteFile} canDelete deleting={false} />
{/if}

<script>
  import Spinner from 'components/Spinner.svelte'
  import FileUploadWithUserDocs from 'components/fields/FileUploadWithUserDocs.svelte'
  import FileUploadItems from 'components/fields/FileUploadItems.svelte'
  import api from 'services/api.js'

  export let value
  export let name
  export let acceptableFileTypeIds

  let allFileTypes = null
  loadFileTypes()

  async function loadFileTypes() {
    allFileTypes = await api.fileType.listAvailableFileTypes(api.noMonitor)
  }

  function onUploadFile(e) {
    const formData = e.data
    if (formData == null) return
    addFile(e.file, formData)
  }

  function addFile(fileOrUserDocument, formData) {
    const userDocumentId = fileOrUserDocument.userDocumentId
    const clientSideFile = {
      id: null,
      userDocumentId,
      name: fileOrUserDocument.name,
      nameOriginal: fileOrUserDocument.nameOriginal,
      size: fileOrUserDocument.size,
      contentType: fileOrUserDocument.contentType ?? fileOrUserDocument.type,
      url: userDocumentId ? fileOrUserDocument.url : URL.createObjectURL(fileOrUserDocument), // so they can view it client-side prior to it being saved server-side
      formData,
    }
    value = [...value, clientSideFile]
  }

  function onDeleteFile(file) {
    value = value.filter(f => f.url !== file.url) // url compare will work for client-side and server-side files
  }

  async function onUploadDocument(e) {
    const { userDocumentId } = e
    if (userDocumentId == null) return
    const userDocument = await loadUserDocument(userDocumentId)
    addFile(userDocument, null)
  }

  async function loadUserDocument(userDocumentId) {
    const response = await api.userDocument.list({
      selectedUserDocumentIds: [userDocumentId],
      pageSize: 0,
      excludeTotalCount: true,
    })
    const userDocument = response.selectedUserDocuments[0]
    // const userDocumentSharing = response.userDocumentSharing
    return userDocument
  }
</script>
