{#if email}
  <Modal {title} on:close={closeModal}>
    <div class="modal-body">
      <slot />

      <p class="m0">
        You should have an email from Clinician Nexus inviting you to confirm that you own <strong>{email}</strong>. If you just signed up or added
        this email address as a secondary, it may take a few minutes to go through.
      </p>
    </div>

    <div class="modal-footer">
      <Btn on:click={resendEmailConfirmation} dataTest="resend-invite-btn" class="btn btn-primary" autofocus>
        I don’t see a confirmation email, please resend it
      </Btn>
      <Btn on:click={closeModal}>Close</Btn>
    </div>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Modal from 'components/Modal.svelte'
  import api from 'services/api.js'
  import toaster from 'services/toaster.js'

  export let email
  export let title = 'Confirm your email address'

  async function resendEmailConfirmation() {
    await api.account.resendEmailConfirmation(email)
    toaster.toast({ message: 'Confirmation email has been resent.', type: 'info', icon: 'email' })
    closeModal()
  }

  function closeModal() {
    email = null
  }
</script>
