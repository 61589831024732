{#if address?.city}
  {#if address.state}
    {address.city}, {address.state}
  {:else}
    {address.city}
  {/if}
{:else if address?.state}
  {address.state}
{/if}

<script>
  export let address
</script>
