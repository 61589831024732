{#if exportJobIds.length}
  <ToastContainer>
    <Toast
      toast={{
        type: 'info',
        icon: 'download',
      }}
      onDismiss={anyInProgress ? null : clearAllJobs}
    >
      {#each exportJobIds as exportJobId (exportJobId)}
        <ExportJob exportJob={$exportJobsById[exportJobId]} />
      {/each}
    </Toast>
  </ToastContainer>
{/if}

<script>
  import Toast from './Toast.svelte'
  import exportJobsById from 'services/export-job-service.js'
  import ToastContainer from './ToastContainer.svelte'
  import ExportJob from './ExportJob.svelte'

  $: exportJobIds = Object.keys($exportJobsById)
  $: anyInProgress = exportJobIds.some(exportJobId => inProgress($exportJobsById[exportJobId]))

  function inProgress(exportJob) {
    return exportJob.percentComplete < 100 && exportJob.cancelDateTime == null && exportJob.error == null
  }

  function clearAllJobs() {
    $exportJobsById = {}
  }
</script>
