<OrgProfilePictureAndName
  name={orgName}
  relativeName={orgRelativeName}
  profilePicture={orgProfilePicture}
  class="flex-row flex-align-center nowrap text-left"
  profilePicClass={teamName ? 'relative' : null}
  profilePicStyle={teamName ? 'padding-right: 15px' : null}
  {size}
>
  <svelte:fragment slot="profile-pic">
    {#if teamName}
      <div class="absolute" style="top: -7px; left: 23px;">
        <TeamProfilePicture xs name={teamName} orgId={teamOrgId} title={noTooltip ? null : teamName} />
      </div>
    {/if}
  </svelte:fragment>

  {#if teamName}
    <div data-test={dataTest ? `${dataTest}-team-name` : ''}>
      {teamName}
    </div>
  {/if}
</OrgProfilePictureAndName>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import TeamProfilePicture from 'components/TeamProfilePicture.svelte'

  export let orgName
  export let orgRelativeName
  export let orgProfilePicture
  export let teamOrgId
  export let teamName
  export let noTooltip = false
  export let size = 'small'
  export let dataTest = null
</script>
