{#if isAi}
  <Icon name="brain-circuit" class="text-info" />
{:else if isCn}
  <img src="/images/logo-no-words.png" alt="Clinician Nexus logo" style="width: 14px; height: 14px" />
{:else if isUser}
  <Icon name="user" class="color-text-steel" />
{/if}

{formatted}

<script>
  import Icon from 'components/Icon.svelte'

  export let label

  $: isAi = label.startsWith('Ai')
  $: isCn = label.startsWith('Cn')
  $: isUser = label.startsWith('User')
  // Strip off the Ai/Cn/User prefix, capitalize the first letter
  $: formatted = _.upperFirst(label.replace(/^\S+ /, ''))
</script>
