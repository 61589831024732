<!--
  1. Put it in the form somewhere: <Captcha bind:this={captchaInstance} />
  2. On form submit, call `await captchaInstance.execute()`, which resolves to one of:
    - null: Captcha is disabled; server will double-check for tampering.
    - { grcResponse: string }: A value provided by reCAPTCHA to be validated by the server.
    - { captchaId: int, securityCode: string }: reCAPTCHA wasn't ready; this is a value provided by the user to be validated by the server.
    - TODO: Handle prompting for phone number.
  3. Also, beware captchaInstance.execute() may throw if the user cancels a fallback captcha check.
-->

<div bind:this={recaptchaElem} id="g-recaptcha-{idPostfix}" />

{#if promptOpen}
  <Modal class="shrink-to-fit" title="Please enter the security code below" on:close={cancelPrompt}>
    <Form on:submit={verifyPrompt}>
      <div class="form-content">
        <div style="width: {captchaWidth}px">
          <div class="security-code" style="height: {captchaHeight}px">
            <LazyImage
              src={captchaImagePromise}
              dataTest="security-code-image"
              width={captchaWidth}
              height={captchaHeight}
              alt="Security code"
              title="Please enter the security code below"
            >
              <div slot="error">
                Unexpected error.
                <Btn clearBtnStyling on:click={refreshPrompt}>Try again?</Btn>
              </div>
            </LazyImage>
          </div>
          <FormGroup>
            <label for="securityCode">
              Security code
              <RequiredMarker />
            </label>
            <InputText name="securityCode" bind:value={securityCode} bind:this={securityCodeInputElem} autofocus maxlength="10" />
          </FormGroup>
          {#if tryAgain && !isVerifying}
            <div>The security code you entered was invalid. Please try again.</div>
          {/if}
        </div>
      </div>
      <div class="modal-footer">
        <SubmitBtn dataTest="security-code-verify-btn" disable={isVerifying || isGenerating}>Verify</SubmitBtn>
        <Btn on:click={refreshPrompt} dataTest="security-code-skip-btn" disable={isVerifying || isGenerating}>Try another</Btn>
        <Btn on:click={cancelPrompt} dataTest="security-code-cancel-btn">Cancel</Btn>
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import { build } from 'services/captcha-service.js'
  import { onDestroy } from 'svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import LazyImage from 'components/LazyImage.svelte'
  import Modal from 'components/Modal.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import initial from 'stores/initial.js'

  export let idPostfix

  onDestroy(resetCaptcha)

  let inited = false
  let recaptchaElem
  let captcha

  function init() {
    captcha = build(recaptchaElem)
    inited = true
  }

  let tryAgain = false
  let isGenerating = false
  let isVerifying = false
  let securityCode = ''
  let securityCodeInputElem
  let promptOpen = false
  let prompt
  let promptResponse
  let promptResolve
  let promptReject
  let captchaImagePromise

  $: if (!inited && $initial.isSuccess && recaptchaElem) init()
  $: captchaWidth = $initial.captchaWidth
  $: captchaHeight = $initial.captchaHeight

  function resetPromptPromise() {
    // Do not reset prompt/promptResponse because closing and reopening the modal
    // should just display the same captcha.
    promptReject = null
    promptResolve = null
  }

  function refreshPrompt() {
    securityCode = ''
    isGenerating = true
    const params = promptResponse?.captchaId ? { skippedCaptchaId: promptResponse.captchaId } : {}
    prompt = api.captcha
      .generate(params, api.noMonitor)
      .then(response => (promptResponse = response))
      .finally(() => (isGenerating = false))
    captchaImagePromise = new Promise(resolve => {
      prompt.then(({ imageUrl }) => {
        resolve(imageUrl)
      })
    })
    securityCodeInputElem?.focus?.()
  }

  function closePrompt() {
    promptOpen = false
    tryAgain = false
  }

  async function verifyPrompt() {
    isVerifying = true
    try {
      const { captchaId, nonce } = await prompt // This nonce is for submission
      const response = await api.captcha.submit({ captchaId }, { nonce, securityCode })
      if (response.success) {
        promptResolve({ captchaId, securityCode, nonce: response.nonce }) // This nonce is different and is for verification by whichever API submits this data (join/login/whatever)
        closePrompt()
      } else {
        tryAgain = true
        promptResponse = null // The user didn't skip it, they failed it
        refreshPrompt()
      }
    } finally {
      isVerifying = false
    }
  }

  function cancelPrompt() {
    promptReject()
    closePrompt()
  }

  export async function execute() {
    const result = await captcha.execute()
    if (result.isDisabled) return null
    if (result.grcResponse) return { grcResponse: result.grcResponse }
    return new Promise((resolve, reject) => {
      promptResolve = resolve
      promptReject = reject
      if (!prompt) refreshPrompt() // If they close and reopen the modal, the last prompt is still okay to use.
      promptOpen = true
    }).finally(resetPromptPromise)
  }

  export function resetCaptcha() {
    captcha.reset()
  }
</script>

<style>
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.grecaptcha-badge) {
    bottom: 105px !important; /* Push reCAPTCHA badge up out from under Intercom */
  }

  .form-content {
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }

  .security-code {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
