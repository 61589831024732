import dateService from 'services/date-service.js'
import loggedInUser from 'stores/user.js'
import { toFriendlyList } from './string-utils.js'

let $loggedInUser = null
loggedInUser.subscribe(l => ($loggedInUser = l))

// prepare hour records for client-side usage
export function prepareHoursFromServer(hours) {
  return hours.map(prepareHourFromServer)
}

export function prepareHourFromServer(hourlog) {
  const fromDate = dayjs(removeZ(hourlog.fromDate))
  return {
    ...hourlog,
    from: formatTimeFromServerDate(hourlog.fromDate),
    to: formatTimeFromServerDate(hourlog.toDate),
    date: fromDate.format('M/D/YYYY'),
    dateFormatted: fromDate.format('ddd MMM D'),
    total: hourlog.hours === 1 ? '1hr' : hourlog.hours + 'hrs',
    canVerify: hourlog.supervisorUserIds && hourlog.supervisorUserIds.some(uid => uid === $loggedInUser.userId),
  }
}

export function prepareHourForServer(hourlog) {
  const assumeNextDay = dateService.parseTimeToDate(hourlog.to).isBefore(dateService.parseTimeToDate(hourlog.from))
  const toDate = assumeNextDay ? dayjs(hourlog.date).add(1, 'days').format('M/D/YYYY') : hourlog.date
  return {
    hourLogId: hourlog.hourLogId,
    orgId: hourlog.orgId,
    userId: hourlog.userId,
    fromDate: dateTime(hourlog.date, hourlog.from),
    toDate: dateTime(toDate, hourlog.to),
    supervisorUserIds: hourlog.supervisorUserIds,
    type: hourlog.type,
    note: hourlog.note,
  }
}

export function anyOverlapping(l1, logs) {
  return logs.some(l2 => {
    if (l1.hourLogId === l2.hourLogId) return false // skip self
    if (dateService.rangesOverLap(removeZ(l1.fromDate), removeZ(l1.toDate), removeZ(l2.fromDate), removeZ(l2.toDate))) return true
    return false
  })
}

function removeZ(dateStr) {
  // remove "Z" since these dates went into db not as UTC, so we just want the literal time within them
  return dateStr.replace(/Z$/, '')
}

function formatTimeFromServerDate(dateStr) {
  const time = dayjs(removeZ(dateStr)).format('h:mmA')
  return dateService.parseTime(time)
}

function dateTime(date, time) {
  return `${date} ${time}`
}

export function getHoursSummary(user, match, matchDaysComputed, date = null) {
  const matchUserLogs =
    match.hourLogsByUserId == null || match.hourLogsByUserId[user.userId] == null
      ? []
      : prepareHoursFromServer(match.hourLogsByUserId[user.userId]).filter(l => date == null || l.date === date)
  const totalHrs = matchUserLogs.reduce((total, log) => total + log.hours, 0)
  const userMatchDays = _.orderBy(
    matchDaysComputed.filter(md => (md.userId === user.userId || md.userId == null) && (date == null || date === md.date)),
    md => new Date(md.date)
  )
  const daysMissingHrs = matchUserLogs ? _.uniq(userMatchDays.filter(md => !matchUserLogs.some(l => l.date === md.date)).map(md => md.date)) : []
  const daysMissingHrsInPast = daysMissingHrs.filter(date => dayjs().isSameOrAfter(dayjs(date)))
  const logsThatNeedVerify =
    matchUserLogs && match.hourLogsMustBeVerified
      ? _.orderBy(
          matchUserLogs.filter(l => l.verifiedUserId == null),
          l => new Date(l.date),
          'desc'
        )
      : []
  const logsToVerify = logsThatNeedVerify.filter(l => l.canVerify)
  return {
    ...getColorAndDesc(daysMissingHrs, daysMissingHrsInPast, logsThatNeedVerify, userMatchDays, date),
    matchUserLogs,
    totalHrs,
    matchDaysComputed,
    userMatchDays,
    daysMissingHrs,
    daysMissingHrsInPast,
    logsThatNeedVerify,
    logsToVerify,
  }
}

function getColorAndDesc(daysMissingHrs, daysMissingHrsInPast, logsThatNeedVerify, userMatchDays, date) {
  if (daysMissingHrsInPast.length > 0) return buildColorDesc(null, `No hours logged for ${toFriendlyList(daysMissingHrsInPast)}`)
  if (logsThatNeedVerify.length > 0)
    return buildColorDesc('warning', `Awaiting verification for ${toFriendlyList(logsThatNeedVerify.map(l => l.date))}`)
  if (date && userMatchDays.length === 0) return buildColorDesc('success', 'No scheduled hours for this day.')
  if (daysMissingHrs.length === 0) return buildColorDesc('success', date ? `All scheduled hours logged for ${date}.` : 'All scheduled hours logged.')
  if (daysMissingHrsInPast.length === 0) {
    const dateInFuture = date ? dayjs(date).isAfter(dayjs().startOf('day')) : false
    if (dateInFuture) return buildColorDesc(null, `${date} is in the future.`)
    return buildColorDesc('success', date ? `You're caught up on hours for ${date}` : "You're caught up on hours for days on which you're scheduled.")
  }
  return null
}

function buildColorDesc(color, desc) {
  return { color, desc }
}
