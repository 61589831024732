{#if filteredOptions == null}
  <Spinner />
{:else if filteredOptions.length === 0}
  <HelpBlock>No {filteredOptions.length < options.length ? 'other ' : ''}staff with faculty role available.</HelpBlock>
{:else}
  <InputSelect
    {name}
    {placeholder}
    bind:value
    on:change
    {multiple}
    filterable={options && options.length > 5}
    filterStringSelector={o => [o.name]}
    options={filteredOptions}
    valueSelector={o => o.userId}
    {isOpen}
    let:option={user}
  >
    <div class="flex-row flex-align-center">
      <ProfilePic src={user.profilePicture} initials={user.initials} name={user.name} id={user.userId} medium class="mr1" />
      {user.name}
    </div>
  </InputSelect>
{/if}

<script>
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import Spinner from 'components/Spinner.svelte'
  import api from 'services/api.js'
  import ProfilePic from 'components/ProfilePic.svelte'

  export let orgId = null
  export let name = 'faculty-ids'
  export let options = null
  export let value = null
  export let multiple = true
  export let placeholder = 'Faculty'
  export let excludeUserIds = []
  export let isOpen = false

  if (options == null && orgId != null) api.staff.listFaculty({ orgId }, api.noMonitor).then(response => (options = response))

  $: filteredOptions = options == null ? null : options.filter(s => !excludeUserIds.some(es => es === s.userId))
</script>
