<ButtonModalPicker
  on:changed
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  {loading}
  valueSelector={s => s.userId}
  labelSelector={s => s.name}
  options={students}
  optionsForLabel={studentsForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  {allowSelectNull}
  {or}
  {hideSelectAll}
>
  <slot slot="top-modal-body" name="top-modal-body" tempSelectedCount={valueTemp?.length ?? 0} />

  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <StudentFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
      <svelte:fragment slot="after-keyword-search">
        <StudentPickerShowDropdown />
      </svelte:fragment>
    </StudentFilters>

    {#if (allowStudentYearOverride && !yearOverride && capacityStudentYears.length > 0) || (!noDisciplineFilterWarning && disciplines?.length)}
      <div>
        <Icon name="filter" class="text-gray" />
        Filtered to

        {#if !yearOverride && capacityStudentYears.length > 0}
          <Icon name="calendars" class="color-text-teal" />
          <StudentYearList studentYears={capacityStudentYears} />
        {/if}

        students

        {#if !noDisciplineFilterWarning && disciplines?.length}
          with the
          <Icon name="book" class="color-text-teal" />
          discipline
          <FriendlyList items={disciplines} punctuation boldPunctuation={false} or let:item><strong>{item}</strong></FriendlyList>
        {/if}

        {#if allowStudentYearOverride && !yearOverride && capacityStudentYears.length <= options.length && capacityStudentYears.length > 0}
          <br />
          <IconTextLink text="Include all students" onClick={() => (yearOverride = true)} />
        {/if}
      </div>
    {/if}

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={students?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
      >
        <EmptyPickerSlot bind:valueTemp {allowSelectNull} text="No student" {multiple} {buttonModalPicker} {dataTest} lg={!showMinimal} />
        {#if students?.length}
          {#each students as s, i (s.userId)}
            <PickerSlot {i} {dataTest} {multiple} value={s.userId} {buttonModalPicker} lg={!showMinimal} bind:valueTemp let:isSelected let:isHovered>
              <slot name="header" student={s} slot="header">
                <UserProfilePicAndName
                  user={s}
                  profilePicSmall={showMinimal}
                  tagName={showMinimal ? 'h4' : 'h3'}
                  headerClass="leading-none m0 normal"
                  pronounSetStyle={showMinimal ? 'font-size: 14px; display: inline' : 'font-size: 16px'}
                  pronounsBelow={!showMinimal}
                >
                  <svelte:fragment slot="after-name">
                    {#if studentPickerSlotWontShowWarning && !showMinimal}
                      <StudentPickerStudentYearWarning student={s} {capacityStudentYears} />
                    {/if}
                  </svelte:fragment>

                  <svelte:fragment slot="after-pronouns">
                    {#if studentPickerSlotWontShowWarning && showMinimal}
                      <StudentPickerStudentYearWarning student={s} {capacityStudentYears} />
                    {/if}
                  </svelte:fragment>
                </UserProfilePicAndName>
              </slot>

              <slot student={s} {isSelected} {isHovered}>
                <StudentPickerSlot student={s} {isHovered} {isSelected} {capacityStudentYears} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if students == null}
          <!-- This should never happen, but just in case... -->
          Failed to load students. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else}
          <h4 class="p3 text-center">No students found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import { DateTimeComparison, DateTimeComparisonProviderType, FeatureType, FilterType, PersonaType } from 'config/enums.js'
  import { options } from 'components/fields/StudentYearPicker.svelte'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import StudentFilters from 'components/StudentFilters.svelte'
  import StudentPickerStudentYearWarning from 'components/fields/StudentPicker.StudentYearWarning.svelte'
  import StudentPickerShowDropdown from 'components/StudentPickerShowDropdown.svelte'
  import StudentPickerSlot from 'components/fields/StudentPicker.Slot.svelte'
  import StudentYearList from 'components/StudentYearList.svelte'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'
  import validator from 'services/validator.js'

  // Common picker exports
  export let value
  export let filters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the students' : 'Select the student'
  export let disabled = false
  export let allowSelectNull = false
  export let dataTest = 'student-picker'
  export let interceptors = {}
  export let open = false

  // Specific picker exports
  export let studentCount = null
  export let selected = null
  export let capacityId = null
  export let capacityStudentYears = []
  export let disciplines = null
  export let noDisciplineFilterWarning = false
  export let allowStudentYearOverride = false
  export let excludeUserIds = null
  export let students = null
  export let or = false
  export let hideSelectAll = false
  export let orgId = null

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let studentsForLabel = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null
  let yearOverride = false

  $: capacityStudentYears, disciplines, setDefaultFilters()

  function setDefaultFilters() {
    const allNonPostgraduate = capacityStudentYears?.every(sy => sy < 10) ?? false
    if (allNonPostgraduate) {
      filters = filters.filter(f => f.type !== FilterType.StudentGraduationDate)
      filters.push({
        type: FilterType.StudentGraduationDate,
        config: {
          comparisonProviderType: DateTimeComparisonProviderType.RelativeDays,
          comparisonProvider: {
            comparison: DateTimeComparison.IsAfter,
            daysFromToday: 0,
          },
        },
      })
      filters = filters
    }
  }

  $: hiddenExcludeUsersFilter = excludeUserIds?.length
    ? {
        type: FilterType.ExcludeUsers,
        config: {
          userIds: excludeUserIds,
        },
      }
    : null
  $: hiddenFilters = [hiddenExcludeUsersFilter].filter(_.identity)

  $: show = $showDropdowns.studentPicker
  $: showStepSummary = $showDropdowns.studentPicker.stepSummary
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  $: studentPickerSlotWontShowWarning = !show.year && !show.discipline

  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: personaOrgId = $persona.orgId
  // Must watch value, so we if external code sets value, we can show selected if they're on subsequent pages of data.
  // valueTemp is updated within, so watching value here won't reload list upon selecting a student a few pages down.
  $: personaOrgId, hasCoreSchedulingFeature, capacityId, capacityStudentYears, yearOverride, value, loadPage(0)
  $: if (showStepSummary) loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: value, students, setSelected()

  function loadFirstPageIfFiltersChanged() {
    if (!open) return
    const filtersChanged =
      !validator.equals(filters ?? [], currentXhrBody?.filters ?? []) ||
      yearOverride !== currentXhrBody?.yearOverride ||
      capacityId !== currentXhrBody?.capacityId ||
      orgId !== currentXhrBody?.orgId
    if (filtersChanged) loadPage(0)
  }

  async function loadPage(offset) {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return

    const thisXhrBody = {
      apiVersion: '2.0',
      capacityId,
      yearOverride: allowStudentYearOverride && yearOverride,
      filters: _.cloneDeep(filters.concat(hiddenFilters)),
      includeSchools: true,
      includeEmails: true,
      includeTags: $persona.personaType === PersonaType.SchoolStaff,
      includeStepSummary: showStepSummary,
      // No reason to include any of these things; the defaults are false so just leaving commented out (so release build doesn't send redundant info).
      // Just documenting here that these options were considered.
      // includeMatches: false,
      // includeCoordinators: false,
      pageSize,
      offset,
      orgId,
    }

    const selectedStudentUserIds = (multiple ? value ?? [] : [value]).filter(id => id != null && !studentsForLabel.some(u => u.userId === id))
    if (selectedStudentUserIds.length) thisXhrBody.selectedStudentUserIds = selectedStudentUserIds

    if (validator.equals(currentXhrBody, thisXhrBody)) return
    loading = true
    currentXhrBody = thisXhrBody

    try {
      const task = api.student.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      students = offset ? [...students, ...response.students] : response.students
      if (selectedStudentUserIds.length) studentsForLabel = [...studentsForLabel, ...(response.selectedStudents ?? [])]
      if (offset === 0 && studentCount == null) studentCount = totalCount
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }
  }

  function setSelected() {
    selected = value == null ? null : multiple ? students?.filter(u => value.includes(u.userId)) : students?.find(u => u.userId === value)
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }

  export function focus() {
    buttonModalPicker?.focus()
  }
</script>
