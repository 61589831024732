{#if match.isCoordinator || match.isSchoolCoordinator}
  {#if !adding}
    <IconTextLink text="Add {matchRoleName}" id="add-{matchRoleName}" class={className} onClick={() => (adding = true)} />
  {:else}
    <div class="well">
      <FormGroup>
        {#if role === MatchRole.SchoolFaculty}
          <FacultyPicker
            name="school-faculty-ids"
            bind:value={selectedUserId}
            orgId={match.guestOrgId}
            bind:options={schoolFaculty}
            on:change={e => addUser(e.detail)}
            {excludeUserIds}
            isOpen
            multiple={false}
          />
        {:else if role === MatchRole.Preceptor}
          <PreceptorsPicker
            name="preceptors-picker"
            bind:value={selectedUserId}
            orgId={match.hostOrgId}
            bind:options={preceptors}
            on:change={e => addUser(e.detail)}
            {excludeUserIds}
            isOpen
            multiple={false}
          />
        {:else if role === MatchRole.Student}
          <!-- TODO: This doesn't support capacity team disciplines, service disciplines, or agreement disciplines -->
          <StudentPicker
            bind:this={studentPicker}
            bind:value={selectedUserId}
            bind:students
            on:changed={e => addUser(e.detail)}
            modalTitle="Select the student to add"
            open
            multiple={false}
            orgId={match.guestOrgId}
            capacityId={match.capacity.capacityId}
            capacityStudentYears={match.capacity.studentYears}
            disciplines={match.capacity.disciplines.length > 0 ? match.capacity.disciplines : match.service?.disciplines}
            allowStudentYearOverride={true}
            {excludeUserIds}
            hideSelectAll
          />
        {/if}
      </FormGroup>
    </div>
  {/if}
{/if}

<script>
  import { MatchRole } from 'config/enums.js'
  import FacultyPicker from 'components/fields/FacultyPicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import PreceptorsPicker from 'components/fields/PreceptorsPicker.svelte'
  import StudentPicker from 'components/fields/StudentPicker.svelte'

  export let role = null
  export let match = null
  export let matchUsers = null
  let className = null
  export { className as class }

  let adding = false
  let studentPicker
  let selectedUserId = null

  // bound to the components which will populate them. kind of awkward use of two-way binding, but works
  let students = null
  let schoolFaculty = null
  let preceptors = null

  $: matchRoleName =
    role === MatchRole.Student ? 'student' : role === MatchRole.SchoolFaculty ? 'school faculty' : role === MatchRole.Preceptor ? 'preceptor' : 'user'
  $: excludeUserIds = matchUsers == null ? [] : matchUsers.filter(mu => mu.matchRole === role).map(mu => mu.userId)
  $: studentCount = matchUsers == null ? [] : matchUsers.filter(mu => mu.matchRole === MatchRole.Student).length

  function addUser(userId) {
    if (userId == null) return
    const byUserId = u => u.userId === userId
    const user = students?.find(byUserId) ?? schoolFaculty?.find(byUserId) ?? preceptors?.find(byUserId)
    const matchUser = {
      ..._.cloneDeep(user),
      matchRole: role,
      matchRoleName: role === MatchRole.Student ? 'Student' : role === MatchRole.SchoolFaculty ? 'SchoolFaculty' : 'Preceptor',
      matchId: match.matchId,
      explicitlyAdded: true,
    }
    matchUsers = matchUsers.concat([matchUser])
    adding = false
    studentPicker?.clear()
  }
</script>
