<FilterTypeCustomTag {filter} {filterOptions} {editing} {appliedMeta} />

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeCustomTag, { meta as baseMeta } from './FilterTypeCustomTag.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.StudentCustomTags,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing
  export let appliedMeta
</script>
