<BtnGroupPicker {options} bind:value class={className} {btnClass} on:change name={dataTest} />

<script>
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import { ArchiveActiveFilter } from 'config/enums.js'

  export let value = ArchiveActiveFilter.Active
  export let btnClass = 'btn-sm'
  let className = ''
  export { className as class }
  export let tooltipActive = null
  export let tooltipArchived = null
  export let tooltipAll = null
  export let activeLabel = 'Active'
  export let archivedLabel = 'Archived'
  export let dataTest = null

  const buildOption = (value, label, dataTest, title) => ({ value, label, dataTest, title })
  $: options = [
    buildOption(ArchiveActiveFilter.Active, activeLabel, 'active-tab', tooltipActive),
    buildOption(ArchiveActiveFilter.Archived, archivedLabel, 'archive-tab', tooltipArchived),
    buildOption(ArchiveActiveFilter.All, 'All', 'all-tab', tooltipAll),
  ]
</script>
