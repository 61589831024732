{#if editing}
  <Tabs sm bind:active={filter.searchMode}>
    <Tab name={AddressSearchMode.Simple}>Simple</Tab>
    <Tab name={AddressSearchMode.Advanced}>Advanced</Tab>
  </Tabs>

  <div class="p2" style="min-width: 300px">
    {#if filter.searchMode === AddressSearchMode.Simple}
      <label for="formattedAddress"><strong>{label}</strong> contains</label>
      <InputText name="formatted-address" placeholder="Road, city, state, and/or zip…" bind:value={filter.formattedAddress} />
    {/if}

    {#if filter.searchMode === AddressSearchMode.Advanced}
      <h4>{label}</h4>

      <FormGroup>
        <label for="streetNumberAndRoute"><strong>Street number/route</strong> contains</label>
        <InputText name="streetNumberAndRoute" bind:value={filter.streetNumberAndRoute} />
      </FormGroup>

      <FormGroup>
        <label for="city"><strong>City</strong> contains</label>
        <InputText name="city" bind:value={filter.city} />
      </FormGroup>

      <FormGroup>
        <label for="postalCodeAndSuffix"><strong>Zip/postal code</strong> contains</label>
        <InputText name="postalCodeAndSuffix" bind:value={filter.postalCodeAndSuffix} />
      </FormGroup>

      <FormGroup>
        <label for="county"><strong>County</strong> contains</label>
        <InputText name="county" bind:value={filter.county} />
      </FormGroup>

      <FormGroup>
        <label for="state"><strong>State</strong> contains</label>
        <InputText name="state" bind:value={filter.state} />
      </FormGroup>

      <FormGroup class="mb0">
        <label for="country"><strong>Country</strong> contains</label>
        <InputText name="country" bind:value={filter.country} />
      </FormGroup>
    {/if}
  </div>
{:else}
  {label} contains{#if items.length}{' '}
    <!-- Yay, whitespace hacks -->
    <FriendlyList punctuation boldPunctuation={false} max={Infinity} {items} let:item><strong>{item}</strong></FriendlyList>
  {:else}…?{/if}
{/if}

<script context="module">
  import { AddressSearchMode } from 'config/enums.js'
  import validator from 'services/validator.js'

  export const meta = {
    icon: 'map-marker',
    btnBarClass: 'px2 pb2',
    dropdownClass: 'below',
    editLabel: null,

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.searchMode)
      if (config.searchMode === AddressSearchMode.Simple) {
        writer.writeArg(config.formattedAddress)
      } else {
        const hasFields = [
          !validator.empty(config.streetNumberAndRoute),
          !validator.empty(config.city),
          !validator.empty(config.postalCodeAndSuffix),
          !validator.empty(config.county),
          !validator.empty(config.state),
          !validator.empty(config.country),
        ]
        writer.writeBoolArray(hasFields)
        if (hasFields[0]) writer.writeArg(config.streetNumberAndRoute)
        if (hasFields[1]) writer.writeArg(config.city)
        if (hasFields[2]) writer.writeArg(config.postalCodeAndSuffix)
        if (hasFields[3]) writer.writeArg(config.county)
        if (hasFields[4]) writer.writeArg(config.state)
        if (hasFields[5]) writer.writeArg(config.country)
      }
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = appliedMeta.create()
      config.searchMode = reader.readInt()
      if (config.searchMode === AddressSearchMode.Simple) {
        try {
          config.formattedAddress = reader.readArg()
        } catch (e) {
          config.formattedAddress = ''
        }
      } else {
        const hasFields = reader.readBoolArray()
        if (hasFields[0]) config.streetNumberAndRoute = reader.readArg()
        if (hasFields[1]) config.city = reader.readArg()
        if (hasFields[2]) config.postalCodeAndSuffix = reader.readArg()
        if (hasFields[3]) config.county = reader.readArg()
        if (hasFields[4]) config.state = reader.readArg()
        if (hasFields[5]) config.country = reader.readArg()
      }
      return config
    },

    create() {
      return {
        searchMode: AddressSearchMode.Simple,
        formattedAddress: '',
        streetNumberAndRoute: '',
        city: '',
        postalCodeAndSuffix: '',
        county: '',
        state: '',
        country: '',
      }
    },

    validate(filter) {
      return filter.searchMode === AddressSearchMode.Simple
        ? !validator.empty(filter.formattedAddress)
        : !validator.empty(filter.streetNumberAndRoute) ||
            !validator.empty(filter.city) ||
            !validator.empty(filter.postalCodeAndSuffix) ||
            !validator.empty(filter.county) ||
            !validator.empty(filter.state) ||
            !validator.empty(filter.country)
    },
  }
</script>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Tab from 'components/Tab.svelte'
  import Tabs from 'components/Tabs.svelte'

  export let filter
  // export let filterOptions
  export let label
  export let editing = false

  $: items = editing
    ? null
    : (filter.searchMode === AddressSearchMode.Simple
        ? [filter.formattedAddress]
        : [filter.streetNumberAndRoute, filter.city, filter.postalCodeAndSuffix, filter.county, filter.state, filter.country]
      )
        .map(item => item?.trim())
        .filter(_.identity)
</script>
