<div class="flex-row flex-align-center p1 {isTooltip ? '' : `card card-left-thick-${guest.color}`}" style="width: fit-content;">
  <div>
    {#if !isTooltip}
      <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis={true} />

      {#if guest.agreement}
        <div class="flex-row flex-align-center g05 mt05">
          <span class="small text-gray em">via</span>
          <Icon name="agreement" autoColor />
          {guest.agreement.name}
        </div>
      {:else}
        <NoAgreementSpecified />
      {/if}
    {/if}
    <div class="{columnView ? 'flex-column flex-align-start' : 'flex-row'} g2">
      <div>
        <p class="mb05 mt05"><strong>General</strong></p>
        {#if guest.allowsAddingPlaceholderStudents}
          <div class="text-grid">
            <div style="grid-area: icon;">
              <Icon name="check" />
            </div>
            <div class="text-left" style="grid-area: text;">
              <div>Allows placeholder students</div>
              <em>Does not require real students before start</em>
            </div>
          </div>
        {:else}
          <div class="text-grid">
            <div style="grid-area: icon;">
              <Icon name="cancel" />
            </div>
            <div style="grid-area: text;">
              <div>
                <strong>DOESN’T</strong> allow placeholder students
              </div>
              <em>Requires real students before start</em>
            </div>
          </div>
        {/if}
        <div class={columnView ? 'flex-column flex-align-start' : 'flex-row'}>
          <div class="text-grid flex-align-start">
            <div style="grid-area: icon;">
              <Icon name={capacity.allowGroups ? 'users' : 'user'} />
            </div>
            <div style="grid-area: text;">
              {#if guest.guaranteedMatches}
                <div>
                  {readableNumber(guest.matchCount)} / {guest.guaranteedMatches}
                  guaranteed rotations
                </div>
                <em>Rotations never released</em>
              {:else if isHealth}
                <div>No guaranteed rotations</div>
              {/if}
              <div class="flex-row flex-align-center g05">
                {readableNumber(guest.matchCount)} / <Unlimited value={guest.maxMatches} />
                max rotations
              </div>
              <div class="flex-row flex-align-center g05">
                {readableNumber(guest.studentCount)} / <Unlimited value={guest.maxStudents} />
                max students
              </div>
            </div>
          </div>
        </div>

        <p class="mb05"><strong>Disciplines</strong></p>
        <div class="mb05">
          <CapacityDisciplines {capacity} isDetailsView={true} />
        </div>
        <p class="mb05"><strong>Dates</strong></p>
        <StartAndEndDate startDate={guest.schedulingStartDate} endDate={guest.schedulingEndDate} />
        <div><Icon name="calendar" class="color-text-blue" /> Confirmed by {guest.confirmDesiredMatchCountByDate}</div>
      </div>
      <div>
        {#if capacity.shifts?.length}
          <p class=" flex-row flex-align-center g05 mb05">
            <strong>Windows</strong><Badge color="blackText" xs count={capacity.shifts.length} />
          </p>
          {#if isTooltip}
            <ShiftCards {capacity} shifts={guestShifts} isDetailsView={true} />
          {:else}
            <div style="overflow-y:auto; max-height:300px">
              <ShiftCards {capacity} shifts={guestShifts} isDetailsView={true} />
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
</div>

<script>
  import { readableNumber } from 'services/string-utils'
  import { PersonaType } from 'config/enums'
  import CapacityDisciplines from 'components/CapacityDisciplines.svelte'
  import Badge from 'components/Badge.svelte'
  import Icon from 'components/Icon.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import persona from 'stores/persona.js'
  import ShiftCards from 'components/ShiftCards.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Unlimited from 'components/Unlimited.svelte'

  export let capacity
  export let guest
  export let isTooltip = false

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: columnView = guestShifts?.length > 1 && isTooltip

  function getGuestShifts() {
    const guestShifts = []
    const unassignedShifts = capacity.shifts.filter(s => !s.guests.length)
    for (const shift of capacity.shifts) {
      if (shift.guests.filter(g => g.guestOrgId === guest.guestOrgId).length) {
        guestShifts.push(shift)
      }
    }
    return guestShifts.concat(unassignedShifts)
  }

  $: guestShifts = getGuestShifts()
</script>

<style>
  .opportunity-information-grid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1rem;
  }

  .text-grid {
    display: grid;
    grid-gap: 5px;
    margin-bottom: 5px;
    grid-template-areas: 'icon  text';
  }
</style>
