<ConfirmDeleteModal
  type="Service"
  typeIcon="shapes"
  typeIconClass="color-text-purple"
  item={service}
  {buildReasons}
  {buildWarnings}
  {onDelete}
  {onCancel}
  {canDeleteItem}
  {deleteItem}
  {itemWasDeleted}
/>

<script>
  import { FilterType } from 'config/enums.js'
  import api from 'services/api.js'
  import ConfirmDeleteModal from 'components/ConfirmDeleteModal.svelte'

  export let service
  export let onDelete
  export let onCancel

  function canDeleteItem() {
    return api.service.canDelete(service, api.noMonitor)
  }

  function deleteItem() {
    return api.service.softDelete(service, api.noMonitor)
  }

  function itemWasDeleted(result) {
    return result.serviceDeleted
  }

  function buildReasons(builder, result) {
    builder.addMatchStatusCountItems(result.matchStatusCountsPreventingDeletion)
  }

  function buildWarnings(builder, result) {
    builder.addMatchStatusCountItems(result.warningMatchStatusCounts)
    builder.addStepTypeCountItems(result.stepCountsByType)
    builder.addStaffCount(result.staffCount)
    builder.addActiveCapacityCount(result.activeCapacityCount, buildFilters())
  }

  // TODO(services): Linking to match status counts should also have a filter...
  function buildFilters() {
    return [
      {
        type: FilterType.Services,
        config: {
          exclude: false,
          serviceIds: [service.serviceId],
        },
      },
    ]
  }
</script>
