{#if show}
  <Btn icon="check" href={fromTemplate(href, capacity) + '/' + capacityGuest.guestOrgId} class={className} dataTest="confirm-matches-btn">
    Confirm rotations
  </Btn>
{/if}

<script>
  import { canConfirmAndReleaseCapacityGuestMatches } from 'services/capacity-usage.js'
  import { fromTemplate } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'

  export let href
  export let capacity
  export let capacityGuest
  let className = 'btn btn-default'
  export { className as class }

  $: show = canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest)
</script>
