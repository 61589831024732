<div class="input-time">
  <div class="input-group" id="{name}Range">
    <span class="input-group-addon">
      <Icon name="time" fw class={iconClass} />
    </span>
    <Box
      {autofocus}
      bind:value={startValue}
      placeholder={startPlaceholder}
      {disabled}
      dataTest="{name}-start"
      on:changed={e => (_start = e.detail)}
      on:selected={focusEnd}
    />
    {#if range}
      <label for="{name}-end" class="input-group-addon"><slot name="rangeEndLabel">to</slot></label>
      <Box
        bind:this={endBox}
        bind:value={endValue}
        placeholder={endPlaceholder}
        {disabled}
        dataTest="{name}-end"
        on:changed={e => (_end = e.detail)}
      />
    {/if}
    {#if showTotal}
      <span class="diff input-group-addon" data-test="{name}-total">{diffTimeHrsFormatted || '0hrs'}</span>
    {/if}
    {#if allowClear}
      <a class="input-group-addon" on:click={clear} href={null} data-test="{name}-clear">
        <Icon name="close" />
      </a>
    {/if}
  </div>
</div>

<script>
  import { getContext } from 'svelte'
  import Icon from 'components/Icon.svelte'
  import dateService from 'services/date-service.js'
  import Box from 'components/fields/InputTime.Box.svelte'

  export let name = null
  export let startValue = null
  export let endValue = null
  export let startPlaceholder = 'start'
  export let endPlaceholder = 'end'
  const initialStartValue = startValue
  const initialEndValue = endValue
  export let allowClear = false
  export let disabled = false
  export let range = false
  export let autofocus = false
  export let iconClass = null
  export let showTotal = true

  let _start
  let _end
  let endBox

  $: diffTimeHrsFormatted = dateService.diffTimeHrsFormatted(_start, _end)

  function clear() {
    startValue = null
    endValue = null
    _start = null
    _end = null
  }

  function focusEnd() {
    if (endBox) endBox.focus()
  }

  const markDirty = getContext('markDirty')
  $: if (markDirty != null && (startValue != initialStartValue || endValue != initialEndValue)) markDirty()
</script>

<style>
  .input-time {
    max-width: 370px;
  }
  .diff {
    min-width: 75px; /*so doesn't bounce around as you press up/down */
    text-align: center;
    font-size: 12px;
  }
</style>
