<Card
  id={user.userId}
  name={user.name}
  image={user.profilePicture}
  initials={user.initials}
  imgHref={user.url}
  deleted={user.deletedDateTime != null}
  class="card-sm card-fill{userMatchStatus ? ` card-${userMatchStatus.color}` : ''}{showOnboardingWarning ? ' height-auto' : ''}"
>
  <div class="user-card">
    <div class="flex-grow">
      <h3><a use:tip={'View profile'} href={user.url}>{user.name}</a></h3>

      {#if showStudentInfo && (user.studentYear || user.discipline || user.otherDiscipline)}
        <p>
          {toStudentYear(user.studentYear)}
          {user.discipline || user.otherDiscipline || 'student'}
          {#if school}@ <a href={school.url}>{school.name}</a>{/if}
        </p>
      {/if}
      {#if anyRoles || school}
        <p>
          {anyRoles ? roles.join(', ') : ''}
          {#if school}
            {#if anyRoles}@{/if}
            <a href={school.url}>{school.name}</a>
          {/if}
        </p>
      {/if}

      {#if user.email && showEmail}
        <a href="mailto:{user.email}" class="mr1">
          <Icon name="email" />
          {user.email}
        </a>
      {/if}

      {#if user.phonePublic && showPhone}
        <a href="tel:{user.phonePublic}">
          <Icon name="phone" />
          {user.phonePublic}
        </a>
      {/if}

      {#if showOnboardingWarning}
        <Alert type="warning" class="mb0" dataTest="onboarding-warning-message">
          {user.name}
          has incomplete onboarding.
          {#if onDelete != null}You can <a on:click={() => onDelete(user)} href={null}>remove them from rotation</a> if necessary.{/if}
        </Alert>
      {/if}
    </div>
    <div class="controls">
      {#if userMatchStatus != null}
        <div class="status strong text-{userMatchStatus.color}">
          {userMatchStatus.label}
          <Icon name={userMatchStatus.icon} />
        </div>
      {/if}
      {#if onDelete != null}
        <div class="delete">
          <Btn clearBtnStyling title={deleteMessage} confirm={deleteMessageConfirm} on:click={() => onDelete(user)} dataTest="del-{user.name}">
            <Icon name="delete" class="text-danger" fw />
          </Btn>
        </div>
      {/if}
    </div>
  </div>
</Card>

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Card from 'components/Card.svelte'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import matchStatusHelper from 'services/match-status-helper.js'
  import { toStudentYear } from 'services/formatters.js'

  export let showStudentInfo = true // if a user has student info filled in, but is not a student on this match, don't confusingly show their student info
  export let user = null
  export let roles = null
  export let school = null
  export let showEmail = false
  export let showPhone = false
  export let matchStatus = null
  export let onDelete = null
  export let deleteMessage = null
  export let deleteMessageConfirm = null
  export let showOnboardingWarning = false

  $: anyRoles = roles && roles.length > 0

  $: userMatchStatus = matchStatus == null ? null : matchStatusHelper.get(matchStatus)
</script>

<style>
  .user-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .status,
  .delete {
    text-align: right;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 500px) {
    .status,
    .delete {
      margin-bottom: 0;
    }
    .status {
      text-align: center;
    }
    .user-card {
      display: block;
    }
  }
</style>
