import LocalStorageStore from 'stores/local-storage-store.js'

const courseGrid = {
  name: true,
  description: true,
  dates: true,
  rotationTypes: true,
  org: true,
  team: true,
  progress: true,
  progressDetails: true,
  matches: true,
  students: true,
  hours: true,
  status: true,
}

const coursePicker = {
  name: true,
  description: true,
  dates: true,
  rotationTypes: true,
  org: true,
  team: true,
  progress: true,
  progressDetails: true,
  matches: true,
  students: true,
  hours: true,
  status: true,
}

const capacityGrid = {
  agreement: true,
  allowCoordinatorScheduling: true,
  disciplineCount: 4,
  disciplines: true,
  guaranteedMatchCount: true,
  guestConfirmMatchesByDate: true,
  locations: true,
  matchesSummary: true,
  matchLeadTimeDays: true,
  maxMatches: true,
  owner: true,
  postToSearchPage: true,
  requestAvailabilityPolicy: true,
  rotationTypes: true,
  school: true,
  service: true,
  shiftLocations: true,
  shifts: true,
  shiftLimits: true,
  shiftSchedule: true,
  shiftStaff: true, //TODO(Nursing): Show availability window staff?
  specialties: true,
  staff: true, //TODO(Nursing): Show opportunity staff?
  startAndEndDate: true,
  status: true,
  studentYears: true,
  team: true,
}

const capacityPicker = {
  description: true,
  startAndEndDate: true,
  matchLeadTimeDays: true,
  rotations: true,
  rotationTypes: true,
  specialties: true,
  disciplines: true,
  service: true,
  serviceDescription: true,
  organization: true,
  team: true,
  locations: true,
  disciplineCount: 4,
}

const serviceGrid = {
  organization: true,
  team: true,
  description: true,
  specialties: true,
  capacities: true,
  disciplines: true,
  agreements: true,
  agreementCount: 2,
}

const servicePicker = {
  activeCapacityCount: true,
  agreements: true,
  description: true,
  specialties: true,
  disciplines: true,
  organization: true,
  team: true,
}

const agreementGrid = {
  status: true,
  organization: true,
  school: true,
  team: true,
  schoolTeam: true,
  startAndEndDate: true,
  capacities: true,
  disciplines: true,
  services: true,
  serviceCount: 2,
}

const agreementPicker = {
  status: true,
  activeCapacityCount: true,
  services: true,
  description: true,
  disciplines: true,
  organization: true,
  team: true,
  school: true,
}

const importFileGrid = {
  status: true,
  personaOrg: true,
  uploadedByUser: true,
  dateTimeUploaded: true,
  size: true,
}

const importStaffGrid = {
  rowNumber: true,
  fullName: false,
  email: true,
  title: true,
  externalId: true,
  staffOrg: true,
  roles: true,
  importedAsUser: true,
}

const staffGrid = {
  organization: true,
  roles: true,
  title: true,
  externalId: true,
  lastActivity: true,
  customFields: true,
}

const studentGrid = {
  year: true,
  graduationDate: true,
  discipline: true,
  school: true,
  coordinators: true,
  coordinatorCount: 2,
  email: true,
  dateCreated: true,
  customFields: true,
  customTags: true,
  stepSummary: true,
}

const studentPicker = {
  year: true,
  discipline: true,
  graduationDate: true,
  school: true,
  email: true,
  customTags: true,
  stepSummary: true,
}

const staffPicker = {
  orgTitle: true,
  externalId: true,
  roles: true,
  org: true,
  email: true,
}

const orgRelationshipGrid = {
  ownerOrg: true,
  allowCoordinatorScheduling: true,
  allowStudentScheduling: true,
  agreementCount: true,
  activeCapacityCount: true,
  rotationCount: true,
}

const orgPicker = {
  agreementCount: true,
  activeCapacityCount: true,
  studentCount: true,
  type: true,
  address: true,
  phoneNumber: true,
  email: true,
  description: true,
}

const userDocumentGrid = {
  user: true,
  fileType: true,
  contentType: true,
  nameOriginal: true,
  name: true,
  size: true,
  containerName: true,
}

const userDocumentPicker = {
  user: true,
  fileType: true,
  contentType: true,
  nameOriginal: true,
  name: true,
  size: true,
  containerName: true,
}

const rotationGrid = {
  rowNumbers: true,
  status: true,
  statusName: true,
  matchName: false,
  school: true,
  opportunity: true,
  location: true,
  schedule: true,
  shifts: true,
  students: true,
  studentCount: 3,
  faculty: true,
  facultyCount: 3,
  preceptors: true,
  preceptorCount: 3,
  dateSubmitted: true,
  course: false,
}

const rotationKanban = {
  service: true,
  school: true,
  opportunity: true,
  location: true,
  students: true,
  faculty: true,
  preceptors: true,
}

const defaultValue = {
  capacityGrid,
  capacityPicker,
  serviceGrid,
  servicePicker,
  agreementGrid,
  agreementPicker,
  importFileGrid,
  importStaffGrid,
  staffGrid,
  courseGrid,
  coursePicker,
  studentGrid,
  studentPicker,
  staffPicker,
  orgRelationshipGrid,
  orgPicker,
  userDocumentGrid,
  userDocumentPicker,
  rotationGrid,
  rotationKanban,
}

function migrate(value) {
  value.capacityGrid ??= value.capacity ?? capacityGrid
  value.serviceGrid ??= value.service ?? serviceGrid
  value.agreementGrid ??= value.agreement ?? agreementGrid

  for (const rootKey in defaultValue) {
    const objectInDefault = defaultValue[rootKey]
    const objectInValue = value[rootKey] ?? (value[rootKey] = {})
    for (const key in objectInDefault) {
      if (!(key in objectInValue)) objectInValue[key] = objectInDefault[key]
    }
    for (const key in objectInValue) {
      if (!(key in objectInDefault)) {
        delete objectInValue[key]
      }
    }
  }

  for (const rootKey in value) {
    if (!(rootKey in defaultValue)) {
      delete value[rootKey]
    }
  }
}

export default LocalStorageStore('show-dropdowns', defaultValue, migrate)
