import { PersonaType, StepCompletionWhen, StepType } from 'config/enums.js'

import { getOrdinalNumberSuffix } from 'services/to-ordinal-number.js'
import { monthsByValue } from 'components/fields/MonthPicker.svelte'
import { toFriendlyList } from 'services/string-utils.js'

const currentYear = new Date().getFullYear()
const nextYear = currentYear + 1

const stepCompletionWhenOptions = [
  {
    value: StepCompletionWhen.Anytime,
    label: () => 'the person is added to the program',
    desc: () => 'This step will be able to be completed immediately.',
  },
  {
    value: StepCompletionWhen.RotationIsCreated,
    label: () => 'the rotation is created',
  },
  {
    value: StepCompletionWhen.StudentSubmitsTheRotation,
    label: () => 'the student submits the rotation',
    // keys off DateSubmitted, which is set to 1 immediately if created by a coordinator
    desc: from =>
      from
        ? 'If a coordinator scheduled the rotation, the date the rotation is created is used instead.'
        : "If a coordinator scheduled the rotation, the rotation's start date is used instead.",
  },
  {
    value: StepCompletionWhen.HostApprovesTheRotation,
    label: () => 'the clinical site approves the rotation',
    // keys off ApplicationApprovedDate, which is set to now immediately if the app requires no approval from host
    desc: from =>
      from
        ? 'If the opportunity is configured to not require approval, the date the rotation is created is used instead.'
        : 'If the opportunity is configured to not require approval, the rotation’s start date is used instead.',
  },
  {
    value: StepCompletionWhen.RotationStarts,
    label: () => 'the rotation starts',
  },
  { value: StepCompletionWhen.RotationEnds, label: () => 'the rotation ends' },
  {
    value: StepCompletionWhen.MonthDay,
    label: (from, completeToDateConfigurations) => {
      const filledMonthDayCombos = completeToDateConfigurations?.filter(ddc => ddc.month && ddc.day)
      return filledMonthDayCombos?.length > 0
        ? toFriendlyList(
            filledMonthDayCombos.map(ddc => `${monthsByValue[ddc.month].nameShort} ${ddc.day}${getOrdinalNumberSuffix(ddc.day)}`),
            4,
            true
          )
        : 'Month/Date'
    },
    desc: () =>
      `When the step is assigned, this date is calculated based on the current date.
      <br/><br/>
      For example if the current date is 3/1/${currentYear}, and the custom date is 3/15, the step will be due on 3/15/${currentYear}.
      <br/><br/>
      If the current date is past the date, the due date is put into the next year. For example if the current date is 3/16/${currentYear}, and the custom date is 3/15, the step will be due on 3/15/${nextYear}.
      <br/><br/>
      If there are multiple custom dates, the earliest date is used. For example if the current date is 3/1/${currentYear}, and the custom dates are 1/15 and 4/15, the step will be due on 4/15/${currentYear}.`,
  },
]

export const stepCompletionWhenOptionsByValue = _.keyBy(stepCompletionWhenOptions, o => o.value)

export function getStepCompletionWhenExplanation(
  completeFrom,
  completeFromAddDays,
  completeTo,
  completeToAddDays,
  completeToDateConfigurations,
  fromDate,
  toDate
) {
  const from = fromDate ?? getWhenExplanation(completeFrom, completeFromAddDays, true, completeToDateConfigurations)
  const to = toDate ?? getWhenExplanation(completeTo, completeToAddDays, false, completeToDateConfigurations)
  return `between ${from} and ${to}`
}

// if this changes, update StepViewModelValidator
export function getStepCompletionWhenErrors(stepType, completeFrom, completeFromAddDays, completeTo, completeToAddDays) {
  const errors = []
  if (completeFrom === StepCompletionWhen.MonthDay || completeTo === StepCompletionWhen.MonthDay) return errors // TODO: add validation for custom dates...
  if (completeFrom == null || completeTo == null) return errors
  if (completeFrom > completeTo) errors.push('From must be before to.')
  if (completeFrom === completeTo && completeFromAddDays >= completeToAddDays)
    errors.push('Please allow people at least one full day to complete steps.')
  if (
    stepType === StepType.Application &&
    !(completeTo < StepCompletionWhen.RotationStarts || (completeTo == StepCompletionWhen.RotationStarts && completeToAddDays <= 0))
  )
    errors.push('Application steps must always be completed before the start date.')
  if (stepType === StepType.SchoolCompliance && completeFrom !== StepCompletionWhen.Anytime)
    errors.push('School compliance steps must be completable immediately')
  return errors
}

function getWhenExplanation(complete, completeAddDays, isFrom, completeToDateConfigurations) {
  if (complete == null) return ''
  const option = stepCompletionWhenOptionsByValue[complete]
  if (option == null) throw new Error(`Unexpected step completion: ${complete}`)
  return completeAddDays === 0
    ? `${complete === StepCompletionWhen.MonthDay ? 'on' : 'when'} ${option.label(isFrom, completeToDateConfigurations)}`
    : `${Math.abs(completeAddDays)} days ${completeAddDays < 0 ? 'before' : 'after'} ${option.label(isFrom, completeToDateConfigurations)}`
}

export function getSelectedOnlySupportsAfter(complete) {
  return (
    complete === StepCompletionWhen.Anytime ||
    complete === StepCompletionWhen.RotationIsCreated ||
    complete === StepCompletionWhen.StudentSubmitsTheRotation ||
    complete === StepCompletionWhen.HostApprovesTheRotation
  )
}

export function getSelectedOnlySupportsBefore(isFrom, isApplicationStep, complete) {
  return !isFrom && isApplicationStep && complete === StepCompletionWhen.RotationStarts
}

export function getFromStepCompletionWhenOptions(personaType, stepType) {
  const isSchool = personaType === PersonaType.SchoolStaff
  const options = []

  if (stepType === StepType.SchoolCompliance) {
    if (isSchool) options.push(StepCompletionWhen.Anytime)
  } else if (stepType === StepType.Application) {
    options.push(StepCompletionWhen.RotationIsCreated)
  } else {
    options.push(
      StepCompletionWhen.RotationIsCreated,
      StepCompletionWhen.StudentSubmitsTheRotation,
      StepCompletionWhen.HostApprovesTheRotation,
      StepCompletionWhen.RotationStarts,
      StepCompletionWhen.RotationEnds
    )
  }
  // custom from date is likely not necessary--only needed to make more customized due date pretty much, I think
  // if (stepType === StepType.SchoolCompliance || stepType === StepType.Application || stepType === StepType.Onboarding)
  //   options.push(StepCompletionWhen.MonthDay)
  return stepCompletionWhenOptions.filter(o => options.includes(o.value))
}

export function getToStepCompletionWhenOptions(personaType, stepType) {
  // We limit app steps to always be completed prior to start date at the latest for now, since an onboarding or active step could be used for that instead.
  // The rotation UI prevents student from submitting rotation application til all application steps are completed, regardless of _when_ they need to be completed by.
  // const isSchool = personaType === PersonaType.SchoolStaff
  const options = []
  // "Anytime" option was just kinda confusing on the UI with the month/day options. So we're just going to give them the month/day configuration as an option for now.
  // if (isSchool && stepType === StepType.SchoolCompliance) options.push(StepCompletionWhen.Anytime)
  if (stepType === StepType.Onboarding || stepType === StepType.Active) options.push(StepCompletionWhen.RotationIsCreated)
  if (stepType === StepType.Application) options.push(StepCompletionWhen.RotationStarts)
  if (stepType !== StepType.Application && stepType !== StepType.SchoolCompliance) options.push(StepCompletionWhen.RotationEnds)
  if (stepType === StepType.SchoolCompliance) options.push(StepCompletionWhen.MonthDay)
  return stepCompletionWhenOptions.filter(o => options.includes(o.value))
}
