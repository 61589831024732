<div class="relative" class:pr1={teamName != null}>
  <ProfilePic src={orgProfilePicture} name={orgName} small title={noTooltip ? null : formatRelativeName(orgRelativeName)} />
  {#if teamName}
    <div class="absolute" style="top: -7px; left: 23px;">
      <TeamProfilePicture xs name={teamName} orgId={teamOrgId} title={noTooltip ? null : teamName} />
    </div>
  {/if}
</div>

<script>
  import { formatRelativeName } from 'services/orgs-service.js'
  import ProfilePic from './ProfilePic.svelte'
  import TeamProfilePicture from './TeamProfilePicture.svelte'

  export let orgName
  export let orgRelativeName
  export let orgProfilePicture
  export let teamOrgId
  export let teamName
  export let noTooltip = false
</script>
