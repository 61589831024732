<!-- TODO(teams) tests
  test:
    teams are only selectable at their owner org or its descendants

  test:
    select a team
    change to an org that doesn't have that team
    team selection should be cleared

  test:
    log in as a user who is assigned the required permission at specific teams
    no team selected = invalid

  test:
    log in as user who is assigned the required permission at all teams
    no team selected = valid
-->

<FormGroup {valid} {validationMessage}>
  <label class="flex-row flex-align-center g05" for="owner-org">
    {#if icon}
      <Icon name={icon} class={iconClass} />
    {/if}
    Owner location {#if teamOptions?.length > 0}/ team{/if}
    {#if editable}
      <RequiredMarker />
    {/if}
  </label>

  <div class="flex-row g1 flex-align-center">
    {#if editable}
      <SimpleOrgPicker
        name="owner-org"
        bind:value={orgId}
        bind:selected={selectedOrg}
        ancestorOrgId={$persona.orgId}
        placeholder="Select location"
        onLoadOrgs={r => (orgs = r.orgs)}
      />

      {#if teamOptions?.length > 0}
        <TeamPicker
          name="owner-team"
          teams={teamOptions}
          bind:value={teamId}
          placeholder="Select team"
          {nullTeamLabel}
          addNullTeamOption={hasPermissionForNullTeamAtSelectedOrg}
        />
      {/if}
    {:else}
      {#if selectedOrg}
        <OrgProfilePictureAndName name={selectedOrg.name} relativeName={selectedOrg.relativeName} profilePicture={selectedOrg.profilePicture} />
      {:else if orgName}
        <OrgProfilePictureAndName name={orgName} relativeName={orgRelativeName} profilePicture={orgProfilePicture} />
      {/if}
      {#if selectedTeam || teamName}
        <div>
          <TeamProfilePicture orgId={selectedTeam?.orgId ?? teamOrgId} name={selectedTeam?.name ?? teamName} small />
          {selectedTeam?.name ?? teamName}
        </div>
      {:else if teams?.length}
        <div>
          <ProfilePic special small />
          {nullTeamLabel}
        </div>
      {/if}
    {/if}
  </div>
</FormGroup>

<script>
  import api from 'services/api.js'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SimpleOrgPicker from 'components/SimpleOrgPicker.svelte'
  import TeamPicker from 'components/TeamPicker.svelte'
  import TeamProfilePicture from 'components/TeamProfilePicture.svelte'

  export let orgId = null
  export let teamId = null
  export let editable = true
  export let nullTeamLabel = 'All teams'

  // if requiredPermission is passed, they can only choose from orgs and teams they have the required permission in
  export let requiredPermission = null
  export let teams // todo: paginate teams too, but not many in there, so meh for now

  // when this control isn't editable because it's inherited, we might not be able to get org name / team name from the persona org's children / teams apis, so we allow passing directly
  export let orgName = null
  export let orgRelativeName = null
  export let orgProfilePicture = null
  export let teamOrgId = null
  export let teamName = null

  export let icon = null
  export let iconClass = null

  // These allow a parent component to fake like their inputInitial was defaulted correctly
  // so unsaved form prompts aren't erroneously triggered.
  export let onDefaultOrgId = _.noop
  export let onDefaultTeamId = _.noop

  // So external components can get the data
  export let selectedOrg = null
  export let selectedTeam

  let orgs = null

  if (teams == null) api.org.getTeams({ orgId: $persona.orgId }, api.noMonitor).then(response => (teams = response))

  $: hasPermissionForNullTeamAtSelectedOrg = personaService.hasPermissionForAllTeams(orgId, requiredPermission)
  $: teamOptions = (function () {
    if (teams == null) return null
    const options = personaService.getTeamsWithPermissionAtOrg(orgId, teams, requiredPermission).map(t => ({
      optionValue: t.teamId,
      ...t,
    }))
    return options
  })()

  // // when the control is editable and teamid is null, load last non-null value from store
  $: if (editable && teamId == null && !hasPermissionForNullTeamAtSelectedOrg && teamOptions?.length > 0) {
    teamId = teamOptions[0].teamId //$lastSelectedTeamId
  }

  // default to first org if none is set
  $: if (editable && orgId == null && orgs?.length > 0) {
    orgId = orgs[0].orgId
    onDefaultOrgId(orgId)
  }

  // default to first available team if user doesn't have permission for null team
  $: if (editable && teamId == null && !hasPermissionForNullTeamAtSelectedOrg && teams?.length === 1) {
    teamId = teams[0].teamId
    onDefaultTeamId(teamId)
  }

  // for display when no picker necessary
  $: selectedTeam = teams != null && teamId != null ? teams.find(t => t.teamId === teamId) : null
  $: if (!editable) setSelectedOrg()

  // if editable, and they change org and make the previously selected team invalid, clear team selection
  $: if (editable && teamId != null && teamOptions != null && !teamOptions.some(t => t.teamId == teamId)) {
    teamId = null
  }

  $: isValidOrg = selectedOrg != null
  $: isValidTeam =
    (teamId == null && hasPermissionForNullTeamAtSelectedOrg) ||
    (selectedTeam != null && personaService.isOrgSameOrDescendant(selectedTeam.orgId, orgId))

  $: valid = isValidOrg && isValidTeam
  $: validationMessage = !isValidOrg ? 'Invalid organization selected.' : !isValidTeam ? 'Invalid team selected.' : null

  async function setSelectedOrg() {
    const res = await api.org.list({
      selectedOrgIds: [orgId],
      excludeTotalCount: true,
    })
    selectedOrg = res.selectedOrgs[0]
  }
</script>
