<Modal on:close={onClose}>
  <h4 slot="title">
    {#if userName === '+'}
      <div>Adding {accessType} staff</div>
    {:else}
      <div class:small={accessTypeName}>Editing {accessType} staff</div>
      {accessTypeName}
    {/if}
  </h4>

  <div class="modal-body">
    {#if loading}
      <Loading />
    {:else if !orgStaff && userName === '+'}
      <AddServiceOrCapacityStaffUserPicker {serviceId} {capacityId} {accessType} {accessTypeIcon} {accessTypeName} bind:value={orgStaff} />
    {:else if orgStaff}
      {#if userName === '+'}
        <div class="mb1">
          <IconTextLink icon="chevron-left" text="Find someone else" onClick={() => (orgStaff = null)} />
        </div>
      {/if}

      <StaffHeader user={orgStaff.user} />

      <StaffForm
        bind:this={staffForm}
        bind:hasChanges
        {orgStaff}
        {routingConfig}
        on:reloadStaff
        {setWarningContext}
        {orgs}
        {teams}
        {service}
        {capacity}
        {accessType}
        {accessTypeIcon}
        {accessTypeName}
        showFooter
      />
      <StaffFooter disabled={!hasChanges} {orgStaff} {routingConfig} onDelete={() => staffForm.deleteStaff()} onSave={() => staffForm.saveStaff()} />
    {/if}
  </div>
</Modal>

<script>
  import AddServiceOrCapacityStaffUserPicker from 'components/AddServiceOrCapacityStaffUserPicker.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import Loading from 'components/Loading.svelte'
  import Modal from 'components/Modal.svelte'
  import StaffForm from 'components/StaffForm.svelte'
  import StaffHeader from 'components/StaffHeader.svelte'
  import StaffFooter from 'components/StaffFooter.svelte'

  export let orgs
  export let teams
  export let setWarningContext
  export let onClose

  $: routingConfig = { close: onClose }

  export let serviceId = null
  export let service = null
  export let capacity = null
  export let capacityId = null
  export let userName
  // Effectively from <StaffRoleGridModal>, but `userName`'s staff may not be on the grid
  // (e.g. go to page 3, click Remove, then refresh), so we'll load the record if it's not given to us
  export let orgStaff
  let staffForm
  let hasChanges

  $: accessType = capacityId ? 'opportunity' : serviceId ? 'service' : null
  $: accessTypeIcon = capacityId ? 'list' : serviceId ? 'shapes' : null
  $: accessTypeName = capacity?.name ?? service?.name ?? null

  // TODO(services): this stuff? made loadingStaff a const for now to pass lint and see where tests are at
  const loadingStaff = false
  $: loading = loadingStaff // || !service || !orgs || !teams
  // $: userName, orgStaff, service, capacity, loadStaffIfNecessary()

  // async function loadStaffIfNecessary() {
  //   if (orgStaff) return
  //   if (userName === '+') {
  //     orgStaff = null
  //     return
  //   }
  //   loadingStaff = true
  //   try {
  //     orgStaff = await api.staff.getStaffUser({ userName }, { capacityId, serviceId }, api.noMonitor)
  //   } finally {
  //     loadingStaff = false
  //   }
  // }
</script>
