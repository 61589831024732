{#if anyPageSizeOptionWouldShowPaginator || totalCount > pageSize}
  <div class="paginator-controls flex-wrap g1{className ? ` ${className}` : ''}">
    {#if anyPageSizeOptionWouldShowPaginator}
      <InputSelect color="outline-lightgray" options={_pageSizeOptions} bind:value={pageSize} name="{name}-page-size" />
    {/if}

    {#if totalCount > pageSize}
      <PaginatorArrow {name} disabled={currentPage === 1} description="Previous page" direction="left" on:interact={goToPreviousPage} />

      {#each pages as page}
        {#if page === null}
          <!-- Note that this is a Mid-line Ellipsis, not a Horizonal Ellipsis -->
          <span class="gap">⋯</span>
        {:else if page === currentPage}
          <span data-test="{name}-page-{page}" class="active">{page}</span>
        {:else}<a data-test="{name}-page-{page}" href={null} use:onInteract={() => onPageClick(page)}>{page}</a>{/if}
      {/each}

      <PaginatorArrow {name} disabled={currentPage === pageCount} description="Next page" class="mr05" direction="right" on:interact={goToNextPage} />
    {/if}

    {#if hasSlot}
      <div class="paginator-controls-slot">
        <slot />
      </div>
    {/if}
  </div>
{/if}

<script>
  import onInteract from 'decorators/on-interact.js'
  import PaginatorArrow from 'components/PaginatorArrow.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import { conservativePageSizeOptions } from 'services/default-page-size-options.js'
  import { tick } from 'svelte'

  export let name = null
  export let totalCount = 0
  export let pageSize = 10
  export let currentPage = 1
  export let useDefaultPageSizeOptions = false
  export let pageSizeOptions = null
  export let pagesOfLabel = 'rows'
  export let onChange
  let className = null
  export { className as class }

  const hasSlot = !!$$props.$$slots?.default

  $: pageCount = Math.ceil(totalCount / pageSize)

  // Reset page when pageSize or totalCount changes
  $: pageSize, totalCount, goToPage(1)

  $: _pageSizeOptions = buildOptions(useDefaultPageSizeOptions, pageSizeOptions)
  $: anyPageSizeOptionWouldShowPaginator = _pageSizeOptions?.some(o => totalCount > o.value)

  function buildOptions(useDefaultPageSizeOptions, pageSizeOptions) {
    const options = useDefaultPageSizeOptions ? conservativePageSizeOptions : pageSizeOptions
    if (!options) return null
    return options.map(value => ({ value, label: value === Infinity ? `Show all ${pagesOfLabel}` : `${value} ${pagesOfLabel} per page` }))
  }

  /*
    This is the expected UI/UX progression:
    <> brackets indicate prev/next page buttons.
    [] brackets indicate the current page.
    … indicates a literal ellipsis in the control.
    ================================================
    < [ 1]   2    3    4    5    6    7    …   42  >
    <   1  [ 2]   3    4    5    6    7    …   42  >
    <   1    2  [ 3]   4    5    6    7    …   42  >
    <   1    2    3  [ 4]   5    6    7    …   42  >
    <   1    2    3    4  [ 5]   6    7    …   42  >
    <   1    …    4    5  [ 6]   7    8    …   42  >
    …many pages of current page being centered…
    <   1    …   35   36  [37]  38   39    …   42  >
    <   1    …   36   37  [38]  39   40   41   42  >
    <   1    …   36   37   38  [39]  40   41   42  >
    <   1    …   36   37   38   39  [40]  41   42  >
    <   1    …   36   37   38   39   40  [41]  42  >
    <   1    …   36   37   38   39   40   41  [42] >
  */
  $: pages = buildPages(currentPage, pageCount)

  const maxPages = 9
  const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
  const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

  function buildPages(currentPage, pageCount) {
    if (pageCount <= maxPages) return _.range(1, 1 + pageCount)
    const range = buildRawRange(currentPage, pageCount)
    range[0] = 1
    if (range[1] !== 2) range[1] = null
    if (range[maxPages - 2] !== pageCount - 1) range[maxPages - 2] = null
    range[maxPages - 1] = pageCount
    return range
  }

  function buildRawRange(currentPage, pageCount) {
    if (currentPage <= maxPagesBeforeCurrentPage) {
      return _.range(1, 1 + maxPages)
    } else if (currentPage + maxPagesAfterCurrentPage >= pageCount) {
      return _.range(pageCount - maxPages + 1, 1 + pageCount)
    }
    return _.range(currentPage - maxPagesBeforeCurrentPage, 1 + currentPage + maxPagesAfterCurrentPage)
  }

  function goToPage(n) {
    // Consumers use `bind:currentPage` and naively compute offsets.
    // If we set `currentPage` to `null` when `totalCount`/`pageCount` is 0,
    // it'll screw up their math. So, let's just avoid that.
    currentPage = Math.clamp(n, 1, pageCount || 1)
  }

  function goToPreviousPage() {
    goToPage(currentPage - 1)
    fireChange()
  }

  function goToNextPage() {
    goToPage(currentPage + 1)
    fireChange()
  }

  function onPageClick(page) {
    goToPage(page)
    fireChange()
  }

  function fireChange() {
    tick().then(onChange)
  }
</script>
