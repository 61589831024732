{#if editing}
  <div class="flex-column g1 mb1" style="min-width: 300px">
    <InputSelect name="field" options={appliedMeta.fields} bind:value={filter.field} let:option>
      <slot name="field" {option}>{option.label}</slot>
    </InputSelect>

    <InputSelect name="comparison-operator" options={appliedMeta.comparisonOperators} bind:value={filter.comparisonOperator} />

    <InputText name="value" bind:value={filter.value} />
  </div>
{:else}
  <slot name="field" option={field}>
    {field.optionLabel}
  </slot>
  {comparisonOperator.optionLabel.toLowerCase()}
  {filter.value}
{/if}

<script context="module">
  import { ComparisonOperator } from 'config/enums.js'
  import enumToOptions from 'services/enum-to-options.js'

  export const meta = {
    label: 'Field comparison',
    editLabel: 'Field',
    icon: 'input-text',
    iconClass: 'color-text-steel',
    canHaveMultiple: true,
    fields: [],
    comparisonOperators: enumToOptions(ComparisonOperator),

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.field)
      writer.writeArg(config.comparisonOperator)
      writer.writeArg(config.value)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.field = reader.readInt()
      config.comparisonOperator = reader.readInt()
      config.value = reader.readArg()
      return config
    },

    create(appliedMeta) {
      return {
        field: appliedMeta.fields[0].optionValue,
        comparisonOperator: appliedMeta.comparisonOperators[0].optionValue,
        value: '',
      }
    },

    // eslint-disable-next-line no-unused-vars
    validate(filter, filters, appliedMeta) {
      return filter.field != null && filter.comparisonOperator != null
    },
  }
</script>

<script>
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'

  export let filter
  export let editing = false
  export let appliedMeta

  $: field = appliedMeta.fields.find(f => f.optionValue === filter.field)
  $: comparisonOperator = appliedMeta.comparisonOperators.find(o => o.optionValue === filter.comparisonOperator)
</script>
