<div
  use:tip={title}
  class:lg
  class:disabled
  class:enabled={!disabled}
  class:has-label={hasSlot}
  class:no-label={!hasSlot}
  class="input-checkbox checkbox-primary{className ? ` ${className}` : ''}"
  {style}
  on:click={stopPropagationIfRequired}
  on:keydown={stopPropagationIfRequired}
>
  {#if noTwoWay}
    <!-- if the checkbox is not checked, but is indeterminate and you click it, it will become checked instead of un-checked,
         unless you have two-way disabled and the application/calling code is controlling the checkbox state entirely programmatically -->
    <input
      type="checkbox"
      {name}
      id={name}
      {disabled}
      data-test="{name}-checkbox"
      checked
      indeterminate
      on:click
      on:focus={e => dispatch('focus', e)}
      on:blur={e => dispatch('blur', e)}
    />
  {:else}
    <input
      type="checkbox"
      {name}
      id={name}
      {disabled}
      data-test="{name}-checkbox"
      bind:checked
      bind:indeterminate
      on:click
      on:focus={e => dispatch('focus', e)}
      on:blur={e => dispatch('blur', e)}
    />
  {/if}
  <label for={name} data-test={name} class={labelClass}>
    <Icon name={iconName} class="icon-checkbox" />

    {#if hasSlot}
      <div>
        <slot />
      </div>
    {/if}
  </label>

  <slot name="after-label">
    {#if tooltip}
      <Help>
        {tooltip}
      </Help>
    {/if}
  </slot>
</div>

<script>
  import { createEventDispatcher } from 'svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import { getContext } from 'svelte'

  export let disabled = false
  export let checked = false
  export let indeterminate = false
  export let name = null
  export { className as class }
  export let style = null
  export let title = null
  export let labelClass = null
  export let lg = false
  export let noTwoWay = false
  export let alwaysPristine = false
  export let stopPropagation = false

  const dispatch = createEventDispatcher()

  const initialChecked = checked
  const markDirty = getContext('markDirty')
  const hasSlot = !!$$props.$$slots?.default

  let className = null
  let tooltip
  let iconName

  $: iconName = checked ? 'check-square' : indeterminate ? 'minus-square' : 'square-empty'
  $: if (!alwaysPristine && markDirty != null && checked != initialChecked) markDirty()

  function stopPropagationIfRequired(event) {
    if (stopPropagation) event.stopPropagation()
  }
</script>
