// TODO(nursing-eachStudentFillsASlot-slots): Update way of calculating opportunity rotations availability
import { shiftOccursOnDate } from 'services/calendar-service.js'
import { MatchRole, MatchStatus, PersonaType } from 'config/enums.js'
import { percent } from 'services/math-service.js'
import { pluralCount } from 'services/string-utils.js'
import personaService from 'services/persona-service.js'
import personaStore from 'stores/persona.js'
import personaFilters from 'stores/persona-filters.js'

let $persona
personaStore.subscribe(p => ($persona = p))

let $personaFilters
personaFilters.subscribe(filters => ($personaFilters = filters))

// TODO(nursing-eachStudentFillsASlot): Update this logic (EachStudentFillsASlot has been removed)
export const implicitMsg = (match, matchPerStudent) => {
  const studentCount = match.matchUsers.filter(u => u.matchRole === MatchRole.Student).length
  const thisOrTheseRotation = matchPerStudent && studentCount > 1 ? 'these rotations' : 'this rotation'
  const forAllStudents =
    !(matchPerStudent && !match.capacity.allowGroups) || studentCount === 1
      ? ''
      : studentCount === 2
        ? ' for both students'
        : ` for all ${studentCount} students`
  return `Because no days are explicitly configured, ${thisOrTheseRotation} will use a slot${forAllStudents} in all shifts between these dates.`
}

/*
  TODO: unit test common scenarios
  - no match days
      - capacity.eachStudentFillsASlot = false: should increment 1 slot from each day between start/end
      - capacity.eachStudentFillsASlot = true: should increment math.max([student count], 1) from each day between start/end
  - match days
      - capacity.eachStudentFillsASlot = false: should increment 1 slot from each match day
      - capacity.eachStudentFillsASlot = true: should increment math.max([student count], 1) from each match day
  - when capacity has no shifts on certain days, no slot should be taken

  TODO: handle and test edge-case: days without students should take a slot
*/
// no-mutation to filledCapacity
// some of this is duplicated logic that happens server-side in CapacityCalendarReport.cs. if it changes, be sure to change it there too
// TODO(nursing-eachStudentFillsASlot): Update this logic (EachStudentFillsASlot has been removed)
export function addMatchToCapacityUsage(match, filledCapacity, matchPerStudent) {
  if (match == null || filledCapacity == null) return null
  const filledCapacityCopy = _.cloneDeep(filledCapacity)
  const students = match.matchUsers.filter(u => u.matchRole === MatchRole.Student)
  const studentCount = students.length
  const matchStudents = new Set(students.map(s => s.userId))
  const shiftsSeenForDay = {}
  const matchDaysComputed = match.matchDays?.length > 0 ? match.matchDays : getImplicitMatchDays(match)
  for (const d of matchDaysComputed) {
    if (filledCapacityCopy[d.date] == null || filledCapacityCopy[d.date][d.shiftId] == null) continue

    let incrementCount = 0
    if (match.capacity.allowGroups || !matchPerStudent || studentCount === 0) {
      // if not filling for each student or if there aren't any students, regardless of what type of user it is, use one slot per match day
      const alreadyCounted = shiftsSeenForDay[d.date] && shiftsSeenForDay[d.date][d.shiftId]
      incrementCount = alreadyCounted ? 0 : 1
    } else {
      // if for all match users, consume 1 slot for each student on the match
      // if day is for a student, consume 1 slot
      incrementCount = d.userId == null ? studentCount : matchStudents.has(d.userId) ? 1 : 0
    }

    if (incrementCount > 0) {
      const shiftBucket = filledCapacityCopy[d.date][d.shiftId]
      if (match.status === MatchStatus.Waitlisted) shiftBucket.waitlisted += incrementCount
      else if (match.status >= MatchStatus.Onboarding && match.status < MatchStatus.Closed) shiftBucket.filled += incrementCount
      else if (match.status <= MatchStatus.PendingClinicalSite && match.status > MatchStatus.Unsubmitted) shiftBucket.pending += incrementCount
    }

    // track what shifts for this day we've started counting
    if (shiftsSeenForDay[d.date] == null) shiftsSeenForDay[d.date] = {}
    shiftsSeenForDay[d.date][d.shiftId] = true
  }

  // re-compute buckets, now that this match is added in (we manage this match client-side so we can update UI slot-usage without going to the server)
  for (const date in filledCapacityCopy) {
    for (const shiftId in filledCapacityCopy[date]) {
      filledCapacityCopy[date][shiftId] = setComputed(filledCapacityCopy[date][shiftId], filledCapacityCopy[date][shiftId].infinite)
    }
  }

  return filledCapacityCopy
}

export function setComputed(bucket, infinite) {
  const slots = computeSlots(bucket, infinite)
  const overfilled = computeOverfill(bucket, slots)
  const overfilledMaybe = computeOverfillMaybe(bucket, slots, overfilled)
  const calculatedTotal = bucket.filled + bucket.pending + bucket.waitlisted + overfilled + overfilledMaybe
  const relativeTotal = calculatedTotal > slots ? calculatedTotal : slots
  const computed = {
    ...bucket,
    relativeTotal,
    overfilled,
    overfilledMaybe,
    overfilledPercent: relativeTotal ? percent(overfilled, relativeTotal) : 0,
    overfilledMaybePercent: relativeTotal ? percent(overfilledMaybe, relativeTotal) : 0,
    filledPercent: relativeTotal ? percent(bucket.filled, relativeTotal) : 0,
    pendingPercent: relativeTotal ? percent(bucket.pending, relativeTotal) : 0,
    waitlistedPercent: relativeTotal ? percent(bucket.waitlisted, relativeTotal) : 0,
  }
  return computed
}

export function getImplicitMatchDays(match) {
  if (match == null || match.startDate == null || match.endDate == null) return []
  const matchDays = []
  const end = dayjs(match.endDate)
  let date = dayjs(match.startDate)
  while (date.isSameOrBefore(end)) {
    // TODO(nursing): Need to pass capacity dates to shiftOccursOnDate.
    const availableShifts = match.shifts.filter(s => shiftOccursOnDate({}, s, date))
    availableShifts.forEach(shift => {
      matchDays.push({
        date: date.format('M/D/YYYY'),
        shiftId: shift.shiftId,
        userId: null,
      })
    })
    date = date.add(1, 'day')
  }
  return matchDays
}

export function getTotalScheduledHoursByUserId(matchDays, shifts, matchUsers) {
  const matchSchedulableUserIds = Object.keys(getSchedulableUsers(matchUsers))
  const shiftDict = {}
  shifts.forEach(s => (shiftDict[s.shiftId] = s))
  const totalScheduledHoursByUserId = {}
  matchDays.forEach(d => {
    const shift = shiftDict[d.shiftId]
    if (shift == null) return
    const userId = d.userId
    if (userId == null) {
      // if no userId, add to all users
      for (const uid of matchSchedulableUserIds) {
        totalScheduledHoursByUserId[uid] ??= 0
        totalScheduledHoursByUserId[uid] += shift.duration
      }
    } else {
      // if userId, add to that user
      totalScheduledHoursByUserId[userId] ??= 0
      totalScheduledHoursByUserId[userId] += shift.duration
    }
  })
  return totalScheduledHoursByUserId
}

function computeSlots(bucket, infinite) {
  return infinite ? bucket.filled + bucket.pending + bucket.waitlisted : bucket.maxMatchCountPerDay
}

function computeOverfill(bucket, slots) {
  return Math.max(bucket.filled - slots, 0)
}

function computeOverfillMaybe(bucket, slots, overfilled) {
  return Math.max(bucket.filled + bucket.pending + bucket.waitlisted - slots - overfilled, 0)
}

export function toggleShift(matchDays, selectedDays, shift, deSelect) {
  return deSelect ? deselectShift(matchDays, selectedDays, shift) : selectShift(matchDays, selectedDays, shift)
}

function deselectShift(matchDays, selectedDays, shift) {
  return matchDays.filter(d => !selectedDays.has(d.date) || d.shiftId !== shift.shiftId)
}

function selectShift(matchDays, selectedDays, shift) {
  // add all people for this shift on all selected days
  const selectedForShift = matchDays.filter(d => selectedDays.has(d.date) && d.shiftId === shift.shiftId)
  for (const date of selectedDays) {
    const hasImplicitAll = selectedForShift.some(d => d.date === date && d.userId == null)
    if (hasImplicitAll) {
      // already implicitly-selected all people for this shift on this day
      continue
    }
    matchDays.push({
      date,
      shiftId: shift.shiftId,
    })
  }
  return matchDays
}

export function togglePerson(matchDays, selectedDays, shift, person, allUserIds, deSelect) {
  return deSelect
    ? deSelectPerson(matchDays, selectedDays, shift, person, allUserIds)
    : selectPerson(matchDays, selectedDays, shift, person, allUserIds)
}

function deSelectPerson(matchDays, selectedDays, shift, person, allUserIds) {
  // person is on this shift for all selected days, so remove them from all selected days (leave other users--remove implicit null record and add explicit selections for other users)
  const selectedForShift = matchDays.filter(d => selectedDays.has(d.date) && d.shiftId === shift.shiftId)
  for (const date of selectedDays) {
    const hasImplicitAll = selectedForShift.some(d => d.date === date && d.userId == null)
    if (hasImplicitAll) {
      // explicitly select other users (remove implicit selection and add other users to this shift for this day)
      matchDays = [
        ...matchDays.filter(d => !(d.shiftId === shift.shiftId && d.date === date && d.userId == null)),
        ...allUserIds
          .filter(userId => userId !== person.userId)
          .map(userId => ({
            date,
            shiftId: shift.shiftId,
            userId,
          })),
      ]
    } else {
      // remove this explicitly-selected user from this shift for this day
      matchDays = matchDays.filter(d => !(d.shiftId === shift.shiftId && d.date === date && d.userId === person.userId))
    }
  }
  return matchDays
}

function selectPerson(matchDays, selectedDays, shift, person, allUserIds) {
  // add person to this shift on all selected days where they're not already selected
  const selectedForShift = matchDays.filter(d => selectedDays.has(d.date) && d.shiftId === shift.shiftId)
  for (const date of selectedDays) {
    const hasImplicitAll = selectedForShift.some(d => d.date === date && d.userId == null)
    if (hasImplicitAll) {
      // user already implicitly-selected for this shift on this day
      continue
    }
    const alreadySelected = selectedForShift.some(d => d.date === date && d.userId === person.userId)
    if (alreadySelected) {
      // user already explicitly-selected for this shift on this day
      continue
    }
    const allOthersSelected = allUserIds
      .filter(userId => userId !== person.userId)
      .every(userId => selectedForShift.some(d => d.date === date && d.userId === userId))
    if (allOthersSelected) {
      // implicitly put all people on this shift for this day (remove explicit selections, add implicit selection)
      matchDays = [
        ...matchDays.filter(d => !(d.date === date && d.shiftId === shift.shiftId)),
        {
          date,
          shiftId: shift.shiftId,
          userId: null,
        },
      ]
    } else {
      // explicitly add this person to this shift for this day
      matchDays.push({
        date,
        shiftId: shift.shiftId,
        userId: person.userId,
      })
    }
  }
  return matchDays
}

// if this changes, probably change MatchRepository.Conflicting.
export function getConflictedDates(m, m2) {
  const mStart = dayjs(m.startDate)
  const mEnd = dayjs(m.endDate)
  const m2Start = dayjs(m2.startDate)
  const m2End = dayjs(m2.endDate)
  const mMatchDays = (m.matchDaysFormatted ?? m.matchDays)?.map(md => dayjs(md.date ?? md))
  const m2MatchDays = (m2.matchDaysFormatted ?? m.matchDays)?.map(md => dayjs(md.date ?? md))
  const selectFormatted = d => d.format('M/D/YYYY')

  // same match
  if (m.matchId == m2.matchId) return []
  // different schedulable user
  if (m.userId != null && m2.userId != null && m.userId != m2.userId) return []
  // both no match days
  if (!mMatchDays?.length && !m2MatchDays?.length) {
    // if start end date chunks cross, then there's a conflict
    const hasConflicts = mStart.isSameOrBefore(m2End) && mEnd.isSameOrAfter(m2Start)
    if (!hasConflicts) return []
    const startOfOverlap = dayjs.max(mStart, m2Start)
    const endOfOverlap = dayjs.min(mEnd, m2End)
    return [{ from: startOfOverlap, to: endOfOverlap }]
  }
  // both have explicit match days
  if (mMatchDays?.length && m2MatchDays?.length) {
    // if any of the match days overlap, then there's a conflict
    return mMatchDays.filter(d => m2MatchDays.some(d2 => d2.isSame(d))).map(selectFormatted)
  }
  // one has explicit match days, the other doesn't
  // if the one with explicit match days has any match days that overlap with the start/end date chunks, then there's a conflict
  return mMatchDays?.length
    ? mMatchDays.filter(d => m2Start.isSameOrBefore(d) && m2End.isSameOrAfter(d)).map(selectFormatted)
    : m2MatchDays.filter(d => mStart.isSameOrBefore(d) && mEnd.isSameOrAfter(d)).map(selectFormatted)
}

export function buildShiftDayLookup(capacity, match, shiftId = null) {
  const startDate = match.startDate
  const endDate = match.endDate
  const shifts = shiftId ? match.shifts.filter(s => s.shiftId === shiftId) : match.shifts
  const matchDays = match.matchDays
  const lookup = {}
  if (startDate == null || endDate == null) return lookup
  let current = dayjs(startDate)
  const end = dayjs(endDate)
  while (current.isSameOrBefore(end)) {
    const formatted = current.format('M/D/YYYY')
    lookup[formatted] = {}
    let shiftCount = 0
    let shiftCountAvailable = 0
    for (const shift of shifts) {
      const selected = matchDays.some(md => md.date === formatted && md.shiftId === shift.shiftId)
      const available = shiftOccursOnDate(capacity, shift, current)
      lookup[formatted][shift.shiftId] = { selected, available }
      if (available || selected) shiftCount++
      if (available) shiftCountAvailable++
    }
    lookup[formatted].shiftCount = shiftCount
    lookup[formatted].shiftCountAvailable = shiftCountAvailable
    current = current.add(1, 'day')
  }
  return lookup
}

export function buildMatchDayLookup(matchDays) {
  const grouped = _.groupBy(matchDays, 'date')
  for (const date in grouped) grouped[date] = _.groupBy(grouped[date], 'shiftId')
  return grouped
}

export function getSchedulableUsers(matchUsers) {
  return _.groupBy(
    matchUsers.filter(mu => mu.matchRole !== MatchRole.SchoolCoordinator && mu.matchRole !== MatchRole.ClinicCoordinator),
    'userId'
  )
}

// TODO(Nursing-slots): Review if we want to check capacity max matches or capacity guest guaranteed rotations here
function areCapacityMaxMatchesFilled(capacity, newRotationCount) {
  if (capacity?.maxMatches) {
    return capacity.matchCount + newRotationCount > capacity.maxMatches
  }
  return false
}

export function getCapacityMatchUsageWarning(capacity, newRotationCount) {
  if (!areCapacityMaxMatchesFilled(capacity, newRotationCount)) return null
  const isProvider = $persona.personaType === PersonaType.ProviderStaff
  const isStudent = $persona.personaType === PersonaType.Student
  const baseMsg = `Opportunity "${capacity.name}" already has <strong>${capacity.matchCount} / ${pluralCount(
    'rotation',
    capacity.maxMatches
  )} filled</strong>.`
  const rotationsPlural = newRotationCount === 1 ? 'another rotation' : `${newRotationCount} more rotations`
  const overrideMsg = isProvider
    ? `Are you sure you want to fill ${rotationsPlural}?`
    : isStudent
      ? `New applications might be waitlisted or rejected.`
      : `Requesting to fill ${rotationsPlural} may be rejected or put onto the waitlist.`
  return `${baseMsg} ${overrideMsg}`
}

export function simpleFilterOption(optionValue) {
  return { optionLabel: optionValue, optionValue }
}

export function getFilteredStatusCounts(capacity, guestOrgId, fromStatus, toStatus = fromStatus) {
  return capacity?.statusCounts?.find(s => s.guestOrgId === guestOrgId)?.byStatus.filter(s => s.status >= fromStatus && s.status <= toStatus) || 0
}

export function canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest, checkConfirmedDate = true) {
  return (
    $persona.personaType === PersonaType.SchoolStaff &&
    capacity?.maxMatches &&
    capacityGuest &&
    capacityGuest.matchCount < capacityGuest.guaranteedMatchCountRemaining &&
    (checkConfirmedDate ? !capacityGuest.desiredMatchCountConfirmedDateTime : true) &&
    personaService.isOrgSameOrDescendant($personaFilters.orgId, capacityGuest.guestOrgId)
  )
}
