import api from './api.js'
import { downloadBinary } from './file-service.js'
import { pluralCount } from 'services/string-utils.js'
import toaster from 'services/toaster.js'

// todo: create an endpoint that puts them into a zip (see downloading stepreport, for instance).
//       for now though, we just download them one at a time in the browser, which should be ok for now.
// note this only works for dbo.UserDocument, not stepfiles, stepsubmissionfiles, agreementfiles--no need to download those in bulk like this. stepsubmissionfiles already are setup to download a zip and no need to download the others in bulk.
export async function downloadUserDocuments(userDocuments) {
  let successCount = 0
  let errorCount = 0
  for (const userDocument of userDocuments) {
    try {
      const res = await api.document.downloadUserDocumentFile(
        { userDocumentId: userDocument.userDocumentId, sendAsDownloadable: true },
        { isBinary: true }
      )
      downloadBinary(res)
      successCount++
    } catch {
      errorCount++
    }
  }

  if (successCount) {
    const prefix = userDocuments.length === 1 ? userDocuments[0].nameOriginal : pluralCount('file', successCount)
    toaster.toast({ message: `${prefix} successfully downloaded`, type: 'success', icon: 'check' })
  }

  if (errorCount) {
    const prefix = userDocuments.length === 1 ? userDocuments[0].nameOriginal : pluralCount('file', errorCount)
    toaster.toast({ message: `${prefix} failed to download`, type: 'danger' })
  }
}
