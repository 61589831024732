<div class="eula">
  {#if eula != null}
    <SafeHtml value={eula.contentHtml} />
  {/if}
</div>

<script>
  import api from 'services/api.js'
  import SafeHtml from './SafeHtml.svelte'
  let eula
  api.eula.getLatest().then(response => (eula = response))
</script>

<style>
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.eula ol) {
    list-style-type: lower-alpha;
  }
</style>
