{#if violatesStudentYear}
  <Icon name="alert-triangle" class="text-warning" title={tooltip} />
{/if}

<div class="hide">
  <div bind:this={tooltipElem}>
    <StudentYearWarning
      studentYear={student.studentYear}
      {capacityStudentYears}
      toStudent={false}
      textClass="text-left"
      class="flex-row flex-align-center g1"
    >
      <Icon x3 name="alert-triangle" class="text-warning" slot="icon" />
    </StudentYearWarning>
  </div>
</div>

<script>
  import StudentYearWarning from 'components/StudentYearWarning.svelte'
  import Icon from 'components/Icon.svelte'

  export let student
  export let capacityStudentYears

  let tooltipElem

  $: violatesStudentYear = capacityStudentYears.length && capacityStudentYears.every(csy => csy !== student.studentYear)
  $: tooltip =
    violatesStudentYear && tooltipElem
      ? {
          content: tooltipElem,
          options: {
            interactive: false,
            theme: 'light-gray-scrollable',
            maxWidth: 480,
          },
        }
      : null
</script>
