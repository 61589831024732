import {
  ArchiveActiveFilter,
  DashboardViewCategory,
  FeatureType,
  FilterType,
  MatchListSortProperty,
  Permission,
  RotationListViewType,
  CriteriaFilterType,
} from 'config/enums.js'

const excludedFilterTypes = [FilterType.MatchStatusCategory, FilterType.StepSubmissionTargetUser]

export default {
  category: DashboardViewCategory.Views,
  permission: Permission.ViewRotationList,
  featureTypes: [FeatureType.TeachingInstitution, FeatureType.HealthInstitution],
  componentName: 'RotationList',
  name: 'Rotation list',
  icon: 'rotation',
  criteria: {
    sortProperty: MatchListSortProperty.StartDate,
    sortAscending: true,
    rotationListViewType: RotationListViewType.Column,
    filters: [
      {
        type: FilterType.MatchStatusCategory,
        config: {
          statusCategory: ArchiveActiveFilter.Active,
        },
      },
    ],

    // properties from calendar originally
    zoom: 'd',
    startDate: dayjs().startOf('month').format('M/D/YYYY'),
    endDate: dayjs().endOf('month').format('M/D/YYYY'),
  },
  availableToStudents: true,
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    sortProperty: { name: 'sp', method: 'readInt' },
    sortAscending: { name: 'sa', method: 'readBool' },
    rotationListViewType: { name: 'rlvt', method: 'readInt' },
    zoom: { name: 'z' },
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    month: { name: 'm', method: 'readDate' },
  },
  waitFor: { method: 'POST', route: /\/matches\/(.+)\/groups/i },
  testFor: ['rotation-list-view-type-picker'],
}
