{#if initFailure}
  <Alert type="danger" class="mx2">
    Map failed to load. Your browser might not support WebGL. If you're sure it does, try restarting your browser.
  </Alert>
{/if}

<div bind:this={container} class="map-container" />

{#if map}
  <slot {map} {mapbox} />
{/if}

<script context="module">
  // if this changes, update MapBounds.cs.WithinBoundsSql
  export function withinBounds(latLong, bounds) {
    const { lat, long } = latLong
    const isCluster = bounds.south !== bounds.north // if we had bounds, we'd have a diff between north/south. Else, we'd have default 0s for all 4 corners.
    return isCluster
      ? lat >= bounds.south && lat < bounds.north && long >= bounds.west && long < bounds.east
      : bounds.lat === lat && bounds.long === long
  }
</script>

<script>
  import { mainNavTransitioning, sideNavTransitioning } from 'stores/media-query.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import mapbox from 'mapbox-gl'
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import LocalStorageStore from 'stores/local-storage-store.js'

  export let tokenKey
  // bounds { north, south, east, west } (lat for north/south, lng for east/west)
  export let bounds = null
  export let center = null
  export let zoom = null
  export let fitToLongLats = null
  export function flyTo(coords, options) {
    $centerStore = [coords.long, coords.lat]
    return map?.flyTo({
      center: $centerStore,
      essential: true,
      // maxDuration: 500, // doesn't seem to do anything...
      ...options,
    })
  }

  export function fitBounds(bounds, padding = 1) {
    const mapboxLatLongBounds = [
      [bounds.west - padding, bounds.south - padding],
      [bounds.east + padding, bounds.north + padding],
    ]
    return map?.fitBounds(mapboxLatLongBounds)
  }

  export function zoomToCluster(cluster) {
    // fly to the the cluster marker, but put it at the center of the size of the cluster's bounds (server uses avg lat/long, so the point is not necessarily in the center of the cluster bounds)
    const nsDiff = Math.abs(cluster.north - cluster.south) / 2
    const ewDiff = Math.abs(cluster.east - cluster.west) / 2
    const bounds = {
      north: cluster.lat + nsDiff,
      south: cluster.lat - nsDiff,
      east: cluster.long + ewDiff,
      west: cluster.long - ewDiff,
    }
    fitBounds(bounds)
  }

  const tokens = {
    addressmap: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwbGc2dTAzZHkzZXFweHRncWFzMG0ifQ.Eing-SCh9pkqJPOZ-UjpBQ',
    orgpicker: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwbHk4NjAyZTMzbXJ1cDdmOWhqMDAifQ.KN3CHiNxLLOrrBa_mnClEg',
    profilesearch: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwazFnaTAwdWYzbnBtcTFsaHV5bmsifQ.TRh6uVCrKxCMngPjksf4NQ',
    rotationsearch: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwa2t6MzAwMW8za3FqZXg2OGt6MHkifQ.ZIifr0P_ez8UXsC0JNVPnA',
    orgs: 'pk.eyJ1IjoiY25wcmltYXJ5IiwiYSI6ImNrOHMwaTJyejAzeGIzbXBhMnNzejY4N3AifQ.73QkpYebbRu1KqYbQ7iQPw',
  }
  const Minneapolis = {
    long: -93.2650108,
    lat: 44.977753,
  }
  const style = 'mapbox://styles/cnprimary/ck8s0v67q1pyh1io447zcvwz3'

  // if explicit center passed, use that, otherwise load from local storage
  const centerStore = center == null ? LocalStorageStore('map-center', [Minneapolis.long, Minneapolis.lat]) : writable(center)

  // if explicit zoom passed, use that, otherwise load from local storage
  const zoomStore = zoom == null ? LocalStorageStore('map-zoom', 10) : writable(zoom)

  let container
  let map
  let initFailure = false

  onMount(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css'
    link.onload = () => initMap()
    document.head.appendChild(link)

    return () => {
      if (map) map.remove()
      if (link && link.parentNode) link.parentNode.removeChild(link)
    }
  })

  $: if (fitToLongLats?.length && map) {
    const newBounds = new mapbox.LngLatBounds()
    fitToLongLats.forEach(marker => newBounds.extend([marker.long, marker.lat]))
    fitBounds(buildBounds(newBounds), 1.5)
  }

  $: $mainNavTransitioning, $sideNavTransitioning, repaintIfNecessary()

  function repaintIfNecessary() {
    if (!$mainNavTransitioning && !$sideNavTransitioning) {
      // Just wait till the transition is done to resize. I also tried calling this in a requestAnimationFrame "loop",
      // but it flickered every single time. This way it sometimes flickers off/on once.
      map?.resize()
    }
  }

  function buildBounds(mapboxBounds) {
    const sw = mapboxBounds.getSouthWest()
    const ne = mapboxBounds.getNorthEast()
    return {
      north: ne.lat,
      south: sw.lat,
      east: ne.lng,
      west: sw.lng,
    }
  }

  function getBounds() {
    return buildBounds(map.getBounds())
  }

  function initMap() {
    if (container == null) return // component probably was destroyed in between component mount and map box css load
    mapbox.accessToken = tokens[tokenKey]
    try {
      map = new mapbox.Map({
        container,
        style,
        center: $centerStore,
        zoom: $zoomStore,
      })
      map.addControl(new mapbox.NavigationControl())
      map.addControl(
        new mapbox.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        })
      )
      bounds = getBounds()
      map.on('load', map.resize) // sometimes it doesn't fill its parent, so resize it upon load (case in point, signup add role, selecting org page--map is initially too short unless this is called or window is manually resized)
      map.on('move', () => {
        bounds = getBounds()

        // store for page reload
        $centerStore = map.getCenter()
        $zoomStore = map.getZoom()
      })

      // attempt to get geolocation and move to user's location if they're on the default location
      if (navigator.geolocation && $centerStore[0] == Minneapolis.long && $centerStore[1] == Minneapolis.lat && window.Cypress == null) {
        navigator.geolocation.getCurrentPosition(
          position => {
            map?.flyTo({
              center: [position.coords.longitude, position.coords.latitude],
              essential: true,
              maxDuration: 500,
            })
          },
          () => {
            // don't care
          }
        )
      }
    } catch (e) {
      // if fail to load map, set bounds to fully-zoomed out, so calling code sends the full earth's bounds and gets all possible results if they're two-way bound to `bounds`
      const earth = {
        north: 85.05112899999989,
        south: -85.05112899999997,
        east: 195.89977880888483,
        west: -454.39635797521333,
      }
      bounds = earth

      initFailure = true
      throw e
    }
  }
</script>

<style lang="scss">
  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
</style>
