<div class="file-name in-{insideOf}">
  <span class="without-extension">{fileNameWithoutExtension}</span>{#if extension}<span class="extension">{extension}</span>{/if}
</div>

<script>
  export let value
  export let insideOf // 'modal-title', 'sentence', 'inline-list', 'grid' (unaffected)

  $: match = value?.match(/(.*)(\.[^.]+)$/)
  $: fileNameWithoutExtension = match ? match[1] : value
  $: extension = match?.[2]
</script>

<style lang="scss">
  .extension {
    opacity: 0.6;
  }

  .without-extension {
    font-weight: bold;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.file-name) {
    &.in-modal-title {
      .extension {
        font-weight: 100;
      }
    }

    &.in-sentence {
      display: inline;

      .without-extension,
      .extension {
        font-weight: 500;
      }
    }

    &.in-inline-list {
      display: inline;
    }
  }
</style>
