<!-- ideally, implement a caret, but not a big deal-->
<div
  class="tip"
  style="opacity: {tooltip?.opacity};
         left: {positionX + tooltip?.caretX}px;
         top: {positionY + tooltip?.caretY}px;
         font-family: {tooltip?._bodyFontFamily};
         font-size: {tooltip?.bodyFontSize}px;
         font-style: {tooltip?._bodyFontStyle};
         padding: {tooltip?.yPadding}px {tooltip?.xPadding}px;">
  {#each titleLines as title}
    <div>{title}</div>
  {/each}
  {#each bodyLines as body, i}
    <div class="count">
      <span
        class="key"
        style="background: {tooltip?.labelColors[i].backgroundColor}; border-color: {tooltip?.labelColors[i].borderColor}; border-width: 2px;" />
      {#if isNaN(body)}{body}{:else}{pluralCount(countSubject, parseInt(body))}{/if}
    </div>
  {/each}
</div>

<script>
  import { pluralCount } from 'services/string-utils.js'
  export let chart
  export let tooltip
  export let countSubject = ''

  $: titleLines = tooltip?.title ?? []
  $: bodyLines = tooltip?.body?.map(bodyItem => bodyItem.lines) ?? []
  $: positionY = chart?.canvas?.offsetTop
  $: positionX = chart?.canvas?.offsetLeft
</script>

<style>
  .tip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 3px;
    transition: all 0.1s ease;
    pointer-events: none;
    transform: translate(-50%, 0);
  }

  .count {
    min-width: 200px;
    margin-top: 5px;
  }

  .key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
</style>
