<MediaQueryTrigger {query} {minWidth} {maxWidth} onChange={onTriggerChange} />

{#if matches}
  <slot />
{:else}
  <slot name="else" />
{/if}

<script>
  import MediaQueryTrigger from 'components/MediaQueryTrigger.svelte'

  export let query = null // Make sure to add your own () if necessary
  // Width queries will be adjusted based on main/side nav current size.
  export let minWidth = null
  export let maxWidth = null
  export let onChange = _.noop

  let matches = false

  function onTriggerChange(_matches) {
    matches = _matches
    onChange(matches)
  }
</script>
