// import LocalStorageStore from 'stores/local-storage-store.js'
// const myStore = LocalStorageStore('some unique key', 'default value')
// todo test this
// then you should be able to bind to it and set to it just like any other store, only it'll be saved in local storage too...
import { writable } from 'svelte/store'

const localStorageStores = {}

export function getItem(key, defaultValue) {
  const valueFromStorage = localStorage.getItem(key)
  const hasJson = valueFromStorage != null && valueFromStorage != 'null' && valueFromStorage != 'undefined'
  const value = hasJson ? JSON.parse(valueFromStorage) : defaultValue
  return value
}

function LocalStorageStore(key, defaultValue, migrateFn = null, acceptNullValues = true) {
  if (localStorageStores[key]) return localStorageStores[key]
  const initialValue = getItem(key, defaultValue)
  if (initialValue !== defaultValue && migrateFn) {
    const before = _.cloneDeep(initialValue)
    migrateFn(initialValue)
    if (!_.isEqual(before, initialValue)) localStorage.setItem(key, JSON.stringify(initialValue))
  }

  const { subscribe, set } = writable(initialValue)
  const store = {
    subscribe,
    update: function (updater) {
      const value = updater(getItem(key, defaultValue))
      set(value)
      localStorage.setItem(key, JSON.stringify(value))
    },
    set: function (value) {
      if (value == null && !acceptNullValues) return
      set(value)
      localStorage.setItem(key, JSON.stringify(value))
    },
  }
  localStorageStores[key] = store
  return store
}

export default LocalStorageStore
