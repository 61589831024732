<InputSelect
  {multiple}
  {name}
  options={!multiple ? [{ value: null, label: placeholder }, ...options] : options}
  {placeholder}
  {autofocus}
  bind:value
  class={className}
/>

<script context="module">
  // Imported in <StudentPicker> to check if all options have been selected for a given capacity.
  export const options = [
    { label: '1st year', value: 1 },
    { label: '2nd year', value: 2 },
    { label: '3rd year', value: 3 },
    { label: '4th year', value: 4 },
    { label: 'Postgraduate', value: 10 },
  ]
</script>

<script>
  import InputSelect from 'components/fields/InputSelect.svelte'

  export let name = 'student-year'
  export let multiple = false
  export let value = null
  export let placeholder = 'None selected'
  export let autofocus = false
  let className = ''
  export { className as class }
</script>
