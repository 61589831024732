import buildIcon from 'config/build-icon.js'
export default buildIcon('rotation', 512, 512, [
  // teal
  'M 511.7459426491481, 267.4023163654864 A 256 256 0 0 1 137.63464305530664, 482.99260401025253 L 140.00063444180108, 384.58969279052815 L 220.87380808328174, 345.3428824329609 A 96 96 0 0 0 351.14404696149944, 268.7910252829081 L 425.26645121942374, 316.8666014197107 Z',
  // // blue
  'M 118.25233304092762, 471.78132506621716 A 256 256 0 0 1 118.60131692828651, 39.99629195275645 L 202.63774215766557, 91.24675620845807 L 196.18989012408852, 180.90838424543395 A 96 96 0 0 0 197.35062368380287, 332.00164904606413 L 118.65475131879663, 372.1557460546052 Z',
  // // orange
  'M 138.00172430992419, 28.81635856829638 A 256 256 0 0 1 511.7640400164068, 245.011104036991 L 425.36162340053335, 292.16355100101407 L 350.93630179262976, 241.74873332160513 A 96 96 0 0 0 219.50532935469772, 167.20732567102783 L 224.07879746177971, 78.97765252568416 Z',
])

/*
  Use the following code to generate the simplified version above.


class Vector2 {
  constructor(x, y) {
    this.x = x
    this.y = y
  }

  static fromPolar(center, angle, radius) {
    const x = center.x + Math.cos(angle) * radius
    const y = center.y + Math.sin(angle) * radius
    return new Vector2(x, y)
  }

  static rightAngleSide(from, to, length) {
    const direction = to.minus(from)
    const normalized = direction.normalized()
    const rightAngle = new Vector2(-normalized.y, normalized.x)
    return to.plus(rightAngle.times(length))
  }

  static pickClosestTo(points, point) {
    let closest = null
    let closestDistance = Number.MAX_VALUE
    for (const p of points) {
      const distance = p.distanceFrom(point)
      if (distance < closestDistance) {
        closest = p
        closestDistance = distance
      }
    }
    return closest
  }

  static atIntersectionOfLines(p1, p2, p3, p4) {
    const x1 = p1.x
    const y1 = p1.y
    const x2 = p2.x
    const y2 = p2.y
    const x3 = p3.x
    const y3 = p3.y
    const x4 = p4.x
    const y4 = p4.y
    const denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)
    if (denom === 0) return null
    const x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / denom
    const y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / denom
    return new Vector2(x, y)
  }

  plus(other) {
    return new Vector2(this.x + other.x, this.y + other.y)
  }

  minus(other) {
    return new Vector2(this.x - other.x, this.y - other.y)
  }

  times(factor) {
    return new Vector2(this.x * factor, this.y * factor)
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y)
  }

  normalized() {
    const length = this.length()
    return new Vector2(this.x / length, this.y / length)
  }

  distanceFrom(other) {
    const dx = this.x - other.x
    const dy = this.y - other.y
    return Math.sqrt(dx * dx + dy * dy)
  }

  toString() {
    return `${this.x}, ${this.y}`
  }
}

function getCircleLineIntersections(radius, center, p1, p2) {
  p1 = p1.minus(center)
  p2 = p2.minus(center)
  const delta = p2.minus(p1)
  const dx = delta.x
  const dy = delta.y
  const dr = delta.length()
  const D = p1.x * p2.y - p2.x * p1.y
  const discriminant = radius * radius * dr * dr - D * D
  if (discriminant < 0) return []
  const sqRtDisc = Math.sqrt(discriminant)
  const drSq = dr * dr
  const x1 = (D * dy + (dy < 0 ? -1 : 1) * dx * sqRtDisc) / drSq
  const y1 = (-D * dx + Math.abs(dy) * sqRtDisc) / drSq
  const x2 = (D * dy - (dy < 0 ? -1 : 1) * dx * sqRtDisc) / drSq
  const y2 = (-D * dx - Math.abs(dy) * sqRtDisc) / drSq
  const intersections = [new Vector2(x1, y1), new Vector2(x2, y2)]
  return intersections.map(p => p.plus(center))
}

const arrow = (outerRadius, innerRadius, center, pointAngleOffset, halfStrokeGap, startAngle, endAngle) => {
  // Builds an arrow like below, curving around an arc.
  // First the exact points are found, then they're all moved inward so the stroke width is taken into account.
  // 1             2
  // ╲..............╲
  //  ╲              ╲
  //   . 6            . 3
  //  ╱              ╱
  // ╱..............╱
  // 5             4

  startAngle *= Math.PI / 180
  endAngle *= Math.PI / 180
  pointAngleOffset *= Math.PI / 180

  startAngle -= 0.01
  endAngle += 0.01

  const middleRadius = (outerRadius + innerRadius) / 2

  const p1 = Vector2.fromPolar(center, startAngle, outerRadius)
  const p2 = Vector2.fromPolar(center, endAngle, outerRadius)
  const p3 = Vector2.fromPolar(center, endAngle + pointAngleOffset, middleRadius)
  const p4 = Vector2.fromPolar(center, endAngle, innerRadius)
  const p5 = Vector2.fromPolar(center, startAngle, innerRadius)
  const p6 = Vector2.fromPolar(center, startAngle + pointAngleOffset, middleRadius)

  const p6p1In90 = Vector2.rightAngleSide(p6, p1, halfStrokeGap)
  const p1p6In90 = Vector2.rightAngleSide(p1, p6, -halfStrokeGap)
  const p6p5In90 = Vector2.rightAngleSide(p6, p5, -halfStrokeGap)
  const p5p6In90 = Vector2.rightAngleSide(p5, p6, halfStrokeGap)
  const p3p2In90 = Vector2.rightAngleSide(p3, p2, -halfStrokeGap)
  const p2p3In90 = Vector2.rightAngleSide(p2, p3, halfStrokeGap)
  const p3p4In90 = Vector2.rightAngleSide(p3, p4, halfStrokeGap)
  const p4p3In90 = Vector2.rightAngleSide(p4, p3, -halfStrokeGap)

  const p5Inner = Vector2.pickClosestTo(getCircleLineIntersections(innerRadius, center, p6p5In90, p5p6In90), p6p5In90)
  const p1Inner = Vector2.pickClosestTo(getCircleLineIntersections(outerRadius, center, p6p1In90, p1p6In90), p6p1In90)
  const p6Inner = Vector2.atIntersectionOfLines(p6p1In90, p1p6In90, p6p5In90, p5p6In90)

  const p2Inner = Vector2.pickClosestTo(getCircleLineIntersections(outerRadius, center, p3p2In90, p2p3In90), p3p2In90)
  const p4Inner = Vector2.pickClosestTo(getCircleLineIntersections(innerRadius, center, p3p4In90, p4p3In90), p3p4In90)
  const p3Inner = Vector2.atIntersectionOfLines(p3p2In90, p2p3In90, p3p4In90, p4p3In90)

  return [
    // Arcs
    `M ${p1Inner} A ${outerRadius} ${outerRadius} 0 0 1 ${p2Inner} L ${p3Inner} L ${p4Inner} A ${innerRadius} ${innerRadius} 0 0 0 ${p5Inner} L ${p6Inner} Z`,
    // Debug
    // `M ${p1Inner} L ${p6Inner} L ${p5Inner} M ${p2Inner} L ${p3Inner} L ${p4Inner}`,
  ]
}

const outerRadius = 256
const innerRadius = 96
const center = new Vector2(256, 256)
const pointAngleOffset = 16
const halfStrokeGap = 12
export default buildIcon('rotation', 512, 512, [
  // teal
  ...arrow(outerRadius, innerRadius, center, pointAngleOffset, halfStrokeGap, 0, 120),
  // // blue
  ...arrow(outerRadius, innerRadius, center, pointAngleOffset, halfStrokeGap, 120, 240),
  // // orange
  ...arrow(outerRadius, innerRadius, center, pointAngleOffset, halfStrokeGap, 240, 360),
])

*/
