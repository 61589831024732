<FormGroup {id} {valid} {validationMessage} {allowSubmitIfInvalid} class="dynamic-field dynamic-field-{type}">
  {#if type !== 'checkbox'}
    <label for={name}>
      {label}
      {#if required}
        <RequiredMarker />
      {/if}
    </label>
    {#if !validator.empty(helpText)}
      <HelpBlock>{helpText}</HelpBlock>
    {/if}
  {/if}

  {#if type === 'text'}
    <InputText {name} bind:value {disabled} {autofocus} {mask} {maxlength} />
  {:else if type === 'textarea'}
    <InputTextarea {name} bind:value {disabled} {autofocus} {maxlength} />
  {:else if type === 'phone'}
    <InputPhone {name} bind:value {disabled} {autofocus} />
  {:else if type === 'email'}
    <InputEmail {name} bind:value {disabled} {autofocus} />
  {:else if type === 'numeric'}
    <InputNumber {name} bind:value {disabled} {autofocus} allowDecimal />
    {#if minValue != null || maxValue != null}
      <HelpBlock>
        {#if minValue != null}Minimum: {minValue}{/if}
        {#if maxValue != null}
          {#if minValue != null},&nbsp;{/if}Maximum:
          {maxValue}
        {/if}
      </HelpBlock>
    {/if}
  {:else if type === 'file'}
    <DynamicFormFileUpload bind:value {name} {acceptableFileTypeIds} />
  {:else if type === 'select'}
    <InputSelect {name} bind:value {options} {multiple} {disabled} filterable={options.length > 5} />
  {:else if type === 'radiogroup'}
    <InputRadioGroup {name} {options} bind:value {disabled} />
  {:else if type === 'yesno'}
    <InputRadioGroup {name} options={yesNoOptions} bind:value {disabled} />
  {:else if type === 'checkboxgroup'}
    <InputCheckboxGroup {name} {options} bind:value {disabled} />
  {:else if type === 'checkbox'}
    <InputCheckbox {name} bind:checked={value} {disabled}>
      {label}
      {#if required}
        <RequiredMarker />
      {/if}
    </InputCheckbox>
  {:else if type === 'date'}
    <DatePicker {name} bind:value {disabled} format={dateFormat} autofocus={false} showError={false} />
  {:else if type === 'rating'}
    <InputRating bind:value {disabled} />
  {/if}
</FormGroup>

<script>
  import { setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import InputEmail from 'components/fields/InputEmail.svelte'
  import InputNumber from 'components/fields/InputNumber.svelte'
  import InputPhone from 'components/fields/InputPhone.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'
  import InputRating from 'components/fields/InputRating.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import validator from 'services/validator.js'
  import DynamicFormFileUpload from 'components/fields/DynamicFormFileUpload.svelte'

  export let disabled = false
  export let allowSubmitIfInvalid = false // for form builder preview fields
  export let field
  export let value = null
  export let autofocus = false
  export let id = null

  const yesNoOptions = [
    { optionValue: true, optionLabel: 'Yes' },
    { optionValue: false, optionLabel: 'No' },
  ]

  $: label = field.label
  $: name = field.uniqueName ?? field.name // calling code can set `uniqueName` if showing multiple forms on the same page, else can assume field.name is unique
  $: type = field.type
  $: options = uniqueOptions(field.options) // Avoid `Cannot have duplicate keys in a keyed each`
  $: multiple = field.multiple
  $: required = field.required
  $: mask = field.mask
  $: regex = field.regex
  $: helpText = field.helpText
  $: maxlength = field.maxLength
  $: minValue = field.minValue
  $: maxValue = field.maxValue
  $: acceptableFileTypeIds = field.acceptableFileTypeIds
  $: dateFormat = mask || 'M/D/YYYY'

  // make sure value isn't undefined
  $: if (typeof value == 'undefined') value = null

  $: valid = !!disabled || validator.dynamicField(field, value, dateFormat)

  function uniqueOptions(allOptions) {
    if (!allOptions) return []
    const unique = new Map()
    for (const o of allOptions) {
      if (!unique.has(o.value)) unique.set(o.value, o)
    }
    // Map is documented to retain original insertion order.
    return [...unique.values()]
  }

  const _fieldValidationMessage = writable(null)
  setContext('fieldValidationMessage', _fieldValidationMessage)

  $: validationMessage = (() => {
    switch (type) {
      case 'text':
      case 'textarea':
        const isEmpty = validator.empty(value)
        if (isEmpty && required) {
          return 'This field is required'
        } else if (!isEmpty && (regex || mask)) {
          // TODO: Allow <FormBuilder> to specify a validation message?
          return 'Please enter a valid value'
        } else {
          return null
        }
      case 'phone':
        return 'Please enter a valid phone number'
      case 'email':
        return 'Please enter a valid email address'
      case 'date':
        return $_fieldValidationMessage
      case 'numeric':
        const min = minValue
        const max = maxValue
        let s = 'Please enter a valid number'
        if (min != null && max != null) s += ' between ' + min + ' and ' + max
        else if (min != null) s += ' >= ' + min
        else if (max != null) s += ' <= ' + max
        s += '.'
        return s
      case 'select':
        return 'Please select ' + (multiple ? 'at least 1 option.' : 'an option.')
      case 'radiogroup':
        return 'Please select an option'
      case 'checkbox':
        return 'You must check this box'
      case 'checkboxgroup':
        return 'Please select at least one option'
      case 'rating':
        return 'You must choose a rating'
    }
  })()
</script>
