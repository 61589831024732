{#if $globalLoading || show}
  <div class="global-loading" out:fade={{ duration: 200 }}>
    <Icon name="spinner" spin class="text-primary" />
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import globalLoading from 'stores/loading.js'
  import { fade } from 'svelte/transition'

  export let show = false
</script>

<style>
  .global-loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483002;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

    text-align: center;
    padding-top: 10%;
  }
  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(.global-loading svg) {
    font-size: 70px;
  }
</style>
