<Btn
  icon="upload"
  class="btn-sm btn-primary btn"
  dataTest="btn-upload-file"
  on:click={() => (showModal = true)}
  loading={uploadingUserDocument || loading}
>
  Upload file
</Btn>
{#if showModal}
  {#if !showGrid}
    <Modal class={className} {color} justify-content: center; on:close={onClose}>
      <h3 slot="title" data-test="title">Where would you like to upload from?</h3>

      <div class="modal-body g3 p3" id="upload-body">
        <div
          class="upload-option flex-column flex-align-center g1 p2 text-center"
          data-test="upload-from-document-library"
          on:click={loadGrid}
          on:keyup={loadGrid}
        >
          <Icon x3 name="files" class="text-primary" />
          <span class="item-title">Document library</span>
          <span class="notes">Select a file you’ve already uploaded to your Document library on Clinician Nexus.</span>
        </div>

        <div class="upload-option flex-column flex-align-center g1 p2 text-center relative" data-test="upload-from-my-device">
          <input type="file" bind:files on:change={prepareUpload} class="absolute block inset-0 opacity-0" disabled={!acceptableFileTypes?.length} />
          <Icon x3 name="desktop" class="text-primary" />
          <span class="item-title">My device</span>
          <span class="notes">Upload a file directly from your device.</span>
        </div>
      </div>
    </Modal>

    <UserDocumentConfirmModal
      fileName={file?.name}
      onUploadHereOnly={uploadHereOnly}
      onSaveInDocumentLibrary={saveUserDocument}
      onClose={resetFileUpload}
      {className}
    />

    <UserDocumentReplaceFileModal
      {existingUserDocument}
      userDocumentSharing={existingUserDocumentSharing}
      {onReplace}
      onAdd={uploadFile}
      onClose={resetExistingUserDocument}
    />
  {:else}
    <Modal class={className} {color} justify-content: center; lg="true" on:close={onClose}>
      <h3 slot="title" data-test="title">Upload from your document library</h3>

      <div class="modal-body">
        <div class="mb1">
          <UserDocumentFilters fullWidth bind:filtersComponent {filters} onChanged={onFiltersChanged} />
        </div>

        <UserDocumentsUploadFormGrid
          {userDocuments}
          {onViewClicked}
          bind:currentPage
          bind:pageSize
          {totalCount}
          {loading}
          {filtersComponent}
          {onRowClicked}
        />
      </div>

      <div class="modal-footer">
        <Btn
          icon="upload"
          class="btn-primary"
          dataTest="btn-upload"
          autofocus
          on:click={sendUserDocument}
          loading={uploadingUserDocument}
          disabled={!selectedUserDocumentId}
        >
          Upload selected file
        </Btn>
        <Btn
          icon="close"
          on:click={() => {
            showGrid = false
            selectedUserDocumentId = null
          }}
          dataTest="btn-cancel">Cancel</Btn
        >
      </div>
    </Modal>
  {/if}

  <UserDocumentPreviewModal
    userDocument={previewFile}
    {userDocumentSharing}
    canDelete={false}
    canEdit={false}
    onDone={() => {
      previewFile = null
    }}
  />
{/if}

<script>
  import { UserDocumentListProperty } from 'config/enums.js'
  import { validateFile } from 'services/file-service.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import UserDocumentConfirmModal from 'components/UserDocumentConfirmModal.svelte'
  import UserDocumentFilters from 'components/UserDocumentFilters.svelte'
  import UserDocumentPreviewModal from 'components/UserDocumentPreviewModal.svelte'
  import UserDocumentReplaceFileModal from 'components/UserDocumentReplaceFileModal.svelte'
  import UserDocumentsUploadFormGrid from 'components/UserDocumentsUploadFormGrid.svelte'
  import validator from 'services/validator.js'

  export let acceptableFileTypes = []
  export let checkGrid = _.identity
  export let color = null
  export let currentPage = 1
  export let maxSizeMegaByte = 10
  export let pageSize = 10
  export let totalCount = null
  export let onFileUploadReady
  export let onDocumentUploadReady
  export let loading = false

  let existingUserDocument = null
  let existingUserDocumentSharing = null
  let uploadingUserDocument = false

  const className = 'row mobile-modal'

  const sortAscending = true
  const sortProperty = UserDocumentListProperty.UserId
  let currentXhr = null
  let currentXhrBody = null
  let data = new FormData()
  let file = null
  let files
  let filters = []
  let filtersComponent = null
  let previewFile = null
  let selectedUserDocumentId = null
  let showGrid = false
  let showModal = false
  let userDocuments = []
  let userDocumentSharing = {}

  api.fileType.listAvailableFileTypes(api.noMonitor).then(response => (acceptableFileTypes = response))

  $: currentPage, loadGrid()

  async function prepareUpload() {
    file = files?.[0]
    if (!validateFile(file, acceptableFileTypes, maxSizeMegaByte)) {
      await resetFileUpload()
      return
    }

    data = new FormData()
    data.append('files', file)
  }

  async function uploadHereOnly() {
    await onFileUploadReady({
      data,
      file,
    })
    showGrid = showModal = false
    file = null
    resetFileUpload()
  }

  async function saveUserDocument() {
    const fileName = data?.get('files')?.name
    if (!fileName) return
    const response = await api.userDocument.getByFileName({ fileName }, api.noMonitor)
    existingUserDocument = response?.existingUserDocument ?? null
    if (existingUserDocument == null) await uploadFile()
    else existingUserDocumentSharing = response.userDocumentSharing
  }

  async function uploadFile() {
    selectedUserDocumentId = await api.userDocument.addDocument(data, api.noMonitor)
    await sendUserDocument()
  }

  async function sendUserDocument(event) {
    if (!selectedUserDocumentId) return
    uploadingUserDocument = true
    try {
      await onDocumentUploadReady({
        userDocumentId: selectedUserDocumentId,
        original: event,
      })
      selectedUserDocumentId = null
      existingUserDocument = null
      showGrid = showModal = false
      await resetFileUpload()
    } finally {
      uploadingUserDocument = false
    }
  }

  async function onReplace() {
    selectedUserDocumentId = await api.userDocument.replaceDocument({ userDocumentId: existingUserDocument.userDocumentId }, data, api.noMonitor)
    await sendUserDocument()
  }

  async function resetFileUpload() {
    files = null
    file = null
    await onFileUploadReady({
      data: null,
      file: null,
    })
  }

  async function loadGrid() {
    // Wait for filtersComponent to update filters; it may automatically add some.
    if (!showModal) return
    loading = true
    let thisXhr = null
    try {
      thisXhr = getGridData()
      const response = await thisXhr
      if (thisXhr === currentXhr) {
        totalCount = response.totalCount
        userDocuments = checkGrid(response.userDocuments)
        userDocumentSharing = response.userDocumentSharing
      }
    } finally {
      if (thisXhr === currentXhr) {
        loading = false
        currentXhr = null
        currentXhrBody = null
        showGrid = true
      }
    }
  }

  function getGridData() {
    const body = {
      ...buildGetDataBodyBase(),
      pageSize,
      offset: pageSize * (currentPage - 1),
    }

    if (currentXhr && validator.equals(currentXhrBody, body)) {
      return currentXhr
    }

    currentXhr = api.userDocument.list(body, api.noMonitor)
    currentXhrBody = body
    return currentXhr
  }

  function buildGetDataBodyBase() {
    return {
      filters: _.cloneDeep(filters),
      sortProperty,
      sortAscending,
    }
  }

  function onClose() {
    showGrid = showModal = false
    selectedUserDocumentId = null
  }

  function onFiltersChanged(_filters) {
    currentPage = 1
    filters = _filters
    loadGrid()
  }

  function onRowClicked(userDocumentId) {
    selectedUserDocumentId = userDocumentId
  }

  function onViewClicked(userDocument) {
    previewFile = userDocument
  }

  function resetExistingUserDocument() {
    existingUserDocument = null
    existingUserDocumentSharing = null
  }
</script>

<style lang="scss">
  @import '../../css/helpers';

  #upload-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  .upload-option {
    position: relative;
    background: rgba(224, 255, 251, 0.5);
    border-radius: 8px;
    border: 1px dashed $primary;
    cursor: pointer;
    * {
      cursor: pointer;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .item-title {
    color: #003d3c;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .notes {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #00857c;
  }

  @media (max-width: 600px) {
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(.mobile-modal) {
      margin: 30% 3% !important;
    }
  }
</style>
