import Key from 'config/key.js'

const className = 'on-interact'

// Calls a function on click, Spacebar down, or Enter down.
// Also sets tabindex="0" for you unless you opt out.
export default function onInteract(node, fnOrOptions) {
  const hadTabIndexAttr = node.hasAttribute('tabindex')
  const hadTabIndex = node.tabIndex
  let clickFn, keydownFn

  function update(fnOrOptions) {
    let fn = fnOrOptions
    let tabIndex = 0

    if (fnOrOptions == null) {
      tabIndex = null
    } else if (typeof fnOrOptions !== 'function') {
      fn = fnOrOptions.fn
      tabIndex = 'tabIndex' in fnOrOptions ? fnOrOptions.tabIndex : tabIndex
    }

    if (tabIndex == null) {
      node.removeAttribute('tabindex')
    } else {
      node.tabIndex = tabIndex
    }

    if (fn) {
      // Gotta be careful with how this is used with other classes
      // because Svelte will happily clobber this class if another directive
      // assigns the class attribute directly.
      node.classList.add(className)

      clickFn = fn
      keydownFn = e => {
        switch (e.which || e.keyCode) {
          case Key.Space:
            e.preventDefault() // then fall through
          case Key.Enter:
            fn(e)
        }
      }
      node.addEventListener('click', clickFn)
      node.addEventListener('keydown', keydownFn)
    } else {
      node.classList.remove(className)
      if (clickFn) node.removeEventListener('click', clickFn)
      if (keydownFn) node.removeEventListener('keydown', keydownFn)
      clickFn = null
      keydownFn = null
    }
  }

  function destroy() {
    if (hadTabIndexAttr) {
      node.tabIndex = hadTabIndex
    } else {
      node.removeAttribute('tabindex')
    }
    node.classList.remove(className)
    if (clickFn) node.removeEventListener('click', clickFn)
    if (keydownFn) node.removeEventListener('keydown', keydownFn)
  }

  update(fnOrOptions)
  return {
    destroy,
    update(newFn) {
      destroy()
      update(newFn)
    },
  }
}
