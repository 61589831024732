{#if bucket}
  <div class="progress" class:lg use:tip={tooltip}>
    {#if infinite}
      <div class="infinite" class:lg>
        <Icon name="infinity" class="text-info" sm={!lg} {lg} />
      </div>
    {/if}
    {#if bucket.overfilledPercent > 0 && showingAllOr(criteria.overfilled)}
      <div class="progress-bar bg-danger" role="progressbar" style="width: {bucket.overfilledPercent}%" />
    {/if}
    {#if bucket.overfilledMaybePercent > 0 && showingAllOr(criteria.overfilledMaybe)}
      <div class="progress-bar bg-warning" role="progressbar" style="width: {bucket.overfilledMaybePercent}%" />
    {/if}
    {#if bucket.filledPercent > 0 && showingAllOr(criteria.filled)}
      <div class="progress-bar bg-success" role="progressbar" style="width: {bucket.filledPercent}%" />
    {/if}
    {#if bucket.pendingPercent > 0 && showingAllOr(criteria.pending)}
      <div class="progress-bar bg-purple" role="progressbar" style="width: {bucket.pendingPercent}%" />
    {/if}
    {#if bucket.waitlistedPercent > 0 && showingAllOr(criteria.waitlisted)}
      <div class="progress-bar bg-sky" role="progressbar" style="width: {bucket.waitlistedPercent}%" />
    {/if}
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import { readableNumber } from 'services/string-utils.js'

  export let bucket
  export let criteria = {
    showAll: true,
  }
  export let showTooltip = false
  export let infinite = false
  export let lg = false

  $: tooltip = showTooltip && bucket ? getTooltip(bucket) : null

  function getTooltip(b) {
    const parts = [
      infinite ? '<strong>Unlimited availability per day limit</strong>' : getTipPart('', b.maxMatchCountPerDay),
      getTipPart('overfilled', b.overfilled, 'danger'),
      getTipPart('might overfill', b.overfilledMaybe, 'warning'),
      getTipPart('filled', b.filled, 'success'),
      getTipPart('pending', b.pending, 'purple'),
      getTipPart('waitlisted', b.waitlisted, 'sky'),
      getTipPart('open', b.guaranteedMatchesOpen, null, !infinite),
    ].join('')
    return `<table class="text-left tip-table">
      ${parts}
    </table>`
  }

  function getTipPart(desc, num, color, always = false) {
    return always || num > 0
      ? `<tr${color ? ` class="text-${color}"` : ''}><td class="text-right">${readableNumber(num)}</td><td>&nbsp;shift${
          num > 1 || num === 0 ? 's' : ''
        } ${desc}</td></tr>`
      : ''
  }

  function showingAllOr(show) {
    return criteria == null || criteria.showAll || show
  }
</script>

<style>
  .progress {
    margin-bottom: 2px;
    position: relative;
    height: 10px;
  }
  .progress.lg {
    height: 20px;
  }
  /*hacky placement...probably cleaner way to place this*/
  .infinite {
    position: absolute;
    background: #eee;
    right: 0;
    padding-left: 3px;
    padding-right: 3px;
    height: 15px;
    margin-top: -4px;
  }
  .infinite.lg {
    height: 30px;
    margin-top: 0px;
    padding-top: 1px;
  }
</style>
