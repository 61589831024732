<FilterTypeDate bind:filter bind:editing {label} />

<script context="module">
  import { meta as dateMeta } from 'components/filter-types/FilterTypeDate.svelte'
  import { FilterType } from 'config/enums.js'
  const label = 'Closed date'
  export const meta = {
    ...dateMeta,
    icon: 'calendar-xmark', // Override
    iconClass: 'color-text-dark-red',
    type: FilterType.MatchClosedDate,
    label,
  }
</script>

<script>
  import FilterTypeDate from 'components/filter-types/FilterTypeDate.svelte'
  export let filter
  export let editing = false
</script>
