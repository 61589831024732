{#if orgs?.length > 1}
  <Filter bind:text={search} on:change={filterResults} {placeholder} class={filterClass} />
{/if}

<slot name="title">
  {#if title != null}
    <h2 class="mb2">
      {title}
      {#if loading}
        <Spinner />
      {/if}
    </h2>
  {/if}
</slot>

{#if orgsFilteredAll && !isEmptySearch && !loading}
  <small><em><strong>{pluralCount('result', orgsFilteredAll.orgsFlat)}</strong> for “{search}”</em></small>
{/if}

<div class="clearfix{className ? ` ${className}` : ''}">
  {#if !hideMap && markers}
    <div class="mapped-locations">
      <Mapbox tokenKey="orgpicker" let:map let:mapbox fitToLongLats={markers.map(m => m.props)}>
        {#each markers as marker (marker.org)}
          <MapboxMarker {...marker.props} {map} {mapbox} color="red" onClick={() => onSelectToggle(marker.org)} />
        {/each}
      </Mapbox>
    </div>
  {/if}

  {#if rootOrgs && rootOrgsAll && !loading}
    <InfiniteScroll
      currentCount={rootOrgs.length}
      totalCount={rootOrgsAll.length}
      distanceToLoadPage={100}
      {loadPage}
      class="tree-container{classContainer ? ` ${classContainer}` : ''}"
    >
      <RenderChildren orgs={rootOrgs} {nested} {noToggle} {onSelectToggle} {selected} {expanded} {isNotMatch} {small} {medium} {large} {xLarge}>
        <div slot="org" let:org>
          <slot name="org" {org}>
            <DefaultOrgSlot {org} {selected} {isNotMatch} {nested} {onSelectToggle} {small} {medium} {large} {xLarge} />
          </slot>
        </div>
      </RenderChildren>
    </InfiniteScroll>
  {:else}
    <HelpBlock>{noResultsText}</HelpBlock>
  {/if}
</div>

<script>
  import { buildOrgProfileUrl } from 'services/orgs-service.js'
  import { getContext, tick } from 'svelte'
  import { pluralCount } from 'services/string-utils.js'
  import DefaultOrgSlot from 'components/filter-types/FilterTypeOrg.OrgsPicker.DefaultOrgSlot.svelte'
  import Filter from 'components/Filter.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import Mapbox from 'components/Mapbox.svelte'
  import MapboxMarker from 'components/MapboxMarker.svelte'
  import orgsService from 'services/orgs-service.js'
  import RenderChildren from 'components/filter-types/FilterTypeOrg.OrgsPicker.RenderChildren.svelte'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'

  export let title = null
  export let orgs = null
  export let value = null
  export let multiple = false
  export let excludeRootOrgs = false
  export { className as class }
  export let filterClass = null
  export let classContainer = 'scrollable-xl'
  export let hideMap = false
  export let noResultsText = 'No locations'
  export let includeChildIds = true
  export let noToggle = false
  export let small = true
  export let medium = false
  export let large = false
  export let xLarge = false
  export let placeholder = 'Search locations'

  const initialValue = value
  const nested = true
  const pageSize = 40 // full client-side infinite-scroll significantly increases speed of rendering many items
  const markDirty = getContext('markDirty')

  let className = ''
  let loading = false
  let search = null
  let orgsFiltered = null
  let rootOrgs = null
  let orgsFilteredAll = null
  let expanded = {}
  let isNotMatch = {}
  let rootOrgsAll
  let markers
  let selected

  $: if (markDirty != null && value != initialValue) markDirty()
  $: value, multiple, setSelected()
  $: orgs, setInitialValues()
  $: isEmptySearch = validator.empty(search)

  function setSelected() {
    selected = getSelected()
  }

  function getSelected() {
    if (value == null) return {}
    const _selected = {}
    if (multiple) value.forEach(v => (_selected[v] = true))
    else _selected[value] = true
    return _selected
  }

  function _getRootOrgs(_orgs) {
    const keypath = nested ? 'orgsTree' : 'orgsFlat'
    return _orgs == null ? null : _orgs[keypath]
  }

  function onSelectToggle(org) {
    if (!multiple) {
      const alreadySelected = value === org.orgId
      hardSetValue(alreadySelected ? null : org.orgId)
    } else {
      const currentSelection = value || []
      const flatTree = orgsService.getFlatChildren(org, orgs, true).map(o => o.orgId)
      const selectionMinusFlatTree = currentSelection.filter(o => !flatTree.some(oo => o === oo))
      if (selected[org.orgId]) {
        hardSetValue(selectionMinusFlatTree) // deselect org tree
      } else {
        if (includeChildIds) {
          hardSetValue(_.uniq([...currentSelection, ...flatTree]))
        } else {
          hardSetValue(_.uniq([...selectionMinusFlatTree, org.orgId]))
        }
      }
    }
  }

  async function filterResults(isInital) {
    let _orgs = orgs
    if (_orgs == null) return

    // excluding roots
    if (excludeRootOrgs) {
      const roots = orgsService.getRoots(_orgs)
      _orgs = _orgs.filter(o => !roots.some(r => r.orgId === o.orgId))
    }

    loading = true
    await tick()

    const _orgsFilteredAll = orgsService.filterOrgs(_orgs, search, o => o.name)

    // map data
    if (!hideMap) {
      markers = _orgsFilteredAll.orgsFlat
        .filter(org => org.address?.addressId > 0)
        .map(org => ({
          org,
          props: {
            href: buildOrgProfileUrl(org),
            lat: org.address.lat,
            long: org.address.long,
            title: org.name,
          },
        }))
    }

    // expand if any selected
    if (isInital) showSelectedIfAny(_orgsFilteredAll)

    orgsFilteredAll = _orgsFilteredAll
    rootOrgsAll = _getRootOrgs(orgsFilteredAll)
    expanded = orgsFilteredAll.expanded
    isNotMatch = orgsFilteredAll.isNotMatch
    orgsFiltered = {
      orgsTree: orgsFilteredAll == null || orgsFilteredAll.orgsTree == null ? [] : orgsFilteredAll.orgsTree.slice(0, pageSize),
      orgsFlat: orgsFilteredAll == null ? null : orgsFilteredAll.orgsFlat.slice(0, pageSize),
    }
    rootOrgs = _getRootOrgs(orgsFiltered)
    loading = false
  }

  function hardSetValue(val) {
    value = val
  }

  function setInitialValues() {
    if (!validator.empty(search)) search = ''
    filterResults(true)
  }

  function showSelectedIfAny(_orgsFilteredAll) {
    if (value == null || value.length === 0) return
    const valueArray = multiple ? value : [value]
    if (valueArray.length > 25) return // don't expand if many are selected
    const isOrHasSelected = org => {
      if (selected[org.orgId]) return true
      return org.children.some(isOrHasSelected) //recursively check children
    }
    const recursivelyExpandSelected = org => {
      _orgsFilteredAll.expanded[org.orgId] = org.children.some(isOrHasSelected) //expand if it has children that are selected
      org.children.forEach(recursivelyExpandSelected) //recursively expand selected children
    }
    if (_orgsFilteredAll.orgsTree != null) _orgsFilteredAll.orgsTree.forEach(recursivelyExpandSelected)
  }

  function loadPage(offset) {
    const nextPage = rootOrgsAll.slice(offset, offset + pageSize)
    const keypath = nested ? 'orgsTree' : 'orgsFlat'
    orgsFiltered[keypath] = orgsFiltered[keypath].concat(nextPage)
    rootOrgs = _getRootOrgs(orgsFiltered)
  }
</script>
