import { FeatureType, Permission } from 'config/enums.js'
import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewActiveStepsReport,
  featureTypes: [FeatureType.HealthInstitution, FeatureType.TeachingInstitution],
  componentName: 'ActiveStepsReport',
  name: 'Active steps report',
  icon: 'Active',
  color: 'green',
}
