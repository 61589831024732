<div class="flex-row flex-align-center g1" style={fullWidth ? 'min-width: 500px' : 'width: 500px'}>
  <Filter bind:text={keywordSearch} placeholder="Search documents by name or type" on:change={onKeywordSearchChanged} class="flex-grow" />
  <slot name="after-keyword-search" />
</div>

<!-- TODO: Consider adding more filters.
<div bind:this={filtersContainerElem} class="flex-row flex-align-center flex-wrap g1">
  <slot />
  <Filters
    bind:this={filtersComponent}
    bind:filters
    interceptors={_interceptors}
    {includedFilterTypes}
    {excludedFilterTypes}
    {metaMapFuncs}
    {onChanged}
    {onCleared}
    class={null}
    dropdownStyle={filtersDropdownStyle}
    filterOptionsController="capacityFilterOptions"
    {label}
  />
</div>
-->
<script context="module">
  import { buildFilterTypesArray } from 'components/Filters.svelte'

  const includedFilterTypes = [FilterType.KeywordSearch]

  const metaMapFuncs = {}

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }
</script>

<script>
  import { FilterType } from 'config/enums.js'
  import Filter from 'components/Filter.svelte'
  // import Filters from 'components/Filters.svelte'
  import validator from 'services/validator.js'

  // export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  // export let excludedFilterTypes = []
  export let fullWidth = false
  export let interceptors = {}
  // export let label = null

  let keywordSearch = filters.find(f => f.type === FilterType.KeywordSearch)?.config.keyword ?? ''

  $: _interceptors = {
    [FilterType.KeywordSearch]: {
      canRemove: false,
      isAvailable: false,
    },
    ...interceptors,
  }

  // let filtersContainerElem = null
  // let filtersDropdownStyle = null

  // function onCleared() {
  //   keywordSearch = ''
  //   onKeywordSearchChanged()
  // }

  function onKeywordSearchChanged() {
    filters = filters.filter(f => f.type !== FilterType.KeywordSearch)
    if (!validator.empty(keywordSearch)) filters.push({ type: FilterType.KeywordSearch, config: { keyword: keywordSearch } })
    onChanged(filters)
  }
</script>
