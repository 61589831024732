/** Key helper so we don't have to keep commenting to explain what each key code is.. feel free to add any missing keys */
/** TODO: test with android touch keyboard, iphone touch keyboard, mac keyboard */
const Key = {
  Backspace: 8,
  Tab: 9,
  Enter: 13,
  Shift: 16,
  Ctrl: 17,
  Escape: 27,
  Space: 32,
  End: 35,
  Home: 36,
  Left: 37,
  Up: 38,
  Right: 39,
  Down: 40,
  Delete: 46,
  HyphenNumPad: 109,
  PeriodNumPad: 110,
  Comma: 188,
  Hyphen: 189,
  Period: 190,
}

export default Key

export function isNumberKey(key) {
  return (key >= 48 && key <= 57) || (key >= 96 && key <= 105)
}

export function isMaskSafeKey(key) {
  return [Key.Tab, Key.Backspace, Key.Delete, Key.Enter, Key.Left, Key.Right, Key.Up, Key.Down, Key.Home, Key.End].includes(key)
}
