{#if pronounSet != null}
  <div data-test="pronoun-set" class="pronoun-set" {style}>
    {#if pronounSet.heShe === 'name'}
      (prefers being referred to
      {#if name}
        as {name})
      {:else}
        by name)
      {/if}
    {:else}
      ({pronounSet.HeShe}/{pronounSet.HimHer})
    {/if}
  </div>
{/if}

<script context="module">
  import { loadPronounSets } from 'services/pronoun-set-helpers.js'

  let pronounSets
  loadPronounSets().then(r => {
    pronounSets = [{ pronounSetId: null }, ...r]
  })
</script>

<script>
  export let pronounSetId
  export let name = null // Leave null in places where there's not enough room to display it
  export let style

  $: pronounSet = pronounSets?.find(p => p.pronounSetId === pronounSetId)
</script>

<style lang="scss">
  .pronoun-set {
    font-weight: 300;
    color: #898989;
    align-self: flex-end;
  }
</style>
