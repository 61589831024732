const defaultValueSelector = [o => o.optionValue, o => o.value]
const defaultLabelSelector = [o => o.optionLabel, o => o.label, o => o.name, ...defaultValueSelector]
const defaultDisabledSelector = [o => o.optionDisabled, o => o.disabled, o => o.isDisabled, () => false]
const defaultHrefSelector = [o => o.href, o => o.url]

function selectFromOption(option, selectors, predicate = v => v != null, defaultValue = option) {
  if (!_.isObject(option)) return option
  for (const selector of selectors) {
    const value = selector(option)
    if (predicate(value)) return value
  }
  return defaultValue
}

function normalizeSelectors(selectors) {
  selectors = Array.isArray(selectors) ? selectors : [selectors]
  return selectors.map(fnOrKey => {
    if (typeof fnOrKey === 'function') return fnOrKey
    if (fnOrKey != null) return option => option[fnOrKey] // Most common case; get a key within the option
    return option => option // null means select the option itself
  })
}

function normalizeValueSelectors(valueSelectors) {
  return normalizeSelectors(Array.isArray(valueSelectors) ? valueSelectors : [valueSelectors])
}

function normalizeLabelSelectors(labelSelector, normalizedValueSelector) {
  return Array.isArray(labelSelector)
    ? [...normalizeSelectors(labelSelector), ...normalizedValueSelector]
    : [...normalizeSelectors([labelSelector]), ...normalizedValueSelector]
}

function buildOptions(options, valueSelector, labelSelector) {
  if (!options?.length) return []
  // If either is non-default, both need to be normalized.
  if (valueSelector !== defaultValueSelector || labelSelector !== defaultLabelSelector) {
    valueSelector = normalizeValueSelectors(valueSelector)
    labelSelector = normalizeLabelSelectors(labelSelector, valueSelector)
  }
  return options.map(o => {
    if (typeof o === 'string') return { value: o, label: o, disabled: false }
    const value = selectFromOption(o, valueSelector, v => v !== undefined)
    const label = selectFromOption(o, labelSelector)
    // Destructure object to support passing a custom object to be used in <slot> content.
    return { ...o, value, label, disabled: !!o.disabled }
  })
}

const sameAsName = Symbol('SameAsName')
export const buildOption = (name, value = sameAsName) => ({ name, value: value === sameAsName ? name : value })

export default {
  buildOptions,
  defaultLabelSelector,
  defaultValueSelector,
  defaultDisabledSelector,
  defaultHrefSelector,
  normalizeSelectors,
  selectFromOption,
}
