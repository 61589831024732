<!--
  completers and coordinators can always view a submission, even if a rotation is closed|completed.
  They just can't edit it once it's closed|completed
-->
{#if !stepSubmission.anonymize}
  <Btn icon="list" dataTest="open-form" class="btn-sm btn-primary" on:click={openForm}>
    {#if stepSubmission.canCompleteNow}
      Open form
    {:else}
      View form
    {/if}
  </Btn>
{/if}

{#if stepModalOpen}
  <Modal on:close={closeModal}>
    <div slot="title" class="flex-row flex-align-center g1" bind:this={stepNameContainer}>
      <h4 class="flex-grow">{step.name}</h4>
      <a
        href={null}
        class="print-form"
        data-test="print-form"
        use:tip={{ content: 'Print form', options: { placement: 'left' } }}
        on:click={printForm}><Icon name="print" fw /></a
      >
    </div>

    <Form on:submit={save} submitted={stepFormSubmission.isPersisted} allowSubmitInvalid>
      <div class="p2">
        <div bind:this={stepInfoContainer}>
          {#if step.stepType === StepType.Evaluation && step.shouldHideRespondentIdentity}
            <HelpBlock>This evaluation is anonymous. We value your honest feedback.</HelpBlock>
          {/if}
          <HelpBlock>
            <StepCompleterExplanation {stepSubmission} />
          </HelpBlock>
          {#if step.descriptionHtml}
            <HelpBlock>
              <SafeHtml value={step.descriptionHtml} />
            </HelpBlock>
          {/if}
        </div>

        <DynamicForm bind:values={stepFormSubmission.values} autoFocusFirstField config={step.config} disabled={!stepSubmission.canCompleteNow} />
        <StepSubmissionSuggestedExpirationDate bind:stepSubmission />
      </div>
      <div class="modal-footer">
        <div class="flex-row">
          {#if stepSubmission.canCompleteNow}
            <SubmitBtn dataTest="submit-form-step" icon="save" {loading}>Save</SubmitBtn>
          {/if}
          <StepActionsCoordinator
            {stepSubmission}
            {matchModalMatchId}
            {startDateOverride}
            {endDateOverride}
            onAction={callOnActionAndCloseModal}
            btnSm={false}
          />
          <Btn on:click={closeModal}>{stepSubmission.canCompleteNow ? 'Cancel' : 'Close form'}</Btn>
        </div>
        {#if stepSubmission.verificationExpirationDate}
          <HelpBlock><StepVerificationExpiration verificationExpirationDate={stepSubmission.verificationExpirationDate} /></HelpBlock>
        {/if}
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import { getVisibleFields } from 'services/dynamic-form-helpers.js'
  import { StepAction, StepType } from 'config/enums.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DynamicForm from 'components/DynamicForm.svelte'
  import Form from 'components/Form.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import StepCompleterExplanation from 'components/StepCompleterExplanation.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import tip from 'decorators/tip.js'
  import toaster from 'services/toaster.js'
  import StepSubmissionSuggestedExpirationDate from 'components/StepSubmissionSuggestedExpirationDate.svelte'
  import StepActionsCoordinator from 'components/StepActions.Coordinator.svelte'
  import StepVerificationExpiration from 'components/StepVerificationExpiration.svelte'

  export let stepSubmission
  export let matchModalMatchId
  export let startDateOverride
  export let endDateOverride
  export let onAction

  let stepModalOpen
  let stepFormSubmission
  let loading = false
  let stepNameContainer
  let stepInfoContainer

  $: matchModalMatchId, closeModal()
  $: step = stepSubmission.step

  async function openForm() {
    const params = {
      stepId: step.stepId,
      stepSubmissionId: stepSubmission.stepSubmissionId,
      formSubmissionId: stepSubmission.formSubmissionId,
      startDateOverride,
      endDateOverride,
    }
    stepFormSubmission = await api.step.getStepFormSubmission(params)
    stepModalOpen = true
  }

  function closeModal() {
    stepModalOpen = false
  }

  async function callOnActionAndCloseModal(...args) {
    await onAction(...args)
    closeModal()
  }

  async function save() {
    loading = true
    try {
      await onAction({
        type: StepAction.SaveForm,
        invoke: saveStepForm,
      })
    } finally {
      loading = false
    }
  }

  async function saveStepForm(stepSubmissions, isCopyingSubmission) {
    await addNewUserFiles()
    const updatedMatch = await api.step.saveStepFormSubmission(
      {
        endDateOverride,
        startDateOverride,
      },
      {
        stepSubmissions,
        formSubmissionId: isCopyingSubmission ? stepSubmission.formSubmissionId : null,
        values: isCopyingSubmission ? null : stepFormSubmission.values,
        verificationExpirationDateSuggestion: stepSubmission.verificationExpirationDateSuggestion,
      },
      api.noMonitor
    )
    closeModal()
    const message = `Form saved successfully${stepSubmissions.length === 1 ? '' : `. Affects ${stepSubmissions.length} submissions`}`
    toaster.toast({ message, type: 'success', icon: 'check' })
    return updatedMatch
  }

  async function addNewUserFiles() {
    // save any new files, if any
    const fileSteps = step.config.fields.filter(f => f.type === 'file').map(f => stepFormSubmission.values[f.name])
    for (let i = 0; i < fileSteps.length; i++) {
      const value = fileSteps[i]
      for (let j = 0; j < value.length; j++) {
        const file = value[j]
        const isNewFile = file.stepSubmissionFileId == null
        if (isNewFile) {
          if (file.userDocumentId != null) {
            fileSteps[i][j] = await api.step.addUserDocument(file.userDocumentId, api.noMonitor)
          } else {
            const newFileIds = await api.step.addUserFiles(file.formData, api.noMonitor)
            fileSteps[i][j] = newFileIds[0]
          }
        }
      }
    }
  }

  function printForm() {
    const printDiv = document.createElement('div')
    printDiv.classList.add('print')

    // put step info in there too, so they have who should complete the step and whatnot just like UI shows
    const stepName = stepNameContainer.cloneNode(true)
    stepName.removeChild(stepName.querySelector('.print-form')) // don't print the print button
    printDiv.appendChild(stepName)

    // remove some formatting that we use in the app
    const stepInfo = stepInfoContainer.cloneNode(true)
    stepInfo.classList.add('print-step-info')
    printDiv.appendChild(stepInfo)

    // add a form-group for each field
    const formConfig = step.config
    const values = stepFormSubmission.values
    const visibleFields = getVisibleFields(formConfig, values)
    visibleFields.forEach(fieldConfig => {
      const fieldDiv = document.createElement('div')
      fieldDiv.classList.add('form-group')

      const label = document.createElement('label')
      label.innerText = fieldConfig.label
      fieldDiv.appendChild(label)

      const valueContainer = document.createElement('div')
      valueContainer.classList.add('form-control-static')
      // TODO: this might need some switching depending on input type / options list / masks / etc
      valueContainer.innerHTML = getReadableValue(fieldConfig, values)
      fieldDiv.appendChild(valueContainer)

      printDiv.appendChild(fieldDiv)
    })

    // make a temporary style that hides everything BUT the .print div
    const style = document.createElement('style')
    style.innerHTML = `
        @media print {
          #app {
            display: none;
          }
          .print {
            padding: 20px;
            width: 100%;
          }
          .print-step-info > div {
            font-style: unset;
            font-size: larger;
          }
        }
        @media screen {
          .print {
            display: none
          }
        }`
    document.body.appendChild(style)
    document.body.appendChild(printDiv)

    // print it
    window.print()

    // remove the print div and the temporary style
    setTimeout(() => {
      document.body.removeChild(printDiv)
      document.body.removeChild(style)
    }, 1000)
  }

  function getReadableValue(fieldConfig, values) {
    const value = values[fieldConfig.name]
    if (fieldConfig.type == 'checkbox') return value ? 'Checked' : 'Unchecked'
    if (fieldConfig.type == 'yesno') return value ? 'Yes' : 'No'
    if ((fieldConfig.type == 'select' && !fieldConfig.multiple) || fieldConfig.type == 'radiogroup') {
      const selectedOption = fieldConfig.options?.find(o => o.value == value)
      return selectedOption?.label ?? selectedOption?.value
    }

    if ((fieldConfig.type == 'select' && fieldConfig.multiple) || fieldConfig.type == 'checkboxgroup') {
      const selectedOptions = fieldConfig.options?.filter(o => o.value == value || (Array.isArray(value) && value.includes(o.value)))
      return selectedOptions?.map(o => o?.label ?? o?.value).join('<br />')
    }

    return value
  }
</script>
