<div on:click={stopPropagationIfRequired}>
  <QuickDropdown
    {dataTest}
    {id}
    anyClickCloses
    noCaret
    icon="ellipsis"
    btnClass="btn btn-default btn-sm overflow-menu-btn"
    dropdownClass="no-round btn-list "
    on:click={stopPropagationIfRequired}
    {stopPropagation}
    {positionMenu}
  >
    <slot />
  </QuickDropdown>
</div>

<script>
  import QuickDropdown from 'components/QuickDropdown.svelte'

  export let dataTest = null
  export let stopPropagation = false
  export let positionMenu = 'absolute'
  export let id = null

  function stopPropagationIfRequired(event) {
    if (stopPropagation) event.stopPropagation()
  }
</script>
