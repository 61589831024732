{#if optionsFiltered?.length}
  <InputSelect
    {name}
    {placeholder}
    bind:value
    on:change
    {multiple}
    filterable={optionsFiltered.length > 5}
    filterStringSelector={o => [o.name]}
    options={optionsFiltered}
    valueSelector={o => o.userId}
    let:option={user}
    {isOpen}
  >
    <div class="flex-row flex-align-center">
      <ProfilePic src={user.profilePicture} name={user.name} id={user.userId} initials={user.initials} medium class="mr1" />
      {user.name}
    </div>
  </InputSelect>
{:else}
  <HelpBlock>No {optionsFiltered?.length < options?.length ? 'other ' : ''}staff with preceptor role available.</HelpBlock>
{/if}

<script>
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import api from 'services/api.js'

  export let orgId = null
  export let name = 'preceptor-ids'
  export let value = null
  export let options = null
  export let multiple = true
  export let placeholder = 'Preceptors'
  export let excludeUserIds = []
  export let isOpen = false

  if (options == null && orgId != null) api.staff.listPreceptors({ orgId }, api.noMonitor).then(response => (options = response))

  $: optionsFiltered = options == null ? null : options.filter(s => !excludeUserIds.some(es => es === s.userId))
</script>
