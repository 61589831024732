<!-- Changing something in here? Consider making a similar change to CapacityForm. -->
<Router>
  <CapacityView {capacity} bind:tabName bind:tabs>
    <Route>
      <div class="flex-column g2">
        <div>
          <label for="capacity-owner-org">
            Owner location {#if capacity.teamId}/ team{/if}
          </label>

          <OrgAndTeamProfilePictureAndName
            orgName={capacity.orgName}
            orgProfilePicture={capacity.orgProfilePicture}
            orgRelativeName={capacity.orgRelativeName}
            teamName={capacity.teamName}
            teamOrgId={capacity.teamOrgId}
            dataTest="capacity-owner-org"
          />
        </div>

        <div>
          <label class="flex-row flex-align-center g05" for="capacity-locations">
            <Icon name="hospital" class="color-text-orange" />
            Where is this opportunity?
          </label>

          <div class="flex-column g1">
            {#if capacity.locations.some(l => l.orgId !== capacity.orgId)}
              <FriendlyList
                items={capacity.locations}
                max={5}
                otherPrefix="Show "
                other="other location"
                otherPlural="other locations"
                let:item={location}
              >
                <OrgProfilePictureAndName
                  name={location.name}
                  relativeName={location.relativeName}
                  profilePicture={location.profilePicture}
                  formattedAddress={location.address?.formattedAddress}
                  size="medium"
                  useOverflowEllipsis
                />
              </FriendlyList>
            {:else}
              <div class="em small">All locations</div>
            {/if}
          </div>
        </div>

        <div>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="capacity-name">
              <Icon name="list" class="color-text-purple" />
              Opportunity name
            </label>
          </div>
          <div>{capacity.name}</div>
        </div>

        <div class="md-text-container">
          <label for="description">Description</label>
          {#if capacity.descriptionHtml}
            <SafeHtml value={capacity.descriptionHtml} />
          {:else}
            <div class="em small">None</div>
          {/if}
        </div>

        <div>
          <label class="flex-row flex-align-center g05" for="service-id">
            <Icon name="shapes" class="color-text-purple" />
            Which service is this opportunity in?
          </label>
          {#if capacity.service}
            <div>{capacity.service.name}</div>
          {:else}
            <div class="em small">None specified</div>
          {/if}
        </div>

        <div>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="rotation-types">
              <Icon name="briefcase-medical" class="text-rotation-type" />
              Experience types
            </label>
          </div>
          {#if capacity.rotationTypes.length}
            <FriendlyList items={capacity.rotationTypes} punctuation max={Infinity} />
          {:else}
            <div class="em small">None specified</div>
          {/if}
        </div>

        <div>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="specialties">
              <Icon name="file-certificate" class="text-specialty" />
              Specialties
            </label>
          </div>
          {#if capacity.specialties.length}
            <FriendlyList items={capacity.specialties} punctuation max={Infinity} />
          {:else}
            <div class="em small">None specified</div>
          {/if}
        </div>

        <div class="flex-column g1">
          <div class="flex-row flex-align-center g05">
            {#if capacity.openForScheduling}
              <Icon name="calendar-check" lg class="text-success" />
              <span>Open for scheduling</span>
            {:else}
              <Icon name="calendar-xmark" lg class="text-danger" />
              <span>Not open for scheduling</span>
            {/if}
          </div>

          <div class="flex-row flex-align-center g05 ml2">
            {#if capacity.postToSearchPage}
              <Icon name="search" lg class="text-gray" />
              <span>Posted on search page</span>
            {:else}
              <Icon name="search" lg class="text-danger" />
              <span>Not posted on search page</span>
            {/if}
          </div>

          <div class="flex-row flex-align-center g05 ml2">
            {#if capacity.allowCoordinatorScheduling}
              <Icon name="plus" lg class="text-gray" />
              <span>Allows coordinator scheduling</span>
            {:else}
              <Icon name="plus" lg class="text-danger" />
              <span>Doesn’t allow coordinator scheduling</span>
            {/if}

            {#if !canScheduleCoreRotations && capacity.allowCoordinatorScheduling}
              <span class="ml2"><RequiresUpgrade /></span>
            {/if}
          </div>

          {#if capacity.allowCoordinatorScheduling}
            <div class="ml2">
              <div class="flex-column g1 ml2">
                <div>
                  When scheduled by coordinators, students/learners
                  <strong>
                    {#if capacity.allowStudentChanges}
                      can
                    {:else}
                      cannot
                    {/if}
                  </strong>
                  propose changes to the schedule
                </div>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </Route>

    <Route path="participants">
      <div class="flex-column g2">
        {#if capacity.postToSearchPage}
          <!-- TODO(stripe): did we make elective settings a higher paid feature?  if so, either link to the page anyway and upsell on it, or do a featuretype check here -->
          {#if personaService.hasStaffPermission(Permission.ViewElectiveSettings, $persona.orgId)}
            <p>
              You can block or allow applicants from specific schools on the
              <span class="nowrap">
                <IconTextLink icon="elective-settings" text="Elective request settings" href="/settings/elective-request-settings" />
                page.
              </span>
            </p>
          {/if}
        {/if}

        <div>
          <label for="agreement-id">Accept students only from schools included in the following agreement</label>
          {#if capacity.agreement}
            <div class="flex-row flex-align-center g05">
              <Icon name="agreement" lg autoColor />
              <span>{capacity.agreement.name}</span>
            </div>
          {:else}
            <div class="em small">None specified</div>
          {/if}
        </div>

        <div>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="student-year-picker">
              <Icon name="calendars" class="color-text-teal" />
              Accept only the following student years
            </label>
          </div>
          {#if capacity.studentYears.length}
            <div><StudentYearList studentYears={capacity.studentYears} /></div>
          {:else}
            <div class="em small">Any student year</div>
          {/if}
        </div>

        <div>
          <label class="flex-row flex-align-center g05 mb05" for="disciplines">
            <Icon name="book" class="color-text-teal" />
            Accept only the following disciplines
          </label>

          {#if capacity.disciplines.length}
            <FriendlyList items={capacity.disciplines} punctuation max={Infinity} />
          {:else}
            <div class="em small">None specified</div>
          {/if}
        </div>
      </div>
    </Route>

    <Route path="scheduling">
      <div class="flex-column g2">
        <div>
          <label for="capacity-dates-start">When is this opportunity available?</label>
          <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} class="flex-row flex-align-center g05" />
        </div>

        <div>
          <div class="well">
            <div class="mb2">
              Students and school coordinators
              <strong>
                {#if capacity.allowStudentScheduling}are{:else}aren’t{/if}
              </strong>
              allowed to select specific days / shifts
            </div>
            <CapacityShiftsView bind:capacity />
          </div>
        </div>

        <div>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="matches">
              <Icon lg name="slots" class="color-text-steel" />
              Total rotations over time
            </label>
          </div>

          {#if capacityGuest?.guaranteedMatches}
            <div class="flex-row flex-align-center g05">
              <Icon name="hashtag" class="text-gray" />
              <span>{capacityGuest.guaranteedMatches}</span>
            </div>
          {:else}
            <div class="flex-row flex-align-center g05">
              <Icon lg name="infinity" class="text-info" />
              <span>Unlimited</span>
            </div>
          {/if}
        </div>

        {#if capacity.allowCoordinatorScheduling && capacity.maxMatches && capacityGuest?.guaranteedMatches}
          <div class="ml2">
            <div class="flex-row flex-align-center">
              <div>
                {#if capacityGuest?.confirmDesiredMatchCountByDate}
                  Schools <strong>must</strong> confirm or release rotations by <strong>{capacity.confirmDesiredMatchCountByDate}</strong>
                {:else}
                  Schools are allowed to confirm or release rotations
                {/if}
              </div>
            </div>
          </div>
        {/if}

        {#if capacity.allowCoordinatorScheduling}
          <div>
            Groups / cohort of learners
            <strong>
              {#if capacity.allowGroups}are{:else}aren’t{/if}
            </strong>
            allowed on the same experience
          </div>

          {#if capacity.allowGroups}
            <div class="ml2">
              <label for="max-students"> Maximum group size </label>

              <!-- TODO(Nursing): {#if capacity.maxStudentsInGroup}
                <div class="flex-row flex-align-center g05">
                  <Icon name="hashtag" class="text-gray" />
                  <span>{pluralCount('student', capacity.maxStudentsInGroup)}</span>
                </div>
              {:else}
                <div>No maximum</div>
              {/if} -->
            </div>
          {/if}
        {/if}
      </div>
    </Route>

    <svelte:fragment slot="footer">
      <Btn icon="chevron-left" on:click={tabs.back} disabled={tabName === 'general'}>Back</Btn>
      <Btn iconRight="chevron-right" on:click={tabs.next} disabled={tabName === 'scheduling'}>Next</Btn>
      <div class="flex-grow" />
      <Btn icon="close" on:click={onClose}>Close</Btn>
    </svelte:fragment>
  </CapacityView>
</Router>

<script>
  import { FeatureType, Permission } from 'config/enums.js'
  import { Route, Router } from 'svelte-routing'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityShiftsView from 'components/CapacityShiftsView.svelte'
  import CapacityView from 'components/CapacityView.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import OrgAndTeamProfilePictureAndName from 'components/OrgAndTeamProfilePictureAndName.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import RequiresUpgrade from 'components/RequiresUpgrade.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StudentYearList from 'components/StudentYearList.svelte'

  export let tabName = 'general'
  export let capacity = null
  export let capacityGuest = null
  export let onClose = _.noop

  let orgSettings = null
  let tabs

  $: canScheduleCoreRotations = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: if ($persona.orgId) loadOrgSettings()

  // when parent component sends a new capacity in (from a websocket update, for instance) and the user hasn't made any changes yet, update the UI

  async function loadOrgSettings() {
    orgSettings = await api.org.getSettings({ orgId: $persona.orgId }, api.noMonitor)
  }
</script>
