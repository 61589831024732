<div>
  <UserProfilePicAndName
    {user}
    pronounsBelow
    profilePicClass="relative"
    {profilePicOnly}
    {profilePicSmall}
    tooltip={tooltipElem ? { content: tooltipElem, options: { theme: 'light-gray-scrollable', interactive: false } } : null}
  >
    <Badge slot="profile-pic" color="info" count={user.matchIds} min={2} class="absolute" style="bottom: -5px; right: -5px" />
  </UserProfilePicAndName>

  <div class="hide">
    <div bind:this={tooltipElem}>
      <strong>{user.name}</strong> has access via <strong>{pluralCount('rotation', user.matchIds)}</strong>.
    </div>
  </div>
</div>

<script>
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'
  import Badge from 'components/Badge.svelte'
  import { pluralCount } from 'services/string-utils'

  export let user
  export let profilePicOnly = false
  export let profilePicSmall = false

  let tooltipElem
</script>
