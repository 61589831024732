<Btn
  on:click={() => context.applyQuickFilter(action, existingFilter)}
  class="btn-outline-default"
  {icon}
  disabled={disabled || existingWrappedFilter?.hasConfigValue}
  on:focus={() => context.updateSimulation(action, 'focus', existingFilter)}
  on:blur={() => context.updateSimulation(action, 'blur', existingFilter)}
  on:mouseenter={() => context.updateSimulation(action, 'mouseenter', existingFilter)}
  on:mouseleave={() => context.updateSimulation(action, 'mouseleave', existingFilter)}>{text}</Btn
>

<script>
  import { getContext } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'

  export let action
  export let text = ''
  export let disabled = false
  export let icon = 'filter'
  export let existingWrappedFilter = null

  $: existingFilter = existingWrappedFilter?.filter ?? null

  const context = getContext('qft')
</script>
