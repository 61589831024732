<div class="step-actions">
  <div class="mb05">
    <svelte:component
      this={StepInputComponents[stepSubmission.step.stepInputType]}
      {stepSubmission}
      {matchModalMatchId}
      {startDateOverride}
      {endDateOverride}
      {onAction}
    />
  </div>
  <StepActionsCoordinator {stepSubmission} {matchModalMatchId} {startDateOverride} {endDateOverride} {onAction} {btnSm} />
</div>

<script>
  import { StepInputType } from 'config/enums.js'
  import StepInputChecklistItem from './step-inputs/StepInputChecklistItem.svelte'
  import StepInputFileUpload from './step-inputs/StepInputFileUpload.svelte'
  import StepInputForm from './step-inputs/StepInputForm.svelte'
  import StepInputPrecheck from './step-inputs/StepInputPrecheck.svelte'
  import StepInputCustomIntegration from './step-inputs/StepInputCustomIntegration.svelte'
  import StepActionsCoordinator from './StepActions.Coordinator.svelte'

  export let stepSubmission
  export let matchModalMatchId
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm

  const StepInputComponents = {
    [StepInputType.ChecklistItem]: StepInputChecklistItem,
    [StepInputType.FileUpload]: StepInputFileUpload,
    [StepInputType.Form]: StepInputForm,
    [StepInputType.PreCheck]: StepInputPrecheck,
    [StepInputType.CustomIntegration]: StepInputCustomIntegration,
  }
</script>

<style>
  .step-actions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  @media only screen and (max-width: 700px) {
    .step-actions {
      margin-top: 5px;
      align-items: flex-start;
    }
  }
</style>
