{#if input}
  <Modal lg on:close={onClose}>
    <h4 slot="title">
      {#if input.isNew}
        Add participant
      {:else}
        Edit participant
      {/if}
    </h4>
    <Form on:submit={apply} class={null}>
      <div class="modal-body">
        <div class="flex-row flex-wrap flex-align-stretch g2">
          <div class="flex-grow flex-column main-form-content">
            <div class="flex-row g2 mb2">
              <div style="margin-top: 26px"><ColorPicker bind:value={input.color} dataTest="color-picker" /></div>
              <FormGroup
                class="flex-grow"
                valid={!hasOpenAnySchool || !input.isNew || validator.int(input.guestOrgId)}
                validationMessage="Please select a school / program."
              >
                <label class="flex-row flex-align-center g05" for="guest-org-id">
                  <Icon name="school" class="color-text-blue" />
                  School / program
                  {#if hasOpenAnySchool && (input.isNew || !isOpenAnySchool)}
                    <RequiredMarker />
                  {/if}
                </label>
                <OrgPicker
                  name="guest-org-id"
                  bind:value={input.guestOrgId}
                  bind:selectedOrg={selectedGuestOrg}
                  placeholder={hasOpenAnySchool && input.isNew && isOpenAnySchool ? 'None selected' : openSchoolLabel}
                  emptyPickerText={openSchoolLabel}
                  class="w390"
                  featureTypes={[FeatureType.TeachingInstitution]}
                  excludeHierarchyOrgIds={capacity.guests?.filter(guest => guest.guestOrgId != null)?.map(guest => guest.guestOrgId)}
                  includeAgreementCount
                  includeAddresses
                  includeTags
                  allowSelectNull={!hasOpenAnySchool || (!input.isNew && isOpenAnySchool)}
                  includeContactInfo
                  includeDescription
                  modalTitle={input.capacityGuestId > 0 ? 'Edit opportunity school / program' : 'Add opportunity school / program'}
                />
              </FormGroup>
            </div>

            {#if input.guestOrgId != null}
              <FormGroup>
                <label class="flex-row flex-align-center g05" for="guest-agreement-id">
                  <Icon name="agreement" lg autoColor />
                  Agreement
                  <Help tipOptions={{ maxWidth: 350, theme: 'light-gray-scrollable' }}>
                    If you don’t see the agreement you’re looking for, make sure it’s selected as available to this opportunity’s service (currently
                    {#if selectedService}
                      set to <Icon name="shapes" class="color-text-purple" />
                      <strong>{selectedService.name}</strong>
                    {:else}
                      unset
                    {/if}
                    on the <Icon name="settings" />
                    <strong class="strongish">General info</strong> tab).
                  </Help>
                </label>

                <AgreementPicker
                  bind:value={input.agreementId}
                  bind:selectedAgreement
                  filters={defaultAgreementFilter}
                  name="guest-agreement-id"
                  allowSelectNull
                  placeholder="None selected"
                  class="agreement-picker-guest-modal"
                />

                <!--
              TODO(OrgRelationship): This snippet came from master, but master was set up for a single agreement.
                                      This code may just work, but I'm just trying to get master merged into this branch,
                                      so I'm leaving it commented out for now.

              {#if selectedAgreement && selectedAgreement.status !== AgreementStatus.Active}
                <Icon name="alert-triangle" class="text-warning" />
                <HelpBlock class="text-danger" inline="true" name="inactive-agreement-{input.agreementId}">
                  This agreement is {formatEnumValue(AgreementStatus, selectedAgreement.status).toLowerCase()}
                </HelpBlock>
              {/if}
            -->
              </FormGroup>
            {/if}

            <FormGroup>
              <InputCheckbox bind:checked={input.autoAcceptMatches} name="auto-accept-matches" labelClass="p0 m0 normal">
                <span>Automatically approve rotation requests from this participant</span>
              </InputCheckbox>
            </FormGroup>

            <div class="flex-row flex-align-top g3">
              <FormGroup valid={input.maxMatches == null || validator.int(input.maxMatches)} class="mb1">
                <div class="flex-row flex-align-center g05 mb05">
                  <label class="flex-row flex-align-center g05 m0" for="maximum-matches" data-test="maximum-rotations-label">
                    <Icon name="rotation" autoColor />
                    Maximum rotations
                    <RequiredMarker />
                    <Help>How many rotations should this participant be limited to?</Help>
                  </label>
                </div>
                <InputOptionalNumber
                  name="maximum-matches"
                  bind:value={input.maxMatches}
                  noValueLabelClass="flex-row flex-align-center g05"
                  noValueIsNull
                >
                  <svelte:fragment slot="no-value-label">
                    <Icon lg name="infinity" class="text-info" />
                    <span>Unlimited</span>
                  </svelte:fragment>
                </InputOptionalNumber>
              </FormGroup>

              {#if capacity.allowGroups}
                <FormGroup valid={input.maxStudents == null || validator.int(input.maxStudents)}>
                  <div class="flex-row flex-align-center g05 mb05">
                    <label class="flex-row flex-align-center g05 m0" for="maximum-students" data-test="maximum-students-label">
                      <Icon name="graduation-cap" class="color-text-teal" />
                      Maximum students
                      <RequiredMarker />
                      <Help>How many students should this participant be limited to?</Help>
                    </label>
                  </div>
                  <InputOptionalNumber
                    name="maximum-students"
                    bind:value={input.maxStudents}
                    noValueLabelClass="flex-row flex-align-center g05"
                    noValueIsNull
                  >
                    <svelte:fragment slot="no-value-label">
                      <Icon lg name="infinity" class="text-info" />
                      <span>Unlimited</span>
                    </svelte:fragment>
                  </InputOptionalNumber>
                </FormGroup>
              {/if}
            </div>

            {#if capacity.maxMatches}
              <div class="flex-column mb2">
                <FormGroup
                  class="mb0"
                  valid={input.guaranteedMatches == null || validator.intRange(input.guaranteedMatches, 0, input.maxMatches ?? Infinity)}
                  validationMessage={input.maxMatches == null
                    ? 'Please enter a number greater than zero.'
                    : `Please enter a number between 0 and ${input.maxMatches} (this participant’s maximum rotations).`}
                >
                  <div class="flex-row flex-align-center g05 mb05">
                    <label class="flex-row flex-align-center g05 m0" for="guaranteed-matches">
                      <Icon sm name="rotation" autoColor />
                      Guaranteed rotations
                      <RequiredMarker />
                      <Help>How many rotations should this participant be guaranteed to have?</Help>
                    </label>
                  </div>
                  <InputOptionalNumber
                    name="guaranteed-matches"
                    bind:value={input.guaranteedMatches}
                    noValueLabelClass="flex-row flex-align-center g05"
                    noValueLabel="None guaranteed"
                  />
                </FormGroup>

                <FormGroup>
                  <div class="flex-column">
                    <label for="capacity-guest-auto-release-unscheduled-matches-when">
                      How should this participant’s guaranteed rotations be released to others?
                    </label>
                    <AutoReleaseUnscheduledMatchesWhenPicker
                      bind:value={input.autoReleaseUnscheduledMatchesWhen}
                      {isOpenAnySchool}
                      disabled={!input.guaranteedMatches}
                    />
                  </div>
                </FormGroup>
              </div>
            {/if}
            <label>Dates & deadlines</label>
            <FormGroup class="mb1">
              <DatePicker
                labelStyle={datePickerLabelStyle}
                name="capacity-guest-scheduling-dates"
                iconClass="color-text-dark-green"
                bind:value={input.schedulingStartDate}
              >
                <span class="small">Request start date</span>
              </DatePicker>
            </FormGroup>

            {#if input.guestOrgId != null}
              <FormGroup class="mb1" valid={confirmDesiredMatchCountByDateIsValid}>
                <svelte:fragment slot="validationMessage">
                  Please enter a date
                  {#if input.schedulingEndDate != null}
                    before the <strong class="strongish">request end date</strong>{/if}{#if confirmDesiredMatchCountByDateIsRequired}
                    <!-- Yay, space hacks-->
                    {' '}when unscheduled rotations should be automatically released{/if}.
                </svelte:fragment>
                <DatePicker
                  labelStyle={datePickerLabelStyle}
                  name="capacity-guest-confirm-rotations-date"
                  iconClass="color-text-blue"
                  bind:value={input.confirmDesiredMatchCountByDate}
                >
                  <span class="small">Confirmation deadline</span>
                  {#if confirmDesiredMatchCountByDateIsRequired}
                    <RequiredMarker />
                  {/if}
                </DatePicker>
              </FormGroup>
            {/if}

            <FormGroup class="mb1" valid={requestEndDateValidationMessageIsValid}>
              <svelte:fragment slot="validationMessage">
                Please enter a date
                {#if input.schedulingStartDate != null}
                  on or after the <strong class="strongish">request start date</strong>{/if}{#if requestEndDateIsRequired}
                  <!-- Yay, space hacks-->
                  {' '}when unscheduled rotations should be automatically released{/if}.
              </svelte:fragment>
              <DatePicker
                labelStyle={datePickerLabelStyle}
                name="capacity-guest-scheduling-dates"
                iconClass="color-text-light-red"
                bind:value={input.schedulingEndDate}
              >
                <span class="small">Request end date</span>
                {#if requestEndDateIsRequired}
                  <RequiredMarker />
                {/if}
              </DatePicker>
            </FormGroup>

            <HelpBlock>
              Leave the <Icon name="calendar" class="color-text-light-red" />
              <strong>End date</strong> empty to keep the ability to request open until the opportunity ends.
            </HelpBlock>
            <!-- TODO(nursing): Fields tied to the allow placeholder students functionality and it should come back when we add that in) -->
            <!--
            <FormGroup class="mb0">
              <InputCheckbox bind:checked={input.allowsAddingPlaceholderStudents} name="allow-adding-placeholder-students" labelClass="p0 m0 normal">
                <span>Allow this participant to add placeholder students.</span>
                <Help>
                  Placeholder students allow participants to tell you how many students will be coming, but not exactly who those students are. You
                  can also set a deadline for participant coordinators to add the actual students to the rotation closer to the rotation’s start date.
                </Help>
              </InputCheckbox>
            </FormGroup>
            <FormGroup class="m0">
              <InputNumber bind:value={input.beforeMatchStartToCloseIfStudentlessCount} />
              <InputSelect
                options={getBeforeMatchStartToCloseIfStudentlessOptions()}
                name="type"
                bind:value={input.beforeMatchStartToCloseIfStudentlessUnit}
              />
            </FormGroup>
            <FormGroup class="mb0">
              <label for="capacity-guest-date-limit-adding-students">Require students to be added by a certain date</label>
              <DatePicker
                name="capacity-guest-add-students-by-date"
                iconClass="color-text-dark-green"
                placeholder="Students added by"
                disabled={!input.allowsAddingPlaceholderStudents}
                bind:value={input.addStudentsByDate}
              />
            </FormGroup> -->
          </div>
          <div class="quick-info flex-column text-center g05 p2">
            <h3>Opportunity quick info</h3>
            <div class="flex-column">
              <span>Total rotations available</span>
              <label data-test="max-rotations-allowed-quick-info">
                {#if capacity.maxMatches}
                  {readableNumber(capacity.maxMatches)}
                {:else}
                  <Icon lg name="infinity" class="text-info" />
                  Unlimited
                {/if}
              </label>
            </div>
            {#if capacity.maxMatches}
              <div class="flex-column">
                <span>Total rotations currently guaranteed to all participants</span>
                <label data-test="maximum-students-group-quick-info">
                  {#if totalGuaranteedMatches > 0}
                    {#if !allTruthyOrAllFalsy(capacity.guests, 'guaranteedMatches')}
                      <Icon name="greater-than-equal" xs />
                    {/if}
                    {readableNumber(totalGuaranteedMatches)}
                  {:else}
                    <Icon lg name="infinity" class="text-info" />
                    Unlimited
                  {/if}
                </label>
              </div>
            {/if}
            {#if capacity.allowGroups}
              <div class="flex-column">
                <span>Total number of maximum groups for all participants</span>
                <label data-test="total-guaranteed-all-participants-quick-info">
                  {#if totalMaxMatches > 0}
                    {#if !allTruthyOrAllFalsy(capacity.guests, 'maxMatches')}
                      <Icon name="greater-than-equal" xs />
                    {/if}
                    {readableNumber(totalMaxMatches)}
                  {:else}
                    <Icon lg name="infinity" class="text-info" />
                    Unlimited
                  {/if}
                </label>
              </div>
              <div class="flex-column">
                <span>Total number of maximum students for all participants</span>
                <label>
                  {#if totalMaxStudents > 0}
                    {#if !allTruthyOrAllFalsy(capacity.guests, 'maxStudents')}
                      <Icon name="greater-than-equal" xs />
                    {/if}
                    {readableNumber(totalMaxStudents)}
                  {:else}
                    <Icon lg name="infinity" class="text-info" />
                    Unlimited
                  {/if}
                </label>
              </div>
            {/if}
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <SubmitBtn icon="check" class="btn-primary" dataTest="apply-capacity-guest-btn">Apply</SubmitBtn>
        <Btn icon="cancel" on:click={onClose} dataTest="cancel">Cancel</Btn>
        {#if !input.isNew}
          <Btn
            icon="delete"
            on:click={removeGuest}
            class="btn-danger pull-right"
            confirm={{
              title: 'Remove participant',
              message: `Are you sure you want to remove the participant <strong>${input.guestOrgName}</strong>?`,
              confirmLabel: 'Delete',
              confirmClass: 'btn-danger',
            }}
          >
            Delete
          </Btn>
        {/if}
      </div>
    </Form>
  </Modal>
{/if}

<script context="module">
  import colorService from 'services/color-service.js'

  export function buildNewGuest(guests = []) {
    let capacityGuestId = -1
    for (const guest of guests) {
      if (guest.capacityGuestId <= capacityGuestId) {
        capacityGuestId = guest.capacityGuestId - 1
      }
    }
    return {
      capacityGuestId,
      guestOrgId: null,
      agreementId: null,
      autoAcceptMatches: null,
      maxMatches: null,
      guaranteedMatches: null,
      maxStudents: null,
      schedulingStartDate: null,
      schedulingEndDate: null,
      confirmDesiredMatchCountByDate: null,
      autoReleaseUnscheduledMatchesWhen: AutoReleaseUnscheduledMatchesWhen.Never,
      allowsAddingPlaceholderStudents: false,
      addStudentsByDate: null,
      color: colorService.getBestAvailableColor(guests.map(g => g.color)),
    }
  }
</script>

<script>
  import { activeStatusesOnly } from 'components/fields/AgreementPicker.svelte'
  import { allTruthyOrAllFalsy } from 'services/array-utils.js'
  import { AutoReleaseUnscheduledMatchesWhen, ComparisonType, FilterType, FeatureType } from 'config/enums.js'
  import { readableNumber } from 'services/string-utils.js'
  import { tick } from 'svelte'
  import AgreementPicker from 'components/fields/AgreementPicker.svelte'
  import AutoReleaseUnscheduledMatchesWhenPicker from 'components/AutoReleaseUnscheduledMatchesWhenPicker.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import ColorPicker from 'components/fields/ColorPicker.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getValueFromCollectionBySubpath from 'services/get-value-from-collection-by-subpath.js'
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputOptionalNumber from 'components/InputOptionalNumber.svelte'
  import Modal from 'components/Modal.svelte'
  import OrgPicker from 'components/OrgPicker.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'

  export let baseHref
  export let subpath
  export let capacity
  export let guests
  export let shifts = []
  export let selectedService
  export let defaultServiceAndAgreementFilter

  const form = 'CapacityGuestForm'
  const openSchoolLabel = 'Open (any school)'
  const datePickerLabelStyle = 'min-width: 170px'

  let index = -1
  let input = null
  let inputInitial = null
  let selectedGuestOrg
  let selectedAgreement

  $: subpath, guests, setInput()
  $: if (input) input.agreement = selectedAgreement
  $: if (input) input.guestOrg = selectedGuestOrg
  $: hasChanges = !validator.equals(comparableInput(input), comparableInput(inputInitial))
  $: hasChanges ? unsavedForms.add(form) : unsavedForms.del(form)
  $: capacityGuestsForCalculations =
    capacity.guests && input ? [...capacity.guests.filter(g => g.capacityGuestId !== input.capacityGuestId), input] : []
  $: totalMaxMatches = _.sumBy(capacityGuestsForCalculations, g => g.maxMatches || 0)
  $: totalGuaranteedMatches = _.sumBy(capacityGuestsForCalculations, g => g.guaranteedMatches || 0)
  $: totalMaxStudents = capacity.allowGroups ? _.sumBy(capacityGuestsForCalculations, g => g.maxStudents || 0) : null
  $: selectedServiceId = capacity.serviceId
  $: if (!input.allowsAddingPlaceholderStudents) input.addStudentsByDate = null
  $: if (input.guestOrgId == null || input.guestOrgId === 0) {
    input.agreementId = null
    input.agreement = null
  }
  $: hasOpenAnySchool = capacity.guests?.some(guest => guest.guestOrgId == null || guest.guestOrgId === 0)
  $: isOpenAnySchool = input.guestOrgId == null || input.guestOrgId === 0

  $: confirmDesiredMatchCountByDateIsRequired =
    input.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.PastConfirmDesiredMatchCountByDate
  $: requestEndDateIsRequired = input.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.PastSchedulingEndDate

  $: confirmDesiredMatchCountByDateIsValid =
    (!confirmDesiredMatchCountByDateIsRequired || input.confirmDesiredMatchCountByDate != null) &&
    (input.confirmDesiredMatchCountByDate == null ||
      input.schedulingEndDate == null ||
      dayjs(input.confirmDesiredMatchCountByDate).isBefore(dayjs(input.schedulingEndDate)))
  $: requestEndDateValidationMessageIsValid =
    (!requestEndDateIsRequired || input.schedulingEndDate != null) &&
    (input.schedulingEndDate == null ||
      input.schedulingStartDate == null ||
      dayjs(input.schedulingStartDate).isSameOrBefore(dayjs(input.schedulingEndDate)))

  $: defaultAgreementFilter = [
    ...defaultServiceAndAgreementFilter,
    input?.guestOrgId
      ? {
          type: FilterType.GuestOrgs,
          config: {
            schoolOrgIds: [input.guestOrgId],
            exclude: false,
          },
        }
      : null,
    activeStatusesOnly,
    selectedServiceId
      ? {
          type: FilterType.Services,
          config: {
            serviceIds: [selectedServiceId],
            comparison: ComparisonType.IsChecked,
          },
        }
      : null,
  ].filter(_.identity)

  function comparableInput(value) {
    const comparable = {
      ...value,
    }
    delete comparable.guestOrg
    delete comparable.agreement
    if (isOpenAnySchool) comparable.confirmDesiredMatchCountByDate = null
    return comparable
  }

  function setInput() {
    if (input) return
    const maybeInput = getValueFromCollectionBySubpath(guests, subpath, 'capacityGuestId', newGuest, copyGuest, null)
    if (maybeInput) {
      index = guests.indexOf(maybeInput)
      input = _.cloneDeep(maybeInput)
      input.isNew = subpath === '+'
      input.agreementId ??= null
      input.guestOrgId ??= null
      input.guestOrgProfilePicture ??= null
      input.maxMatches ??= null
      input.guaranteedMatches ??= null
      input.maxStudents ??= null
      input.addStudentsByDate ??= null
      input.confirmDesiredMatchCountByDate ??= null
      input.autoReleaseUnscheduledMatchesWhen ??= AutoReleaseUnscheduledMatchesWhen.Never
      inputInitial = _.cloneDeep(input)
    } else {
      setInputGuestOrg()
    }
    // TODO: Not sure how I want to handle the guest not being found.
    //       Could be the user screwing with the URL, or the guest having been deleted.
  }

  function setInputGuestOrg() {
    if (selectedGuestOrg) {
      input.guestOrgId = selectedGuestOrg.orgId
      input.guestOrgName = selectedGuestOrg.name
      input.guestOrgProfilePicture = selectedGuestOrg.profilePicture
      input.guestOrgRelativeName = selectedGuestOrg.relativeName
    } else {
      input.guestOrgId = null
    }
  }

  async function apply() {
    if (isOpenAnySchool) input.confirmDesiredMatchCountByDate = null
    if (index > -1) {
      guests[index] = input
      guests = guests
    } else {
      guests = guests.concat(input)
    }
    setInputGuestOrg()
    inputInitial = _.cloneDeep(input)
    // Allow hasChanges to update before closing the modal so the user doesn't get prompted about unsaved changes.
    await tick()
    onClose()
  }

  function newGuest() {
    return buildNewGuest(guests)
  }

  function copyGuest(guest) {
    return {
      ...guest,
      capacityGuestId: null,
    }
  }

  function onClose() {
    unsavedForms.navigateSafe(baseHref)
  }

  function removeGuest() {
    guests = guests.filter(g => g.capacityGuestId !== input.capacityGuestId)
    for (const shift of shifts) {
      if (shift.guests?.length) shift.guests = shift.guests.filter(g => g.guestOrgId !== input.guestOrgId)
    }
    onClose()
  }
</script>

<style lang="scss">
  .modal-body {
    --quick-info-width: 300px;
  }

  .quick-info {
    width: var(--quick-info-width);
    background: #f8f8f8;
    height: fit-content;
  }

  .main-form-content {
    width: calc(100% - var(--quick-info-width) - 20px);
  }

  @media only screen and (max-width: 1250px) {
    .main-form-content {
      width: 100%;
    }
  }
</style>
