{#if inline}
  <TagCloudContent {rotationTypes} {specialties} {disciplines} {orgTags} />
{:else if disciplines.length || specialties.length || rotationTypes.length || orgTags.length}
  <div class="flex-row flex-align-center flex-wrap gc2r05">
    <TagCloudContent {rotationTypes} {specialties} {disciplines} {orgTags} />
  </div>
{/if}

<script>
  import TagCloudContent from 'components/TagCloud.Content.svelte'

  export let ctx = {}
  export let inline = false

  $: rotationTypes = ctx?.rotationTypes ?? []
  $: specialties = ctx?.specialties ?? []
  $: disciplines = ctx?.disciplines ?? []
  $: orgTags = ctx?.orgTags ?? []
</script>
