<div class="radio radio-primary">
  <input
    {disabled}
    type="radio"
    name="{name}-no-value"
    data-test="{name}-no-value"
    id="{name}-no-value"
    bind:group={hasValue}
    value={false}
    on:click={valueSetToNull}
  />
  <label for="{name}-no-value" class={noValueLabelClass}><slot name="no-value-label">{noValueLabel}</slot></label>
</div>
<div class="radio radio-primary">
  <input {disabled} type="radio" name="{name}-has-value" data-test="{name}-has-value" id="{name}-has-value" bind:group={hasValue} value={true} />
  <label for="{name}-has-value">
    <InputNumber
      bind:value
      on:focus={onFocusHasValueField}
      sm
      name="{name}-input"
      {condense}
      {min}
      {disabled}
      class={inputNumberClass}
      style={inputNumberStyle}
      {width}
      let:focus
    >
      <slot>
        {#if valueLabelPostfix}<span on:click={focus} class="input-group-addon">{valueLabelPostfix}</span>{/if}
      </slot>
    </InputNumber>
  </label>
</div>

<script>
  import InputNumber from 'components/fields/InputNumber.svelte'

  export let value = null
  export let name = 'optional-number'
  export let valueLabelPostfix = ''
  export let noValueLabel = 'No maximum'
  export let noValueLabelClass = null
  export let noValueIsNull = false
  export let condense = false
  export let min = -Infinity
  export let disabled = false
  export let inputNumberClass = 'input-group'
  export let inputNumberStyle = null
  export let width = null
  let hasValue = false

  function onFocusHasValueField() {
    hasValue = true
  }

  function valueSetToNull() {
    value = null
  }

  $: if ((noValueIsNull && value != null) || (!noValueIsNull && value > 0)) {
    hasValue = true
  }
</script>
