{#if $user.isImpersonating || $persona.personaType === PersonaType.CN}
  <SubNavItem
    href="/app-version"
    icon="merge"
    title="Code was built {releaseDateLocalFriendly}, check Trello released board for release date (likely the same date, different time)."
    >App version</SubNavItem
  >
{/if}

<script context="module">
  import { PersonaType } from 'config/enums.js'
  import user from 'stores/user.js'
  import persona from 'stores/persona.js'
  import SubNavItem from './SubNavItem.svelte'

  // formatted like YYYYMMDD_hhmmss_GitHash
  // window.APP_VERSION = '20230111_215933_751e091164e5e4c40c4eb1ca1489cc4cba9452d2' // prod example for testing locally. You can get it from the html page in a script block.
  const v = window.APP_VERSION
  export const releaseYear = v.substring(0, 4)
  export const releaseMonth = v.substring(4, 6)
  export const releaseDay = v.substring(6, 8)
  export const releaseHour = v.substring(9, 11)
  export const releaseMinute = v.substring(11, 13)
  export const releaseSecond = v.substring(13, 15)
  export const releaseDate = dayjs(`${releaseYear}-${releaseMonth}-${releaseDay}T${releaseHour}:${releaseMinute}:${releaseSecond}Z`)
  export const releaseDateLocalFriendly = releaseDate.format('MMM D, YYYY h:mm:ss A') // displaying in local time, rather than UTC.
  export const releaseGitCommit = v.substring(16, v.length)
</script>
