import advancedFormat from 'dayjs/plugin/advancedFormat.js' // to get ordinal like 1st, 2nd, etc.
import calendar from 'dayjs/plugin/calendar.js'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween.js'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
import minMax from 'dayjs/plugin/minMax.js'
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js'
import relativeTime from 'dayjs/plugin/relativeTime.js' // dayjs.to, dayjs.fromNow...
import utc from 'dayjs/plugin/utc.js' // dayjs.utc
import weekday from 'dayjs/plugin/weekday.js'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(quarterOfYear)
dayjs.extend(minMax)

window.dayjs = dayjs
// console.log('dayjs is on window...')
// console.log(dayjs('Tuesday February 4th, 2020', 'dddd MMMM Do, YYYY').format('M/D/YYYY'))
// console.log(dayjs('Tuesday February 4, 2020', 'dddd MMMM D, YYYY').format('M/D/YYYY'))
// console.log(dayjs('2/2020', 'M/YYYY').format('M/D/YYYY'))
// console.log(dayjs('02/2020', 'MM/YYYY').format('M/D/YYYY'))
// console.log(dayjs('2/4/2020', 'M/D/YYYY').format('M/D/YYYY'))

export default dayjs
