<HelpBlock>
  <Icon name="info" />
  Tip:
  <slot />
</HelpBlock>

<script>
  import Icon from 'components/Icon.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
</script>
