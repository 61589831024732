<!-- Show 1 line per setting. Toggle the line green/red depending whether the requirement is met or not. -->
<div class="mt1 mb1">
  <PasswordComplexitySetting {submitted} dataTest="min-characters" count={passwordSettings.min} valid={minValid} unit="character" />
  <PasswordComplexitySetting
    {submitted}
    dataTest="lower-characters"
    count={passwordSettings.lowercase}
    valid={lowercaseValid}
    unit="lower case letter"
  />
  <PasswordComplexitySetting
    {submitted}
    dataTest="upper-characters"
    count={passwordSettings.uppercase}
    valid={uppercaseValid}
    unit="upper case letter"
  />
  <PasswordComplexitySetting {submitted} dataTest="numeric-characters" count={passwordSettings.numeric} valid={numericValid} unit="number" />
  <PasswordComplexitySetting
    {submitted}
    dataTest="special-characters"
    count={passwordSettings.specialCharacter}
    valid={specialCharacterValid}
    unit="special character"
  />
</div>

<script>
  import PasswordComplexitySetting from './PasswordComplexitySetting.svelte'

  export let valid
  export let password
  export let passwordSettings
  export let submitted

  $: minValid = password.length >= passwordSettings.min
  $: lowercaseValid = countLowercase(password) >= passwordSettings.lowercase
  $: uppercaseValid = countUppercase(password) >= passwordSettings.uppercase
  $: numericValid = countNumbers(password) >= passwordSettings.numeric
  $: specialCharacterValid = countSpecialCharacters(password) >= passwordSettings.specialCharacter
  $: password, setValid()

  function setValid() {
    valid = minValid && lowercaseValid && uppercaseValid && numericValid && specialCharacterValid
  }

  function countLowercase(str) {
    return (str.match(/[a-z]/g) || []).length
  }
  function countUppercase(str) {
    return (str.match(/[A-Z]/g) || []).length
  }
  function countNumbers(str) {
    return (str.match(/[0-9]/g) || []).length
  }
  function countSpecialCharacters(str) {
    return (str.match(/[^a-zA-Z0-9]/g) || []).length
  }
</script>
