{#if !studentRecord.deleted && studentRecord.totalMatchStepsAssigned != null}
  {#if hasSchoolComplianceStepsFeature}
    <StudentStepSummaryLine
      {userId}
      unit="school compliance steps"
      total={studentRecord.totalSchoolComplianceStepsAssigned}
      completed={studentRecord.totalSchoolComplianceStepsCompleted}
      stepTypes={[StepType.SchoolCompliance]}
    />
  {/if}

  <StudentStepSummaryLine
    {userId}
    unit={hasSchoolComplianceStepsFeature ? 'rotation steps' : 'steps'}
    total={studentRecord.totalMatchStepsAssigned}
    completed={studentRecord.totalMatchCompletedSteps}
    stepTypes={hasSchoolComplianceStepsFeature ? Object.values(StepType).filter(st => st !== StepType.SchoolCompliance) : null}
  />
{/if}

<script>
  import personaService from 'services/persona-service'
  import StudentStepSummaryLine from './StudentStepSummaryLine.svelte'
  import { FeatureType, StepType } from 'config/enums'

  export let userId
  export let studentRecord

  const hasSchoolComplianceStepsFeature = personaService.canUseAnyFeatureType(FeatureType.SchoolComplianceRequirements)
</script>
