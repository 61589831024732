<div class="calendar-box" {tabindex} on:keydown={handleKeydown} on:focus={onContainerFocused} on:blur={() => (containerIsFocused = false)}>
  {#each options as day (day.value)}
    {@const disabled = disabledDays.includes(day.value)}
    <div
      class="flex-row flex-align-center flex-justify-center clickable day"
      class:selected={value.includes(day.value)}
      class:disabled
      class:not-allowed={disabled}
      class:focused={containerIsFocused && day.value === focusedDay}
      on:mousedown={() => focusDayIfPossible(day.value)}
      on:click={() => toggleDay(day.value)}
    >
      {#if day.text}
        <small>{day.text}</small>
      {:else}
        <div>{day.value}</div>
      {/if}
    </div>
  {/each}
</div>

<script context="module">
  const options = [...Array.from({ length: 31 }, (_, i) => ({ value: i + 1, text: '' })), { value: 32, text: 'Last ' }]
</script>

<script>
  import Key from 'config/key.js'

  export let value = []
  export let disabledDays = []

  $: tabindex = options.every(o => disabledDays.includes(o.value)) ? null : 0

  let containerIsFocused = false
  let focusedDay = null

  function onContainerFocused() {
    containerIsFocused = true
    if (focusedDay != null && !disabledDays.includes(focusedDay)) return
    focusedDay = 0
    focusDay(1)
    if (focusedDay === 0) focusedDay = null
  }

  function handleKeydown(e) {
    const key = e.which || e.keyCode
    switch (key) {
      case Key.Left:
        focusDay(-1, 'X')
        break
      case Key.Up:
        focusDay(-7, 'Y')
        e.preventDefault()
        break
      case Key.Right:
        focusDay(1, 'X')
        break
      case Key.Down:
        focusDay(7, 'Y')
        e.preventDefault()
        break
      case Key.Space:
        toggleDay(focusedDay)
        break
    }
  }

  function focusDay(direction, axis) {
    const potentialDaysToFocus = Math.floor(options.length / Math.abs(direction))
    let dayToMaybeFocus = focusedDay + direction
    for (let i = 0; i < potentialDaysToFocus; i++) {
      // The X axis wraps the 1st and Last days of the month
      // but also wraps linearly, as in 7 <-> 8, 14 <-> 15, etc.
      if (axis === 'X') {
        if (dayToMaybeFocus < 1) dayToMaybeFocus = 32
        else if (dayToMaybeFocus > 32) dayToMaybeFocus = 1
      }
      // Whereas the Y axis just wraps vertically
      else if (dayToMaybeFocus < -2) dayToMaybeFocus += 35
      else if (dayToMaybeFocus < 1) dayToMaybeFocus += 28
      else if (dayToMaybeFocus > 35) dayToMaybeFocus -= 35
      else if (dayToMaybeFocus > 32) dayToMaybeFocus -= 28

      if (!disabledDays.includes(dayToMaybeFocus)) {
        focusedDay = dayToMaybeFocus
        break
      }

      dayToMaybeFocus += direction
    }
  }

  function focusDayIfPossible(day) {
    if (disabledDays.includes(day)) return
    focusedDay = day
  }

  function toggleDay(day) {
    if (disabledDays.includes(day)) return
    focusedDay = day
    if (value.includes(day)) {
      value = value.filter(d => d !== day)
    } else {
      value = [...value, day]
    }
    value.sort()
  }
</script>

<style lang="scss">
  @import '../../../css/helpers';

  .calendar-box {
    display: inline-grid;
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: repeat(5, 40px);
    border-left: 1px solid $primary;
    border-top: 1px solid $primary;
    outline: none;
  }

  .day {
    background: #e6f7f5;
    color: $primary;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;

    &:hover {
      background: #cceeeb;
    }

    &.focused {
      outline: 1px auto -webkit-focus-ring-color;
      outline-offset: -1px;
    }

    &.disabled,
    &.disabled:hover {
      color: #77777a;
      opacity: 40%;
      background: #e8e8e9;
      border-color: #77777a;
    }

    &.selected {
      background: $primary;
      color: #fff;

      &:hover {
        background: #66cbc4;
        border-color: #66cbc4;
      }
    }
  }
</style>
