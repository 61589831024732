{#if disabled}
  <span data-test="{name}-arrow-{direction}" class="paginator-arrow disabled{className ? ` ${className}` : ''}">
    <Icon name={icon} fw />
  </span>
{:else}
  <a
    data-test="{name}-arrow-{direction}"
    class="paginator-arrow{className ? ` ${className}` : ''}"
    href={null}
    use:onInteract={() => dispatch('interact')}
    use:tip={description}
  >
    <Icon name={icon} fw />
  </a>
{/if}

<script>
  import tip from 'decorators/tip.js'
  import onInteract from 'decorators/on-interact.js'
  import Icon from 'components/Icon.svelte'
  import { createEventDispatcher } from 'svelte'

  export let name
  export let disabled
  export let description
  export let direction

  let className = 'btn-default'
  export { className as class }

  const dispatch = createEventDispatcher()

  $: icon = `chevron-${direction}`
</script>
