<div class="mr2 mb1">
  {#if exportJob.error}
    <div class="text-danger">
      <Icon name="alert-triangle" />
      Exporting {exportJob?.reportType} failed: {exportJob.error}
    </div>
  {:else if exportJob.percentComplete === 100}
    Export {exportJob.reportType} complete.
    {#if exportJob.autoDownload}Downloading…{:else}
      <Btn clearBtnStyling icon="download" title="Download the file" on:click={() => downloadExportJob(exportJob.exportJobId)} stopPropagation
        >Download</Btn
      >
    {/if}
  {:else}
    Exporting {exportJob?.reportType}…
    {#if isCanceling}
      <Spinner class="text-danger small" />
    {:else if exportJob?.percentComplete < 100}
      <Btn clearBtnStyling class="text-danger" icon="close" title="Cancel export" on:click={cancelExportJob} stopPropagation />
    {/if}
    <Progress lowProfile>
      <ProgressBar color="primary" percent={exportJob.percentComplete} />
    </Progress>
  {/if}
</div>

<script>
  import api from 'services/api.js'
  import { downloadExportJob } from 'services/export-job-service.js'
  import Btn from './bootstrap/Btn.svelte'
  import Icon from './Icon.svelte'
  import Progress from './Progress.svelte'
  import ProgressBar from './ProgressBar.svelte'
  import Spinner from './Spinner.svelte'

  export let exportJob

  let isCanceling = false

  async function cancelExportJob() {
    isCanceling = true // leave this set to true, since websocket will confirm canceled after this cancel http request completes...
    try {
      api.exportJob.cancelExportJob({ exportJobId: exportJob.exportJobId }, api.noMonitor)
    } catch {
      isCanceling = false
    }
  }
</script>
