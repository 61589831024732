<div class={className} use:tip={tooltip}>
  {#if startDate == null && endDate != null}
    <span>Anytime before</span>
    <Icon name="calendar" class="color-text-light-red" lg={iconLg} />
    <span data-test="end-date">{dateService.datestamp(endDate)}</span>
  {:else}
    <Icon name="calendar" class="color-text-dark-green" lg={iconLg} />

    {#if startDate === endDate}
      {#if startDate}
        {#if includeOnly}
          <span data-test="start-date">{dateService.datestamp(startDate)} only</span>
        {:else}
          <span data-test="start-date">{dateService.datestamp(startDate)}</span>
        {/if}
      {:else}
        <span>Anytime</span>
      {/if}
    {:else if endDate == null}
      <span data-test="start-date">{dateService.datestamp(startDate)} onwards</span>
    {:else}
      <span data-test="start-date">{dateService.datestamp(startDate)}</span>
      {#if validator.dateAfter(endDate, startDate)}
        <span>–</span>
        <Icon name="calendar" class="color-text-light-red" lg={iconLg} />
        <span data-test="end-date">{dateService.datestamp(endDate)}</span>
      {/if}
    {/if}
  {/if}
</div>

<script>
  import dateService from 'services/date-service.js'
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'
  import tip from 'decorators/tip.js'

  export let startDate
  export let endDate
  export let tooltip = null
  let className = 'inline-flex-row flex-align-center g05'
  export { className as class }
  export let iconLg = false
  export let includeOnly = true
</script>
