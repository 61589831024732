<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={o} labelSelector={o => o.name} />

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    idKey: 'slug',
    type: FilterType.OrgTags,
    label: 'Organization type',
    labelApplied: 'organization type',
    icon: 'building',
    iconClass: 'text-primary',
    optionsEndpoint: 'getOrgTags',
    filterProp: 'tagSlugs',
    filterPropType: 'string',
    canHaveMultiple: true,
    hasDoesntHave: true,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
