<p>
  <Icon name="hospital" class="color-text-orange" /> <strong>{orgName}</strong> is requesting that you confirm the number of rotations you’ll need, and
  release any back to them if you know you won’t need them all so they can allocate them to someone else.
</p>

{#if confirmDesiredMatchCountByDate}
  <p class="em">
    <Icon lg name="calendar" class="color-text-steel" /> You must confirm or release rotations by {dateService.datestamp(
      confirmDesiredMatchCountByDate
    )}.
  </p>
{/if}

<script>
  import dateService from 'services/date-service.js'
  import Icon from 'components/Icon.svelte'

  export let orgName
  export let confirmDesiredMatchCountByDate
</script>
