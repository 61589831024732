// TODO (minor) refactor sockets.js to not use this, then delete it--we could just add methods to subscribe/unsubscribe to the grouped pubsub events
const _handlers = {}

const fire = (event, detail) => {
  if (_handlers[event] == null) return
  _handlers[event].forEach(h => h(detail))
}

const on = (event, handler) => {
  if (_handlers[event] == null) _handlers[event] = [handler]
  else _handlers[event].push(handler)

  return () => off(event, handler)
}

const off = (event, handler) => {
  if (_handlers[event] == null) return
  _handlers[event] = _handlers[event].filter(h => h !== handler)
}

const pubsub = {
  fire,
  on,
  off,
}

export default pubsub
