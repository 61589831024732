<!-- Changing something in here? Consider making a similar change to ShiftForm. -->

<div class="modal-body flex-column g2">
  <div>
    <label> Name </label>
    <div>{shift.name}</div>
  </div>

  <div>
    <label> Description </label>
    <div>{shift.description}</div>
  </div>

  <div>
    <label>Color</label>
    <SelectedColor value={shift.color} dataTest="shift-color" />
  </div>

  <div>
    <label>Maximum {capacity.allowGroups ? 'groups' : 'students / learners'} per day</label>
    <div class="flex-row flex-align-center g05">
      {#if shift.maxMatchCountPerDay}
        <Icon name="hashtag" class="text-gray" />
        <span>
          {pluralCount(capacity.allowGroups ? 'group' : 'student', shift.maxMatchCountPerDay)}
        </span>
      {:else}
        <Icon lg name="infinity" class="text-info" />
        <span>Unlimited</span>
      {/if}
    </div>
  </div>
  {#if capacity.allowGroups}
    <div>
      <label>Maximum students per group</label>
      <div class="flex-row flex-align-center g05">
        {#if shift.maxStudentsInGroup}
          <Icon name="graduation-cap" class="color-text-teal" />
          <span>
            {pluralCount('student', shift.maxStudentsInGroup)}
          </span>
        {:else}
          <Icon lg name="infinity" class="text-info" />
          <span>Unlimited</span>
        {/if}
      </div>
    </div>
  {/if}

  <div class="flex-column g1">
    <label>Restrict total number of days per week that rotations can use:</label>
    {#if shift.matchDayLimitPerWeek}
      <span>Restrict to <strong>{shift.matchDayLimitPerWeek}</strong> days.</span>
    {:else}
      <span>Allow all selected opportunity days to be filled.</span>
    {/if}
  </div>

  <div>
    <label>Time</label>
    {#if shift.startTime}
      <StartAndEndTime startTime={shift.startTime} endTime={shift.endTime} class="flex-row flex-align-center g05" />
    {:else}
      <div class="em small">Not specified</div>
    {/if}
  </div>

  <div>
    <div class="flex-row flex-align-center g05 mb05">
      <label for="locations" class="m0">Location</label>
    </div>

    {#if location}
      <OrgProfilePictureAndName
        size="medium"
        name={location.name}
        profilePicture={location.profilePicture}
        relativeName={location.relativeName}
        formattedAddress={location?.formattedAddress}
      />
    {:else}
      <div class="em small">None specified</div>
    {/if}
  </div>

  <div>
    <label>Schedules</label>
    {#if shift.shiftDays.length === 0}
      <Alert type="info">
        This shift has the same availability as the opportunity itself (<StartAndEndDate
          startDate={capacity.startDate}
          endDate={capacity.endDate}
          class="inline"
        />) because it doesn’t have any schedules.
      </Alert>
    {:else}
      <div>
        {#each shift.shiftDays as shiftDay}
          <ShiftDayView {capacity} {shift} {shiftDay} />
        {/each}
      </div>
    {/if}
  </div>
</div>

<div class="modal-footer">
  <Btn icon="close" on:click={onClose}>Close</Btn>
</div>

<script>
  import { pluralCount } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import SelectedColor from 'components/fields/SelectedColor.svelte'
  import ShiftDayView from 'components/ShiftDayView.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'

  export let shift
  export let capacity
  export let orgs
  export let onClose = _.noop

  $: location = orgs?.[shift.locationId] ?? null
</script>
