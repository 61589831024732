const baseHref = 'https://www.google.com/maps/search/?api=1&query='

export function buildGoogleMapsLink(address) {
  if (address == null) return null
  return address.lat != null && address.long != null
    ? `${baseHref}${address.lat},${address.long}`
    : address.formattedAddress
    ? `${baseHref}${encodeURIComponent(address.formattedAddress)}`
    : null
}
