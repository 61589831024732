{#if canEdit}
  <MediaQuery {...mediaQueries.edit ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="edit"
      title={editServiceTitle}
      dataTest="edit-service-btn-{service.serviceId}"
      href={fromTemplate(viewOrEditHref, service)}>{editServiceLabel}</Btn
    >
  </MediaQuery>
{:else if canView}
  <MediaQuery {...mediaQueries.view ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="eye"
      title={viewServiceTitle}
      dataTest="view-service-btn-{service.serviceId}"
      href={fromTemplate(viewOrEditHref, service)}>{viewServiceLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if canViewUtilization}
  <MediaQuery {...mediaQueries.utilization ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="calendar"
      title={serviceUtilizationTitle}
      dataTest="service-utilization-btn-{service.serviceId}"
      href={fromTemplate(utilizationHref, service)}>{serviceUtilizationLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if canCopy}
  <MediaQuery {...mediaQueries.copy ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="copy"
      title={copyServiceTitle}
      dataTest="copy-service-btn-{service.serviceId}"
      href={fromTemplate(copyHref, service)}>{copyServiceLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if canDelete}
  <MediaQuery {...mediaQueries.delete ?? always}>
    <Btn
      color="outline-danger{className ? ` ${className}` : ''}"
      icon="delete"
      title={deleteServiceTitle}
      dataTest="delete-service-btn-{service.serviceId}"
      on:click={() => onDeleteClicked(service)}>{deleteServiceLabel}</Btn
    >
  </MediaQuery>
{/if}

<hr class="my05" />

{#if canViewStaff}
  <MediaQuery {...mediaQueries.staff ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="staff-settings"
      title={staffTitle}
      dataTest="service-staff-btn-{service.serviceId}"
      href={fromTemplate(staffHref, service)}>{staffLabel}</Btn
    >
  </MediaQuery>
{/if}

{#if show.organization}
  <MediaQuery {...mediaQueries.organization ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="hospital"
      dataTest="service-org-btn-{service.serviceId}"
      href="/orgs/{service.orgId}/{service.orgRelativePath}"
    >
      View organization
    </Btn>
  </MediaQuery>
{/if}

{#if canViewTeam && show.team}
  <MediaQuery {...mediaQueries.team ?? always}>
    <Btn
      color="outline-gray{className ? ` ${className}` : ''}"
      icon="users"
      dataTest="service-team-btn-{service.serviceId}"
      href="/settings/teams/{service.teamRelativePath}"
    >
      View team
    </Btn>
  </MediaQuery>
{/if}

<script context="module">
  const always = { query: 'all' }
</script>

<script>
  import { Permission } from 'config/enums.js'
  import { fromTemplate } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'
  import personaService from 'services/persona-service.js'
  import showDropdowns from 'stores/show-dropdowns.js'

  export let mediaQueries = {}
  export let service
  export let viewOrEditHref
  export let utilizationHref
  export let staffHref
  export let copyHref
  export let onDeleteClicked

  export let viewServiceLabel = 'View service'
  export let viewServiceTitle = null
  export let editServiceLabel = 'Edit service'
  export let editServiceTitle = null
  export let serviceUtilizationLabel = 'View utilization'
  export let serviceUtilizationTitle = null
  export let staffLabel = 'View staff'
  export let staffTitle = null
  export let copyServiceLabel = 'Copy service'
  export let copyServiceTitle = null
  export let deleteServiceLabel = 'Delete service'
  export let deleteServiceTitle = null
  export { className as class }
  let className = null

  $: show = $showDropdowns.serviceGrid
  $: canView = true
  $: canViewUtilization = personaService.hasStaffPermission(Permission.ViewRotationList, service.orgId, service.teamId)
  $: canViewStaff = personaService.hasStaffPermission(Permission.ViewStaff, service.orgId, service.teamId, { anyTeam: true })
  $: canViewTeam = service.teamId && personaService.hasStaffPermission(Permission.ViewTeams, service.orgId, service.teamId)
  $: canEdit = personaService.canEditService(service)
  $: canDelete = canEdit
  $: canCopy = canEdit // TODO: Allow them to to this if they're an service/opportunity manager
</script>
