<div class="flex-row flex-align-center">
  <ProfilePic name={user.name} initials={user.initials} src={user.profilePicture} id={user.userId} small class="mr1" />
  <div>
    {user.name}
    <small>@{user.userName}</small>
  </div>
</div>

<script>
  import ProfilePic from 'components/ProfilePic.svelte'
  export let user
</script>
