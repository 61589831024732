{#if show}
  <Icon name="user-xmark" class="text-gray" {title} dataTest="inactive-user-icon" />
{/if}

<script>
  import Icon from 'components/Icon.svelte'

  export let show
  export let title = 'Inactive user'
</script>
