<div class="pull-right">
  {#if overfilledShifts.length > 0}
    <Icon
      name="alert-triangle"
      class="text-danger"
      title="{toFriendlyList(overfilledShifts.map(s => s.shiftName))} shift{overfilledShifts.length === 1 ? '' : 's'} will overfill."
    />
  {/if}
  {#if overfilledMaybeShifts.length > 0}
    <Icon
      name="alert-triangle"
      class="text-warning"
      title="{toFriendlyList(overfilledMaybeShifts.map(s => s.shiftName))} shift{overfilledMaybeShifts.length === 1 ? '' : 's'} might overfill."
    />
  {/if}
  {#if anyBusy}
    <Icon name="calendar-day" class="text-warning" dataTest="is-double-book" title={busyMsg} />
  {/if}
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import { toFriendlyList } from 'services/string-utils.js'

  export let busy
  export let buckets

  let overfilledShifts = []
  let overfilledMaybeShifts = []

  $: buckets, setOverFillWarnings()
  $: anyBusy = busy?.length > 0
  $: busyMsg =
    busy == null || busy.length === 0
      ? null
      : `${toFriendlyList(busy.map(otherMatch => otherMatch.name))} ${
          busy.length === 1 ? 'has' : 'have'
        } another rotation on this day. See above for details.`

  function setOverFillWarnings() {
    overfilledShifts = []
    overfilledMaybeShifts = []
    for (const shiftId in buckets) {
      const bucket = buckets[shiftId]
      if (bucket.overfilled) overfilledShifts.push(bucket)
      if (bucket.overfilledMaybe > 0) overfilledMaybeShifts.push(bucket)
    }
  }
</script>
