<div class="must-accept-eula">
  <p>
    {#if $user.hasAcceptedAnyEula}
      We have updated our
      <strong>End User License Agreement.</strong>
      To continue using Clinician Nexus, you must accept this agreement.
    {:else}
      To use Clinician Nexus, you must accept our
      <strong>End User License Agreement.</strong>
    {/if}
  </p>

  <div class="eula-scroller">
    <Eula />
  </div>

  <Btn dataTest="accept-eula" class="btn-primary" on:click={accept}>I accept</Btn>
  <Btn class="btn-default" on:click={reject}>I do not accept</Btn>
</div>

{#if rejected}
  <Modal on:close={unreject}>
    <div class="p2">
      <strong>If you do not accept the End User License Agreement, you will not be able to use Clinician Nexus.</strong>
    </div>

    <div class="modal-footer">
      <a class="btn btn-primary" href="{environment.linkApi}/api/home/logout" noroute>
        <Icon name="signout" />
        Log out
      </a>
      <Btn class="btn-default" on:click={unreject}>Cancel</Btn>
    </div>
  </Modal>
{/if}

<script>
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Eula from 'components/Eula.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import user from 'stores/user.js'
  import environment from 'services/environment.js'

  let rejected = false

  function accept() {
    api.eula.accept().then(response => {
      $user = response
    })
  }

  function reject() {
    rejected = true
  }

  function unreject() {
    rejected = false
  }
</script>

<style>
  .must-accept-eula {
    padding: 20px;
  }

  .eula-scroller {
    padding: 20px;
    background: #efefef;
    margin-bottom: 20px;
    overflow: auto;
    max-height: 300px;
  }
</style>
