Repeats
{#if repeatUnit === RepeatUnit.Daily && repeatEvery === 1}
  everyday
{:else}
  every
  {#if repeatUnit === RepeatUnit.Daily}
    {pluralCount('day', repeatEvery, 'omitOne')}
  {:else if repeatUnit === RepeatUnit.Weekly}
    {pluralCount('week', repeatEvery, 'omitOne')}
    on
  {:else if repeatUnit === RepeatUnit.Monthly}
    {pluralCount('month', repeatEvery, 'omitOne')}
    on the
  {/if}
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import { RepeatUnit } from 'config/enums.js'

  export let repeatSchedule

  $: repeatUnit = repeatSchedule.repeatUnit
  $: repeatEvery = repeatSchedule.repeatEvery
</script>
