{#if show}
  <Btn href={fromTemplate(href, capacity) + '/' + capacityGuest.guestOrgId} class={className} dataTest="start-release-matches-btn">
    <Icon lg name="slots-release" {style} />
    Release
    {#if capacityGuest.guaranteedMatchCountRemaining === 1}
      1 guaranteed rotation
    {:else}
      some or all {capacityGuest.guaranteedMatchCountRemaining} guaranteed rotations
    {/if}
    {#if !className?.includes('btn-sm') && capacity.startDate && capacity.endDate}
      for {capacity.startDate}–{capacity.endDate}
    {/if}
  </Btn>
{/if}

<script>
  import { canConfirmAndReleaseCapacityGuestMatches } from 'services/capacity-usage.js'
  import { fromTemplate } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'

  export let href
  export let capacity
  export let capacityGuest
  let className = 'btn btn-default'
  export { className as class }

  $: style = className?.includes('btn-default') ? '--path-0-color: #444; --path-1-color: var(--user-steel)' : null
  $: show = canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest, false)
</script>
