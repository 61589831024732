{#if relativeName && relativeName !== name}
  <OverflowEllipsis wrapper={useOverflowEllipsis ? 'div' : null}>
    <Breadcrumbs
      crumbs={getParentNamesArray(relativeName)}
      current={null}
      hrefSelector={null}
      class="m0 p0{useOverflowEllipsis ? ' text-overflow-ellipsis' : ''}"
      liClass="inline"
      itemClass="small em"
      iconStyle="opacity: 0.6; margin-bottom: 1px"
      iconSize="xxs"
    />
  </OverflowEllipsis>
{/if}

{#if useOverflowEllipsis}
  <OverflowEllipsis wrapper="div" class={nameClass}>
    {name}
  </OverflowEllipsis>
{:else if nameClass || href}
  <svelte:element this={href ? 'a' : 'span'} {href} class={nameClass}>{partialDesc(name, maxNameLength)}</svelte:element>
{:else}
  {name}
{/if}

<script>
  import { getParentNamesArray } from 'services/orgs-service.js'
  import { partialDesc } from 'services/string-utils.js'
  import Breadcrumbs from 'components/Breadcrumbs.svelte'
  import OverflowEllipsis from 'components/OverflowEllipsis.svelte'

  export let relativeName
  export let name
  export let nameClass
  export let maxNameLength
  export let useOverflowEllipsis
  export let href
</script>
