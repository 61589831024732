{#if stepSubmission.canCompleteNow}
  <Btn
    icon={stepSubmission.completed ? 'undo' : 'check'}
    class="btn-sm btn-{stepSubmission.completed ? 'warning' : 'primary'}"
    on:click={onClick}
    {loading}
  >
    Mark
    {stepSubmission.completed ? 'incomplete' : 'complete'}
  </Btn>
  {#if collectExpirationSuggestion}
    <StepSubmissionSuggestedExpirationDateModal bind:stepSubmission onSubmit={dispatchAction} />
  {/if}
{/if}

<script>
  import StepSubmissionSuggestedExpirationDateModal from 'components/StepSubmissionSuggestedExpirationDateModal.svelte'
  import { shouldCollectVerificationExpirationSuggestion } from 'components/StepSubmissionSuggestedExpirationDate.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import { StepAction } from 'config/enums.js'
  import api from 'services/api.js'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction

  let loading
  let collectExpirationSuggestion = false

  async function onClick() {
    if (!stepSubmission.completed && shouldCollectVerificationExpirationSuggestion(stepSubmission)) collectExpirationSuggestion = true
    else await dispatchAction()
  }

  async function dispatchAction() {
    collectExpirationSuggestion = false
    loading = true
    try {
      await onAction({
        type: StepAction.ToggleCheck,
        invoke: setStepCompleted,
      })
    } finally {
      loading = false
    }
  }

  function setStepCompleted(stepSubmissions) {
    return api.step.setStepsCompleted(
      {
        completed: !stepSubmission.completed,
        endDateOverride,
        startDateOverride,
      },
      {
        stepSubmissions,
        verificationExpirationDateSuggestion: stepSubmission.verificationExpirationDateSuggestion,
      },
      api.noMonitor
    )
  }
</script>
