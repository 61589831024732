<ProfilePic src={user.profilePicture} {title} name={user.name} initials={user.initials} id={user.userId} class="mr1 mb1" pseudoDisabled={deleted} />

<div class="hide">
  <div class="text-left" bind:this={tooltipElem}>
    <strong class="strongish">{user.name}</strong>

    {#if deleted}
      <div class="flex-row flex-align-center g05">
        <InactiveUserIcon show />
        Inactive user
      </div>
    {/if}

    {#if showStudentInfo && (user.studentYear || user.discipline || user.otherDiscipline)}
      <div>
        {toStudentYear(user.studentYear)}
        {user.discipline || user.otherDiscipline || 'student'}
        {#if school}
          @ <a href={school.url}>{school.name}</a>
        {/if}
      </div>
    {/if}

    {#if roles?.length}
      <div>
        <FriendlyList items={roles} max={Infinity} />
        {#if school}
          @ <a href={school.url}>{school.name}</a>
        {/if}
      </div>
    {/if}
  </div>
</div>

<script>
  import { toStudentYear } from 'services/formatters.js'
  import FriendlyList from 'components/FriendlyList.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import InactiveUserIcon from 'components/InactiveUserIcon.svelte'

  export let user
  export let roles = null
  export let school = null
  export let showStudentInfo = true // if a user has student info filled in, but is not a student on this match, don't confusingly show their student info

  let tooltipElem
  $: title = tooltipElem ? { content: tooltipElem, options: { theme: 'light-gray-scrollable', interactive: true } } : null
  $: deleted = user.deletedDateTime != null
</script>
