<InputSelect
  {name}
  {options}
  bind:value
  bind:selected
  {loading}
  {multiple}
  filterable={options?.length > 5}
  filterStringSelector={o => [o.name]}
  placeholder={placeholder ?? `Team${multiple ? 's' : ''}`}
  max={maxVisible}
  {title}
  {tooltipPlacement}
  on:change
  contentClass="flex-row flex-align-center {profilePicOnly ? 'g05' : 'g1'}"
  valueSelector={t => t.teamId}
  bind:this={inputSelect}
  showOthersTip
  othersTipClass="flex-column g05"
  let:option={team}
>
  <TeamPickerSlot {team} {nullTeamLabel} showOrgRelativeName profilePicProps={{ medium: true }} />
  <TeamPickerSlot {team} {nullTeamLabel} slot="label" {profilePicOnly} profilePicProps={{ xs: true }} />
  <TeamPickerSlot {team} {nullTeamLabel} slot="labelOther" showOrgRelativeName profilePicProps={{ medium: true }} />
</InputSelect>

<script>
  import InputSelect from 'components/fields/InputSelect.svelte'
  import TeamPickerSlot from 'components/TeamPicker.Slot.svelte'

  export let name = 'simple-team-picker'
  export let teams
  export let value
  export let selected = null // for binding up to parent
  export let loading = false
  export let placeholder
  export let title
  export let tooltipPlacement
  export let addNullTeamOption = false
  export let nullTeamLabel = 'All teams'
  export let multiple = false
  export let maxVisible = 17

  $: sortedOptions =
    teams?.sort((t1, t2) => {
      if (t1.name > t2.name) return 1
      if (t1.name < t2.name) return -1
      return 0
    }) ?? []
  $: options = addNullTeamOption ? [{ teamId: null, name: nullTeamLabel }, ...sortedOptions] : sortedOptions

  $: profilePicOnly = multiple ? value?.length >= 4 : false

  let inputSelect
  export function focus() {
    inputSelect?.focus?.()
  }

  export function open() {
    inputSelect?.open()
  }
</script>
