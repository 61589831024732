{#if users.length}
  <div class="flex-column g05">
    {#each users as user (user.userId)}
      {@const deleted = user.deletedDateTime != null}
      <a href={null} on:click={() => startImpersonating(user)} class="impersonate-btn border-transparent flex-row flex-align-center g05 rounded-lg">
        <UserProfilePicAndName
          {user}
          profilePicSmall
          tagName="h4"
          headerClass="leading-none m0 normal"
          pronounSetStyle="font-size: 14px; display: inline"
          tooltip={deleted ? 'This user is inactive; impersonating inactive users is not supported.' : null}
        />
        <div class="flex-grow" />
        <Icon name="impersonate" class={deleted ? 'text-gray ' : 'text-purple'} />
        <Icon name="chevron-right" class={deleted ? 'text-gray ' : 'text-purple'} />
      </a>
    {/each}
  </div>
{/if}

<script>
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'
  import Icon from 'components/Icon.svelte'

  export let users
  export let startImpersonating
</script>

<style lang="scss">
  .impersonate-btn {
    padding: 2px;
    text-decoration: none !important;

    &:hover,
    &:focus {
      border-color: var(--theme-purple) !important;
    }
  }
</style>
