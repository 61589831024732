{#if loading && iconRight == null}
  <Spinner />
{:else if icon}
  <Icon name={icon} {...iconProps} />
{/if}
<slot />
{#if loading && iconRight}
  <Spinner />
{:else if iconRight}
  <Icon name={iconRight} {...iconRightProps} />
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import Spinner from 'components/Spinner.svelte'

  export let loading
  export let icon
  export let iconRight
  export let iconProps
  export let iconRightProps
</script>
