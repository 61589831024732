{#if editing}
  <FormGroup>
    <InputToggle name="open-for-scheduling" bind:checked={filter.openForScheduling}>
      {#if filter.openForScheduling}
        <Icon name="calendar-check" lg class="text-success" />
      {:else}
        <Icon name="calendar-xmark" lg class="text-danger" />
      {/if}
      Open for scheduling
    </InputToggle>
  </FormGroup>
{:else if filter.openForScheduling}
  <Icon name="calendar-check" lg class="text-success" />
  Open for scheduling
{:else}
  <Icon name="calendar-xmark" lg class="text-danger" />
  <strong>NOT</strong> open for scheduling
{/if}

<script context="module">
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import { FilterType } from 'config/enums.js'

  const label = 'Open for scheduling'

  export const meta = {
    type: FilterType.CapacityOpenForScheduling,
    label,
    editLabel: null,
    hideLabelIcon: true,
    icon: 'calendar-check',
    iconClass: 'text-success',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.openForScheduling)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.openForScheduling = reader.readBool()
      return config
    },

    create() {
      return { openForScheduling: true }
    },

    validate() {
      return true
    },
  }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'

  export let filter
  export let editing = false
  // export let appliedMeta
</script>
