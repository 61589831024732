<Badge
  class="mr05"
  pulsateColor={tooManyStudents ? 'warning' : null}
  label="{course.studentCount}{course.studentCountGoal ? ` / ${course.studentCountGoal}` : ''}"
  tooltip={tooManyStudents
    ? 'Too many students. Either remove some or increase the course’s target number of students.'
    : course.studentCountGoal
    ? `${course.studentCount} of ${course.studentCountGoal} target number of students.`
    : ''}
/>

<script>
  import Badge from './Badge.svelte'
  export let course

  $: tooManyStudents = course.studentCountGoal && course.studentCount > course.studentCountGoal
</script>
