import { Permission, DashboardViewCategory, FeatureType } from 'config/enums.js'

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewConsortiumRelations,
  featureTypes: [FeatureType.Consortium],
  componentName: 'ConsortiumRelations',
  name: 'Consortium relations',
  icon: 'chart-pie',
  color: 'purple',
  criteria: {
    chordType: 'SankeyDiagram',
    schools: [],
    orgs: [],
  },
  criteriaConfig: {
    chordType: { name: 'chordType' },
    schools: { name: 'schools', method: 'readArray' },
    orgs: { name: 'orgs', method: 'readArray' },
  },

  waitFor: { method: 'GET', route: /\/consortiums\/[0-9]+\/relations/i },
  testFor: ['chords'],
}
