{#if show}
  <div class="filter-input{className ? ` ${className}` : ''}" {style}>
    <span class="input-group{inputGroupClass ? ` ${inputGroupClass}` : ''}">
      {#if icon}
        {#if loading}
          <span class="input-group-addon">
            <Spinner fw />
          </span>
        {:else}
          <a class="input-group-addon" href={null} on:click={fireChangeAndClick} tabindex="-1">
            <Icon name={icon} fw />
          </a>
        {/if}
      {/if}
      <InputText bind:this={boxComponent} bind:value={text} on:input={fireChangedDebounced} {placeholder} name={id} {autofocus} {autocomplete} />

      {#if text?.length > 0}
        <a class="input-group-addon" on:click={clearBox} href={null} use:tip={'Clear'} tabindex="-1" data-test={id ? `${id}-clear-btn` : null}>
          <Icon name="close" fw />
        </a>
      {/if}
    </span>
  </div>
{/if}

<script>
  import { createEventDispatcher } from 'svelte'
  import Icon from 'components/Icon.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Spinner from './Spinner.svelte'
  import tip from 'decorators/tip.js'

  export { className as class }
  export let style = null
  export let inputGroupClass = null
  export let autofocus = false
  export let autocomplete = null
  export let icon = 'search'
  export let id = null
  export let loading = false
  export let placeholder = 'Search'
  export let show = true
  export let text = null

  let className = null
  let boxComponent

  const dispatch = createEventDispatcher()
  const fireChangedDebounced = _.debounce(fireChange, 300)

  function clearBox() {
    text = ''
    focus()
    fireChange()
  }

  function fireChange() {
    dispatch('change')
  }

  function fireChangeAndClick() {
    fireChange()
    dispatch('click')
  }

  export function focus() {
    boxComponent?.focus?.()
  }
</script>

<style>
  .filter-input {
    min-width: 200px;
  }
</style>
