import { Permission, DashboardViewCategory, FeatureType } from 'config/enums.js'

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewPerformanceAndUtilizationReport,
  featureTypes: [FeatureType.HealthInstitution, FeatureType.TeachingInstitution],
  componentName: 'PerformanceAndUtilizationReport',
  name: 'Performance and utilization report',
  icon: 'analytics',
  criteria: {},

  waitFor: { method: 'GET', route: /\/monitor\/stats/i },
  testFor: ['metrics', 'utilization-report'],
}
