<div class="rating" tabindex="0" on:keydown={onKeyDown}>
  {#each options as i (i)}
    <div
      class="rating-option"
      class:disabled
      data-test="rating-{i}"
      on:click={() => setValue(i)}
      use:onMayInteract={e => setHovered(e.focusIn || e.mouseIn ? i : null)}
    >
      <Icon
        name="star{value >= i ? '-filled' : disabled ? '-thin' : ''}"
        class={!disabled && (value >= i || hovered >= i) ? 'text-warning' : ''}
        fw
        lg
        dataTest="rating-{i}-icon"
      />
    </div>
  {/each}
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import Key from 'config/key'
  import onMayInteract from 'decorators/on-may-interact.js'

  export let value = null
  export let disabled = false

  const options = [1, 2, 3, 4, 5]
  let hovered = null

  function setValue(i) {
    if (disabled) return
    value = i
  }

  function setHovered(i) {
    if (disabled) return
    hovered = i
  }

  function onKeyDown(e) {
    if (disabled) return
    const key = e.which || e.keyCode
    switch (key) {
      case Key.Left:
        if (value > 0) setValue(value - 1)
        break
      case Key.Right:
        if (value < options.length) setValue(value + 1)
        break
    }
  }
</script>

<style lang="scss">
  .rating {
    white-space: nowrap;

    .rating-option {
      display: inline-block;
      cursor: pointer;
    }

    .rating-option.disabled {
      cursor: var(--disabled-cursor, not-allowed);
      opacity: var(--disabled-opacity, 0.65);
    }
  }
</style>
