{#if fileName}
  <Modal on:close={onClose} class={className}>
    <h3 slot="title" data-test="title">Document library</h3>

    <div class="modal-body">
      Do you want to save <strong>{fileName}</strong> in your <Icon lg name="files" class="text-primary" /> Document library?
    </div>

    <div class="modal-footer">
      <Btn class="btn-primary" on:click={_onSaveInDocumentLibrary} dataTest="btn-yes" icon="check" loading={confirming}>Save in Document library</Btn>
      <Btn class="btn-default" on:click={_onUploadHereOnly} dataTest="btn-no" loading={uploadingHere}>Upload here only</Btn>
    </div>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'

  export let fileName
  export let className
  export let onSaveInDocumentLibrary
  export let onUploadHereOnly
  export let onClose

  let confirming = false
  export let uploadingHere = false

  async function _onSaveInDocumentLibrary() {
    confirming = true
    try {
      await onSaveInDocumentLibrary()
      fileName = null
    } finally {
      confirming = false
    }
  }

  async function _onUploadHereOnly() {
    uploadingHere = true
    try {
      await onUploadHereOnly()
      fileName = null
    } finally {
      uploadingHere = false
    }
  }
</script>
