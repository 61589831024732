const blobUrlPromises = {}
function getBlobUrlPromise(url) {
  const cache = blobUrlPromises[url]
  if (cache) return cache
  return blobUrlPromises[url] = fetch(url)
    .then(response => {
      if (!response.ok) throw response
      return response.blob()
    })
    .then(blob => URL.createObjectURL(blob))
}

function getSrcPromise(src) {
  return new Promise((resolve, reject) => {
    Promise.resolve(src).then(url => {
      getBlobUrlPromise(url)
        .then(resolve)
        .catch(reject)
    })
  })
}

export { getSrcPromise }